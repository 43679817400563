import React, { FC, useState } from "react";
import { useLocalState } from "../../graphql/hooks";
import {
    Button,
    ButtonGroup,
    Container,
    FormControl,
    InputGroup,
    OverlayTrigger,
    Popover
} from "react-bootstrap";
import styles from "./DetectedSets.module.scss";
import cx from "classnames";

interface Props {
    title?: React.ReactNode;
    allSets?: string[];
    usedSets?: string[];
    onToggle: (set: string) => void;
}

export const DetectedSetsPopover: FC<Props> = ({
    title = "Sets",
    allSets,
    usedSets,
    onToggle
}) => {
    const [search, setSearch] = useState("");
    const { useDarkMode } = useLocalState();
    const setButtons = allSets?.flatMap(set =>
        set.toLowerCase().includes(search.toLowerCase()) ? (
            <Button
                key={set}
                onClick={() => onToggle(set)}
                className={cx(`btn-sm outline-none ${styles.noWrap}`, {
                    "btn-primary": usedSets?.includes(set),
                    "btn-secondary": !usedSets?.includes(set)
                })}
            >
                {set}
            </Button>
        ) : (
            []
        )
    );

    if (!setButtons) {
        return null;
    }

    return (
        <OverlayTrigger
            trigger="click"
            placement="right"
            rootClose
            onToggle={() => setSearch("")}
            overlay={
                <Popover
                    id="popover-sets"
                    className={cx(
                        styles.largePopover,
                        useDarkMode ? styles.popoverDark : ""
                    )}
                >
                    <Popover.Header
                        style={{
                            backgroundColor: "#4c4c4c",
                            color: "white",
                            border: "none"
                        }}
                    >
                        <strong>Detected Sets</strong>
                    </Popover.Header>
                    <Popover.Body>
                        <InputGroup className={styles.stickySearch}>
                            <InputGroup.Text>Search</InputGroup.Text>
                            <FormControl
                                placeholder="Search"
                                value={search}
                                onChange={(event: any) => {
                                    setSearch(event.target.value);
                                }}
                            />
                        </InputGroup>
                        <ButtonGroup
                            as={Container}
                            size="sm"
                            vertical
                            className="m-0 p-0"
                        >
                            {setButtons}
                        </ButtonGroup>
                    </Popover.Body>
                </Popover>
            }
        >
            <Button className="no-border-button pt-1 pb-1">{title}</Button>
        </OverlayTrigger>
    );
};
