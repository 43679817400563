import React, { useCallback } from "react";
import { ApolloClient, useQuery, useSubscription } from "@apollo/client";
import { TASK_READY_FUNNEL_SUBSCRIPTION } from "../../graphql/subscriptions";
import {
    AnalyticsLoadingState,
    AnalyticsQueryType,
    AnalyticsStateData,
    AthenaDatasetResult,
    AthenaQueryResult,
    AthenaTaskQueryResult,
    MeData
} from "../../models/types";
import { GET_LOCAL_ANALYTICS_STATE, GET_ME } from "../../graphql/queries";
import { AnalyticsPlayground } from "../pages/AnalyticsPlayground";

export const AnalyticsSubscriptions = () => {
    const { data: { me } = {} } = useQuery<MeData>(GET_ME, {
        fetchPolicy: "cache-only"
    });

    const updateAnalyticsResult = useCallback(
        (
            client: ApolloClient<any>,
            taskReadyNotification: AthenaTaskQueryResult
        ) => {
            const updateSubQueryResult = (
                client: ApolloClient<any>,
                data: AnalyticsStateData,
                subQueryResult: AthenaQueryResult
            ) => {
                if (!data.analyticsState.analyticsTask) {
                    return;
                }
                const currentTask = JSON.parse(
                    JSON.stringify(data.analyticsState.analyticsTask)
                );
                client.writeQuery<AnalyticsStateData, AnalyticsStateData>({
                    query: GET_LOCAL_ANALYTICS_STATE,
                    data: {
                        analyticsState: {
                            ...data.analyticsState,
                            analyticsTask: {
                                ...currentTask,
                                subQueries: {
                                    ...currentTask.subQueries,
                                    completedCount:
                                        subQueryResult.completedCount
                                }
                            }
                        }
                    }
                });
            };

            const { success, taskId, queryType, results, allComplete } =
                taskReadyNotification as AthenaTaskQueryResult;
            if (success) {
                const data = client.readQuery<AnalyticsStateData>({
                    query: GET_LOCAL_ANALYTICS_STATE
                });
                if (data && data.analyticsState?.analyticsTask !== undefined) {
                    if (data.analyticsState.analyticsTask.taskId === taskId) {
                        if (results.length > 0) {
                            switch (queryType) {
                                case AnalyticsQueryType.AllCtr: {
                                    client.writeQuery<
                                        AnalyticsStateData,
                                        AnalyticsStateData
                                    >({
                                        query: GET_LOCAL_ANALYTICS_STATE,
                                        data: {
                                            analyticsState: {
                                                ...data.analyticsState,
                                                analyticsTask: undefined,
                                                allCtrAnalyticsResults:
                                                    results as AthenaQueryResult[]
                                            }
                                        }
                                    });
                                    break;
                                }
                                case AnalyticsQueryType.Funnel: {
                                    if (!allComplete) {
                                        const result =
                                            results[0] as AthenaQueryResult;
                                        updateSubQueryResult(
                                            client,
                                            data,
                                            result
                                        );
                                    } else {
                                        client.writeQuery<
                                            AnalyticsStateData,
                                            AnalyticsStateData
                                        >({
                                            query: GET_LOCAL_ANALYTICS_STATE,
                                            data: {
                                                analyticsState: {
                                                    ...data.analyticsState,
                                                    analyticsTask: undefined,
                                                    analyticsLoadingState:
                                                        AnalyticsLoadingState.Ready,
                                                    funnelAnalyticsResults:
                                                        // @ts-ignore
                                                        results as AthenaDatasetResult[]
                                                }
                                            }
                                        });
                                    }
                                    break;
                                }
                                case AnalyticsQueryType.DaySelectionFunnel:
                                case AnalyticsQueryType.TimeAverages:
                                case AnalyticsQueryType.TimeDistribution:
                                case AnalyticsQueryType.HeatmapCombined:
                                case AnalyticsQueryType.HeatmapPortrait:
                                case AnalyticsQueryType.HeatmapLandscape:
                                    const result =
                                        results[0] as AthenaQueryResult;
                                    updateSubQueryResult(client, data, result);
                                    break;
                                default:
                                    console.log(
                                        "[DEBUG] Analytics mode for " +
                                            queryType +
                                            " not implemented"
                                    );
                                    break;
                            }
                        }
                    }
                }
            }
        },
        []
    );

    useSubscription(TASK_READY_FUNNEL_SUBSCRIPTION, {
        variables: { userId: me?.id },
        skip: me === undefined,
        onData: ({
            data: { data: { taskReadyFunnelNotification } = {} },
            client
        }) => {
            console.log(
                "[DEBUG] taskReadyFunnelNotification ",
                taskReadyFunnelNotification
            );
            updateAnalyticsResult(client, taskReadyFunnelNotification);
        }
    });

    return <AnalyticsPlayground />;
};
