import React, { FC, useCallback, useEffect, useState } from "react";
import { validateBuildSetName } from "../../../common/Helpers";
import { useLazyQuery } from "@apollo/client";
import { ExampleNameData, ExampleNameVariables } from "../../../models/types";
import { GET_EXAMPLE_NAME } from "../../../graphql/queries";
import { useParams } from "react-router-dom";
import cx from "classnames";

interface Props {
    namingScheme: string;
}

export const NameTemplatePreview: FC<Props> = ({ namingScheme }) => {
    const { revisionId } = useParams<{
        revisionId: string;
    }>();

    const [currentNamingSchema, updateCurrentNamingSchema] =
        useState(namingScheme);
    const [timerId, updateTimerId] = useState<NodeJS.Timeout | undefined>(
        undefined
    );
    const [getExampleName, { data }] = useLazyQuery<
        ExampleNameData,
        ExampleNameVariables
    >(GET_EXAMPLE_NAME, { fetchPolicy: "network-only" });

    const updateBuildJobsData = useCallback(() => {
        getExampleName({
            variables: {
                nameTemplate: namingScheme,
                revisionId: Number(revisionId)
            }
        });
    }, [getExampleName, namingScheme, revisionId]);

    useEffect(() => {
        updateBuildJobsData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (currentNamingSchema !== namingScheme) {
            if (timerId) {
                clearTimeout(timerId);
            }
            const timer = setTimeout(() => {
                updateBuildJobsData();
            }, 1000);
            updateTimerId(timer);
            updateCurrentNamingSchema(namingScheme);
        }

        return () => {
            if (timerId) {
                clearTimeout(timerId);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [namingScheme, currentNamingSchema]);

    const exampleName = data && data.getExampleName ? data.getExampleName : "";
    const isValid = validateBuildSetName(exampleName);

    return (
        <>
            {!isValid && exampleName.length > 0 ? (
                <>
                    <small className="text-danger">
                        Invalid characters detected!
                    </small>
                    <small className="ms-2 text-success">
                        Allowed: a-zA-ZäÄöÖåÅ 0-9_-.&()[]
                    </small>
                </>
            ) : null}
            <p
                className={cx(
                    { "text-primary": isValid },
                    { "text-danger": !isValid }
                )}
            >
                <span className="text-inverse ms-1 me-1">
                    Name Template Preview:{" "}
                </span>
                {exampleName}
            </p>
        </>
    );
};
