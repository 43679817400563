import { useQuery } from "@apollo/client";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC, SyntheticEvent } from "react";
import { Button } from "react-bootstrap";
import { GET_ANALYTICS_NETWORK_BUILDS } from "../../../graphql/queries";
import { BatchBuild } from "../../../models/types";

interface Props {
    build: BatchBuild;
    isInFunnel: boolean;
    toggleFunnelSelection: () => void;
}

const AnalyticsHistoryFunnelToggleImplementation: FC<Props> = ({
    build,
    toggleFunnelSelection,
    isInFunnel
}) => {
    const { data } = useQuery(GET_ANALYTICS_NETWORK_BUILDS, {
        variables: {
            buildId: build.id
        }
    });

    if (!data || data.analyticsNetworkBuilds?.length === 0) {
        return null;
    }

    return (
        <Button
            className="btn-sm me-1"
            onClick={(event: SyntheticEvent) => {
                event.stopPropagation();
                toggleFunnelSelection();
            }}
            variant={isInFunnel ? "analytics" : "secondary"}
        >
            <FontAwesomeIcon icon={faFilter} size="1x" />
        </Button>
    );
};

export const AnalyticsHistoryFunnelToggle = React.memo(
    AnalyticsHistoryFunnelToggleImplementation
);
