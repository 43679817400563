import React, { useState, useEffect, useRef } from "react";
import { Dropdown, Col, Row } from "react-bootstrap";
import { SortingMode, IconClickHandlerType } from "../../models/types";
import { useApolloClient, useQuery } from "@apollo/client";
import { GET_LOCAL_SORTING_STATE } from "../../graphql/queries";
import { replaceSortingState, resetSortingState } from "../../common/Helpers";
import { updateSortingState } from "../../common/Helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faRotateLeft,
    faSortUp,
    faSortDown,
    faEllipsisVertical
} from "@fortawesome/free-solid-svg-icons";
import { SortingButton } from "../buttons/SortingButton";
import styles from "../dropdowns/TitleDropdown.module.scss";

interface TitleDropdownProps {
    title: string;
    sortingModes: SortingMode[];
}

export const TitleDropdown: React.FC<TitleDropdownProps> = ({
    title,
    sortingModes
}) => {
    const client = useApolloClient();
    const { data } = useQuery(GET_LOCAL_SORTING_STATE);
    const [activeSorts, setActiveSorts] = useState<SortingMode[]>([]);
    const labels = ["1 Products", "2 Projects", "3 Revisions"];

    useEffect(() => {
        if (data && data.sortingState) {
            const sortingPriorities = data.sortingState.sortingPriorities || [];
            setActiveSorts(sortingPriorities);
        }
    }, [data]);

    const renderSortLabel = (mode: SortingMode): JSX.Element => {
        switch (mode) {
            case SortingMode.NameAscending:
                return <span>Title</span>;
            case SortingMode.NameDescending:
                return <span>Title</span>;
            case SortingMode.DateAscending:
            case SortingMode.DateDescending:
                return <span>Date</span>;
            case SortingMode.CtrAscending:
            case SortingMode.CtrDescending:
                return (
                    <span>
                        CTR<sup className={styles.superscript}>*</sup>
                    </span>
                );
            case SortingMode.UserCtrAscending:
            case SortingMode.UserCtrDescending:
                return <span>CTR</span>;
            case SortingMode.ImpressionsAscending:
            case SortingMode.ImpressionsDescending:
                return (
                    <span>
                        Impressions<sup className={styles.superscript}>*</sup>
                    </span>
                );
            case SortingMode.UserImpressionsAscending:
            case SortingMode.UserImpressionsDescending:
                return <span>Impressions</span>;
            default:
                return <span>Default mode</span>;
        }
    };

    const renderSortIcon = (
        mode: SortingMode,
        iconClickHandler: IconClickHandlerType
    ): JSX.Element => {
        const iconStyleUp = {
            marginTop: "0.4rem"
        };
        const iconStyleDown = {
            marginBottom: "0.2rem"
        };

        switch (mode) {
            case SortingMode.NameAscending:
                return (
                    <FontAwesomeIcon
                        icon={faSortUp}
                        style={iconStyleUp}
                        onClick={iconClickHandler(mode)}
                    />
                );
            case SortingMode.NameDescending:
                return (
                    <FontAwesomeIcon
                        icon={faSortDown}
                        style={iconStyleDown}
                        onClick={iconClickHandler(mode)}
                    />
                );
            case SortingMode.DateAscending:
                return (
                    <FontAwesomeIcon
                        icon={faSortUp}
                        style={iconStyleUp}
                        onClick={iconClickHandler(mode)}
                    />
                );
            case SortingMode.DateDescending:
                return (
                    <FontAwesomeIcon
                        icon={faSortDown}
                        style={iconStyleDown}
                        onClick={iconClickHandler(mode)}
                    />
                );
            case SortingMode.CtrAscending:
                return (
                    <FontAwesomeIcon
                        icon={faSortUp}
                        style={iconStyleUp}
                        onClick={iconClickHandler(mode)}
                    />
                );
            case SortingMode.CtrDescending:
                return (
                    <FontAwesomeIcon
                        icon={faSortDown}
                        style={iconStyleDown}
                        onClick={iconClickHandler(mode)}
                    />
                );
            case SortingMode.UserCtrAscending:
                return (
                    <FontAwesomeIcon
                        icon={faSortUp}
                        style={iconStyleUp}
                        onClick={iconClickHandler(mode)}
                    />
                );
            case SortingMode.UserCtrDescending:
                return (
                    <FontAwesomeIcon
                        icon={faSortDown}
                        style={iconStyleDown}
                        onClick={iconClickHandler(mode)}
                    />
                );
            case SortingMode.ImpressionsAscending:
                return (
                    <FontAwesomeIcon
                        icon={faSortUp}
                        style={iconStyleUp}
                        onClick={iconClickHandler(mode)}
                    />
                );
            case SortingMode.ImpressionsDescending:
                return (
                    <FontAwesomeIcon
                        icon={faSortDown}
                        style={iconStyleDown}
                        onClick={iconClickHandler(mode)}
                    />
                );
            case SortingMode.UserImpressionsAscending:
                return (
                    <FontAwesomeIcon
                        icon={faSortUp}
                        style={iconStyleUp}
                        onClick={iconClickHandler(mode)}
                    />
                );
            case SortingMode.UserImpressionsDescending:
                return (
                    <FontAwesomeIcon
                        icon={faSortDown}
                        style={iconStyleDown}
                        onClick={iconClickHandler(mode)}
                    />
                );
            default:
                return <></>;
        }
    };

    const handleDragStart = (
        e: React.DragEvent<HTMLDivElement>,
        index: string
    ) => {
        e.dataTransfer.setData("index", index);
    };

    const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
    };

    const handleDrop = (e: React.DragEvent<HTMLDivElement>, overId: string) => {
        e.preventDefault();
        const draggedId = Number(e.dataTransfer.getData("index"));

        let newActiveSorts = [...activeSorts];
        const element = newActiveSorts.splice(draggedId, 1)[0];
        newActiveSorts.splice(Number(overId), 0, element);
        const activeSortsAsSet = new Set(newActiveSorts);
        if (activeSortsAsSet.size !== 3) {
            const generatedSorting: number[] = [];
            activeSortsAsSet.forEach(element => {
                generatedSorting.push(element);
            });
            const copyElement =
                generatedSorting.length > 0
                    ? generatedSorting[generatedSorting.length - 1]
                    : SortingMode.None;
            const copyCount = 3 - generatedSorting.length;
            for (let index = 0; index < copyCount; index++) {
                generatedSorting.push(copyElement);
            }
            newActiveSorts = generatedSorting;
        }

        setActiveSorts(newActiveSorts);
        replaceSortingState(client, newActiveSorts);
    };

    const handleSortChange = (newSortingMode: SortingMode) => {
        if (data && data.sortingState) {
            const sortingPriorities = data.sortingState.sortingPriorities || [];
            const index = sortingPriorities.findIndex(
                (mode: SortingMode) => mode === newSortingMode
            );

            if (index !== -1) {
                const toggleMode = getToggleMode(newSortingMode);
                updateSortingState(client, index, toggleMode);
            }
        }
    };

    const getToggleMode = (currentMode: SortingMode): SortingMode => {
        switch (currentMode) {
            case SortingMode.NameAscending:
                return SortingMode.NameDescending;
            case SortingMode.NameDescending:
                return SortingMode.NameAscending;
            case SortingMode.DateAscending:
                return SortingMode.DateDescending;
            case SortingMode.DateDescending:
                return SortingMode.DateAscending;
            case SortingMode.CtrAscending:
                return SortingMode.CtrDescending;
            case SortingMode.CtrDescending:
                return SortingMode.CtrAscending;
            case SortingMode.UserCtrAscending:
                return SortingMode.UserCtrDescending;
            case SortingMode.UserCtrDescending:
                return SortingMode.UserCtrAscending;
            case SortingMode.ImpressionsAscending:
                return SortingMode.ImpressionsDescending;
            case SortingMode.ImpressionsDescending:
                return SortingMode.ImpressionsAscending;
            case SortingMode.UserImpressionsAscending:
                return SortingMode.UserImpressionsDescending;
            case SortingMode.UserImpressionsDescending:
                return SortingMode.UserImpressionsAscending;
            default:
                return currentMode;
        }
    };

    const iconClickHandler =
        (newMode: SortingMode) => (event: React.MouseEvent) => {
            event.stopPropagation();
            handleSortChange(newMode);
        };

    const resetSorting = () => {
        resetSortingState(client);
        setShowDropdown(false);
    };

    const [showDropdDown, setShowDropdown] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current?.contains(event.target as Node) === false) {
                setShowDropdown(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <Dropdown ref={dropdownRef} show={showDropdDown} id="title-dropdown">
            <Dropdown.Toggle
                variant="primary"
                id="title-dropdown-toggle"
                className={`text-inverse ${styles.size} ${styles.invisibleButton}`}
                onClick={() => setShowDropdown(!showDropdDown)}
            >
                <FontAwesomeIcon
                    icon={faEllipsisVertical}
                    size="xs"
                    title={`Sorting order by level`}
                    className={`text-inverse-50 ${styles.ellipsisVertical}`}
                />
                {title}
            </Dropdown.Toggle>

            <Dropdown.Menu
                id="product-title-sort-dropdown"
                className={`primary-background`}
            >
                <span className={`text-inverse ms-4 ${styles.dropdownTitle}`}>
                    Sorting options
                </span>
                <Dropdown.Item
                    onClick={e => e.stopPropagation()}
                    className={styles.dropdownItemText}
                ></Dropdown.Item>
                {activeSorts.every(mode => mode === SortingMode.None) ? (
                    <Dropdown.Item
                        onClick={e => e.stopPropagation()}
                        className={`text-inverse mb-2 ${styles.dropdownItemText}`}
                    >
                        <span
                            className={`d-flex nowrap pt-0 pb-2 text-inverse ${styles.dottedLine}`}
                        >
                            <span className="pe-2">{title}</span>
                            <SortingButton sortingModes={sortingModes} />
                        </span>
                        <span className="text-inverse-50">
                            No active sorts.
                        </span>
                    </Dropdown.Item>
                ) : (
                    activeSorts.map((sortItem, index) => (
                        <Dropdown.Item
                            key={index}
                            draggable
                            onDragStart={(e: React.DragEvent<HTMLDivElement>) =>
                                handleDragStart(e, index.toString())
                            }
                            onDragOver={(e: React.DragEvent<HTMLDivElement>) =>
                                handleDragOver(e)
                            }
                            onDrop={(e: React.DragEvent<HTMLDivElement>) =>
                                handleDrop(e, index.toString())
                            }
                            className={`pb-0 pt-0 ${styles.dropdownItemText}`}
                            onClick={e => e.stopPropagation()}
                        >
                            <Row
                                className={`d-flex nowrap g-0 p-0 ${styles.dropdownItemContainer}`}
                            >
                                <Col
                                    className={`col-5 text-inverse-50 ${styles.priorityLabel}`}
                                >
                                    {labels[index] || "Other"}
                                </Col>{" "}
                                <Col
                                    title="Drag to re-order"
                                    className={`col-5 text-inverse ${styles.sortItemLabel}`}
                                >
                                    {renderSortLabel(sortItem)}
                                </Col>
                                <Col
                                    title="Toggle sort direction"
                                    className={`d-flex col-2 align-items-center justify-content-end text-inverse-50 ${styles.sortItemIcon}`}
                                >
                                    {renderSortIcon(sortItem, iconClickHandler)}
                                </Col>
                            </Row>
                        </Dropdown.Item>
                    ))
                )}
                {activeSorts.length > 0 &&
                    !activeSorts.every(mode => mode === SortingMode.None) && (
                        <Dropdown.Item
                            className={`text-inverse ${styles.dropdownReset}`}
                            onClick={resetSorting}
                            href="#/action-4"
                        >
                            <span>
                                <FontAwesomeIcon
                                    className="me-2"
                                    icon={faRotateLeft}
                                    size="xs"
                                />
                                Reset sorting
                            </span>
                        </Dropdown.Item>
                    )}
            </Dropdown.Menu>
        </Dropdown>
    );
};
