import React, { FC } from "react";
import { AnalyticsQueryType, HeatmapData } from "../../../models/types";
import { FunnelElements } from "../../simple/analytics/FunnelElements";
import { QueryOptions } from "./QueryOptions";
import { Card } from "react-bootstrap";
import { DSVRowArray } from "d3";
import { QuerySelection } from "./QuerySelection";

interface Props {
    queryType: AnalyticsQueryType;
    apiVersion: string;
    updateHeatmapDataset?: (dataset: HeatmapData | undefined) => void;
    updateLandscapeBaseHeatmapDataset?: (
        dataset: HeatmapData | undefined
    ) => void;
    updateTimeDistributionDataset?: (dataset: DSVRowArray | undefined) => void;
}

export const QueryOptionsWrapper: FC<Props> = ({ queryType, apiVersion }) => {
    return (
        <Card.Body>
            <QuerySelection />
            <FunnelElements queryType={queryType} apiVersion={apiVersion} />
            <QueryOptions apiVersion={apiVersion} queryType={queryType} />
        </Card.Body>
    );
};
