import React, { FC, ReactElement, useState } from "react";
import { AssetBundle, Mutator, MutatorProperty } from "../../../models/types";
import {
    Alert,
    Card,
    Row,
    Col,
    Collapse,
    Button,
    OverlayTrigger,
    Tooltip
} from "react-bootstrap";
import { AssetbundleCard } from "../../cards/mutator/AssetbundleCard";
import { MutatorNumberCard } from "../../cards/mutator/MutatorNumberCard";
import { MutatorStringCard } from "../../cards/mutator/MutatorStringCard";
import { CustomTypeObjectCard } from "../../cards/mutator/CustomTypeObjectCard";
import { CustomTypeEnumCard } from "../../cards/mutator/CustomTypeEnumCard";
import { useLocalState, useMutatorState } from "../../../graphql/hooks";
import { updateMutatorState } from "../../../common/Helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faChevronDown,
    faChevronUp,
    faCircleInfo,
    faExclamation
} from "@fortawesome/free-solid-svg-icons";
import { useApolloClient } from "@apollo/client";
import styles from "./MutatorView.module.scss";
import cx from "classnames";

interface Props {
    mutatorConfig: Mutator;
    assetBundles: AssetBundle[];
    onChange: (name: string, value: any) => void;
    onSave: () => void;
    updateHasUnsavedData?: boolean;
}

const MutatorOptionsImplementation: FC<Props> = ({
    mutatorConfig,
    assetBundles,
    onChange,
    onSave,
    updateHasUnsavedData
}) => {
    const client = useApolloClient();
    const mutatorState = useMutatorState();
    const { isDeveloper } = useLocalState();
    const [showContent, setShowContent] = useState(true);
    const getVariantByType = (type: string) => {
        switch (type) {
            case "assetbundle":
                return "info";
            case "string":
                return "primary";
            case "number":
                return "secondary";
            default:
                if (type.includes("@")) {
                    return "success";
                } else if (type.includes("$")) {
                    return "misc";
                }
                return "warning";
        }
    };
    const numberProperties: MutatorProperty[] = [];
    const stringProperties: MutatorProperty[] = [];
    const assetbundleProperties: MutatorProperty[] = [];
    const customTypeEnumProperties: MutatorProperty[] = [];
    const customTypeObjectProperties: MutatorProperty[] = [];
    const unknownTypeProperties: MutatorProperty[] = [];

    mutatorConfig.properties.forEach(property => {
        switch (property.type) {
            case "number":
                numberProperties.push(property);
                break;
            case "string":
                stringProperties.push(property);
                break;
            case "assetbundle":
                assetbundleProperties.push(property);
                break;
            default:
                if (property.type.includes("@")) {
                    customTypeEnumProperties.push(property);
                } else if (property.type.includes("$")) {
                    customTypeObjectProperties.push(property);
                } else {
                    unknownTypeProperties.push(property);
                }
                break;
        }
    });

    const renderMutatorProperty = (
        property: MutatorProperty,
        index: number
    ): ReactElement => {
        // const mutatorElements: ReactElement[] = mutatorConfig.properties.flatMap(
        //     (property: MutatorProperty, index: number) => {
        switch (property.type) {
            case "number":
                return (
                    <MutatorNumberCard
                        key={index}
                        property={property}
                        getVariantByType={getVariantByType}
                        onChange={onChange}
                    />
                );
            case "string":
                return (
                    <MutatorStringCard
                        key={index}
                        property={property}
                        getVariantByType={getVariantByType}
                        onChange={onChange}
                    />
                );
            case "assetbundle": {
                return (
                    <AssetbundleCard
                        key={index}
                        property={property}
                        assetbundles={assetBundles}
                        getVariantByType={getVariantByType}
                        onChange={onChange}
                    />
                );
            }
            default: {
                if (property.type.includes("@")) {
                    return (
                        <CustomTypeEnumCard
                            key={index}
                            property={property}
                            enums={mutatorConfig.enums}
                            getVariantByType={getVariantByType}
                            onChange={onChange}
                        />
                    );
                } else if (property.type.includes("$")) {
                    const customType = mutatorConfig.types[property.type];
                    return (
                        <CustomTypeObjectCard
                            key={index}
                            property={property}
                            assetbundles={assetBundles}
                            enums={mutatorConfig.enums}
                            customType={customType}
                            getVariantByType={getVariantByType}
                            onChange={onChange}
                        />
                    );
                }
                return (
                    <>
                        {isDeveloper ? (
                            <Alert variant="danger" key={index}>
                                Unknown Type: {property.type}
                            </Alert>
                        ) : null}
                    </>
                );
            }
        }
    };

    const renderPropertyRow = (properties: MutatorProperty[]) => (
        <Row className="d-flex justify-content-start">
            {properties.map((property, index) => (
                <Col key={index}>{renderMutatorProperty(property, index)}</Col>
            ))}
        </Row>
    );

    return (
        <>
            <Card>
                <Card.Header
                    className={`d-flex align-items-center ${styles.cardHeaderOptions}`}
                >
                    <h5 className="text-inverse-50">Options</h5>
                    <div
                        onClick={() => setShowContent(!showContent)}
                        title={showContent ? "Hide Options" : "Show Options"}
                    >
                        <FontAwesomeIcon
                            className={`text-inverse-50 ${styles.optionsChevron}`}
                            size="sm"
                            icon={showContent ? faChevronUp : faChevronDown}
                        />
                    </div>
                    <div className="ms-auto mb-2 d-flex align-items-center">
                        <div className="mx-2">
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip id="mutator-save-btn">
                                        {updateHasUnsavedData
                                            ? "You have unsaved changes!"
                                            : "No changes to save"}
                                    </Tooltip>
                                }
                            >
                                <Button
                                    size="sm"
                                    variant={
                                        updateHasUnsavedData
                                            ? "warning"
                                            : "primary"
                                    }
                                    onClick={onSave}
                                >
                                    Save{" "}
                                    {updateHasUnsavedData ? (
                                        <FontAwesomeIcon
                                            icon={faExclamation}
                                            size="xs"
                                        />
                                    ) : (
                                        ""
                                    )}
                                </Button>
                            </OverlayTrigger>
                        </div>
                        <div className="mx-2">
                            <FontAwesomeIcon
                                title="Toggle help and additional info on all options"
                                icon={faCircleInfo}
                                className={cx({
                                    "text-inverse-25 mouseHover":
                                        !mutatorState.showMeta,
                                    "text-inverse-50 mouseHover":
                                        mutatorState.showMeta
                                })}
                                onClick={() =>
                                    updateMutatorState(
                                        client,
                                        !mutatorState.showMeta
                                    )
                                }
                            />
                        </div>
                    </div>
                </Card.Header>
                <Collapse in={showContent}>
                    <div>
                        <Card.Body className="p-2">
                            {customTypeEnumProperties.length > 0 &&
                                renderPropertyRow(customTypeEnumProperties)}
                            {customTypeObjectProperties.length > 0 &&
                                renderPropertyRow(customTypeObjectProperties)}
                            {unknownTypeProperties.length > 0 &&
                                renderPropertyRow(unknownTypeProperties)}
                            {assetbundleProperties.length > 0 &&
                                renderPropertyRow(assetbundleProperties)}
                            {(stringProperties.length > 0 ||
                                numberProperties.length > 0) &&
                                renderPropertyRow([
                                    ...stringProperties,
                                    ...numberProperties
                                ])}
                        </Card.Body>
                    </div>
                </Collapse>
            </Card>
        </>
    );
};

export const MutatorOptions = React.memo(MutatorOptionsImplementation);
