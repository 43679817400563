import React, { FC } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useLocalAnalyticsState } from "../../../graphql/hooks";
import { formatDateRange } from "../../../common/AnalyticsHelpers";
import styles from "./DailyBadges.module.scss";

interface Props {
    gameplay?: number;
    endScreen?: number;
    impressions?: number;
}

const UserQueryCTRImplementation: FC<Props> = ({
    gameplay,
    endScreen,
    impressions
}) => {
    const { analyticsEnabled, analyticsTimestamps } = useLocalAnalyticsState();
    const formattedDateRange = analyticsTimestamps
        ? formatDateRange(
              analyticsTimestamps.startTimestamp,
              analyticsTimestamps.endTimestamp
          )
        : "";

    if (
        !analyticsEnabled ||
        (!gameplay && !endScreen) ||
        !impressions ||
        impressions <= 0
    ) {
        return null;
    }
    const ctr = (
        (((gameplay || 0) + (endScreen || 0)) / impressions) *
        100
    ).toFixed(1);

    return (
        <>
            <div className={`${styles.analyticsText} text-inverse`}>
                <OverlayTrigger
                    placement="top"
                    delay={{ show: 200, hide: 100 }}
                    overlay={
                        <Tooltip id="user-badge-tooltip-ctr">
                            {`CTR - ${formattedDateRange}`}
                        </Tooltip>
                    }
                >
                    <span style={{ whiteSpace: "nowrap" }}>
                        {parseFloat(ctr).toLocaleString()}{" "}
                        <span className="text-inverse">%</span>
                    </span>
                </OverlayTrigger>
            </div>
        </>
    );
};

export const UserQueryCTR = React.memo(UserQueryCTRImplementation);
