import React, { FC, useState } from "react";
import {
    RevisionConfigData,
    RevisionConfigInputVariables,
    RevisionVariables
} from "../../../models/types";
import { useMutation, useQuery } from "@apollo/client";
import { GET_REVISION_CONFIG_CONFIGURATOR } from "../../../graphql/queries";
import { UPDATE_REVISION_CONFIG_CONFIGURATOR } from "../../../graphql/mutations";
import { EditableJsonView } from "../../editable/EditableJsonView";
import "../views.scss";
import { Alert, Button } from "react-bootstrap";

interface Props {
    revisionId: string;
    developerMode: boolean;
}

const TemplatorConfigImplementation: FC<Props> = ({
    revisionId,
    developerMode
}) => {
    const [updating, updateUpdating] = useState(false);
    const { loading: templatorLoading, data: { revisionConfig } = {} } =
        useQuery<RevisionConfigData, RevisionVariables>(
            GET_REVISION_CONFIG_CONFIGURATOR,
            {
                variables: { revisionId: Number(revisionId) }
            }
        );

    const [updateMutation] = useMutation<
        RevisionConfigData,
        RevisionConfigInputVariables
    >(UPDATE_REVISION_CONFIG_CONFIGURATOR);

    const updateTemplatorConfig = async (templatorConfig: any) => {
        updateUpdating(true);
        const input = {
            revisionId: Number(revisionId),
            templatorConfig: templatorConfig
        };
        try {
            await updateMutation({
                variables: {
                    input: input
                }
            });
        } catch (error) {
            console.log("[DEBUG] updateTemplatorConfig error ", error);
        }

        updateUpdating(false);
    };

    if (templatorLoading) {
        return null;
    }

    if (!revisionConfig?.templatorConfig) {
        if (!developerMode) {
            return (
                <div className="aditorViewSmall text-inverse d-flex justify-content-center align-items-center">
                    <div>
                        <h5 className="text-center text-info">
                            You should not be seeing this
                        </h5>
                        <br />
                        <span>
                            The developer has enabled customator, but there is
                            no configuration yet. Check back later.
                        </span>
                    </div>
                </div>
            );
        }
        return (
            <div className="aditorViewSmall text-inverse d-flex flex-column justify-content-center align-items-center">
                <Button onClick={() => updateTemplatorConfig({})}>
                    Start By Creating a New Templator Config
                </Button>
                <Alert variant="info" className="mt-2">
                    If you have local version available, you can copy and paste
                    it afterwards into the json view
                </Alert>
            </div>
        );
    }

    return (
        <EditableJsonView
            jsonData={JSON.stringify(revisionConfig.templatorConfig, null, 4)}
            updateJsonData={updateTemplatorConfig}
            updating={updating}
        />
    );
};

export const TemplatorConfig = React.memo(TemplatorConfigImplementation);
