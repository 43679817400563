import React, { FC } from "react";
import { useLocalAnalyticsState } from "../../../graphql/hooks";
import { GET_USER_ANALYTICS_QUERY_ANALYTIC } from "../../../graphql/queries";
import { useQuery } from "@apollo/client";
import {
    DailyAnalyticVariables,
    UserQueryAnalyticsData
} from "../../../models/types";
import { formatDateRange } from "../../../common/AnalyticsHelpers";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

interface Props {
    reference: string;
    referenceId: number;
    big?: boolean;
}

const UserQueryBadgesImplementation: FC<Props> = ({
    reference,
    referenceId,
    big = false
}) => {
    const { analyticsEnabled, analyticsTimestamps } = useLocalAnalyticsState();
    const { data: { getUserQueryAnalytic } = {} } = useQuery<
        UserQueryAnalyticsData,
        DailyAnalyticVariables
    >(GET_USER_ANALYTICS_QUERY_ANALYTIC, {
        variables: { referenceId: referenceId, reference: reference },
        skip: !analyticsEnabled
    });

    const formattedDateRange = analyticsTimestamps
        ? formatDateRange(
              analyticsTimestamps.startTimestamp,
              analyticsTimestamps.endTimestamp
          )
        : "";

    if (!getUserQueryAnalytic || getUserQueryAnalytic.impressions <= 0) {
        return null;
    }
    const gameplay = getUserQueryAnalytic.gameplay || 0;
    const endScreen = getUserQueryAnalytic.endScreen || 0;
    const ctr = (
        ((gameplay + endScreen) / getUserQueryAnalytic.impressions) *
        100
    ).toFixed(1);

    return (
        <div className="d-flex analytics-font float-end px-2">
            <OverlayTrigger
                overlay={
                    <Tooltip id="user-impressions-tooltip">
                        {`Impressions - ${formattedDateRange}`}
                    </Tooltip>
                }
                delay={{
                    show: 100,
                    hide: 200
                }}
            >
                <div className="me-2">
                    {getUserQueryAnalytic.impressions.toLocaleString()}
                </div>
            </OverlayTrigger>
            <div className="text-secondary">|</div>
            <OverlayTrigger
                overlay={
                    <Tooltip id="user-ctr-tooltip">
                        {`CTR - ${formattedDateRange}`}
                    </Tooltip>
                }
                delay={{
                    show: 100,
                    hide: 200
                }}
            >
                <div style={{ whiteSpace: "nowrap" }} className="ms-2">
                    {ctr} %
                </div>
            </OverlayTrigger>
        </div>
    );
};

export const UserQueryBadges = React.memo(UserQueryBadgesImplementation);
