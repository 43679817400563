import React from "react";
import { Card, Tab, Tabs } from "react-bootstrap";
import { FunnelBarChart } from "../../charts/FunnelBarChart";
import { QueryControllerViewAdvanced } from "./QueryControllerViewAdvanced";
import { AnalyticsQueryType } from "../../../models/types";
import { useLocalAnalyticsState, useLocalState } from "../../../graphql/hooks";
import { MultiFunnelBarChart } from "../../charts/MultiFunnelBarChart";
import { FunnelLineChart } from "../../charts/FunnelLineChart";
import { MultiFunnelLineChart } from "../../charts/MultiFunnelLineChart";
import { OverlayBadge } from "../../simple/OverlayBadge";
import { TimeLineChart } from "../../charts/TimeLineChart";
import { MultiTimeLineChart } from "../../charts/MultiTimeLineChart";
import { TimeLineChartSegmented } from "../../charts/TimeLineChartSegmented";
import { MultiTimeLineChartSegmented } from "../../charts/MultiTimeLineChartSegmented";
import { BothHeatmaps } from "../../charts/BothHeatmaps";
import { DayFunnelChartsView } from "./DayFunnelChartsView";

const SINGLE_FUNNEL_QUERY_COUNT = 1;
export const FunnelAnalyticsViewImplementation = () => {
    const { useDarkMode } = useLocalState();
    const { funnelAnalyticsResults } = useLocalAnalyticsState();

    const comparison =
        funnelAnalyticsResults.length > SINGLE_FUNNEL_QUERY_COUNT;

    return (
        <>
            <QueryControllerViewAdvanced
                queryType={AnalyticsQueryType.Funnel}
            />
            {funnelAnalyticsResults.length > 0 ? (
                <Tabs defaultActiveKey="bar" className="tabs-inverse mt-3">
                    <Tab eventKey="bar" title="Bar Chart">
                        <Card className="text-inverse mt-4">
                            <Card.Header>Funnel</Card.Header>
                            <Card.Body>
                                {!comparison ? (
                                    <FunnelBarChart
                                        data={funnelAnalyticsResults}
                                    />
                                ) : (
                                    <MultiFunnelBarChart
                                        data={funnelAnalyticsResults}
                                    />
                                )}
                            </Card.Body>
                        </Card>
                    </Tab>
                    <Tab eventKey="line" title="Line Chart">
                        <Card className="text-inverse mt-4">
                            <Card.Header>Funnel</Card.Header>
                            <Card.Body className="d-flex justify-content-center">
                                {!comparison ? (
                                    <FunnelLineChart
                                        data={funnelAnalyticsResults}
                                        darkMode={useDarkMode}
                                    />
                                ) : (
                                    <MultiFunnelLineChart
                                        data={funnelAnalyticsResults}
                                    />
                                )}
                            </Card.Body>
                        </Card>
                    </Tab>
                </Tabs>
            ) : null}
            {funnelAnalyticsResults.length > 0 ? (
                <Card className="text-inverse mt-4">
                    <Card.Header>
                        Time Medians
                        <OverlayBadge
                            badgeHeader="?"
                            badgeVariant={useDarkMode ? "dark" : "light"}
                            className="float-end"
                            overlayText="Time medians exclude duplicates (etc.) so may not match the funnel results exactly"
                        ></OverlayBadge>
                    </Card.Header>
                    <Card.Body>
                        {!comparison ? (
                            <TimeLineChart data={funnelAnalyticsResults} />
                        ) : (
                            <MultiTimeLineChart data={funnelAnalyticsResults} />
                        )}
                    </Card.Body>
                </Card>
            ) : null}
            {funnelAnalyticsResults.length > 0 ? (
                !comparison ? (
                    <TimeLineChartSegmented
                        data={funnelAnalyticsResults}
                        darkMode={useDarkMode}
                    />
                ) : (
                    <MultiTimeLineChartSegmented
                        data={funnelAnalyticsResults}
                    />
                )
            ) : null}
            {funnelAnalyticsResults.length > 0 ? (
                <BothHeatmaps data={funnelAnalyticsResults} />
            ) : null}
            {funnelAnalyticsResults.length > 0 ? (
                <DayFunnelChartsView data={funnelAnalyticsResults} />
            ) : null}
        </>
    );
};

export const FunnelAnalyticsView = React.memo(
    FunnelAnalyticsViewImplementation
);
