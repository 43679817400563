import React, { FC, useEffect, useState } from "react";
import { Badge, Button, Card, Collapse, Container } from "react-bootstrap";
import {
    OrganizationConfigData,
    PartialRevisionData,
    PartialRevisionVariables,
    ProjectConfigData,
    Revision,
    SoftLimitsData,
    SoftLimitState
} from "../../../models/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faChevronDown,
    faChevronUp,
    faPlus
} from "@fortawesome/free-solid-svg-icons";
import { useMutation, useQuery } from "@apollo/client";
import { PARTIAL_UPDATE_REVISION } from "../../../graphql/mutations";
import { EditableJsonView } from "../../editable/EditableJsonView";
import {
    GET_ORGANIZATION_CONFIG,
    GET_PROJECT_CONFIG
} from "../../../graphql/queries";
import { SoftLimitViewOnly } from "./SoftLimitViewOnly";
import "../views.scss";

interface Props {
    revision: Revision;
    developerMode: boolean;
}

export const SoftLimits: FC<Props> = ({ revision, developerMode }) => {
    const [currentSoftLimits, updateSoftLimits] = useState<
        SoftLimitsData | undefined
    >(revision.buildData?.softLimits);
    const [currentSoftLimitState, updateSoftLimitState] =
        useState<SoftLimitState>(SoftLimitState.Organization);
    const { data: { organizationConfig } = {} } =
        useQuery<OrganizationConfigData>(GET_ORGANIZATION_CONFIG, {
            variables: {
                organizationId: revision.project.product?.organization.id
            },
            skip: revision.id === -1
        });
    const { data: { projectConfig } = {} } = useQuery<ProjectConfigData>(
        GET_PROJECT_CONFIG,
        {
            variables: {
                projectId: revision.project.id
            },
            skip: revision.id === -1
        }
    );
    const [hasUnsaved, updateHasUnsaved] = useState(false);
    const [updating, updateUpdating] = useState(false);
    const [showEditView, updateShowEditView] = useState(false);

    const organizationSoftLimits = organizationConfig?.softLimits;
    const projectSoftLimits = projectConfig?.softLimits;

    const [partialUpdateRevision] = useMutation<
        PartialRevisionData,
        PartialRevisionVariables
    >(PARTIAL_UPDATE_REVISION);

    useEffect(() => {
        if (revision.buildData?.softLimits !== currentSoftLimits) {
            updateSoftLimits(revision.buildData?.softLimits);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [revision.buildData?.softLimits]);

    useEffect(() => {
        if (currentSoftLimits !== undefined) {
            updateSoftLimitState(SoftLimitState.Revision);
        } else if (projectConfig?.softLimits !== undefined) {
            updateSoftLimitState(SoftLimitState.Project);
        } else if (organizationConfig?.softLimits !== undefined) {
            updateSoftLimitState(SoftLimitState.Organization);
        } else {
            updateSoftLimitState(SoftLimitState.None);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organizationSoftLimits, projectSoftLimits, currentSoftLimits]);

    const createSoftLimits = () => {
        const newSoftLimits: SoftLimitsData = {
            global: []
        };
        updateSoftLimits(newSoftLimits);
    };

    const saveSoftLimits = async (newSoftLimit: SoftLimitsData) => {
        updateUpdating(true);
        try {
            const result = await partialUpdateRevision({
                variables: {
                    revisionId: revision.id,
                    partialData: { softLimits: newSoftLimit }
                }
            });
            updateSoftLimits(newSoftLimit);
            console.log("[DEBUG] result ", result);
        } catch (error) {
            console.log("[DEBUG] error ", error);
        }
        updateUpdating(false);
    };

    const getSoftLimitState = () => {
        if (currentSoftLimitState === SoftLimitState.Revision) {
            return <p>Loading...</p>;
        } else if (currentSoftLimitState === SoftLimitState.Organization) {
            return (
                <Card className="inner-card m-2 p-0">
                    <Card.Header className="d-flex text-inverse align-items-center me-0 pe-1">
                        Effective Soft Limits:{" "}
                        <Badge className="ms-2" bg="success">
                            Organization
                        </Badge>
                    </Card.Header>
                    <SoftLimitViewOnly
                        codeBlock={JSON.stringify(
                            organizationSoftLimits,
                            null,
                            2
                        )}
                    />
                </Card>
            );
        } else if (currentSoftLimitState === SoftLimitState.Project) {
            return (
                <Card className="inner-card m-2 p-0">
                    <Card.Header className="d-flex text-inverse align-items-center me-0 pe-1">
                        Effective Soft Limits:{" "}
                        <Badge className="ms-2" bg="info">
                            Project
                        </Badge>
                    </Card.Header>
                    <SoftLimitViewOnly
                        codeBlock={JSON.stringify(projectSoftLimits, null, 2)}
                    />
                </Card>
            );
        }
        return (
            <Card className="inner-card m-2 p-0">
                <Card.Header className="d-flex text-inverse align-items-center me-0 pe-1">
                    Effective Soft Limits:{" "}
                    <Badge className="ms-2" bg="secondary">
                        None
                    </Badge>
                </Card.Header>
            </Card>
        );
    };

    if (!developerMode) {
        return null;
    }

    return (
        <>
            <Container className="mb-4 p-0">
                <Card>
                    <Card.Header className="d-flex align-items-center">
                        <h5 className="text-inverse-50 mb-1 me-2">
                            Soft Limits
                        </h5>
                        <div
                            onClick={() => {
                                updateShowEditView(!showEditView);
                            }}
                            data-toggle="tooltip"
                            title="Toggle Placeholders visibility"
                            className="ms-1 mb-1 mouseHover text-inverse-50"
                        >
                            <FontAwesomeIcon
                                icon={
                                    showEditView ? faChevronUp : faChevronDown
                                }
                            />
                        </div>
                        {currentSoftLimits === undefined ? (
                            <div className="d-flex float-end ms-auto">
                                <Button
                                    variant="primary"
                                    size="sm"
                                    className="mx-1"
                                    onClick={createSoftLimits}
                                    data-toggle="tooltip"
                                    title="Add Override"
                                >
                                    <FontAwesomeIcon icon={faPlus} />
                                </Button>
                            </div>
                        ) : null}
                        {hasUnsaved ? (
                            <div className="d-flex float-end ms-auto">
                                <Badge bg="warning">Changes Not Saved</Badge>
                            </div>
                        ) : null}
                    </Card.Header>

                    <Collapse in={showEditView}>
                        <div>
                            {currentSoftLimits !== undefined ? (
                                <EditableJsonView
                                    jsonData={JSON.stringify(
                                        currentSoftLimits,
                                        null,
                                        4
                                    )}
                                    updateJsonData={saveSoftLimits}
                                    updating={updating}
                                    updateHasUnsavedData={updateHasUnsaved}
                                />
                            ) : (
                                <div className="w-100 p-2 text-inverse text-center">
                                    {getSoftLimitState()}
                                </div>
                            )}
                        </div>
                    </Collapse>
                </Card>
            </Container>
        </>
    );
};
