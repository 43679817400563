import React, { FC, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { GET_PRODUCTS, GET_USER } from "../../../graphql/queries";
import {
    Product,
    ProductsAdminData,
    UpdateUserData,
    UpdateUserVariables,
    User
} from "../../../models/types";
import { Button, Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import cx from "classnames";
import { UPDATE_USER_ACCESS_BATCH } from "../../../graphql/mutations";
interface Props {
    user: User;
    updateProducts: (productId: number, handleProjects: boolean) => void;
}

export const SelectableProducts: FC<Props> = ({ user, updateProducts }) => {
    const [handleRelatedProjects, updateRelatedProjects] = useState(false);
    const { data: { allProducts } = {} } = useQuery<ProductsAdminData>(
        GET_PRODUCTS,
        {
            variables: {
                organizationId: Number(user.organization?.id)
            }
        }
    );
    const [selectAll] = useMutation<UpdateUserData, UpdateUserVariables>(
        UPDATE_USER_ACCESS_BATCH
    );
    const selectAllButton = () => {
        if (user.organization) {
            selectAll({
                variables: {
                    userId: user.id,
                    organizationId: user.organization.id
                },
                refetchQueries: [
                    {
                        query: GET_USER,
                        variables: {
                            userId: user.id
                        }
                    }
                ]
            }).catch(error => {
                console.log(error);
            });
        }
    };
    const selectedProduct = (product: Product): boolean => {
        if (!user.products) {
            return false;
        }
        if (
            user.products.some(
                (userProduct: Product) => userProduct.id === product.id
            )
        ) {
            return true;
        }
        return false;
    };

    const alternatives = allProducts?.map((product: Product, index: number) => (
        <li
            key={index}
            className={cx(
                "list-group-item  mouseHover",
                selectedProduct(product)
                    ? "list-group-item-primary"
                    : "list-group-item-secondary"
            )}
            onClick={() =>
                updateProducts(Number(product.id), handleRelatedProjects)
            }
        >
            {product.id}: {product.name} ({product.organization?.name})
        </li>
    ));

    const [showList, setShowList] = useState(true);
    const listPopulated = allProducts && allProducts?.length > 0;

    return (
        <>
            <Row className="m-0 p-0">
                <Col className=" d-flex align-items-center mb-2">
                    <h4
                        className="mouseHover"
                        onClick={() => setShowList(!showList)}
                    >
                        Products
                        {listPopulated && (
                            <FontAwesomeIcon
                                icon={showList ? faChevronUp : faChevronDown}
                                style={{
                                    color: "gray",
                                    marginLeft: "6px",
                                    fontSize: "16px"
                                }}
                            />
                        )}
                    </h4>
                    {listPopulated && (
                        <div className="float-end ms-auto">
                            <Button
                                className="ms-2"
                                title={
                                    handleRelatedProjects
                                        ? "Turn off to select Projects separately"
                                        : "Turn on to update related Projects when selecting Products"
                                }
                                variant={
                                    handleRelatedProjects
                                        ? "success"
                                        : "secondary"
                                }
                                onClick={() =>
                                    updateRelatedProjects(
                                        !handleRelatedProjects
                                    )
                                }
                            >
                                {handleRelatedProjects
                                    ? "Updating projects also"
                                    : "Update projects?"}
                            </Button>
                            <Button
                                title="Select all Products and Projects"
                                className="ms-2"
                                onClick={selectAllButton}
                            >
                                Select All
                            </Button>
                        </div>
                    )}
                </Col>
            </Row>
            {showList && (
                <ul className="list-group" id="selectable-products-list">
                    {alternatives}
                </ul>
            )}
        </>
    );
};
