import React, { FC, useEffect, useState } from "react";
import { Button, Card, Col, Container, Nav, Row, Tab } from "react-bootstrap";
import {
    AnalyticsEventDataSchema,
    AnalyticsEventSchema,
    AnalyticsEventVariables,
    AnalyticsSchema,
    UpdateAnalyticsLibEventResult
} from "../../../models/types";
import { FunnelElementsPreview } from "../../simple/analytics/FunnelElementsPreview";
import { AnalyticsEventItem } from "../../variationItems/analytics/AnalyticsEventItem";
import { AnalyticsSchemaSnippets } from "./AnalyticsSchemaSnippets";
import { AnalyticsSchemaGuide } from "./AnalyticsSchemaGuide";
import { ImportAnalyticsModal } from "../../modals/analytics/ImportAnalyticsModal";
import { AnalyticsEventLibraryModal } from "../../modals/analytics/AnalyticsEventLibraryModal";
import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import { GET_ANALYTICS_EVENTS } from "../../../graphql/queries";
import {
    UPDATE_ANALYTICS_LIB_EVENT,
    UPDATE_REVISION
} from "../../../graphql/mutations";
import { updateNotificationState } from "../../../common/Helpers";
import { AnalyticsEventUploadModal } from "../../modals/analytics/AnalyticsEventUploadModal";
import { SchemaDescription } from "./SchemaDescription";
import Prism from "prismjs";
import "prismjs/components/prism-json.min";
import "prismjs/themes/prism.css";

interface Props {
    revisionId: number;
    analyticsSchema: AnalyticsSchema | undefined;
}

const AnalyticsSchemaDesignerViewImplementation: FC<Props> = ({
    revisionId,
    analyticsSchema
}) => {
    const client = useApolloClient();
    const [showImportModal, updateShowImportModal] = useState(false);
    const [showEventLibraryModal, updateShowEventLibraryModal] =
        useState(false);
    const [showAlert, updateShowAlert] = useState(false);
    const { data: { events } = {} } =
        useQuery<AnalyticsSchema>(GET_ANALYTICS_EVENTS);
    const [analyticsLibEventMutation] = useMutation<
        UpdateAnalyticsLibEventResult,
        AnalyticsEventVariables
    >(UPDATE_ANALYTICS_LIB_EVENT);
    const [updateRevision] = useMutation(UPDATE_REVISION);
    const [selectedTab, updateSelectedTab] = useState("preview");
    const [toBeSaved, updateToBeSaved] = useState<
        AnalyticsEventSchema | undefined
    >(undefined);
    const [currentSchema, updateCurrentSchema] = useState<
        AnalyticsSchema | undefined
    >(analyticsSchema);
    const [dragDone, updateDragDone] = useState(true);
    const dragItem = React.useRef<any>(null);
    const dragOverItem = React.useRef<any>(null);

    const updateRevisionSchema = async (schema: AnalyticsSchema) => {
        if (schema) {
            try {
                await updateRevision({
                    variables: {
                        revisionId: revisionId,
                        analyticsConfig: JSON.stringify(schema)
                    }
                });
            } catch (error) {
                console.log("[DEBUG] updateRevisionSchema error ", error);
            }
        }
    };

    useEffect(() => {
        if (analyticsSchema) {
            updateCurrentSchema(analyticsSchema);
        }
    }, [analyticsSchema]);

    useEffect(() => {
        if (events && !currentSchema) {
            const frozenEvents = events.filter(event => {
                return event.frozen;
            });
            updateCurrentSchema({
                events: frozenEvents,
                description: ""
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [events]);

    useEffect(() => {
        if (currentSchema && dragDone) {
            updateRevisionSchema(currentSchema);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentSchema, dragDone]);

    const updateLibraryEvent = async (event?: AnalyticsEventSchema) => {
        if (!event) return;
        try {
            await analyticsLibEventMutation({
                variables: {
                    eventName: event.eventName,
                    eventData: JSON.stringify(event.eventData),
                    comment: event.comment
                },
                update: (cache, { data }) => {
                    const currentLibEvents = events?.filter(element => {
                        return element.eventName !== event.eventName;
                    });
                    if (currentLibEvents && data) {
                        const updatedLibEvents = [
                            ...currentLibEvents,
                            data.updateAnalyticsLibEvent
                        ];
                        cache.writeQuery<AnalyticsSchema>({
                            query: GET_ANALYTICS_EVENTS,
                            data: { events: updatedLibEvents }
                        });
                    }
                }
            });
            if (toBeSaved) {
                updateToBeSaved(undefined);
            }
            updateNotificationState(client, false, true, {
                success: true,
                header: "Analytics Event Updating Status",
                message: `Event saved successfully: ${event.eventName}`,
                delay: 3000
            });
        } catch (error) {
            console.log("[DEBUG] updateLibraryEvent error ", error);
            updateNotificationState(client, false, true, {
                success: false,
                header: "Analytics Event Updating Status",
                // @ts-ignore
                message: error.message,
                delay: 3000
            });
        }
    };

    const addNewEvent = () => {
        const newSchema: AnalyticsSchema = currentSchema
            ? { ...JSON.parse(JSON.stringify(currentSchema)) }
            : { events: [] };

        const newIndex = Math.max(1, newSchema.events.length - 1);
        newSchema.events.splice(newIndex, 0, {
            id: 0,
            eventName: "new...",
            eventData: [],
            comment: "",
            frozen: false
        });

        updateCurrentSchema(newSchema);
    };

    const addNewLibraryEvent = (newEvent: AnalyticsEventSchema) => {
        const newSchema: AnalyticsSchema = currentSchema
            ? { ...JSON.parse(JSON.stringify(currentSchema)) }
            : { events: [] };

        const isNew =
            newSchema.events.findIndex(
                element => element.eventName === newEvent.eventName
            ) === -1;
        if (!isNew) {
            newEvent = JSON.parse(JSON.stringify(newEvent));
            newEvent.eventName = newEvent.eventName + " - DUPLICATE -";
        }
        const newIndex = Math.max(1, newSchema.events.length - 1);
        newSchema.events.splice(newIndex, 0, newEvent);

        updateCurrentSchema(newSchema);
    };

    const updateAnalyticsSchema = (
        index: number,
        event?: AnalyticsEventSchema
    ) => {
        if (!currentSchema?.events) {
            return;
        }

        if (event) {
            const newEvents = [...currentSchema.events];
            newEvents[index] = event;
            updateCurrentSchema({ ...currentSchema, events: newEvents });
        } else {
            const newEvents = [
                ...currentSchema.events.slice(0, index),
                ...currentSchema.events.slice(index + 1)
            ];
            updateCurrentSchema({ ...currentSchema, events: newEvents });
        }
    };

    const saveToLibrary = (event: AnalyticsEventSchema) => {
        updateToBeSaved(event);
        updateShowAlert(true);
    };

    const findDuplicatesEvents = (event: AnalyticsEventSchema) => {
        const findDuplicatesEventData = (
            eventData?: AnalyticsEventDataSchema[]
        ) => {
            const keys = eventData?.map(item => {
                return item.key;
            });
            return keys?.some((item, idx) => {
                return keys.indexOf(item) !== idx;
            });
        };

        if (!currentSchema) {
            return false;
        }
        const duplicates = currentSchema.events.filter(element => {
            return (
                element.eventName === event.eventName ||
                findDuplicatesEventData(event.eventData)
            );
        });
        return duplicates.length > 1;
    };

    const isEqualToLib = (event: AnalyticsEventSchema) => {
        if (!events) {
            return false;
        }

        return events.some(e => {
            const element = JSON.parse(JSON.stringify(e));
            delete element.id;
            delete element.__typename;
            return (
                element.eventName === event.eventName &&
                element.comment === event.comment &&
                JSON.stringify(element.eventData) ===
                    JSON.stringify(event.eventData)
            );
        });
    };

    const reorder = () => {
        if (
            currentSchema?.events[dragOverItem.current].frozen ||
            !dragOverItem
        ) {
            return;
        }
        const newSchema = { ...JSON.parse(JSON.stringify(currentSchema)) };
        const draggedItemContent = newSchema.events.splice(
            dragItem.current,
            1
        )[0];

        newSchema.events.splice(dragOverItem.current, 0, draggedItemContent);

        dragItem.current = dragOverItem.current;
        dragOverItem.current = null;

        updateCurrentSchema({
            events: newSchema.events
        });
    };

    const analyticsEvents = currentSchema?.events.map(
        (event: AnalyticsEventSchema, index: number) => {
            return (
                <AnalyticsEventItem
                    defaultValue={event}
                    index={index}
                    hasDuplicatesEvents={findDuplicatesEvents(event)}
                    isEqualToLib={isEqualToLib(event)}
                    key={event.eventName + index}
                    saveToLibrary={saveToLibrary}
                    updateAnalyticsSchema={updateAnalyticsSchema}
                    dragStart={() => {
                        dragItem.current = index;
                        updateDragDone(false);
                    }}
                    dragEnter={() => {
                        dragOverItem.current = index;
                        if (dragItem.current !== dragOverItem.current) {
                            reorder();
                        }
                    }}
                    dragEnd={() => {
                        dragItem.current = null;
                        updateDragDone(true);
                    }}
                    isDragged={dragItem.current === index}
                />
            );
        }
    );

    Prism.highlightAll();

    const updateSchemaDescription = (description: string) => {
        if (currentSchema) {
            updateCurrentSchema({
                ...currentSchema,
                description: description
            });
        }
    };

    return (
        <Container className="designerView px-0 px-sm-3">
            <Card className="my-4">
                <Card.Header className="px-3">
                    <Row className="d-flex align-contents-center">
                        <Col className="col-12 col-sm-2 d-flex align-items-center">
                            <h5 className="text-inverse-50">Events</h5>
                        </Col>
                        <Col className="col-12 col-sm-10">
                            <div className="d-flex align-items-center justify-content-end">
                                <Button
                                    className="btn-info m-1"
                                    onClick={() => updateShowImportModal(true)}
                                >
                                    Import
                                </Button>
                                <Button
                                    className="btn-success m-1"
                                    as={"a"}
                                    href={`data:text/json;charset=utf-8,${encodeURIComponent(
                                        JSON.stringify(currentSchema, null, 2)
                                    )}`}
                                    download={"analyticsSchema.json"}
                                >
                                    Export
                                </Button>
                                <Button
                                    className="btn-analytics m-1"
                                    onClick={() =>
                                        updateShowEventLibraryModal(true)
                                    }
                                >
                                    Event Library
                                </Button>
                                <Button className="m-1" onClick={addNewEvent}>
                                    Add Event
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Card.Header>
                <Card.Body className="px-0 px-sm-2">
                    {analyticsEvents && analyticsEvents.length > 0 ? (
                        analyticsEvents
                    ) : (
                        <div className="text-inverse-50 text-center">
                            Events list is empty
                        </div>
                    )}
                    <SchemaDescription
                        updateCurrentSchema={updateSchemaDescription}
                        defaultValue={currentSchema?.description}
                    />
                </Card.Body>
            </Card>
            <Tab.Container activeKey={selectedTab}>
                <Nav
                    variant="tabs"
                    className="tabs-inverse"
                    onSelect={eventKey => {
                        updateSelectedTab(eventKey || "preview");
                    }}
                >
                    <Nav.Item>
                        <Nav.Link eventKey="preview">Preview</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="guide">Guide</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="schema">Schema</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="snippets">Snippets</Nav.Link>
                    </Nav.Item>
                </Nav>
                <Tab.Content>
                    <Tab.Pane eventKey="preview" className="text-inverse">
                        <FunnelElementsPreview schema={currentSchema} />
                    </Tab.Pane>
                    <Tab.Pane eventKey="guide" className="text-inverse">
                        <AnalyticsSchemaGuide />
                    </Tab.Pane>
                    <Tab.Pane eventKey="schema">
                        <Card className="mt-3 mb-1">
                            <Card.Header className="text-inverse-50">
                                <h5>Analytics Schema</h5>
                            </Card.Header>
                            <Card.Body>
                                <pre>
                                    <code className="language-json">
                                        {JSON.stringify(currentSchema, null, 2)}
                                    </code>
                                </pre>
                            </Card.Body>
                        </Card>
                    </Tab.Pane>
                    <Tab.Pane eventKey="snippets">
                        <AnalyticsSchemaSnippets schema={currentSchema} />
                    </Tab.Pane>
                </Tab.Content>
            </Tab.Container>
            <ImportAnalyticsModal
                show={showImportModal}
                updateSchema={updateCurrentSchema}
                onClose={() => updateShowImportModal(!showImportModal)}
            />
            <AnalyticsEventLibraryModal
                show={showEventLibraryModal}
                analyticsEvents={events}
                updateSchema={addNewLibraryEvent}
                onClose={() =>
                    updateShowEventLibraryModal(!showEventLibraryModal)
                }
            />
            <AnalyticsEventUploadModal
                key={Date.now()}
                show={showAlert}
                analyticsEvent={toBeSaved}
                updateSchemaEvent={() => {
                    updateLibraryEvent(toBeSaved);
                    updateShowAlert(false);
                }}
                onClose={() => updateShowAlert(false)}
            />
        </Container>
    );
};

export const AnalyticsSchemaDesignerView = React.memo(
    AnalyticsSchemaDesignerViewImplementation
);
