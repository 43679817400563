import React, { FC, useState } from "react";
import {
    ConfiguratorData,
    RevisionConfigData,
    RevisionConfigInputVariables,
    RevisionVariables
} from "../../../models/types";
import { useMutation, useQuery } from "@apollo/client";
import { GET_REVISION_CONFIG_CONFIGURATOR } from "../../../graphql/queries";
import { UPDATE_REVISION_CONFIG_CONFIGURATOR } from "../../../graphql/mutations";
import { EditableJsonView } from "../../editable/EditableJsonView";
import "../views.scss";

interface Props {
    revisionId: string;
    developerMode: boolean;
}

const ConfiguratorImplementation: FC<Props> = ({
    revisionId,
    developerMode
}) => {
    const [updating, updateUpdating] = useState(false);
    const { loading: configuratorLoading, data: { revisionConfig } = {} } =
        useQuery<RevisionConfigData, RevisionVariables>(
            GET_REVISION_CONFIG_CONFIGURATOR,
            {
                variables: { revisionId: Number(revisionId) }
            }
        );

    const [updateConfiguratorMutation] = useMutation<
        RevisionConfigData,
        RevisionConfigInputVariables
    >(UPDATE_REVISION_CONFIG_CONFIGURATOR);

    const updateConfigurator = async (configuratorData: ConfiguratorData) => {
        updateUpdating(true);
        const input = {
            revisionId: Number(revisionId),
            configurator: configuratorData
        };
        try {
            await updateConfiguratorMutation({
                variables: {
                    input: input
                }
            });
        } catch (error) {
            console.log("[DEBUG] updateConfigurator error ", error);
        }
        updateUpdating(false);
    };

    if (
        !developerMode ||
        configuratorLoading ||
        !revisionConfig?.configurator
    ) {
        return null;
    }

    return (
        <EditableJsonView
            jsonData={JSON.stringify(revisionConfig.configurator, null, 4)}
            updateJsonData={updateConfigurator}
            updating={updating}
        />
    );
};

export const Configurator = React.memo(ConfiguratorImplementation);
