import React, { FC } from "react";
import { Row } from "react-bootstrap";
import { Variation } from "../../../models/types";
import { VariationViewItemProps } from "../../../models/common";
import { VariationItem } from "../../variationItems/VariationItem";
import { VariationItemList } from "../../variationItems/VariationItemList";
import "../views.scss";

export const Variations: FC<VariationViewItemProps> = props => {
    const { revision, developerMode, shouldBeVisible } = props;

    const allowedSets = revision?.revisionConfig?.sets?.filter(
        set => !revision?.revisionConfig?.setBlacklist?.includes(set)
    );

    const version = revision?.buildData?.version || -1;

    const items = revision?.buildData?.variations.map((object: Variation) =>
        developerMode ? (
            <VariationItem
                key={object.id}
                defaultValue={object}
                revision={revision}
                version={version}
                allowedSets={allowedSets}
                shouldBeVisible={shouldBeVisible}
            />
        ) : (
            <VariationItemList
                key={object.id}
                defaultValue={object}
                revision={revision}
            />
        )
    );
    return <Row className="fullSize">{items}</Row>;
};
