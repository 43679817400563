import { AssetBundle, Mutator } from "../models/types";

const getScriptTag = async (
    type: string,
    value: unknown,
    scripts: string[],
    checkedEnums: Set<string>,
    mutatorConfig: Mutator,
    mutatorAssetbundles: AssetBundle[]
) => {
    if (type === "assetbundle") {
        const assetbundle = mutatorAssetbundles.find(
            element => element.path === value
        );

        if (assetbundle) {
            const response = await fetch(assetbundle.url);
            const text = await response.text();
            scripts.push(`<script>${text}</script>`);
        }
    } else if (type.startsWith("@")) {
        const enumEntry = mutatorConfig.enums[type];
        if (enumEntry) {
            const option = enumEntry.options.find(
                option => option.name === value
            );
            if (option) {
                const enumId = `${type}.${value}`;
                if (!checkedEnums.has(enumId)) {
                    checkedEnums.add(enumId);
                    await getScriptTag(
                        enumEntry.type,
                        option.value,
                        scripts,
                        checkedEnums,
                        mutatorConfig,
                        mutatorAssetbundles
                    );
                }
            }
        }
    } else if (type.startsWith("$")) {
        const typeEntry = mutatorConfig.types[type];
        if (typeEntry) {
            for (const property of typeEntry.properties) {
                await getScriptTag(
                    property.type,
                    (value as Record<string, unknown>)[property.name],
                    scripts,
                    checkedEnums,
                    mutatorConfig,
                    mutatorAssetbundles
                );
            }
        }
    }
};

export const fetchHtmlFromS3 = async (
    generateLink: string,
    mutatorConfig: Mutator | undefined,
    mutatorAssetbundles: AssetBundle[] | undefined
): Promise<string | undefined> => {
    try {
        if (!mutatorConfig || !mutatorAssetbundles) {
            return;
        }
        const response = await fetch(generateLink);
        if (!response.ok) {
            console.log(
                "[DEBUG] Failed to fetch the HTML file from S3, no build yet or a failed build"
            );
            return;
        }

        const htmlData = await response.text();
        const scriptStrings: string[] = [];
        const enums = new Set<string>();
        for (const property of mutatorConfig.properties) {
            await getScriptTag(
                property.type,
                property.value,
                scriptStrings,
                enums,
                mutatorConfig,
                mutatorAssetbundles
            );
        }
        scriptStrings.push(
            `<script>window.mutator = ${JSON.stringify(
                mutatorConfig
            )};</script>`
        );
        const finalHtmlText = htmlData.replace(
            "<!-- mutator string -->",
            scriptStrings.join("\n\t")
        );
        // setIFrameContent(finalHtmlText);
        return finalHtmlText;
    } catch (error) {
        console.error("Error fetching the file:", error);
        return undefined;
    }
};
