import React from "react";
import { LogCard, LogType } from "../../models/types";
import { Accordion, Badge, Card } from "react-bootstrap";
import "./BatchBuildReport.module.scss";

const IsJson = (json: string) => {
    try {
        JSON.parse(json);
    } catch (e) {
        return false;
    }
    return true;
};

const TypeToVariant = ["", "primary", "warning", "danger"];
const TypeToText = ["", "Info", "Warning", "Error"];

export const BuildLogsModalLogCard = (
    log: any,
    activeKey: number | undefined
) => {
    if (!log) {
        const logCard: LogCard = {
            card: <p className="m-3 bread-text">No log found</p>,
            warnings: 0,
            errors: 0
        };
        return logCard;
    }
    let warnings = 0;
    let errors = 0;

    const jsonLogs: LogType[] = IsJson(log)
        ? JSON.parse(log)
        : JSON.parse(JSON.stringify(log));
    const mapped = jsonLogs.map((obj, index) => {
        if (obj.type === 2) {
            warnings++;
        } else if (obj.type === 3) {
            errors++;
        }

        return (
            <Accordion.Item eventKey={index.toString()} key={index}>
                <Accordion.Header className="mouseHover">
                    {obj.event}
                    {obj.duration > 0.009 ? (
                        <Badge className="ms-2" bg="info">
                            Duration: {obj.duration.toFixed(2)}s
                        </Badge>
                    ) : (
                        <Badge className="ms-2" bg="info">
                            Duration: 0.01s
                        </Badge>
                    )}
                    {obj.type !== 1 ? (
                        <Badge className="ms-2" bg={TypeToVariant[obj.type]}>
                            {TypeToText[obj.type]}
                        </Badge>
                    ) : null}
                </Accordion.Header>
                <Accordion.Body className="p-2">
                    {IsJson(obj.log) ? (
                        <pre>
                            {JSON.stringify(JSON.parse(obj.log), undefined, 4)}
                        </pre>
                    ) : (
                        <pre>
                            {typeof obj.log === "object"
                                ? JSON.stringify(obj.log, undefined, 4)
                                : obj.log}
                        </pre>
                    )}
                </Accordion.Body>
            </Accordion.Item>
        );
    });

    const logCard: LogCard = {
        card: (
            <Accordion defaultActiveKey={String(activeKey)}>{mapped}</Accordion>
        ),
        warnings: warnings,
        errors: errors
    };
    return logCard;
};
