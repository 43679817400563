import React, { FC, ReactElement } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { DropdownAction } from "../../models/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import styles from "../../components/dropdowns/Dropdowns.module.scss";

interface Props {
    header?: string | ReactElement;
    variant?: string;
    actions: DropdownAction[];
    className?: string;
    id?: string;
}

export const ActionDropdown: FC<Props> = ({
    header,
    variant,
    actions,
    className = "",
    id
}) => {
    const generateAlternatives = actions?.map(
        (action: DropdownAction, index: number) => (
            <Dropdown.Item
                key={index}
                eventKey={index.toString()}
                onClick={e => {
                    action.callback();
                }}
                className={`${styles.dropdownItem}  text-${action.variant} ${action.className}`}
            >
                {action.header}
            </Dropdown.Item>
        )
    );

    return (
        <DropdownButton
            onClick={e => {
                e.preventDefault();
            }}
            id={id}
            variant="link"
            className={`${styles.dropdownButton} ${className}`}
            title={<FontAwesomeIcon icon={faBars} className="text-inverse" />}
        >
            {generateAlternatives}
        </DropdownButton>
    );
};
