import React, { FC, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useLazyQuery, useSubscription } from "@apollo/client";
import {
    Project,
    ProjectsData,
    ProjectVariables,
    SortingMode
} from "../../models/types";
import { GET_PROJECTS_BY_PRODUCT } from "../../graphql/queries";
import { Project as ProjectView } from "../views/Project";
import {
    PROJECT_CHANGED_SUBSCRIPTION,
    PROJECT_METADATA_CHANGED_SUBSCRIPTION
} from "../../graphql/subscriptions";
import { sortItems } from "../../common/Helpers";
import { useLocalState, useSortingState } from "../../graphql/hooks";
import "./views.scss";

interface Props {
    productId: number;
    show: boolean;
    // sortingSettings: SortingData;
    updateLoaded: (loaded: boolean) => void;
}

const ClientViewProjectsImplementation: FC<Props> = ({
    productId,
    show,
    // sortingSettings,
    updateLoaded
}) => {
    // const client = useApolloClient();
    const [childrenLoaded, setChildrenLoaded] = useState(false);
    const [sortedProjects, updateSortedProjects] = useState<Project[]>([]);
    const state = useLocalState();
    const sortingState = useSortingState();
    const [
        fetchProjects,
        // @ts-ignore
        { loading, data: { projects } = {}, error }
    ] = useLazyQuery<ProjectsData, ProjectVariables>(GET_PROJECTS_BY_PRODUCT, {
        variables: { productId: productId, searchTerm: state.searchTerm }
    });

    useEffect(() => {
        if (show && !projects) {
            fetchProjects();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show]);

    useEffect(() => {
        if (!loading && projects && childrenLoaded) {
            updateLoaded(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading, projects, childrenLoaded]);

    useEffect(() => {
        if (projects) {
            const sortingMode =
                sortingState.sortingPriorities[1] || SortingMode.None;
            if (sortingMode === SortingMode.None) {
                updateSortedProjects(projects);
                return;
            }
            const sorted = [...projects];
            sorted.sort((a, b) => sortItems(a, b, sortingMode));
            updateSortedProjects(sorted);
        }
    }, [sortingState.sortingPriorities, projects, productId]);

    // useEffect(() => {
    //     if (projects) {
    //         const sorted = [...projects];
    //         if (sortingSettings.project !== SortingMode.None) {
    //             sorted.sort((first: Project, second: Project) => {
    //                 if (sortingSettings.isNameBased) {
    //                     return sortingSettings.project ===
    //                         SortingMode.NameAscending
    //                         ? first.name.localeCompare(second.name)
    //                         : second.name.localeCompare(first.name);
    //                 }
    //                 return sortingSettings.project === SortingMode.DateAscending
    //                     ? dateComparison(second.dateUpdated, first.dateUpdated)
    //                     : dateComparison(first.dateUpdated, second.dateUpdated);
    //             });
    //         }

    //         updateSortedProjects(sorted);
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [sortingSettings.project, projects, productId]);

    useSubscription(PROJECT_CHANGED_SUBSCRIPTION, {
        variables: { productId: productId },
        onData: ({
            data: { data: { projectChangedNotification } = {} },
            client
        }) => {
            const currentProjectData = client.cache.readQuery<
                ProjectsData,
                ProjectVariables
            >({
                variables: { productId: productId },
                query: GET_PROJECTS_BY_PRODUCT
            });

            const currentProjects = currentProjectData?.projects;

            if (currentProjects && projectChangedNotification) {
                if (projectChangedNotification.action !== 0) {
                    const newProjects = currentProjects.filter(
                        project =>
                            Number(project.id) !==
                            Number(projectChangedNotification.project.id)
                    );

                    if (projectChangedNotification.action === 1) {
                        client.cache.writeQuery<ProjectsData, ProjectVariables>(
                            {
                                query: GET_PROJECTS_BY_PRODUCT,
                                data: {
                                    projects: newProjects
                                        ? [
                                              ...newProjects,
                                              projectChangedNotification.project
                                          ]
                                        : [projectChangedNotification.project]
                                },
                                variables: { productId: productId }
                            }
                        );
                    } else if (projectChangedNotification.action === 2) {
                        client.cache.writeQuery<ProjectsData, ProjectVariables>(
                            {
                                query: GET_PROJECTS_BY_PRODUCT,
                                data: { projects: newProjects },
                                variables: { productId: productId }
                            }
                        );
                    }
                } else {
                    client.cache.writeQuery<ProjectsData, ProjectVariables>({
                        query: GET_PROJECTS_BY_PRODUCT,
                        data: {
                            projects: currentProjects
                                ? [
                                      ...currentProjects,
                                      projectChangedNotification.project
                                  ]
                                : [projectChangedNotification.project]
                        },
                        variables: { productId: productId }
                    });
                }
            }
        }
    });

    useSubscription(PROJECT_METADATA_CHANGED_SUBSCRIPTION, {
        variables: { productId: productId },
        onData: ({
            data: { data: { projectMetaChangedNotification } = {} }
        }) => {
            console.log(
                "[DEBUG] projectMetaChangedNotification ",
                projectMetaChangedNotification
            );
        }
    });

    if (error) {
        return (
            <Container className="fullSize widthProducts p-2 pt-5">
                <p>Error: {error.message}</p>
            </Container>
        );
    }

    const items = sortedProjects?.map((project: Project) => (
        <ProjectView
            key={project.id}
            project={project}
            showChildren={true}
            updateLoaded={setChildrenLoaded}
            // sortingSettings={sortingSettings}
            // skipSearch={skipSearch}
        />
    ));

    if (!items || items.length === 0) {
        return (
            <div className="text-inverse-50 bread-text text-start m-2 ps-4">
                No projects currently in development.
            </div>
        );
    }

    return <Container className="fullSizeChild px-1">{items}</Container>;
};

export const ClientViewProjects = React.memo(ClientViewProjectsImplementation);
