import React, { FC } from "react";
import { useLocalAnalyticsState } from "../../../graphql/hooks";
import { useQuery } from "@apollo/client";
import {
    DailyAnalyticsBuildData,
    DailyAnalyticBuildVariables
} from "../../../models/types";
import { GET_DAILY_BUILD_ANALYTIC } from "../../../graphql/queries";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

interface Props {
    id: number;
}

const DailyNetworkBuildAnalyticsImplementation: FC<Props> = ({ id }) => {
    const { analyticsEnabled } = useLocalAnalyticsState();
    const { data: { getDailyBuildAnalytic } = {} } = useQuery<
        DailyAnalyticsBuildData,
        DailyAnalyticBuildVariables
    >(GET_DAILY_BUILD_ANALYTIC, {
        variables: { id: id },
        skip: !analyticsEnabled
    });

    if (!getDailyBuildAnalytic || getDailyBuildAnalytic.impressions <= 0) {
        return null;
    }
    const gameplay = getDailyBuildAnalytic.gameplay || 0;
    const endScreen = getDailyBuildAnalytic.endScreen || 0;
    const ctr = (
        ((gameplay + endScreen) / getDailyBuildAnalytic.impressions) *
        100
    ).toFixed(1);

    return (
        <div className="d-flex analytics-font float-end px-2">
            <OverlayTrigger
                overlay={
                    <Tooltip id="network-impressions-tooltip">
                        Impressions - previous day
                    </Tooltip>
                }
                delay={{
                    show: 100,
                    hide: 200
                }}
            >
                <div className="me-2">
                    {getDailyBuildAnalytic.impressions.toLocaleString()}
                </div>
            </OverlayTrigger>
            <div className="text-secondary">|</div>
            <OverlayTrigger
                overlay={
                    <Tooltip id="network-ctr-tooltip">
                        CTR - previous day
                    </Tooltip>
                }
                delay={{
                    show: 100,
                    hide: 200
                }}
            >
                <div style={{ whiteSpace: "nowrap" }} className="ms-2">
                    {ctr} %
                </div>
            </OverlayTrigger>
        </div>
    );
};

export const DailyNetworkBuildAnalytics = React.memo(
    DailyNetworkBuildAnalyticsImplementation
);
