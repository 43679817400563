import React, { FC } from "react";
import { Badge, OverlayTrigger, Tooltip } from "react-bootstrap";

interface Props {
    text: string;
    variant: string;
    children: React.ReactNode;
}

export const BadgeOverlay: FC<Props> = ({ text, variant, children }) => {
    return (
        <OverlayTrigger
            key={`${text}-tooltip`}
            placement="top"
            overlay={<Tooltip id={`tooltip-${text}`}>{text}</Tooltip>}
        >
            <Badge className="ms-1" bg={variant}>
                {children}
            </Badge>
        </OverlayTrigger>
    );
};
