import React, { FC, useEffect, useState } from "react";
import { Badge, Card, Col, Collapse, Row } from "react-bootstrap";
import {
    AssetBundle,
    MutatorEnums,
    MutatorProperty
} from "../../../models/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faChevronDown,
    faChevronUp,
    faCircleInfo
} from "@fortawesome/free-solid-svg-icons";
import { MutatorStringFieldCard } from "./MutatorStringFieldCard";
import { MutatorNumberFieldCard } from "./MutatorNumberFieldCard";
import { MutatorAssetbundleFieldCard } from "./MutatorAssetbundleFieldCard";
import { MutatorEnumFieldCard } from "./MutatorEnumFieldCard";
import { useMutatorState } from "../../../graphql/hooks";
import styles from "./CustomTypeCard.module.scss";
import cx from "classnames";

interface Props {
    property: MutatorProperty | any;
    assetbundles: AssetBundle[];
    enums: MutatorEnums;
    customType: any;
    getVariantByType: (type: string) => string;
    onChange: (name: string, value: any) => void;
}

const CustomTypeObjectCardImplementation: FC<Props> = ({
    property,
    assetbundles,
    enums,
    customType,
    getVariantByType,
    onChange
}) => {
    const mutatorState = useMutatorState();
    const [showMeta, updateShowMeta] = useState(mutatorState.showMeta);
    const [showSpecifications, updateShowSpecifications] = useState(false);

    useEffect(() => {
        if (showMeta !== mutatorState.showMeta) {
            updateShowMeta(mutatorState.showMeta);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mutatorState.showMeta]);

    const changeProperty = (
        fieldPropertyName: string,
        value: string | number | any
    ) => {
        const newValue = {
            ...property.value,
            [fieldPropertyName]: value
        };
        onChange(property.name, newValue);
    };

    const renderInput = (fieldProperty: any) => {
        const typeProperty = customType.properties.find(
            (element: any) =>
                element.type === fieldProperty.type &&
                element.name === fieldProperty.name
        );
        if (!typeProperty) {
            console.log("[DEBUG] missing type property from custom type");
            return null;
        }
        if (fieldProperty.type === "string") {
            return (
                <MutatorStringFieldCard
                    fieldProperty={fieldProperty}
                    typeProperty={typeProperty}
                    getVariantByType={getVariantByType}
                    onChange={changeProperty}
                />
            );
        } else if (fieldProperty.type === "number") {
            return (
                <MutatorNumberFieldCard
                    fieldProperty={fieldProperty}
                    typeProperty={typeProperty}
                    getVariantByType={getVariantByType}
                    onChange={changeProperty}
                />
            );
        } else if (fieldProperty.type === "assetbundle") {
            return (
                <MutatorAssetbundleFieldCard
                    fieldProperty={fieldProperty}
                    typeProperty={typeProperty}
                    assetbundles={assetbundles.filter(element =>
                        element.path.includes(typeProperty.meta.path)
                    )}
                    getVariantByType={getVariantByType}
                    onChange={changeProperty}
                />
            );
        } else if (fieldProperty.type.includes("@")) {
            return (
                <MutatorEnumFieldCard
                    fieldProperty={fieldProperty}
                    typeProperty={typeProperty}
                    enums={enums}
                    customType={customType}
                    getVariantByType={getVariantByType}
                    onChange={changeProperty}
                />
            );
        } else {
            return null;
        }
    };

    return (
        <Card
            className="m-2 inner-card text-inverse"
            key={property.name}
            id={property.name}
        >
            <Card.Header
                className={`d-flex align-items-center ${styles.cardHeader}`}
            >
                <div className="d-flex align-items-center">
                    <span className={styles.headerText}>{property.name}</span>
                    <Badge
                        className="ms-2"
                        bg={getVariantByType(property.type)}
                    >
                        {property.type}
                    </Badge>
                    <div
                        className="ms-2 mouseHover text-inverse-50"
                        onClick={() =>
                            updateShowSpecifications(!showSpecifications)
                        }
                        title={
                            showSpecifications ? "Hide Details" : "Show Details"
                        }
                    >
                        <FontAwesomeIcon
                            size="xs"
                            icon={
                                showSpecifications ? faChevronUp : faChevronDown
                            }
                        />
                    </div>
                </div>
                {property.meta ? (
                    <div
                        className="ms-auto float-end mouseHover"
                        onClick={() => updateShowMeta(!showMeta)}
                        title="Help and additional info"
                    >
                        <FontAwesomeIcon
                            icon={faCircleInfo}
                            className={cx({
                                "text-inverse-25": !showMeta,
                                "text-inverse-50": showMeta
                            })}
                        />
                    </div>
                ) : null}
            </Card.Header>
            <Collapse in={showSpecifications}>
                <div>
                    <Card.Body className={styles.customObjectCardBody}>
                        <Row>
                            {customType.properties.map((field: any) => (
                                <Col key={field.name} className="px-2 pb-2">
                                    {renderInput({
                                        name: field.name,
                                        type: field.type,
                                        value: property.value[field.name]
                                    })}
                                </Col>
                            ))}
                        </Row>
                    </Card.Body>
                </div>
            </Collapse>
            {property.meta ? (
                <Collapse in={showMeta}>
                    <div>
                        <Card.Footer className={styles.footerInfo}>
                            {property.meta.description ? (
                                <div className="bread-text text-inverse-50">
                                    {property.meta.description}
                                </div>
                            ) : (
                                <span className="bread-text text-inverse-50">
                                    No description provided.
                                </span>
                            )}
                        </Card.Footer>
                    </div>
                </Collapse>
            ) : null}
        </Card>
    );
};

export const CustomTypeObjectCard = React.memo(
    CustomTypeObjectCardImplementation
);
