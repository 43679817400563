import React, { FC, useEffect, useState } from "react";
import { Badge, Card, Col, InputGroup, Row } from "react-bootstrap";
import {
    Build,
    PartialRevisionData,
    PartialRevisionVariables
} from "../../models/types";
import { VariationItemProps } from "../../models/common";
import { useApolloClient, useMutation } from "@apollo/client";
import { PARTIAL_UPDATE_REVISION } from "../../graphql/mutations";
import { updateRevisionPartially, validateBuild } from "../../common/Helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { AlertModal } from "../modals/AlertModal";
import "./variationItems.scss";

interface BuildProps extends VariationItemProps {
    defaultValue: Build;
    version: number;
}

export const BuildItemList: FC<BuildProps> = ({
    revision,
    defaultValue,
    version
}) => {
    const client = useApolloClient();
    const [build, setBuild] = useState(defaultValue);
    const [currentVersion, setCurrentVersion] = useState(version);
    const [hasBlacklistedSet, setHasBlacklistedSet] = useState(false);
    const [showAlertModal, setShowAlertModal] = useState(false);
    const toggleAlertModal = () => {
        setShowAlertModal(!showAlertModal);
    };
    const [showWarning, setShowWarning] = useState(false);
    const [partialUpdateRevision] = useMutation<
        PartialRevisionData,
        PartialRevisionVariables
    >(PARTIAL_UPDATE_REVISION);
    const updateBuilds = async (buildData?: Build) => {
        if (revision) {
            if (!buildData) {
                buildData = build;
            }
            // updateRevision({ builds: [buildData] });
            await updateRevisionPartially(
                client,
                revision,
                partialUpdateRevision,
                { builds: [buildData] }
            );
        }
    };

    useEffect(() => {
        if (defaultValue && currentVersion < version) {
            setCurrentVersion(version);
            setBuild(defaultValue);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultValue, version]);

    useEffect(() => {
        const buildValidation = validateBuild(build, revision?.buildData);
        if (buildValidation.valid === showWarning) {
            setShowWarning(!buildValidation.valid);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [build]);

    const blacklist = revision?.revisionConfig?.setBlacklist;
    useEffect(() => {
        if (blacklist && build.sets) {
            let hasSet = false;
            for (const set of build.sets) {
                if (blacklist.includes(set)) {
                    hasSet = true;
                    break;
                }
            }
            setHasBlacklistedSet(hasSet);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [build.sets, build.include, blacklist]);

    const sizeBadgeVariant = (size: number) => {
        switch (size) {
            case 2:
                return "density-1";
            case 3:
                return "density-2";
            case 5:
                return "density-3";
            default:
                return "primary";
        }
    };
    const durationBadgeVariant = (duration: string) => {
        switch (duration) {
            case "15s":
                return "info";
            case "30s":
                return "info-2";
            default:
                return "primary";
        }
    };

    if (hasBlacklistedSet) {
        return null;
    }

    return (
        <Card className="fullSize inner-card mb-2 text-inverse">
            <Card.Header className="p-2">
                <Row className="d-flex justify-content-start align-items-center">
                    <Col className="col-1 d-flex">
                        <div>
                            <InputGroup className="custom-input-background">
                                <InputGroup.Checkbox
                                    className="mouseHover"
                                    title="Include in next Build set"
                                    aria-label="Include in next Build set"
                                    checked={build.include}
                                    onClick={(event: any) => {
                                        event.stopPropagation();
                                    }}
                                    onChange={(event: any) => {
                                        const newBuildData = {
                                            ...build,
                                            include: event.target.checked
                                        };
                                        updateBuilds(newBuildData);
                                    }}
                                />
                            </InputGroup>
                        </div>
                        {showWarning && (
                            <div>
                                <FontAwesomeIcon
                                    icon={faTriangleExclamation}
                                    className="text-warning mt-1 mouseHover"
                                    onClick={() => setShowAlertModal(true)}
                                />
                            </div>
                        )}
                    </Col>
                    <Col title="Base version name" className="col-4">
                        {build.name}
                    </Col>
                    <Col className="col-2 d-flex align-items-center">
                        <Badge
                            bg={sizeBadgeVariant(build.size)}
                            className="ms-2 text-white"
                            title="Size"
                        >
                            {build.size} MB
                        </Badge>
                        <Badge
                            title="Duration"
                            bg={durationBadgeVariant(build.duration)}
                            className="ms-2 text-white"
                        >
                            {build.duration}
                        </Badge>
                    </Col>
                    <Col title="Description" className="col-5">
                        <div className="bread-text">{build.description}</div>
                    </Col>
                </Row>
            </Card.Header>
            {showAlertModal && (
                <AlertModal
                    visible={toggleAlertModal}
                    header={<h5>Network Configuration Warning</h5>}
                    message={
                        <>
                            <p>
                                Selected base version has size that is not
                                supported by any of the currently selected
                                networks.
                            </p>
                            <p>
                                Builds with no eligible network will not be
                                generated in the bundle.
                            </p>
                            <p>
                                If you need a base version with a different
                                size, contact the development team.
                            </p>
                        </>
                    }
                    footer={null}
                    component={null}
                />
            )}
        </Card>
    );
};
