import React, { FC, useState } from "react";
import { Dropdown, FormControl } from "react-bootstrap";
import { AssetBundle, MutatorProperty } from "../../../models/types";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import styles from "./MutatorDropdown.module.scss";

interface Props {
    property: MutatorProperty | any;
    assetbundles: AssetBundle[];
    getVariantByType: (type: string) => string;
    onChange: (name: string, value: string) => void;
}

const AssetbundleDropdownImplementation: FC<Props> = ({
    property,
    assetbundles,
    getVariantByType,
    onChange
}) => {
    const [searchString, updateSearchString] = useState<string>("");
    const showSearch = assetbundles.length > 8;
    const sortedItems =
        showSearch && searchString !== ""
            ? assetbundles.filter(element =>
                  element.name
                      .toLowerCase()
                      .includes(searchString.toLowerCase())
              )
            : assetbundles;
    const sortedElements = sortedItems.map((element: any, index: number) => (
        <Dropdown.Item
            key={index}
            onClick={() => {
                onChange(property.name, element.path);
            }}
        >
            {element.name}
        </Dropdown.Item>
    ));

    return (
        <>
            <Dropdown id={property.name}>
                <Dropdown.Toggle
                    className={styles.toggleButton}
                    variant={getVariantByType(property.type)}
                    id="dropdown-basic"
                >
                    {property.value.split("/").pop()}
                </Dropdown.Toggle>
                <DropdownMenu
                    style={{
                        maxHeight: "400px",
                        overflowY: "auto"
                    }}
                >
                    {showSearch ? (
                        <div className="sticky-top mt-0 px-2 pt-0 pb-1 w-100 bg-dark">
                            <FormControl
                                type="text"
                                placeholder="Search..."
                                value={searchString}
                                onChange={e =>
                                    updateSearchString(e.target.value)
                                }
                            />
                        </div>
                    ) : null}
                    {sortedElements}
                </DropdownMenu>
            </Dropdown>
        </>
    );
};

export const AssetbundleDropdown = React.memo(
    AssetbundleDropdownImplementation
);
