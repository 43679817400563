import React, { FC, useEffect, useState } from "react";
import { Badge, Card, Collapse, Modal } from "react-bootstrap";
import {
    BatchBuildData,
    BatchBuildVariables,
    LogCard,
    PipelineLogsData,
    PipelineLogsVariables,
    ReportData,
    ReportDataV2
} from "../../models/types";
import { BuildLogsModalLogCard } from "../simple/BuildLogsModalLogCard";
import { useLazyQuery, useQuery } from "@apollo/client";
import { GET_PIPELINE_LOGS, GET_RESULT } from "../../graphql/queries";
import { BuildLogsCard } from "../cards/BuildLogsCard";
import { useLocalState } from "../../graphql/hooks";

interface Props {
    batchBuildId: number;
    show: boolean;
    onClose: () => void;
    job: string | undefined;
    id: number | undefined;
    targetIndex: number | undefined;
    buildReport: ReportData | ReportDataV2 | undefined;
}

export const BuildLogsModal: FC<Props> = ({
    batchBuildId,
    show,
    onClose,
    job,
    id,
    targetIndex,
    buildReport
}) => {
    const [showStartJob, updateShowStartJob] = useState(
        job === "start_job" ?? false
    );
    const [showCleanJob, updateShowCleanJob] = useState(
        job === "clean_job" ?? false
    );
    const [showNotifyJob, updateShowNotifyJob] = useState(
        job === "notify_job" ?? false
    );

    const { useDarkMode } = useLocalState();

    const { loading, data: { getPipelineLogs } = {} } = useQuery<
        PipelineLogsData,
        PipelineLogsVariables
    >(GET_PIPELINE_LOGS, {
        variables: {
            buildId: batchBuildId
        }
    });
    console.log("[DEBUG] getPipelineLogs ", getPipelineLogs);

    // @ts-ignore
    const [getBuildReport, { data: { build } = {} }] = useLazyQuery<
        BatchBuildData,
        BatchBuildVariables
    >(GET_RESULT, {
        variables: { id: Number(batchBuildId) }
    });

    useEffect(() => {
        if (!buildReport) {
            getBuildReport();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [buildReport]);

    const startLogCard: LogCard = BuildLogsModalLogCard(
        getPipelineLogs?.startJob,
        job === "start_job" ? id : undefined
    );
    const cleanLogCard: LogCard = BuildLogsModalLogCard(
        getPipelineLogs?.cleanJob,
        job === "clean_job" ? id : undefined
    );
    const notifyLogCard: LogCard = BuildLogsModalLogCard(
        getPipelineLogs?.notifyJob,
        job === "notify_job" ? id : undefined
    );

    if (build && build.reportData) {
        console.log(">", build.reportData);
    }

    if (loading) {
        return (
            <Modal
                show={show}
                onHide={onClose}
                size="xl"
                className={useDarkMode ? "modal-dark" : ""}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Pipeline Logs</Modal.Title>
                </Modal.Header>
                <Modal.Body className="d-flex justify-content-center align-content-center">
                    <p className="text-center">Fetching logs...</p>
                </Modal.Body>
            </Modal>
        );
    }

    return (
        <Modal
            show={show}
            onHide={onClose}
            size="xl"
            className={useDarkMode ? "modal-dark" : ""}
        >
            <Modal.Header closeButton>
                <Modal.Title>Pipeline Logs</Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-inverse px-2">
                {startLogCard.card ? (
                    <Card>
                        <Card.Header
                            className="mouseHover"
                            onClick={() => {
                                updateShowStartJob(!showStartJob);
                            }}
                        >
                            <h6>
                                Start Stage
                                {startLogCard.warnings > 0 ? (
                                    <Badge className="m-0 ms-2" bg="warning">
                                        Warning
                                    </Badge>
                                ) : null}
                                {startLogCard.errors > 0 ? (
                                    <Badge className="m-0 ms-2" bg="danger">
                                        Error
                                    </Badge>
                                ) : null}
                            </h6>
                        </Card.Header>
                        <Collapse in={showStartJob} dimension="height">
                            <div>{startLogCard.card}</div>
                        </Collapse>
                    </Card>
                ) : null}
                <BuildLogsCard
                    job={job}
                    targetIndex={targetIndex}
                    buildReport={buildReport}
                    build={build}
                    logs={getPipelineLogs}
                />
                {cleanLogCard.card ? (
                    <Card className="mt-2">
                        <Card.Header
                            className="mouseHover"
                            onClick={() => {
                                updateShowCleanJob(!showCleanJob);
                            }}
                        >
                            <h6>
                                Clean Stage
                                {cleanLogCard.warnings > 0 ? (
                                    <Badge className="ms-2" bg="warning">
                                        Warning
                                    </Badge>
                                ) : null}
                                {cleanLogCard.errors > 0 ? (
                                    <Badge className="ms-2" bg="danger">
                                        Error
                                    </Badge>
                                ) : null}
                            </h6>
                        </Card.Header>
                        <Collapse in={showCleanJob} dimension="height">
                            {cleanLogCard.card}
                        </Collapse>
                    </Card>
                ) : null}
                {notifyLogCard.card ? (
                    <Card className="mt-2">
                        <Card.Header
                            className="mouseHover"
                            onClick={() => {
                                updateShowNotifyJob(!showNotifyJob);
                            }}
                        >
                            <h6>
                                Notify Stage
                                {notifyLogCard.warnings > 0 ? (
                                    <Badge className="ms-2" bg="warning">
                                        Warning
                                    </Badge>
                                ) : null}
                                {notifyLogCard.errors > 0 ? (
                                    <Badge className="ms-2" bg="danger">
                                        Error
                                    </Badge>
                                ) : null}
                            </h6>
                        </Card.Header>
                        <Collapse in={showNotifyJob} dimension="height">
                            {notifyLogCard.card}
                        </Collapse>
                    </Card>
                ) : null}
            </Modal.Body>
        </Modal>
    );
};
