import React, { FC, useState } from "react";
import {
    Button,
    ButtonGroup,
    Card,
    FormControl,
    InputGroup,
    Row,
    Collapse,
    Col
} from "react-bootstrap";
import { ReportBasicData, SearchData, ExpandedList } from "../../models/types";
import { useLocalState } from "../../graphql/hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCaretDown,
    faCaretUp,
    faChevronDown,
    faChevronUp,
    faCircleInfo
} from "@fortawesome/free-solid-svg-icons";
import "./SearchBar.scss";
import cx from "classnames";

interface Props {
    info: ReportBasicData;
    onSearchChange: (value: string) => void;
    onSearchDataChange: (values: string) => void;
    onBlur?: (values: string[]) => void;
}

export const SearchBar: FC<Props> = props => {
    const { developerMode } = useLocalState();
    const [searchValue, setSearchValue] = useState<string>("");
    const [expandedList, setExpandedList] = useState<ExpandedList>({});
    const [showSearch, updateShowSearch] = useState(true);
    const [showTags, updateShowTags] = useState(true);
    const maxItemsVisible = 5;
    const [search, updateSearch] = useState<SearchData>({
        networks: [],
        localizations: [],
        builds: [],
        variations: [],
        sizes: [],
        durations: [],
        sets: []
    });

    const searchInfo = {
        ...props.info,
        sizes: props.info.sizes.map(size => size + "MB")
    } as SearchData;

    const [showHelp, setShowHelp] = useState(false);
    const toggleShowHelp = () => setShowHelp(!showHelp);

    const updateSearchKeys = (value: string, key: string) => {
        const newSearch = search;
        for (const [keyArr, valueArr] of Object.entries(newSearch)) {
            if (key === keyArr) {
                const index = valueArr.indexOf(value);
                if (index !== -1) {
                    valueArr.splice(index, 1);
                } else {
                    valueArr.push(value);
                }
            }
        }

        updateSearch(newSearch);
        props.onSearchDataChange(JSON.stringify(newSearch));
    };

    const generateButtons = (
        items: string[],
        values: string[],
        key: string
    ) => {
        if (!items || items.length === 0) {
            return null;
        }

        items = items.filter(item => item !== "");
        const isExpanded = expandedList[key];
        const itemsToShow = isExpanded
            ? items
            : items.slice(0, maxItemsVisible);

        return (
            <>
                {itemsToShow.map(item => (
                    <Button
                        key={item}
                        onClick={(event: any) => {
                            event.target.blur();
                            updateSearchKeys(item, key);
                        }}
                        className={cx("btn-sm outline-none text-white", {
                            "btn-primary": values.includes(item),
                            "btn-secondary": !values.includes(item)
                        })}
                    >
                        {item}
                    </Button>
                ))}
                {items.length > maxItemsVisible && (
                    <Button
                        title={isExpanded ? "Show less" : "Show more"}
                        onClick={() =>
                            setExpandedList(prev => ({
                                ...prev,
                                [key]: !isExpanded
                            }))
                        }
                        variant="secondary"
                        className="btn-sm outline-none p-0"
                    >
                        {isExpanded ? (
                            <FontAwesomeIcon icon={faCaretUp} />
                        ) : (
                            <FontAwesomeIcon icon={faCaretDown} />
                        )}
                    </Button>
                )}
            </>
        );
    };

    const generateTags = () => {
        const networks = generateButtons(
            searchInfo.networks,
            search.networks,
            "networks"
        );
        const sets = generateButtons(searchInfo.sets, search.sets, "sets");
        const builds = generateButtons(
            searchInfo.builds,
            search.builds,
            "builds"
        );
        const variations = generateButtons(
            searchInfo.variations,
            search.variations,
            "variations"
        );
        const sizes = searchInfo.sizes
            ? generateButtons(searchInfo.sizes, search.sizes, "sizes")
            : null;

        const durations = generateButtons(
            searchInfo.durations,
            search.durations,
            "durations"
        );

        return (
            <>
                <Row className="d-flex mb-2 align-start-onwrap g-0">
                    <Col className="text-center me-2">
                        <strong className="text-inverse-50">Networks</strong>
                        <div className="search-tags">
                            <ButtonGroup
                                className="search-tag-btngroup"
                                vertical
                            >
                                {networks}
                            </ButtonGroup>
                        </div>
                    </Col>
                    {builds && searchInfo.builds.length ? (
                        <Col className="text-center me-2">
                            <strong className="text-inverse-50">Builds</strong>
                            <div className="search-tags">
                                <ButtonGroup
                                    className="search-tag-btngroup"
                                    vertical
                                >
                                    {builds}
                                </ButtonGroup>
                            </div>
                        </Col>
                    ) : null}
                    {variations && searchInfo.variations.length > 0 ? (
                        <Col className="text-center me-2">
                            <strong className="text-inverse-50">
                                Variations
                            </strong>
                            <div className="search-tags">
                                <ButtonGroup
                                    className="search-tag-btngroup"
                                    vertical
                                >
                                    {variations}
                                </ButtonGroup>
                            </div>
                        </Col>
                    ) : null}
                    <Col className="text-center me-2">
                        <strong className="mb-1 mt-1 text-inverse-50">
                            Sizes
                        </strong>
                        <div className="search-tags">
                            <ButtonGroup
                                className="search-tag-btngroup"
                                vertical
                            >
                                {sizes}
                            </ButtonGroup>
                        </div>
                    </Col>
                    {durations && searchInfo.durations.length > 0 ? (
                        <Col className="text-center me-2">
                            <strong className="mb-1 mt-1 text-inverse-50">
                                Durations
                            </strong>
                            <div className="search-tags">
                                <ButtonGroup
                                    className="search-tag-btngroup"
                                    vertical
                                >
                                    {durations}
                                </ButtonGroup>
                            </div>
                        </Col>
                    ) : null}
                    {developerMode &&
                    searchInfo.sets &&
                    searchInfo.sets.length ? (
                        <Col className="text-center me-2">
                            <strong className="mb-1 mt-1 text-inverse-50">
                                Sets
                            </strong>
                            <div className="search-tags">
                                <ButtonGroup
                                    className="search-tag-btngroup"
                                    vertical
                                >
                                    {sets}
                                </ButtonGroup>
                            </div>
                        </Col>
                    ) : null}
                </Row>
            </>
        );
    };

    return (
        <>
            <Card className="mb-2 text-inverse">
                <Card.Header className="p-1 ps-3 d-flex align-items-center">
                    <h5 className="text-inverse-50 mt-1">Search</h5>
                    <div
                        onClick={() => {
                            updateShowSearch(!showSearch);
                        }}
                        title="Toggle search and tags visibility"
                        className="ms-2 text-inverse-50 mouseHover"
                    >
                        <FontAwesomeIcon
                            icon={showSearch ? faChevronUp : faChevronDown}
                            size="xs"
                        />
                    </div>
                    {!developerMode && (
                        <div className="float-end ms-auto text-inverse-25 pe-2 pt-1">
                            <FontAwesomeIcon
                                icon={faCircleInfo}
                                onClick={toggleShowHelp}
                                className={`mouseHover ms-3 ${
                                    showHelp
                                        ? "text-inverse-50"
                                        : "text-inverse-25"
                                }`}
                                title={showHelp ? "Hide Help" : "Show Help"}
                            />
                        </div>
                    )}
                </Card.Header>
                <Collapse in={showSearch}>
                    <div>
                        <Card.Body className="p-2">
                            <InputGroup className="mb-2">
                                <FormControl
                                    id="search-build-variations"
                                    aria-label="Search from builds"
                                    placeholder="Search from builds"
                                    value={searchValue}
                                    onChange={(event: any) => {
                                        setSearchValue(event.target.value);
                                        props.onSearchChange(
                                            event.target.value
                                        );
                                    }}
                                    onBlur={(event: any) => {
                                        if (props.onBlur) {
                                            props.onBlur(event.target.value);
                                        }
                                    }}
                                />
                            </InputGroup>
                            {!developerMode ? (
                                <Collapse in={showHelp}>
                                    <div>
                                        <Card.Footer className="bread-text no-border text-inverse-50 mb-3 pe-2">
                                            <>
                                                <p>
                                                    <b>Search</b> from the
                                                    Builds list by file name or
                                                    parts of it. The tags are
                                                    also usable as search words.
                                                </p>
                                                <p>
                                                    <b>Tags</b> let your filter
                                                    the Builds list quickly by
                                                    selecting any or all of the
                                                    preset keywords. Tags lists
                                                    only include keywords that
                                                    are present in the current
                                                    build set.
                                                </p>
                                            </>
                                        </Card.Footer>
                                    </div>
                                </Collapse>
                            ) : null}
                        </Card.Body>
                    </div>
                </Collapse>
            </Card>
            <Card className="text-inverse mb-2">
                <Card.Header className="p-1 ps-3 d-flex align-items-center">
                    <h5 className="text-inverse-50 mt-1">Tags</h5>
                    <div
                        onClick={() => {
                            updateShowTags(!showTags);
                        }}
                        title="Toggle tags visibility"
                        className="ms-2 text-inverse-50 mouseHover"
                    >
                        <FontAwesomeIcon
                            icon={showTags ? faChevronUp : faChevronDown}
                            size="xs"
                        />
                    </div>
                </Card.Header>
                <Collapse in={showTags}>
                    <div>
                        <Card.Body className="tags-card-body">
                            {generateTags()}
                        </Card.Body>
                    </div>
                </Collapse>
            </Card>
        </>
    );
};
