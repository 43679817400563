import React, { FC, useEffect, useState } from "react";
import { Card, Container, InputGroup } from "react-bootstrap";
import {
    Localization,
    PartialRevisionData,
    PartialRevisionVariables
} from "../../models/types";
import { VariationItemProps } from "../../models/common";
import "./variationItems.scss";
import { useApolloClient, useMutation } from "@apollo/client";
import { PARTIAL_UPDATE_REVISION } from "../../graphql/mutations";
import { updateRevisionPartially } from "../../common/Helpers";

interface LocalizationProps extends VariationItemProps {
    defaultValue: Localization;
    version: number;
}

export const LegacyLocalizationItemClient: FC<LocalizationProps> = ({
    defaultValue,
    version,
    revision
}) => {
    const client = useApolloClient();
    const [localization, setLocalization] = useState(defaultValue);
    const [currentVersion, setCurrentVersion] = useState(version);
    const [partialUpdateRevision] = useMutation<
        PartialRevisionData,
        PartialRevisionVariables
    >(PARTIAL_UPDATE_REVISION);

    useEffect(() => {
        if (defaultValue && currentVersion < version) {
            setCurrentVersion(version);
            setLocalization(defaultValue);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultValue, version]);

    const updateLocalizations = async (localizationData?: Localization) => {
        if (revision) {
            if (!localizationData) {
                localizationData = localization;
            }
            // updateRevision({ legacyLocalizations: [localizationData] });
            await updateRevisionPartially(
                client,
                revision,
                partialUpdateRevision,
                { legacyLocalizations: [localizationData] }
            );
        }
    };

    return (
        <Card className="m-2 p-0 client-card-localization mouseHover">
            <Card.Header
                className="d-flex inner-card text-inverse justify-content-center align-items-center"
                onClick={() => {
                    const newLocalizationData = {
                        ...localization,
                        include: !localization.include
                    };
                    setLocalization(newLocalizationData);
                    updateLocalizations(newLocalizationData);
                }}
            >
                <Container className="m-0 p-0 col-10">
                    {localization.name}
                </Container>
                <Container className="m-0 p-0 col-2">
                    <InputGroup className="m-0 p-0 me-1 custom-input-background">
                        <InputGroup.Checkbox
                            id="legacy-localization-checkbox"
                            title="Include in next Build set"
                            aria-label="Include in next build set"
                            checked={localization.include}
                            onClick={(event: any) => {
                                event.stopPropagation();
                            }}
                            onChange={(event: any) => {
                                const newLocalizationData = {
                                    ...localization,
                                    include: event.target.checked
                                };
                                setLocalization(newLocalizationData);
                                updateLocalizations(newLocalizationData);
                            }}
                        />
                    </InputGroup>
                </Container>
            </Card.Header>
        </Card>
    );
};
