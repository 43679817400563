import React, { FC, useEffect, useState } from "react";
import { Card, Col, Row, InputGroup } from "react-bootstrap";
import {
    PartialRevisionData,
    PartialRevisionVariables,
    Variation
} from "../../models/types";
import { VariationItemProps } from "../../models/common";
import { updateRevisionPartially } from "../../common/Helpers";
import { useApolloClient, useMutation } from "@apollo/client";
import { PARTIAL_UPDATE_REVISION } from "../../graphql/mutations";
import "./variationItems.scss";

interface VariationProps extends VariationItemProps {
    defaultValue: Variation;
}

export const VariationItemList: FC<VariationProps> = ({
    defaultValue,
    revision
}) => {
    const client = useApolloClient();
    const [hasBlacklistedSet, setHasBlacklistedSet] = useState(false);
    const blacklist = revision?.revisionConfig?.setBlacklist;
    const [partialUpdateRevision] = useMutation<
        PartialRevisionData,
        PartialRevisionVariables
    >(PARTIAL_UPDATE_REVISION);

    useEffect(() => {
        if (blacklist && defaultValue.sets) {
            let hasSet = false;
            for (const item of defaultValue.sets) {
                if (blacklist.includes(item.set)) {
                    hasSet = true;
                    break;
                }
            }
            setHasBlacklistedSet(hasSet);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultValue.sets, defaultValue.include, blacklist]);

    const updateVariations = async (
        newVariationData?: Variation,
        operation?: string
    ) => {
        if (revision) {
            if (!newVariationData) {
                newVariationData = defaultValue;
            }
            await updateRevisionPartially(
                client,
                revision,
                partialUpdateRevision,
                { variations: [newVariationData] },
                operation
            );
        }
    };

    if (hasBlacklistedSet) {
        return null;
    }

    return (
        <Card className="fullSize inner-card mb-2 text-inverse">
            <Card.Header
                className="p-2"
                onClick={() => {
                    const newVariationData = {
                        ...defaultValue,
                        include: !defaultValue.include
                    };
                    updateVariations(newVariationData);
                }}
            >
                <Row className="d-flex justify-content-start align-items-center">
                    <Col className="col-1">
                        <InputGroup className="m-0 p-0 custom-input-background">
                            <InputGroup.Checkbox
                                className="mouseHover"
                                title="Include in next Build set"
                                aria-label="Include in next Build set"
                                checked={defaultValue.include}
                                onClick={(event: any) => {
                                    event.stopPropagation();
                                }}
                                onChange={(event: any) => {
                                    const newVariationData = {
                                        ...defaultValue,
                                        include: event.target.checked
                                    };
                                    updateVariations(newVariationData);
                                }}
                            />
                        </InputGroup>
                    </Col>
                    <Col title="Variation name" className="col-5">
                        {defaultValue.name}
                    </Col>
                    <Col title="Description" className="col-6 bread-text">
                        {defaultValue.description}
                    </Col>
                </Row>
            </Card.Header>
        </Card>
    );
};
