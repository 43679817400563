import React, { Component } from "react";
import { DSVRowArray } from "d3";
import { AnalyticsQueryType, AthenaDatasetResult } from "../../models/types";
import { loadCsv } from "../../common/AnalyticsHelpers";
import { ChartConfiguration } from "chart.js";
import { ChartJsWrapper } from "./ChartJsWrapper";

interface State {
    csvData: DSVRowArray | undefined;
}

interface Props {
    data: AthenaDatasetResult[];
    darkMode: boolean;
}

export class FunnelLineChart extends Component<Props> {
    state: State = {
        csvData: undefined
    };

    shouldComponentUpdate(_: Readonly<Props>, nextState: Readonly<State>) {
        return this.state.csvData !== nextState.csvData;
    }
    componentDidMount(): void {
        const { data } = this.props;
        const funnelData = data[0].dataset.find(
            element => element.type === AnalyticsQueryType.Funnel
        );
        if (funnelData?.success && funnelData.message) {
            loadCsv(funnelData.message)
                .then(result => {
                    this.setState({ csvData: result });
                })
                .catch(error => console.log("[DEBUG] error ", error));
        }
    }

    render() {
        const { darkMode } = this.props;
        const { csvData } = this.state;
        if (!csvData) {
            return null;
        }

        const { columns, ...cleanData } = csvData;
        const datasets: number[] = [];
        const labels: string[] = [];
        for (const key in cleanData) {
            // @ts-ignore
            labels.push(cleanData[key].eventName);
            datasets.push(Number(cleanData[key].events));
        }
        const config: ChartConfiguration = {
            type: "line",
            data: {
                labels: labels,
                datasets: [
                    {
                        label: "count",
                        borderColor: darkMode ? "turquoise" : "blue",
                        data: datasets
                    }
                ]
            },
            options: {
                maintainAspectRatio: false,
                scales: {
                    y: {
                        beginAtZero: true
                    }
                },
                plugins: {
                    legend: {
                        display: false
                    }
                }
            }
        };
        return <ChartJsWrapper config={config} />;
    }
}
