import React, { FC, useEffect, useState } from "react";
import {
    Badge,
    Card,
    Collapse,
    FormControl,
    InputGroup
} from "react-bootstrap";
import { MutatorProperty } from "../../../models/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { useMutatorState } from "../../../graphql/hooks";
import styles from "./CustomTypeCard.module.scss";
import cx from "classnames";

interface Props {
    property: MutatorProperty;
    getVariantByType: (type: string) => string;
    onChange: (name: string, value: string) => void;
}

const MutatorStringCardImplementation: FC<Props> = ({
    property,
    getVariantByType,
    onChange
}) => {
    const mutatorState = useMutatorState();
    const [value, updateValue] = useState(property.value);
    const [showMeta, updateShowMeta] = useState(mutatorState.showMeta);
    // const [showContent, setShowContent] = useState(false);

    useEffect(() => {
        if (showMeta !== mutatorState.showMeta) {
            updateShowMeta(mutatorState.showMeta);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mutatorState.showMeta]);

    useEffect(() => {
        if (property.value && property.value !== value) {
            updateValue(property.value);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [property.value]);

    return (
        <Card className="m-2 inner-card text-inverse">
            <Card.Header
                className={`d-flex align-items-center ${styles.cardHeader}`}
            >
                <span className={styles.headerText}>{property.name}</span>
                <Badge className="mx-2" bg={getVariantByType(property.type)}>
                    {property.type}
                </Badge>
                {property.meta ? (
                    <div
                        className="float-end ms-auto mouseHover"
                        onClick={() => updateShowMeta(!showMeta)}
                        title="Help and additional info"
                    >
                        <FontAwesomeIcon
                            icon={faCircleInfo}
                            className={cx({
                                "text-inverse-25": !showMeta,
                                "text-inverse-50": showMeta
                            })}
                        />
                    </div>
                ) : null}
            </Card.Header>
            <div>
                <Card.Body className={`${styles.cardBody} ms-1`}>
                    <InputGroup className={styles.inputGroup}>
                        <InputGroup.Text className="input-group-prepend">
                            Value
                        </InputGroup.Text>
                        <FormControl
                            className={styles.inputGroup}
                            type="string"
                            value={value}
                            onChange={e => updateValue(e.target.value)}
                            onBlur={e =>
                                onChange(property.name, e.target.value)
                            }
                        />
                    </InputGroup>
                </Card.Body>
            </div>
            {property.meta ? (
                <Collapse in={showMeta}>
                    <div>
                        <Card.Footer className={styles.footerInfo}>
                            {property.meta.description ? (
                                <div className="bread-text text-inverse-50">
                                    {property.meta.description}
                                </div>
                            ) : (
                                <span className="bread-text text-inverse-50">
                                    No description provided.
                                </span>
                            )}
                        </Card.Footer>
                    </div>
                </Collapse>
            ) : null}
        </Card>
    );
};

export const MutatorStringCard = React.memo(MutatorStringCardImplementation);
