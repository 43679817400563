import React, { FC, useEffect, useState } from "react";
import {
    BatchBuild,
    BatchBuildsData,
    BatchBuildState,
    BatchBuildsVariables,
    DropdownAction,
    EventType,
    FeedbackType,
    Revision,
    RevisionHistoryData,
    RevisionVariables
} from "../../models/types";
import { Card, Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { Link, useMatch, useNavigate } from "react-router-dom";
import { useQuery, useSubscription } from "@apollo/client";
import { GET_BATCH_BUILDS, GET_REVISION_HISTORY } from "../../graphql/queries";
import { ActionDropdown } from "../dropdowns/ActionDropdown";
import { EventModal } from "../modals/EventModal";
import { DailyCTR } from "./analytics/DailyCTR";
import { DailyImpressions } from "./analytics/DailyImpressions";
import { UserQueryCTR } from "./analytics/UserQueryCTR";
import { UserQueryImpressions } from "./analytics/UserQueryImpressions";
import { BUILD_CHANGED_SUBSCRIPTION } from "../../graphql/subscriptions";
import { useLocalAnalyticsState } from "../../graphql/hooks";
import { SimpleDate } from "./SimpleDate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFlag } from "@fortawesome/free-solid-svg-icons";
import { getFormattedTime } from "../../common/Helpers";
import cx from "classnames";
import "./simple.scss";

interface Props {
    revision?: Revision;
    projectId: number;
}

const ClientTestReleaseItemImplementation: FC<Props> = ({
    revision,
    projectId
}) => {
    const navigate = useNavigate();
    const match = useMatch("/:path/*");
    const { analyticsEnabled } = useLocalAnalyticsState();
    const [showFeedbackRequest, updateShowFeedbackRequest] = useState(false);
    const { data } = useQuery<BatchBuildsData, BatchBuildsVariables>(
        GET_BATCH_BUILDS,
        {
            variables: { revisionId: revision?.id },
            fetchPolicy: "network-only",
            skip: revision === undefined
        }
    );
    const productId = revision?.project.product?.id;
    const eventPath = `${match?.pathname}/${productId}/projects/${projectId}/revisions/${revision?.id}/events`;
    const historyPath = `${match?.pathname}/${productId}/projects/${projectId}/revisions/${revision?.id}/history`;
    let buildReportPath = "";

    const { data: revisionHistory } = useQuery<
        RevisionHistoryData,
        RevisionVariables
    >(GET_REVISION_HISTORY, {
        variables: { revisionId: Number(revision?.id) },
        fetchPolicy: "network-only"
    });

    useSubscription(BUILD_CHANGED_SUBSCRIPTION, {
        variables: { projectId: projectId },
        onData: ({
            data: { data: { buildChangedNotification } = {} },
            client
        }) => {
            const currentBuildsData = client.cache.readQuery<
                BatchBuildsData,
                BatchBuildsVariables
            >({
                variables: { revisionId: revision?.id },
                query: GET_BATCH_BUILDS
            });

            const currentBuilds = currentBuildsData?.builds;

            if (currentBuilds && buildChangedNotification) {
                const newBuilds = currentBuilds.map(build => {
                    if (
                        build.id === buildChangedNotification.buildId.toString()
                    ) {
                        return {
                            ...build,
                            state: buildChangedNotification.buildState
                        };
                    } else {
                        if (
                            build.state === BatchBuildState.Review ||
                            build.state === BatchBuildState.Test
                        ) {
                            return {
                                ...build,
                                state: BatchBuildState.Completed
                            };
                        }
                        return build;
                    }
                });
                client.cache.writeQuery<BatchBuildsData, BatchBuildsVariables>({
                    query: GET_BATCH_BUILDS,
                    data: { builds: newBuilds },
                    variables: { revisionId: revision?.id }
                });
            }
        }
    });

    const [latestEvent, setLatestEvent] = useState<null | {
        eventType: EventType;
        dateCreated: string;
    }>(null);

    useEffect(() => {
        if (revisionHistory) {
            const sortedEvents = [...revisionHistory.revisionEvents].sort(
                (a, b) =>
                    new Date(b.dateCreated).getTime() -
                    new Date(a.dateCreated).getTime()
            );
            setLatestEvent(sortedEvents[0]);
        }
    }, [revisionHistory]);

    const showTestFlag =
        latestEvent && latestEvent.eventType === EventType.RequestTestBuild;

    let testRequestedTime;
    let testRequestedTimeShort;
    if (revisionHistory) {
        testRequestedTime = revisionHistory.revisionEvents.find(
            event => event.eventType === EventType.RequestTestBuild
        )?.dateCreated;
        testRequestedTimeShort = testRequestedTime
            ? getFormattedTime(testRequestedTime)
            : "";
    }

    const hasBuilds = data && data.builds.length > 0;
    let hasTestTag = false;
    let testBuilds: BatchBuild[] | undefined;

    if (hasBuilds) {
        testBuilds = data?.builds.filter(
            (build: BatchBuild) => build.state === BatchBuildState.Test
        );

        if (testBuilds && testBuilds.length > 0) {
            hasTestTag = true;
            buildReportPath += `${match?.pathname}/${productId}/projects/${projectId}/revisions/${revision?.id}/history/${testBuilds[0].id}`;
        }
    }

    const actions: DropdownAction[] = [
        {
            header: "Events",
            variant: "inverse",
            callback: () => navigate(eventPath)
        },
        {
            header: "Request Test",
            variant: "inverse",
            callback: () => updateShowFeedbackRequest(true)
        }
    ];

    if (!revision) return null;

    if (hasTestTag && testBuilds && testBuilds.length > 1) {
        actions.unshift({
            header: "Builds",
            variant: "inverse",
            callback: () => navigate(historyPath)
        });
    }

    if (hasTestTag) {
        actions.unshift({
            header: "Go to Latest Test",
            variant: "inverse",
            callback: () => navigate(buildReportPath)
        });
    }

    return (
        <>
            <Card.Header
                className={cx(
                    "text-inverse highlight-revision-card bread-text custom-card-body-children",
                    {
                        "bg-test btn mouseHover": hasTestTag
                    }
                )}
                onClick={e => {
                    if (e.defaultPrevented) return;
                    if (hasTestTag) {
                        navigate(buildReportPath);
                    }
                }}
            >
                <Row className="d-flex align-items-center">
                    <Col
                        className={cx("text-start client-release-title", {
                            "col-4": analyticsEnabled,
                            "col-6": !analyticsEnabled
                        })}
                    >
                        {revision?.project.name}
                    </Col>
                    <Col className="col-1 d-flex ps-0 no-wrap xs-font justify-content-center">
                        <SimpleDate dateUpdated={revision.dateUpdated} />
                    </Col>
                    <Col
                        className={cx("text-start", {
                            "col-2": analyticsEnabled,
                            "col-4": !analyticsEnabled
                        })}
                    >
                        {showTestFlag && (
                            <>
                                <OverlayTrigger
                                    key="test-flag"
                                    placement="top"
                                    overlay={
                                        <Tooltip id="tooltip-test-flag">
                                            {`Test requested ${testRequestedTimeShort}`}
                                        </Tooltip>
                                    }
                                >
                                    <Link to={eventPath}>
                                        <FontAwesomeIcon
                                            icon={faFlag}
                                            className="text-test"
                                        />
                                    </Link>
                                </OverlayTrigger>
                            </>
                        )}
                    </Col>
                    {analyticsEnabled ? (
                        <>
                            <Col className="col-1 text-start pe-0 ps-1">
                                <DailyImpressions
                                    impressions={revision.impressions}
                                />
                            </Col>
                            <Col className="col-1 text-start ps-1 pe-0">
                                <DailyCTR
                                    gameplay={revision.gameplay}
                                    endScreen={revision.endScreen}
                                    impressions={revision.impressions}
                                />
                            </Col>
                            <Col className="col-1 text-start pe-0">
                                <UserQueryImpressions
                                    impressions={revision.userImpressions}
                                />
                            </Col>
                            <Col className="col-1 text-start pe-0">
                                <UserQueryCTR
                                    gameplay={revision.userGameplay}
                                    endScreen={revision.userEndScreen}
                                    impressions={revision.userImpressions}
                                />
                            </Col>
                        </>
                    ) : null}
                    <Col className="col-1 d-flex justify-content-end pe-1">
                        <ActionDropdown
                            id={`client-release-${revision.id}`}
                            variant={cx(
                                { info: !hasTestTag },
                                { "bg-test": hasTestTag }
                            )}
                            actions={actions}
                        />
                    </Col>
                </Row>
            </Card.Header>
            <EventModal
                title={`Request Test for ${revision?.project.name}`}
                type={FeedbackType.RequestTestBuild}
                revision={revision}
                show={showFeedbackRequest}
                onClose={() => updateShowFeedbackRequest(false)}
            />
        </>
    );
};

export const ClientTestReleaseItem = React.memo(
    ClientTestReleaseItemImplementation
);
