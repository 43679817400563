import React, { FC } from "react";
import { Row } from "react-bootstrap";
import { Network } from "../../../models/types";
import { VariationViewItemProps } from "../../../models/common";
import { NetworkItem } from "../../variationItems/NetworkItem";
import "../views.scss";
import { NetworkItemClient } from "../../variationItems/NetworkItemClient";

export const Networks: FC<VariationViewItemProps> = ({
    revision,
    developerMode
}) => {
    const useAnalytics = revision.buildData?.analytics ?? false;

    const items = revision?.buildData?.networks.map((object: Network) =>
        developerMode ? (
            <NetworkItem
                key={object.id}
                network={object}
                revision={revision}
                useAnalytics={useAnalytics}
            />
        ) : (
            <NetworkItemClient
                key={object.id}
                defaultValue={object}
                revision={revision}
            />
        )
    );
    return <Row className="justify-content-start ms-auto">{items}</Row>;
};
