import React, { FC } from "react";
import { InputGroup, Dropdown, DropdownButton, Button } from "react-bootstrap";
import { TechStack, TechStackConfig } from "../../models/types";
import { TechStackType } from "../../models/common";
import { getCurrentTechStackSelectionByType } from "../../common/Helpers";

interface Props {
    type: TechStackType;
    modules: string[];
    baseTechStackConfig: TechStackConfig;
    currentTechStackConfig: TechStackConfig;
    updateProject: (type: TechStackType, selection: string[]) => void;
}

const techStackColours = [
    "primary",
    "success",
    "info",
    "analytics",
    "analytics-alt",
    "secondary"
];

export const SelectableTechStack: FC<Props> = ({
    type,
    modules,
    baseTechStackConfig,
    currentTechStackConfig,
    updateProject
}) => {
    const currentSelection = getCurrentTechStackSelectionByType(
        type,
        currentTechStackConfig
    );
    const selectionKey = TechStackType[type].toString().toLowerCase();
    // @ts-ignore
    const baseTechStackData = baseTechStackConfig[selectionKey];

    const checkBlocking = (item: TechStack) => {
        if (
            item.blocking &&
            item.blocking.some(
                element =>
                    currentTechStackConfig.frameworks.selection.some(
                        innerElement => innerElement.name === element
                    ) ||
                    currentTechStackConfig.sounds.selection.some(
                        innerElement => innerElement.name === element
                    ) ||
                    currentTechStackConfig.physics.selection.some(
                        innerElement => innerElement.name === element
                    ) ||
                    currentTechStackConfig.particles.selection.some(
                        innerElement => innerElement.name === element
                    ) ||
                    currentTechStackConfig.animations.selection.some(
                        innerElement => innerElement.name === element
                    ) ||
                    currentTechStackConfig.networks.selection.some(
                        innerElement => innerElement.name === element
                    ) ||
                    modules.some(innerElement => innerElement === element)
            )
        ) {
            return true;
        }
        return false;
    };

    const alternatives = baseTechStackData.selection.flatMap(
        (tech: TechStack, index: number) =>
            !currentSelection.includes(tech.name) && !checkBlocking(tech) ? (
                <Dropdown.Item key={index} eventKey={tech.name}>
                    {tech.name}
                </Dropdown.Item>
            ) : (
                []
            )
    );
    const selected = currentSelection?.map(element => (
        <Button
            key={element}
            className="m-1 text-inverse"
            variant={techStackColours[type]}
            onClick={() => {
                const newSelection = currentSelection.filter(
                    selection => selection !== element
                );
                updateProject(type, newSelection);
            }}
        >
            {element}
        </Button>
    ));

    const handleTechUpdate = (selectedItem: string) => {
        const item = baseTechStackData.selection.find(
            (element: TechStack) => element.name === selectedItem
        );
        if (!item || (item && checkBlocking(item))) {
            return;
        }
        let newList: string[] = [];
        if (baseTechStackData.allowMultiple) {
            newList = [...currentSelection, selectedItem];
        } else {
            newList.push(selectedItem);
        }
        updateProject(type, newList);
    };

    return (
        <div className="mb-3">
            <InputGroup className="mb-2">
                <InputGroup.Text>{TechStackType[type]}</InputGroup.Text>
                <DropdownButton
                    id="selectable-tech-stack-dropdown"
                    title={baseTechStackData.allowMultiple ? "Add" : "Select"}
                    variant={techStackColours[type]}
                    className="text-center"
                    onSelect={(selectedItem: string | null | undefined) => {
                        if (selectedItem) {
                            handleTechUpdate(selectedItem);
                        }
                    }}
                >
                    {alternatives}
                </DropdownButton>
            </InputGroup>
            {selected}
        </div>
    );
};
