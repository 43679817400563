import React from "react";
import { Col, Row } from "react-bootstrap";
import {
    useLocalAnalyticsState,
    useLocalState,
    useSortingState
} from "../../graphql/hooks";
import { SortingButton } from "../buttons/SortingButton";
import { TitleDropdown } from "../dropdowns/TitleDropdown";
import { SortingMode } from "../../models/types";
import { useApolloClient } from "@apollo/client";
import { updateDevelopmentSortingState } from "../../common/Helpers";
import { formatDateRange } from "../../common/AnalyticsHelpers";
import cx from "classnames";
import styles from "./SortableTitles.module.scss";

export const SortableTitles = () => {
    const client = useApolloClient();
    const { developerMode } = useLocalState();
    const { analyticsEnabled, analyticsTimestamps } = useLocalAnalyticsState();
    const { useDarkMode } = useLocalState();
    const sortingState = useSortingState();
    const formattedDateRange = analyticsTimestamps
        ? formatDateRange(
              analyticsTimestamps.startTimestamp,
              analyticsTimestamps.endTimestamp
          )
        : "";

    let productColumnClass = "col-2";
    let statusColumnClass = "col-2";
    let orgColumnClass = "col-2";

    if (!analyticsEnabled && developerMode) {
        productColumnClass = "col-3";
        statusColumnClass = "col-4";
        orgColumnClass = "col-3";
    } else if (analyticsEnabled && !developerMode) {
        productColumnClass = "col-4";
    } else if (!analyticsEnabled && !developerMode) {
        productColumnClass = "col-6";
    }

    return (
        <Row className="d-flex no-wrap border-bottom border-secondary ms-2 g-0">
            <Col
                className={`${productColumnClass} sortable-titles ps-4 text-inverse-50`}
            >
                <span
                    title="Products, projects and revisions"
                    className={styles.marginToCounterSuperscript}
                >
                    <TitleDropdown
                        title="Title"
                        sortingModes={[
                            SortingMode.NameAscending,
                            SortingMode.NameDescending
                        ]}
                    />
                </span>
                <SortingButton
                    sortingModes={[
                        SortingMode.NameAscending,
                        SortingMode.NameDescending
                    ]}
                />
            </Col>

            {developerMode && (
                <Col
                    className={cx(
                        "sortable-titles ps-3 text-inverse-50 col-2",
                        orgColumnClass
                    )}
                >
                    <>
                        <span className="sortable-titles text-inverse me-1">
                            Organization
                        </span>
                        {sortingState.organizationSorting && (
                            <span className={styles.AtoZ}>(A-Z)</span>
                        )}
                        <label
                            title="Sort inside organization"
                            className={`${styles.container} ${
                                !useDarkMode ? styles.lightmodeCheckmark : ""
                            }`}
                        >
                            <input
                                id="organization-checkbox"
                                checked={sortingState.organizationSorting}
                                onChange={() =>
                                    updateDevelopmentSortingState(
                                        client,
                                        !sortingState.organizationSorting
                                    )
                                }
                                type="checkbox"
                            />
                            <span className={styles.checkmark}></span>
                        </label>
                    </>
                </Col>
            )}

            <Col className="col-1 sortable-titles justify-content-center text-inverse-50">
                <span
                    title="Last updated"
                    className={`d-flex nowrap ${styles.marginToCounterSuperscript}`}
                >
                    <TitleDropdown
                        title="Date"
                        sortingModes={[
                            SortingMode.DateAscending,
                            SortingMode.DateDescending
                        ]}
                    />
                </span>
                <SortingButton
                    sortingModes={[
                        SortingMode.DateAscending,
                        SortingMode.DateDescending
                    ]}
                />
            </Col>

            <Col
                title="Status badge contains number of projects"
                className={cx(
                    "sortable-titles ps-3 text-inverse",
                    statusColumnClass
                )}
            >
                Status
            </Col>

            {analyticsEnabled ? (
                <>
                    <Col className="col-1 sortable-titles me-1 text-inverse-50">
                        <div
                            className="sortable-titles text-inverse-50"
                            title="Impressions - previous 24 hrs"
                        >
                            <TitleDropdown
                                title="Impr"
                                sortingModes={[
                                    SortingMode.ImpressionsAscending,
                                    SortingMode.ImpressionsDescending
                                ]}
                            />
                            <span className={styles.superscript}>
                                <sup>*</sup>
                            </span>
                            <SortingButton
                                sortingModes={[
                                    SortingMode.ImpressionsAscending,
                                    SortingMode.ImpressionsDescending
                                ]}
                            />
                        </div>
                    </Col>

                    <Col className="col-1 sortable-titles text-inverse-50">
                        <div
                            className="sortable-titles text-inverse-50"
                            title="Click-through rate (CTR) - previous 24 hrs"
                        >
                            <TitleDropdown
                                title="CTR"
                                sortingModes={[
                                    SortingMode.CtrAscending,
                                    SortingMode.CtrDescending
                                ]}
                            />
                            <span className={styles.superscript}>
                                <sup>*</sup>
                            </span>
                            <SortingButton
                                sortingModes={[
                                    SortingMode.CtrAscending,
                                    SortingMode.CtrDescending
                                ]}
                            />
                        </div>
                    </Col>

                    <Col className="col-1 sortable-titles text-inverse-50">
                        <div
                            className="sortable-titles text-inverse-50"
                            title={`Impressions - ${formattedDateRange}`}
                        >
                            <span className={styles.marginToCounterSuperscript}>
                                <TitleDropdown
                                    title="Impr"
                                    sortingModes={[
                                        SortingMode.UserImpressionsAscending,
                                        SortingMode.UserImpressionsDescending
                                    ]}
                                />
                            </span>
                            <SortingButton
                                sortingModes={[
                                    SortingMode.UserImpressionsAscending,
                                    SortingMode.UserImpressionsDescending
                                ]}
                            />
                        </div>
                    </Col>

                    <Col className="col-1 sortable-titles text-inverse-50">
                        <>
                            <div
                                className="sortable-titles text-inverse-50"
                                title={`Click-through rate (CTR) - ${formattedDateRange}`}
                            >
                                <span
                                    className={
                                        styles.marginToCounterSuperscript
                                    }
                                >
                                    <TitleDropdown
                                        title="CTR"
                                        sortingModes={[
                                            SortingMode.UserCtrAscending,
                                            SortingMode.UserCtrDescending
                                        ]}
                                    />
                                </span>
                            </div>
                            <SortingButton
                                sortingModes={[
                                    SortingMode.UserCtrAscending,
                                    SortingMode.UserCtrDescending
                                ]}
                            />
                        </>
                    </Col>
                </>
            ) : null}

            <Col className="col-1"></Col>
        </Row>
    );
};
