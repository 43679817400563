import { Button, Card, Col, Dropdown, DropdownButton } from "react-bootstrap";
import React, { FC, useState } from "react";
import { Organization, Product, ProductsData } from "../../models/types";
import { useQuery } from "@apollo/client";
import { GET_PRODUCTS_BY_USER_ORGANIZATION } from "../../graphql/queries";
import { NewProductModal } from "../modals/NewProductModal";

interface Props {
    organization: Organization;
    product: Product | undefined;
    updateProduct: (selectedProduct: Product) => void;
}

export const ProductSelectionView: FC<Props> = ({
    organization,
    product,
    updateProduct
}) => {
    const [showModal, updateModal] = useState(false);
    const { data: { products } = {} } = useQuery<ProductsData>(
        GET_PRODUCTS_BY_USER_ORGANIZATION
    );
    const generateOrganizationAlternatives = products?.flatMap(
        (product: Product, index: number) =>
            product.organization.id === organization.id ? (
                <Dropdown.Item key={index} eventKey={product.id.toString()}>
                    {product.name}
                </Dropdown.Item>
            ) : (
                []
            )
    );

    return (
        <Card className="border-info my-2">
            <Col className="m-0 ps-2 mb-2" md={12}>
                <h5 className="text-inverse p-2">Product</h5>
            </Col>
            <Col md={12} className="d-flex justify-content-center">
                <DropdownButton
                    title={
                        product === undefined ? "Select Product" : product.name
                    }
                    variant="info"
                    className="text-center m-2"
                    onSelect={(selectedItem: string | null | undefined) => {
                        if (selectedItem) {
                            const selectedProduct = products?.find(
                                element =>
                                    Number(element.id) === Number(selectedItem)
                            );
                            if (selectedProduct) {
                                updateProduct(selectedProduct);
                            }
                        }
                    }}
                >
                    {generateOrganizationAlternatives}
                </DropdownButton>
                <Button className="m-2" onClick={() => updateModal(true)}>
                    New Product
                </Button>
                {showModal ? (
                    <NewProductModal
                        isDeveloperMode={true}
                        show={showModal}
                        onClose={newProduct => {
                            if (newProduct) {
                                updateProduct(newProduct);
                            }
                            updateModal(false);
                        }}
                        defaultOrganization={organization}
                    />
                ) : null}
            </Col>
        </Card>
    );
};
