import React, { FC } from "react";
import { Card } from "react-bootstrap";
import { BasicResult } from "../../models/types";
import { faClipboard } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cx from "classnames";

interface Props {
    result: BasicResult;
}

export const CreateProjectResult: FC<Props> = ({ result }) => {
    const commands = result.success ? result.message.split("|") : [];
    console.log("[DEBUG] commands ", commands);
    const copyToClipboard = async (command: string) => {
        await navigator.clipboard.writeText(command);
    };

    const unixCommands = commands.join(" && ");
    const powerShell = commands.join(" ; ");
    const cmdLine = commands.join(" & ");

    const sshUrl =
        commands.length === 3
            ? commands[0].replace("git clone --recursive", "")
            : "";

    return (
        <Card className="border-success">
            <Card.Header>
                <h5 className="text-inverse">
                    {result.success
                        ? "Project Created Successfully!"
                        : "Project Creation Failed"}
                </h5>
            </Card.Header>
            <Card.Body
                className={cx("text-start", {
                    "text-danger": !result.success
                })}
            >
                {result.success ? (
                    <>
                        {commands.length === 3 ? (
                            <Card className="mt-0 mb-2 border-info">
                                <Card.Header className="text-inverse">
                                    Project Info
                                </Card.Header>
                                <Card.Body>
                                    <h6 className="text-inverse">SSH url</h6>
                                    <code>{sshUrl}</code>
                                    <FontAwesomeIcon
                                        icon={faClipboard}
                                        className="text-success ms-2 mouseHover"
                                        onClick={(event: any) => {
                                            event.preventDefault();
                                            event.stopPropagation();
                                            copyToClipboard(sshUrl);
                                        }}
                                    />
                                    <h6 className="mt-2 text-inverse">
                                        Seepia CLI init command
                                    </h6>
                                    <code>{commands[2]}</code>
                                    <FontAwesomeIcon
                                        icon={faClipboard}
                                        className="text-success ms-2 mouseHover"
                                        onClick={(event: any) => {
                                            event.preventDefault();
                                            event.stopPropagation();
                                            copyToClipboard(commands[2]);
                                        }}
                                    />
                                    <div className="border-left border-info mt-2">
                                        <div className="m-1 p-2 text-info">
                                            You can also initialize the project
                                            using the ready made snippets below
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        ) : null}
                        <Card className="my-2 border-success">
                            <Card.Header className="text-inverse">
                                Unix based systems and Git Bash
                                <div className="float-end">
                                    <FontAwesomeIcon
                                        icon={faClipboard}
                                        className="text-success ms-2 mouseHover"
                                        onClick={(event: any) => {
                                            event.preventDefault();
                                            event.stopPropagation();
                                            copyToClipboard(unixCommands);
                                        }}
                                    />
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <code>{unixCommands}</code>
                            </Card.Body>
                        </Card>
                        <Card className="my-2 border-analytics">
                            <Card.Header className="text-inverse">
                                Windows PowerShell
                                <div className="float-end">
                                    <FontAwesomeIcon
                                        icon={faClipboard}
                                        className="text-success ms-2 mouseHover"
                                        onClick={(event: any) => {
                                            event.preventDefault();
                                            event.stopPropagation();
                                            copyToClipboard(powerShell);
                                        }}
                                    />
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <code>{powerShell}</code>
                            </Card.Body>
                        </Card>
                        <Card className="my-2 border-warning">
                            <Card.Header className="text-inverse">
                                Windows CMD
                                <div className="float-end">
                                    <FontAwesomeIcon
                                        icon={faClipboard}
                                        className="text-success ms-2 mouseHover"
                                        onClick={(event: any) => {
                                            event.preventDefault();
                                            event.stopPropagation();
                                            copyToClipboard(cmdLine);
                                        }}
                                    />
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <code>{cmdLine}</code>
                            </Card.Body>
                        </Card>
                    </>
                ) : (
                    <>
                        <p className="text-center">{result.message}</p>
                        {result.state ? (
                            <p className="text-center">{result.state}</p>
                        ) : null}
                    </>
                )}
            </Card.Body>
        </Card>
    );
};
