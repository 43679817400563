import React, { FC, useState } from "react";
import { LegacyLocalization } from "../../models/types";
import { Button, Card, Collapse, Container, Row } from "react-bootstrap";
import { LegacyLocalizationItem } from "../variationItems/LegacyLocalizationItem";
import { LegacyLocalizationItemClient } from "../variationItems/LegacyLocalizationItemClient";
import {
    VariationCollapseState,
    VariationViewItemProps,
    VariationViewType
} from "../../models/common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faChevronDown,
    faChevronUp,
    faCircleInfo,
    faPlus
} from "@fortawesome/free-solid-svg-icons";
import { useLocalState } from "../../graphql/hooks";
import {
    getVariationCollapseState,
    saveVariationCollapseState
} from "../../common/Helpers";
import cx from "classnames";

interface Props extends VariationViewItemProps {
    openNewItemModal: (type: VariationViewType) => void;
}

export const LegacyLocalizations: FC<Props> = ({
    revision,
    developerMode,
    openNewItemModal
}) => {
    const state = useLocalState();
    const version = revision?.buildData?.version || -1;
    const [showHelp, setShowHelp] = useState(false);
    const [showLegacyLocalizations, setShowLegacyLocalizations] = useState(
        getVariationCollapseState(VariationCollapseState.LegacyLocalizations)
    );

    const updateShowLegacyLocalizations = (newState: boolean) => {
        saveVariationCollapseState(
            VariationCollapseState.LegacyLocalizations,
            newState
        );
        setShowLegacyLocalizations(newState);
    };

    const items = revision?.buildData?.legacyLocalizations?.map(
        (object: LegacyLocalization) =>
            developerMode ? (
                <LegacyLocalizationItem
                    key={object.id}
                    version={version}
                    defaultValue={object}
                    revision={revision}
                />
            ) : (
                <LegacyLocalizationItemClient
                    key={object.id}
                    version={version}
                    defaultValue={object}
                    revision={revision}
                />
            )
    );
    const hasLocalizations =
        revision &&
        revision.buildData &&
        revision.buildData.legacyLocalizations &&
        revision.buildData.legacyLocalizations.length > 0;
    const toggleShowHelp = () => setShowHelp(!showHelp);

    return (
        <>
            <Container
                className={cx("mb-4 p-0", {
                    editPanelClientView: !state.developerMode
                })}
            >
                <Card>
                    <Card.Header className="d-flex align-items-center">
                        <h5 className="text-inverse-50 mb-1 me-2">
                            Legacy Localizations
                        </h5>
                        {hasLocalizations && (
                            <div
                                className="mb-1 ms-1 text-inverse-50 mouseHover"
                                onClick={() => {
                                    updateShowLegacyLocalizations(
                                        !showLegacyLocalizations
                                    );
                                }}
                                data-toggle="tooltip"
                                title="Toggle Localizations visibility"
                            >
                                <FontAwesomeIcon
                                    icon={
                                        showLegacyLocalizations
                                            ? faChevronUp
                                            : faChevronDown
                                    }
                                />
                            </div>
                        )}
                        {state.developerMode ? (
                            <div className="d-flex float-end ms-auto">
                                <Button
                                    onClick={() =>
                                        openNewItemModal(
                                            VariationViewType.LegacyLocalizations
                                        )
                                    }
                                    className="text-primary btn-sm ms-2 mouseHover"
                                    data-toggle="tooltip"
                                    title="Add new Legacy localization"
                                >
                                    <FontAwesomeIcon
                                        className="text-white"
                                        icon={faPlus}
                                        size="lg"
                                    />
                                </Button>
                            </div>
                        ) : (
                            <div className="float-end mt-1 ms-auto">
                                <FontAwesomeIcon
                                    icon={faCircleInfo}
                                    onClick={toggleShowHelp}
                                    className={`mouseHover ${
                                        showHelp
                                            ? "text-inverse-50"
                                            : "text-inverse-25"
                                    }`}
                                    title={showHelp ? "Hide Help" : "Show Help"}
                                />
                            </div>
                        )}
                    </Card.Header>
                    <Collapse in={showLegacyLocalizations}>
                        <div>
                            <Card.Body className="p-2">
                                <div>
                                    <Row className="d-flex m-auto justify-content-start">
                                        {items}
                                    </Row>
                                </div>
                            </Card.Body>
                        </div>
                    </Collapse>
                    {!state.developerMode ? (
                        <Collapse in={showHelp}>
                            <div>
                                <Card.Footer className="bread-text text-inverse-50">
                                    <p>
                                        <strong>Localizations</strong> include
                                        the available language settings for any
                                        text elements in the playable ad. All
                                        selected localizations will be included
                                        in the generated ad builds, so this does
                                        not produce any additional builds.
                                    </p>{" "}
                                    <p>
                                        Localizations are named with official
                                        language abbreviations.
                                    </p>
                                </Card.Footer>
                            </div>
                        </Collapse>
                    ) : null}
                </Card>
            </Container>
        </>
    );
};
