import React, { ChangeEvent, FC, useEffect, useState } from "react";
import { Card, Col, FormControl, InputGroup } from "react-bootstrap";
import { AnalyticsEventDataSchema } from "../../../models/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import cx from "classnames";

interface Props {
    frozen?: boolean;
    index: number;
    hasDuplicatesKey: boolean;
    defaultValue: AnalyticsEventDataSchema;
    updateAnalyticsDataSchema: (
        index: number,
        eventData?: AnalyticsEventDataSchema
    ) => void;
}

export const AnalyticsEventDataItem: FC<Props> = ({
    frozen,
    index,
    hasDuplicatesKey,
    defaultValue,
    updateAnalyticsDataSchema
}) => {
    const [eventData, updateEventData] = useState(defaultValue);
    const updateSchema = () => {
        if (JSON.stringify(eventData) !== JSON.stringify(defaultValue)) {
            updateAnalyticsDataSchema(index, eventData);
        }
    };

    const remove = () => {
        updateAnalyticsDataSchema(index, undefined);
    };

    useEffect(() => {
        if (eventData !== defaultValue) {
            updateEventData(defaultValue);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultValue]);

    return (
        <Col xs="auto" className="m-1">
            <Card
                className={cx("inner-card", {
                    "border-danger": hasDuplicatesKey
                })}
            >
                <Card.Header className="d-flex no-border pb-0">
                    <InputGroup className="me-3">
                        <InputGroup.Text
                            className={hasDuplicatesKey ? "text-danger" : ""}
                        >
                            Key
                        </InputGroup.Text>
                        <FormControl
                            value={eventData.key}
                            disabled={frozen}
                            onChange={(eventChange: any) => {
                                const newKey = eventChange.target.value;
                                updateEventData({
                                    ...eventData,
                                    key: newKey
                                });
                            }}
                            onBlur={() => {
                                updateSchema();
                            }}
                            className={hasDuplicatesKey ? "text-danger" : ""}
                            placeholder="Enter new key"
                        />
                    </InputGroup>
                    {frozen ? null : (
                        <FontAwesomeIcon
                            icon={faTrashAlt}
                            size="lg"
                            title="Remove from Event Data"
                            className="text-primary float-end mt-2 ms-auto mouseHover"
                            onClick={remove}
                        />
                    )}
                </Card.Header>
                <Card.Footer className="no-border">
                    <InputGroup>
                        <InputGroup.Text>Values</InputGroup.Text>
                        <FormControl
                            value={
                                eventData.values
                                    ? eventData.values.join(",")
                                    : ""
                            }
                            disabled={frozen}
                            onChange={(
                                eventChange: React.ChangeEvent<HTMLInputElement>
                            ) => {
                                const newValues = eventChange.target.value
                                    .split(",")
                                    .map(value => value.trim());
                                updateEventData({
                                    ...eventData,
                                    values: newValues
                                });
                            }}
                            onBlur={() => {
                                updateSchema();
                            }}
                            placeholder="Enter values separated by comma"
                        />
                    </InputGroup>
                </Card.Footer>
            </Card>
        </Col>
    );
};
