import React, { FC } from "react";
import { Card } from "react-bootstrap";
import { FollowIcon } from "../selectable/analytics/FollowIcon";

interface Props {
    batchId: string;
    buildId: string;
    analyticsVersion: string;
}

export const BuildAnalyticsInfo: FC<Props> = ({
    batchId,
    buildId,
    analyticsVersion
}) => {
    return (
        <Card className="mt-1 mb-1">
            <Card.Header className="d-flex justify-content-start">
                <strong className="text-inverse-50">Analytics Info</strong>
                <div className="ms-2">
                    <FollowIcon buildId={buildId} size="lg" />
                </div>
            </Card.Header>
            <Card.Body className="text-inverse bread-text">
                <p>
                    <b className="text-inverse-50">Build Id:</b> {buildId}
                </p>
                <p>
                    <b className="text-inverse-50">Batch Id:</b> {batchId}
                </p>
                <p>
                    <b className="text-inverse-50">Version Id:</b>{" "}
                    {analyticsVersion}
                </p>
            </Card.Body>
        </Card>
    );
};
