import React, { FC, useEffect, useState } from "react";
import {
    Button,
    Card,
    Col,
    FormControl,
    InputGroup,
    Row
} from "react-bootstrap";
import { Build } from "../../models/types";
import { VariationItemProps } from "../../models/common";
import { DetectedSetsPopover } from "../selectable/DetectedSetsPopover";
import { OverlayBadge } from "../simple/OverlayBadge";
import { SimpleDropdown } from "../dropdowns/SimpleDropdown";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { handleSetsUpdate } from "../../common/Helpers";
import "./variationItems.scss";

interface BuildProps extends VariationItemProps {
    defaultValue: Build;
    allowedSets: string[] | undefined;
}

export const NewBuildItem: FC<BuildProps> = ({
    revision,
    defaultValue,
    allowedSets,
    callback
}) => {
    const [build, setBuild] = useState(defaultValue);
    const [hasBlacklistedSet, setHasBlacklistedSet] = useState(false);

    const blacklist = revision?.revisionConfig?.setBlacklist;

    const disableBuild = () => {
        const newBuildData = {
            ...build,
            include: false
        };
        setBuild(newBuildData);
    };

    useEffect(() => {
        if (blacklist && build.sets) {
            let hasSet = false;
            for (const set of build.sets) {
                if (blacklist.includes(set)) {
                    if (build.include) {
                        disableBuild();
                    }
                    hasSet = true;
                    break;
                }
            }
            setHasBlacklistedSet(hasSet);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [build.sets, build.include, blacklist]);

    const toggleSet = (set: string) => {
        let currentSets = build.sets;
        if (currentSets.includes(set)) {
            currentSets = currentSets.filter(element => element !== set);
        } else {
            currentSets.push(set);
        }
        const updatedBuild = { ...build, sets: currentSets };
        setBuild(updatedBuild);
    };

    return (
        <Card className="no-border">
            <span className="me-3">
                <InputGroup className="d-flex text-inverse justify-content-end align-items-center mt-1 me-2">
                    <InputGroup.Text>Include in next Build set</InputGroup.Text>
                    {hasBlacklistedSet ? (
                        <OverlayBadge
                            badgeVariant="danger"
                            className="ms-2"
                            badgeHeader="!"
                            overlayText="Build has blacklisted set(s)!"
                        />
                    ) : (
                        <InputGroup.Checkbox
                            className="mouseHover mb-1"
                            title="Include in next Build set"
                            checked={build.include}
                            onChange={(event: any) => {
                                const newBuildData = {
                                    ...build,
                                    include: event.target.checked
                                };
                                setBuild(newBuildData);
                            }}
                        />
                    )}
                </InputGroup>
            </span>
            <Card.Body className="d-flex flex-column">
                <InputGroup className="mb-2">
                    <InputGroup.Text>Name</InputGroup.Text>
                    <FormControl
                        placeholder="Descriptive title"
                        value={build.name}
                        onChange={(event: any) => {
                            const newName = event.target.value.replace(" ", "");
                            setBuild({
                                ...build,
                                name: newName
                            });
                        }}
                    />
                </InputGroup>
                <InputGroup className="mb-3">
                    {revision?.revisionConfig?.sets &&
                    revision?.revisionConfig?.sets.length > 0 ? (
                        <DetectedSetsPopover
                            title={
                                <>
                                    <span className="pe-2">Sets</span>
                                    <FontAwesomeIcon
                                        size="xs"
                                        icon={faCaretDown}
                                    />
                                </>
                            }
                            onToggle={set => toggleSet(set)}
                            allSets={allowedSets}
                            usedSets={build.sets}
                        />
                    ) : null}
                    <FormControl
                        value={build.sets.join(",")}
                        onChange={(event: any) => {
                            setBuild({
                                ...build,
                                sets: handleSetsUpdate(event.target.value)
                            });
                        }}
                    />
                </InputGroup>
                <Row className="m-0 p-0 mb-3 d-flex justify-content-between">
                    <Col md={6} className="m-0 p-0">
                        <InputGroup className="mb-3">
                            <InputGroup.Text>Size Target</InputGroup.Text>
                            <SimpleDropdown
                                header={build.size.toString()}
                                choices={["2", "3", "5"]}
                                updateSelection={(selection: string) => {
                                    setBuild({
                                        ...build,
                                        size: Number(selection)
                                    });
                                }}
                            />
                        </InputGroup>
                    </Col>
                    <Col md={6} className="m-0 p-0">
                        <InputGroup className="mb-3">
                            <InputGroup.Text>Duration</InputGroup.Text>
                            <FormControl
                                defaultValue={build.duration}
                                onChange={(event: any) => {
                                    setBuild({
                                        ...build,
                                        duration: event.target.value
                                    });
                                }}
                            />
                        </InputGroup>
                    </Col>
                </Row>
                <InputGroup className="mb-2">
                    <InputGroup.Text>Path</InputGroup.Text>
                    <FormControl
                        defaultValue={build.path}
                        onChange={(event: any) => {
                            setBuild({
                                ...build,
                                path: event.target.value
                            });
                        }}
                    />
                </InputGroup>
                <InputGroup className="mb-4">
                    <InputGroup.Text>Description</InputGroup.Text>
                    <FormControl
                        placeholder="Describe the base build"
                        defaultValue={build.description}
                        as="textarea"
                        rows={1}
                        onChange={(event: any) => {
                            setBuild({
                                ...build,
                                description: event.target.value
                            });
                        }}
                    />
                </InputGroup>
                <Button onClick={() => (callback ? callback(build) : null)}>
                    Save
                </Button>
            </Card.Body>
        </Card>
    );
};
