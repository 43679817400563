import React, { useState } from "react";
import { BatchBuild } from "../../../models/types";
import { useLocalAnalyticsState } from "../../../graphql/hooks";
import { Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { QueryDetailsBuildModal } from "../../modals/QueryDetailsBuildModal";
import { FunnelBatchBuild } from "../../simple/analytics/FunnelBatchBuild";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAnglesRight } from "@fortawesome/free-solid-svg-icons";

const FunnelBuildDetailsImplementation = () => {
    const {
        analyticsBuilds,
        analyticsRevisions,
        analyticsProjects,
        analyticsProducts
    } = useLocalAnalyticsState();
    const [selectedItem, setSelectedItem] = useState<BatchBuild | undefined>(
        undefined
    );
    const [showModal, setShowModal] = useState(false);

    const handleShow = (item: BatchBuild) => {
        setSelectedItem(item);
        setShowModal(true);
    };

    const handleClose = () => {
        setSelectedItem(undefined);
        setShowModal(false);
    };

    if (analyticsBuilds.length === 0) {
        return null;
    }
    const builds = analyticsBuilds
        .map((build: BatchBuild) => {
            if (
                analyticsProducts.some(
                    product => build.revision.project.product?.id === product.id
                )
            ) {
                return null;
            }
            if (
                analyticsProjects.some(
                    project => build.revision.project.id === project.id
                )
            ) {
                return null;
            }
            if (
                analyticsRevisions.some(
                    revision => build.revision.id === revision.id
                )
            ) {
                return null;
            }
            return (
                <FunnelBatchBuild
                    key={build.id}
                    build={build}
                    handleShow={handleShow}
                />
            );
        })
        .filter(build => build !== null);

    return (
        <>
            <div className="mb-2">
                <Row>
                    {builds.length > 0 && (
                        <>
                            <Col xs={11} lg={12} className="mb-2">
                                <b>Selected Builds</b>
                            </Col>
                            <Col
                                xs={1}
                                className="d-lg-none d-flex align-items-center justify-content-end"
                            >
                                <OverlayTrigger
                                    placement="left"
                                    overlay={
                                        <Tooltip id="show-more">
                                            Expand window to show Analytics data
                                            columns
                                        </Tooltip>
                                    }
                                >
                                    <FontAwesomeIcon
                                        className="text-inverse-25 me-2"
                                        icon={faAnglesRight}
                                    />
                                </OverlayTrigger>
                            </Col>
                        </>
                    )}
                </Row>
                {builds}
            </div>
            {selectedItem ? (
                <QueryDetailsBuildModal
                    batchBuild={selectedItem}
                    showModal={showModal}
                    hideModal={handleClose}
                />
            ) : null}
        </>
    );
};

export const FunnelBuildDetails = React.memo(FunnelBuildDetailsImplementation);
