import React from "react";
import { Product } from "../../../models/types";
import { useLocalAnalyticsState } from "../../../graphql/hooks";
import { Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { FunnelProduct } from "../../simple/analytics/FunnelProduct";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAnglesRight } from "@fortawesome/free-solid-svg-icons";

const FunnelProductsDetailsImplementation = () => {
    const { analyticsProducts } = useLocalAnalyticsState();

    if (analyticsProducts.length === 0) {
        return null;
    }

    const products = analyticsProducts
        .map((product: Product, index: number) => (
            <FunnelProduct key={index} product={product} />
        ))
        .filter(product => product !== null);

    return (
        <>
            {products.length > 0 && (
                <div className="mb-3">
                    <Row>
                        <Col xs={11} lg={12} className="mb-2">
                            <b>Selected products</b>
                        </Col>
                        <Col
                            xs={1}
                            className="d-lg-none d-flex align-items-center justify-content-end"
                        >
                            <OverlayTrigger
                                placement="left"
                                overlay={
                                    <Tooltip id="show-more">
                                        Expand window to show Analytics data
                                        columns
                                    </Tooltip>
                                }
                            >
                                <FontAwesomeIcon
                                    className="text-inverse-25 me-2"
                                    icon={faAnglesRight}
                                />
                            </OverlayTrigger>
                        </Col>
                    </Row>
                    {products}
                </div>
            )}
        </>
    );
};

export const FunnelProductsDetails = React.memo(
    FunnelProductsDetailsImplementation
);
