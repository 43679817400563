import React, { FC } from "react";
import { faChevronCircleLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { SizeProp } from "@fortawesome/fontawesome-svg-core";

interface Props {
    target?: string;
    size: SizeProp;
    hasHistory?: boolean;
    goBack?: () => void;
    title?: string;
}

export const BackButton: FC<Props> = ({
    target,
    size,
    hasHistory,
    goBack,
    title
}) => {
    if (goBack && hasHistory) {
        return (
            <FontAwesomeIcon
                icon={faChevronCircleLeft}
                size={size}
                onClick={() => goBack()}
                className="mouseHover text-primary"
                title={title}
            />
        );
    }

    if (!target) {
        target = "/products";
    }

    return (
        <Link to={target || "#"}>
            <FontAwesomeIcon
                className="mouseHover text-primary"
                icon={faChevronCircleLeft}
                size={size}
            />
        </Link>
    );
};
