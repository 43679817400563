import React, { FC, useEffect, useState } from "react";
import {
    Button,
    Card,
    Col,
    Collapse,
    Container,
    FormControl,
    InputGroup,
    Row
} from "react-bootstrap";
import {
    Build,
    PartialRevisionData,
    PartialRevisionVariables
} from "../../models/types";
import { VariationItemProps } from "../../models/common";
import { DetectedSetsPopover } from "../selectable/DetectedSetsPopover";
import { OverlayBadge } from "../simple/OverlayBadge";
import {
    handleSetsUpdate,
    updateRevisionPartially,
    validateBuildSetName
} from "../../common/Helpers";
import { SimpleDropdown } from "../dropdowns/SimpleDropdown";
import {
    faCaretDown,
    faChevronDown,
    faChevronUp
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useApolloClient, useMutation } from "@apollo/client";
import { PARTIAL_UPDATE_REVISION } from "../../graphql/mutations";
import "./variationItems.scss";

interface BuildProps extends VariationItemProps {
    defaultValue: Build;
    version: number;
    allowedSets: string[] | undefined;
    shouldBeVisible?: boolean;
}

export const BuildItem: FC<BuildProps> = ({
    defaultValue,
    version,
    allowedSets,
    revision,
    shouldBeVisible
}) => {
    const client = useApolloClient();
    const [build, setBuild] = useState(defaultValue);
    const [currentVersion, setCurrentVersion] = useState(version);
    const [hasBlacklistedSet, setHasBlacklistedSet] = useState(false);
    const [show, toggleShow] = useState(true);
    const blacklist = revision?.revisionConfig?.setBlacklist;
    const [partialUpdateRevision] = useMutation<
        PartialRevisionData,
        PartialRevisionVariables
    >(PARTIAL_UPDATE_REVISION);

    const updateBuilds = async (buildData?: Build, operation?: string) => {
        if (revision) {
            if (!buildData) {
                buildData = build;
            }
            await updateRevisionPartially(
                client,
                revision,
                partialUpdateRevision,
                { builds: [buildData] },
                operation
            );
        }
    };

    const disableBuild = () => {
        const newBuildData = {
            ...build,
            include: false
        };
        setBuild(newBuildData);
        updateBuilds(newBuildData);
    };

    useEffect(() => {
        if (defaultValue && currentVersion < version) {
            setCurrentVersion(version);
            setBuild(defaultValue);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultValue, version]);

    useEffect(() => {
        if (blacklist && build.sets) {
            let hasSet = false;
            for (const set of build.sets) {
                if (blacklist.includes(set)) {
                    if (build.include) {
                        disableBuild();
                    }
                    hasSet = true;
                    break;
                }
            }
            setHasBlacklistedSet(hasSet);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [build.sets, build.include, blacklist]);

    useEffect(() => {
        if (shouldBeVisible !== undefined && shouldBeVisible !== show) {
            toggleShow(shouldBeVisible);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shouldBeVisible]);

    const remove = () => {
        if (revision) {
            // updateBuilds({ builds: [build] }, undefined, "delete");
            updateBuilds(build, "delete");
        }
    };

    const toggleSet = (set: string) => {
        let currentSets = [...build.sets];
        if (currentSets.includes(set)) {
            currentSets = currentSets.filter(element => element !== set);
        } else {
            currentSets.push(set);
        }
        const updatedBuild = { ...build, sets: currentSets };
        setBuild(updatedBuild);
        updateBuilds(updatedBuild);
    };

    return (
        <Col xs="auto" className="p-sm-2 p-0 mb-3">
            <Card className="inner-card">
                <Card.Header className="p-md-3 p-2">
                    <Row className="m-0 p-0 d-flex text-inverse justify-content-end align-items-center">
                        <Container className="m-0 col-9 col-md-10">
                            <InputGroup className="m-0 p-0">
                                <InputGroup.Text>Name</InputGroup.Text>
                                <FormControl
                                    placeholder="Descriptive title"
                                    className="pe-5"
                                    value={build.name}
                                    onChange={(event: any) => {
                                        const newName =
                                            event.target.value.replace(" ", "");
                                        if (validateBuildSetName(newName)) {
                                            setBuild({
                                                ...build,
                                                name: newName
                                            });
                                        }
                                    }}
                                    onBlur={() => {
                                        updateBuilds();
                                    }}
                                />
                            </InputGroup>
                        </Container>
                        <Container className="d-flex align-items-center m-0 col-3 p-0 ps-1 col-md-2">
                            <div className="checkbox-width mb-1 me-2">
                                {hasBlacklistedSet ? (
                                    <OverlayBadge
                                        badgeVariant="danger"
                                        className="ms-2"
                                        badgeHeader="!"
                                        overlayText="Build has blacklisted set(s)!"
                                    />
                                ) : (
                                    <InputGroup className="m-0 p-0 custom-input-background">
                                        <InputGroup.Checkbox
                                            title="Include in next Build set"
                                            aria-label="Include in next build set"
                                            checked={build.include}
                                            onChange={(event: any) => {
                                                const newBuildData = {
                                                    ...build,
                                                    include:
                                                        event.target.checked
                                                };
                                                setBuild(newBuildData);
                                                updateBuilds(newBuildData);
                                            }}
                                        />
                                    </InputGroup>
                                )}
                            </div>
                            <FontAwesomeIcon
                                icon={show ? faChevronUp : faChevronDown}
                                className="ms-2 mouseHover"
                                onClick={() => {
                                    toggleShow(!show);
                                }}
                                title={
                                    show
                                        ? "Hide Build details"
                                        : "Show Build details"
                                }
                            />
                        </Container>
                    </Row>
                </Card.Header>
                <Collapse in={show}>
                    <div>
                        <Card.Body className="p-md-3 p-2 d-flex flex-column">
                            <InputGroup className="mb-2">
                                {revision?.revisionConfig?.sets &&
                                revision?.revisionConfig?.sets.length > 0 ? (
                                    <DetectedSetsPopover
                                        title={
                                            <>
                                                <span className="pe-2">
                                                    Sets
                                                </span>
                                                <FontAwesomeIcon
                                                    size="xs"
                                                    icon={faCaretDown}
                                                />
                                            </>
                                        }
                                        onToggle={set => toggleSet(set)}
                                        allSets={allowedSets}
                                        usedSets={build.sets}
                                    />
                                ) : null}
                                <FormControl
                                    value={build.sets.join(",")}
                                    onChange={(event: any) => {
                                        setBuild({
                                            ...build,
                                            sets: handleSetsUpdate(
                                                event.target.value
                                            )
                                        });
                                    }}
                                    onBlur={() => {
                                        updateBuilds();
                                    }}
                                />
                            </InputGroup>
                            <Row className="m-0 p-0 mb-2 d-flex justify-content-between">
                                <Col md={6} className="m-0 p-0">
                                    <InputGroup className="mb-md-0 mb-2 mb-lg-0">
                                        <InputGroup.Text>
                                            Size Target
                                        </InputGroup.Text>
                                        <SimpleDropdown
                                            header={build.size.toString()}
                                            choices={["2", "3", "5"]}
                                            updateSelection={(
                                                selection: string
                                            ) => {
                                                updateBuilds({
                                                    ...build,
                                                    size: Number(selection)
                                                });
                                            }}
                                        />
                                    </InputGroup>
                                </Col>
                                <Col md={6} className="m-0 p-0">
                                    <InputGroup>
                                        <InputGroup.Text>
                                            Duration
                                        </InputGroup.Text>
                                        <FormControl
                                            value={build.duration}
                                            onChange={(event: any) => {
                                                setBuild({
                                                    ...build,
                                                    duration: event.target.value
                                                });
                                            }}
                                            onBlur={() => {
                                                updateBuilds();
                                            }}
                                        />
                                    </InputGroup>
                                </Col>
                            </Row>
                            <InputGroup className="mb-2">
                                <InputGroup.Text>Path</InputGroup.Text>
                                <FormControl
                                    value={build.path}
                                    onChange={(event: any) => {
                                        setBuild({
                                            ...build,
                                            path: event.target.value
                                        });
                                    }}
                                    onBlur={() => {
                                        updateBuilds();
                                    }}
                                />
                            </InputGroup>
                            <InputGroup className="mb-3">
                                <InputGroup.Text>Description</InputGroup.Text>
                                <FormControl
                                    placeholder="Describe the base build"
                                    value={build.description}
                                    as="textarea"
                                    rows={2}
                                    onChange={(event: any) => {
                                        setBuild({
                                            ...build,
                                            description: event.target.value
                                        });
                                    }}
                                    onBlur={() => {
                                        updateBuilds();
                                    }}
                                />
                            </InputGroup>
                            <Button className="btn-danger" onClick={remove}>
                                Remove
                            </Button>
                        </Card.Body>
                    </div>
                </Collapse>
            </Card>
        </Col>
    );
};
