import React from "react";
import { EditPanel } from "../panels/EditPanel";
import { useSubscription } from "@apollo/client";
import {
    REVISION_CONFIG_SUBSCRIPTION,
    REVISION_SUBSCRIPTION
} from "../../graphql/subscriptions";
import {
    RevisionConfig,
    RevisionData,
    RevisionVariables
} from "../../models/types";
import {
    GET_REVISION,
    GET_REVISION_CONFIG_LOCALIZATIONS
} from "../../graphql/queries";
import { useParams } from "react-router-dom";
import { getUnixTimestampFromDateString } from "../../common/Helpers";

export const RevisionSubscriptions = () => {
    const { revisionId } = useParams<{
        revisionId: string;
    }>();

    useSubscription(REVISION_SUBSCRIPTION, {
        variables: { revisionId: revisionId },
        onData: ({ data: { data: { revisionNotification } = {} }, client }) => {
            const currentRevisionData = client.cache.readQuery<
                RevisionData,
                RevisionVariables
            >({
                query: GET_REVISION,
                variables: { revisionId: Number(revisionId) }
            });

            const currentRevision = currentRevisionData?.revision;
            if (
                revisionNotification &&
                currentRevision &&
                getUnixTimestampFromDateString(
                    revisionNotification.dateUpdated
                ) > getUnixTimestampFromDateString(currentRevision.dateUpdated)
            ) {
                client.refetchQueries({
                    include: ["getRevision"]
                });
            }
        }
    });

    useSubscription(REVISION_CONFIG_SUBSCRIPTION, {
        variables: { revisionId: revisionId },
        onData: ({
            data: { data: { revisionConfigNotification } = {} },
            client
        }) => {
            const currentRevisionConfigLocalizationData =
                client.cache.readQuery<{
                    revisionConfig: RevisionConfig;
                }>({
                    query: GET_REVISION_CONFIG_LOCALIZATIONS,
                    variables: { revisionId: Number(revisionId) }
                });

            const currentRevisionConfigLocalization =
                currentRevisionConfigLocalizationData?.revisionConfig;

            if (
                currentRevisionConfigLocalization &&
                revisionConfigNotification
            ) {
                // TODO: Version checks etc

                client.cache.writeQuery<{
                    revisionConfig: RevisionConfig;
                }>({
                    query: GET_REVISION_CONFIG_LOCALIZATIONS,
                    variables: { revisionId: Number(revisionId) },
                    data: { revisionConfig: currentRevisionConfigLocalization }
                });
            }
        }
    });

    return <EditPanel />;
};
