import React, { useEffect, useState } from "react";
import { Button, Container, Row } from "react-bootstrap";
import { useMutation, useQuery } from "@apollo/client";
import { AUTHENTICATE_CLI } from "../../../graphql/mutations";
import { useLocation } from "react-router-dom";
import {
    DevApiKeyStatusData,
    GenerateDevApiKeyData,
    GenerateDevApiKeyVariables
} from "../../../models/types";
import { QUERY_DEV_KEY_STATUS } from "../../../graphql/queries";

enum State {
    StatusQuery,
    WaitingUserActionNew,
    WaitingUserActionOld,
    Authenticating,
    Error,
    Success,
    AlreadyCreated
}

export const CliAuthPage = () => {
    const location = useLocation();
    const { data } = useQuery<DevApiKeyStatusData>(QUERY_DEV_KEY_STATUS);
    const [authenticate] = useMutation<
        GenerateDevApiKeyData,
        GenerateDevApiKeyVariables
    >(AUTHENTICATE_CLI);
    const [state, updateState] = useState(State.StatusQuery);

    const params = new URLSearchParams(location.search);
    const redirectUri = params.get("redirect_uri");

    useEffect(() => {
        if (data?.getDevApiKeyStatus) {
            if (data.getDevApiKeyStatus.status === 0) {
                updateState(State.WaitingUserActionNew);
            } else if (data.getDevApiKeyStatus.status === 1) {
                updateState(State.WaitingUserActionOld);
            } else {
                updateState(State.Error);
            }
        }
    }, [data]);

    if (!redirectUri) {
        return null;
    }

    const startAuthProcess = async (removeOld = false) => {
        try {
            updateState(State.Authenticating);
            const { data } = await authenticate({
                variables: { removeOld: removeOld }
            });
            if (data?.generateDevApiKey) {
                if (data.generateDevApiKey.result === 1) {
                    updateState(State.AlreadyCreated);
                } else {
                    updateState(State.Success);
                    window.location.href = `${redirectUri}?api_key=${data?.generateDevApiKey.key}`;
                }
            } else {
                updateState(State.Error);
            }
        } catch (error) {
            console.log("[DEBUG] startAuthProcess error ", error);
        }
    };

    let stateElement = null;
    switch (state) {
        case State.WaitingUserActionNew: {
            stateElement = (
                <>
                    <Container className="d-flex flex-column mb-4">
                        <h3>Generate developer key</h3>
                        <h5 className="text-inverse-50">
                            Please note: Keep your key safe as it will not be
                            visible anywhere after authentication
                        </h5>
                    </Container>
                    <Button onClick={() => startAuthProcess()}>
                        Generate Key
                    </Button>
                </>
            );
            break;
        }
        case State.WaitingUserActionOld: {
            stateElement = (
                <>
                    <Container className="d-flex flex-column mb-4">
                        <h3>Developer key already generated!</h3>
                        <h5 className="text-inverse-50">
                            Please note: Re-generating will remove your old key.
                        </h5>
                    </Container>
                    <Button onClick={() => startAuthProcess(true)}>
                        Re-generate
                    </Button>
                </>
            );
            break;
        }
        case State.Authenticating: {
            stateElement = <h5>Authenticating...</h5>;
            break;
        }
        case State.Error: {
            stateElement = <h5>Authentication Failed!</h5>;
            break;
        }
        case State.Success: {
            stateElement = <h5>Authentication successful!</h5>;
            break;
        }
        case State.AlreadyCreated: {
            stateElement = <h5>Authentication failed! Contact support.</h5>;
            break;
        }
        default:
            return null;
    }

    return (
        <Container className="d-flex justify-content-center align-items-center align-content-center text-inverse">
            <Row className="d-flex justify-content-center text-center mt-5">
                {stateElement}
            </Row>
        </Container>
    );
};
