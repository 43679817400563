import React, { FC, useEffect, useState } from "react";
import { Badge, Card, Collapse } from "react-bootstrap";
import { MutatorEnums, MutatorProperty } from "../../../models/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { EnumDropdown } from "../../dropdowns/mutator/EnumDropdown";
import { useMutatorState } from "../../../graphql/hooks";
import styles from "./CustomTypeCard.module.scss";
import cx from "classnames";

interface Props {
    property: MutatorProperty | any;
    enums: MutatorEnums;
    getVariantByType: (type: string) => string;
    onChange: (name: string, value: string) => void;
}

const CustomTypeEnumCardImplementation: FC<Props> = ({
    property,
    enums,
    getVariantByType,
    onChange
}) => {
    const mutatorState = useMutatorState();
    const [showMeta, updateShowMeta] = useState(mutatorState.showMeta);
    // const [showContent, setShowContent] = useState(false);

    useEffect(() => {
        if (showMeta !== mutatorState.showMeta) {
            updateShowMeta(mutatorState.showMeta);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mutatorState.showMeta]);

    return (
        <Card
            className="m-2 inner-card text-inverse"
            key={property.name}
            id={property.name}
        >
            <Card.Header
                className={`d-flex align-items-center ${styles.cardHeader}`}
            >
                <span className={styles.headerText}>{property.name}</span>
                <Badge
                    className="m-0 ms-2"
                    bg={getVariantByType(property.type)}
                >
                    {property.type}
                </Badge>
                {property.meta ? (
                    <div
                        className="ms-auto float-end mouseHover"
                        onClick={() => updateShowMeta(!showMeta)}
                        title="Help and additional info"
                    >
                        <FontAwesomeIcon
                            icon={faCircleInfo}
                            className={cx({
                                "text-inverse-25": !showMeta,
                                "text-inverse-50": showMeta
                            })}
                        />
                    </div>
                ) : null}
            </Card.Header>
            <div>
                <Card.Body className={`${styles.cardBody} ms-1`}>
                    <EnumDropdown
                        isOptional={false}
                        property={property}
                        enums={enums}
                        getVariantByType={getVariantByType}
                        onChange={element => {
                            onChange(property.name, element);
                        }}
                    />
                </Card.Body>
            </div>
            {property.meta ? (
                <Collapse in={showMeta}>
                    <div>
                        <Card.Footer className={styles.footerInfo}>
                            {property.meta.description ? (
                                <div className="bread-text text-inverse-50">
                                    {property.meta.description}
                                </div>
                            ) : (
                                <span className="bread-text text-inverse-50">
                                    No description provided.
                                </span>
                            )}
                        </Card.Footer>
                    </div>
                </Collapse>
            ) : null}
        </Card>
    );
};

export const CustomTypeEnumCard = React.memo(CustomTypeEnumCardImplementation);
