import React, { FC, useEffect, useState, useRef, SyntheticEvent } from "react";
import { Form, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { useMutation } from "@apollo/client";
import styles from "../../sass/components/EditableText.module.scss";

interface Props {
    productId?: number;
    revisionId?: number;
    isEditable: boolean;
    text: string;
    mutation: any;
    valueName: string;
    className?: {
        container?: string;
        form?: string;
        text?: string;
    };
}

export const EditableTextComponent: FC<Props> = props => {
    let nameField: any = useRef(null);
    const [text, updateText] = useState("");
    const [isEditing, updateEditing] = useState(false);
    const [updateValue] = useMutation(props.mutation);

    useEffect(() => {
        if (isEditing) {
            nameField.focus();
        }
    }, [isEditing]);

    const toggleEditing = () => {
        if (!isEditing && props.isEditable) {
            updateText(props.text || "");
            updateEditing(true);
        }
    };

    const updateTextValue = async (text: string | undefined) => {
        try {
            if (!text) {
                return;
            }

            if (props.productId !== undefined) {
                await updateValue({
                    variables: {
                        productId: props.productId,
                        [props.valueName]: text
                    }
                });
            } else if (props.revisionId !== undefined) {
                await updateValue({
                    variables: {
                        revisionId: props.revisionId,
                        [props.valueName]: text
                    }
                });
            } else {
                console.log(
                    "[DEBUG] configuration error or smth smth darkside"
                );
                return;
            }

            setTimeout(() => {
                updateEditing(false);
            }, 100);
        } catch (error) {
            console.log("[DEBUG] updateTextValue error ", error);
        }
    };

    return (
        <div>
            <div
                className={[styles.container, props.className?.container].join(
                    " "
                )}
            >
                {!isEditing ? (
                    <span
                        className={[styles.text, props.className?.text].join(
                            " "
                        )}
                    >
                        {props.text}
                    </span>
                ) : null}
                {props.isEditable && isEditing ? (
                    <Form
                        className={[styles.form, props.className?.form].join(
                            " "
                        )}
                        onSubmit={(event: any) => {
                            event.preventDefault();
                            updateTextValue(text);
                        }}
                    >
                        <Form.Control
                            ref={(input: any) => {
                                nameField = input;
                            }}
                            type="text"
                            className={styles.customInput}
                            value={text}
                            onChange={(event: any) => {
                                updateText(event.target.value);
                            }}
                            onBlur={() => {
                                updateTextValue(text);
                            }}
                            onKeyDown={(
                                event: React.KeyboardEvent<HTMLInputElement>
                            ) => {
                                if (event.key === "Enter") {
                                    event.preventDefault();
                                    updateTextValue(text);
                                }
                            }}
                        />
                    </Form>
                ) : null}
                {props.isEditable && !isEditing && (
                    <FontAwesomeIcon
                        title="Edit title"
                        icon={faEdit}
                        size="xs"
                        onClick={(event: SyntheticEvent) => {
                            event.stopPropagation();
                            toggleEditing();
                        }}
                        className={`mouseHover text-inverse-25 ${styles.editIcon}`}
                    />
                )}
            </div>
        </div>
    );
};
