import React, { FC } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import {
    PartialRevisionData,
    PartialRevisionVariables,
    Revision
} from "../../../models/types";
import { DetectedSetsPopover } from "../../selectable/DetectedSetsPopover";
import { useApolloClient, useMutation } from "@apollo/client";
import { PARTIAL_UPDATE_REVISION } from "../../../graphql/mutations";
import { updateRevisionPartially } from "../../../common/Helpers";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../views.scss";

interface setPlaceholderProps {
    revision: Revision;
}

export const SetPlaceholder: FC<setPlaceholderProps> = ({ revision }) => {
    const client = useApolloClient();
    const [partialUpdateRevision] = useMutation<
        PartialRevisionData,
        PartialRevisionVariables
    >(PARTIAL_UPDATE_REVISION);

    const toggleSet = async (set: string) => {
        const placeholder = revision.buildData?.placeholders;
        if (!placeholder) {
            const newBuildData = {
                placeholders: [set]
            };
            // updateRevision(newBuildData);
            await updateRevisionPartially(
                client,
                revision,
                partialUpdateRevision,
                newBuildData
            );
            return;
        }

        if (placeholder.includes(set)) {
            const list = placeholder.filter(
                (currSet: string) => currSet !== set
            );
            const newBuildData = {
                placeholders: list
            };
            // updateRevision(newBuildData);
            await updateRevisionPartially(
                client,
                revision,
                partialUpdateRevision,
                newBuildData
            );
        } else {
            const newBuildData = {
                placeholders: [...placeholder, set]
            };
            // updateRevision(newBuildData);
            await updateRevisionPartially(
                client,
                revision,
                partialUpdateRevision,
                newBuildData
            );
        }
    };

    return (
        <Container className="my-2">
            <Row>
                <Col className="mb-1">
                    {revision?.revisionConfig?.sets &&
                    revision?.revisionConfig?.sets.length > 0 ? (
                        <DetectedSetsPopover
                            title={
                                <>
                                    <span className="pe-2">Sets</span>
                                    <FontAwesomeIcon
                                        size="sm"
                                        icon={faCaretDown}
                                    />
                                </>
                            }
                            onToggle={set => toggleSet(set)}
                            allSets={revision?.revisionConfig?.sets}
                            usedSets={revision?.buildData.placeholders}
                        />
                    ) : null}
                </Col>
            </Row>
            <Row>
                <Col>
                    {revision.buildData?.placeholders.map((set: string) => {
                        return (
                            <Button
                                key={set}
                                className="mt-1 me-1 btn-sm btn-secondary"
                                onClick={() => toggleSet(set)}
                            >
                                {set}
                            </Button>
                        );
                    })}
                </Col>
            </Row>
        </Container>
    );
};
