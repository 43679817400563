import React, { FC, SyntheticEvent } from "react";
import { Button } from "react-bootstrap";
import { BasicVariables, LegacyPageData } from "../../models/types";
import { useApolloClient, useMutation } from "@apollo/client";
import { GET_RESULT } from "../../graphql/queries";
import { GENERATE_LINK_PAGE } from "../../graphql/mutations";
import { LoadingSpinner } from "../simple/LoadingSpinner";
import { updateNotificationState } from "../../common/Helpers";

interface Props {
    jobId: number;
    legacyLink: string | undefined;
    zip: string | undefined;
    pathTemplate: string | undefined;
}

export const LegacyLinkPageButton: FC<Props> = ({
    jobId,
    legacyLink,
    zip,
    pathTemplate
}) => {
    const client = useApolloClient();
    const [startLinkPageGeneration] = useMutation<
        LegacyPageData,
        BasicVariables
    >(GENERATE_LINK_PAGE);

    const showToastGenerationStarted = () => {
        updateNotificationState(client, false, true, {
            success: true,
            header: "Link Page Generation Started",
            message:
                "This might take some time, enjoy some coffee or tee while you wait.",
            delay: 5000
        });
    };

    const showToastGenerationFailed = () => {
        updateNotificationState(client, false, true, {
            success: false,
            header: "Link Page Generation Failed",
            message:
                "Try again later. If it still fails, contact platform admin",
            delay: 5000
        });
    };

    const showToastZipRequired = () => {
        updateNotificationState(client, false, true, {
            success: false,
            header: "Zip is not generated yet!",
            message:
                "Start or wait for zip generation to finish before creating link page",
            delay: 5000
        });
    };

    const getLink = async () => {
        try {
            if (!zip || zip === "loading") {
                showToastZipRequired();
                return;
            }
            const { data } = await startLinkPageGeneration({
                variables: { id: Number(jobId) },
                refetchQueries: [
                    {
                        query: GET_RESULT,
                        variables: { id: Number(jobId) }
                    }
                ]
            });
            if (data?.generateLinkPage?.success) {
                showToastGenerationStarted();
            } else {
                showToastGenerationFailed();
            }
        } catch (error) {
            console.log("[DEBUG] getLink error ", error);
        }
    };

    if (!pathTemplate) {
        return null;
    }

    if (legacyLink) {
        return (
            <Button
                variant="info"
                onClick={(event: SyntheticEvent) => {
                    event.stopPropagation();
                    if (!zip || zip === "loading") {
                        showToastZipRequired();
                        return;
                    }
                    if (legacyLink === "loading") {
                        return;
                    }
                    window.open(legacyLink, "_blank");
                }}
            >
                {legacyLink === "loading" ? (
                    <LoadingSpinner header="Generating link" size="sm" />
                ) : (
                    "Link Page"
                )}
            </Button>
        );
    }

    return (
        <Button
            variant="info"
            onClick={event => {
                event.stopPropagation();
                getLink();
            }}
        >
            Generate Link Page
        </Button>
    );
};
