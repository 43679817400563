import React, { FC, useState } from "react";
import { useQuery } from "@apollo/client";
import { Card } from "react-bootstrap";
import { GET_ME, GET_USERS } from "../../../graphql/queries";
import { MeData, User, UsersData } from "../../../models/types";
import { UserModal } from "../../modals/admin/UserModal";
import cx from "classnames";

interface Props {
    organizationName: string;
    organizationId: number | undefined;
}

const UsersPerOrganizationImplementation: FC<Props> = ({
    organizationName,
    organizationId
}) => {
    const { data: { me } = {} } = useQuery<MeData>(GET_ME, {
        fetchPolicy: "cache-first"
    });

    const [currentUserId, updateUserId] = useState<number | undefined>(
        undefined
    );

    const [showUserModal, updateShowUserModal] = useState(false);

    const { data: { users } = {} } = useQuery<UsersData>(GET_USERS, {
        variables: {
            organizationId: Number(organizationId)
        }
    });

    const baseUsers: User[] = [];
    const admins: User[] = [];
    const developers: User[] = [];

    if (!users && !organizationId) {
        return null;
    }

    if (!users) {
        return <p className="text-inverse">No users found</p>;
    }

    for (let i = users.length - 1; i >= 0; i--) {
        const element = users[i];
        if (element.scopes && element.scopes.includes("admin")) {
            admins.push(element);
        } else if (element.scopes && element.scopes.includes("developer")) {
            developers.push(element);
        } else {
            baseUsers.push(element);
        }
    }

    const adminElements = admins?.map((user: User, index: number) => (
        <li
            key={index}
            onClick={() => {
                updateUserId(user.id);
                updateShowUserModal(true);
            }}
            className={cx(
                "list-group-item list-group-item-danger",
                me?.scopes.includes("admin") ? "mouseHover" : "disabled"
            )}
        >
            {user.id}: {user.email} ({user.displayName})
        </li>
    ));

    const developerElements = developers?.map((user: User, index: number) => (
        <li
            key={index}
            onClick={() => {
                updateUserId(user.id);
                updateShowUserModal(true);
            }}
            className="list-group-item list-group-item-primary mouseHover"
        >
            {user.id}: {user.email} ({user.displayName})
        </li>
    ));

    const baseUserElements = baseUsers?.map((user: User, index: number) => (
        <li
            key={index}
            onClick={() => {
                updateUserId(user.id);
                updateShowUserModal(true);
            }}
            className="list-group-item list-group-item-success mouseHover"
        >
            {user.id}: {user.email} ({user.displayName})
        </li>
    ));

    if (!users || users.length === 0) {
        return null;
    }

    return (
        <>
            <Card className="m-3 inner-card text-inverse">
                <Card.Header>{organizationName}</Card.Header>
                <Card.Body>
                    {admins.length > 0 ? (
                        <>
                            <h4 className="text-danger">Admin</h4>
                            <ul className="list-group mb-2">{adminElements}</ul>
                        </>
                    ) : null}
                    {developers.length > 0 ? (
                        <>
                            <h4 className="text-primary">Developer</h4>
                            <ul className="list-group mb-2">
                                {developerElements}
                            </ul>
                        </>
                    ) : null}
                    {baseUsers.length > 0 ? (
                        <>
                            <h4 className="text-success">User</h4>
                            <ul className="list-group mb-2">
                                {baseUserElements}
                            </ul>
                        </>
                    ) : null}
                </Card.Body>
            </Card>
            {showUserModal && currentUserId ? (
                <UserModal
                    userId={currentUserId}
                    visible={() => {
                        updateUserId(undefined);
                        updateShowUserModal(false);
                    }}
                />
            ) : null}
        </>
    );
};

export const UsersPerOrganization = React.memo(
    UsersPerOrganizationImplementation
);
