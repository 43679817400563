import React, { FC, useEffect, useState } from "react";
import {
    Button,
    Card,
    Col,
    Collapse,
    Container,
    FormControl,
    InputGroup
} from "react-bootstrap";
import {
    PartialRevisionData,
    PartialRevisionVariables,
    Variation,
    VariationSet
} from "../../models/types";
import { VariationItemProps } from "../../models/common";
import { DetectedSetsPopover } from "../selectable/DetectedSetsPopover";
import { OverlayBadge } from "../simple/OverlayBadge";
import {
    handleSetUpdate,
    updateRevisionPartially,
    validateBuildSetName
} from "../../common/Helpers";
import {
    faCaretDown,
    faChevronDown,
    faChevronUp,
    faPlus
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useApolloClient, useMutation } from "@apollo/client";
import { PARTIAL_UPDATE_REVISION } from "../../graphql/mutations";
import "./variationItems.scss";
import cx from "classnames";

interface VariationProps extends VariationItemProps {
    defaultValue: Variation;
    version: number;
    allowedSets: string[] | undefined;
    shouldBeVisible: boolean | undefined;
}

export const VariationItem: FC<VariationProps> = ({
    defaultValue,
    version,
    allowedSets,
    revision,
    shouldBeVisible
}) => {
    const client = useApolloClient();
    const [show, toggleShow] = useState(true);
    const [variation, updateVariation] = useState(defaultValue);
    const [currentVersion, setCurrentVersion] = useState(version);
    const [hasBlacklistedSet, setHasBlacklistedSet] = useState(false);
    const blacklist = revision?.revisionConfig?.setBlacklist;

    const [partialUpdateRevision] = useMutation<
        PartialRevisionData,
        PartialRevisionVariables
    >(PARTIAL_UPDATE_REVISION);

    const updateVariations = async (
        newVariationData?: Variation,
        operation?: string
    ) => {
        if (revision) {
            if (!newVariationData) {
                newVariationData = variation;
            }
            // updateRevision({ variations: [newVariationData] });
            await updateRevisionPartially(
                client,
                revision,
                partialUpdateRevision,
                { variations: [newVariationData] },
                operation
            );
        }
    };

    const disableVariation = () => {
        const newBuildData = {
            ...variation,
            include: false
        };
        updateVariation(newBuildData);
        updateVariations(newBuildData);
    };

    useEffect(() => {
        if (defaultValue && currentVersion < version) {
            setCurrentVersion(version);
            updateVariation(defaultValue);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultValue, version]);

    useEffect(() => {
        if (blacklist && variation.sets) {
            let hasSet = false;
            for (const item of variation.sets) {
                if (blacklist.includes(item.set)) {
                    if (variation.include) {
                        disableVariation();
                    }
                    hasSet = true;
                    break;
                }
            }
            setHasBlacklistedSet(hasSet);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [variation.sets, variation.include, blacklist]);

    useEffect(() => {
        if (shouldBeVisible !== undefined && shouldBeVisible !== show) {
            toggleShow(shouldBeVisible);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shouldBeVisible]);

    const remove = () => {
        if (revision) {
            // updateRevision({ variations: [variation] }, undefined, "delete");
            updateVariations(variation, "delete");
        }
    };

    const addSet = (variation: Variation) => {
        const newSet = { name: "", set: "" };
        const newSets = [...variation.sets, newSet];
        const updatedVariation = { ...variation, sets: newSets };
        updateVariation(updatedVariation);
        updateVariations(updatedVariation);
    };

    const removeSet = (index: number) => {
        if (variation.sets.length <= 1) {
            console.log("[DEBUG] can't have zero sets for variation!");
            return;
        }
        const newSets = variation.sets.filter((item, i) => i !== index);
        const updatedVariation = { ...variation, sets: newSets };
        updateVariation(updatedVariation);
        updateVariations(updatedVariation);
    };

    const updateVariationSet = (variationSet: VariationSet, index: number) => {
        const newSets = variation.sets.map((item, i) =>
            i === index ? variationSet : item
        );
        updateVariation({ ...variation, sets: newSets });
    };

    const updateVariationSetAndSave = (
        variationSet: VariationSet,
        index: number
    ) => {
        const newSets = variation.sets.map((item, i) =>
            i === index ? variationSet : item
        );
        const updatedVariation = { ...variation, sets: newSets };
        updateVariation(updatedVariation);
        updateVariations(updatedVariation);
    };

    const sets = variation.sets.map((item: VariationSet, index) => (
        <Card
            key={index}
            className="m-1 p-1 p-md-2 inner-card ms-3 me-3 d-flex align-content-center"
        >
            <InputGroup>
                <InputGroup.Text>Name</InputGroup.Text>
                <FormControl
                    value={item.name}
                    onChange={(event: any) => {
                        const newName = event.target.value.replace(" ", "");
                        if (validateBuildSetName(newName, true)) {
                            updateVariationSet(
                                { ...item, name: newName },
                                index
                            );
                        }
                    }}
                    onBlur={() => {
                        updateVariations();
                    }}
                />
            </InputGroup>
            <InputGroup className="mt-1 mb-1">
                <DetectedSetsPopover
                    title={
                        <>
                            <span className="pe-2">Set</span>
                            <FontAwesomeIcon
                                size="sm"
                                className="pe-1"
                                icon={faCaretDown}
                            />
                        </>
                    }
                    onToggle={set => {
                        updateVariationSetAndSave({ ...item, set: set }, index);
                    }}
                    allSets={allowedSets}
                    usedSets={[item.set]}
                />
                <FormControl
                    value={item.set}
                    onChange={(event: any) =>
                        updateVariationSet(
                            {
                                ...item,
                                set: handleSetUpdate(event.target.value)
                            },
                            index
                        )
                    }
                    onBlur={() => {
                        updateVariations();
                    }}
                />
            </InputGroup>
            <div>
                <Button
                    className={cx("btn-danger mt-2 w-30 float-end ms-auto", {
                        disabled: variation.sets.length <= 1
                    })}
                    onClick={() => {
                        removeSet(index);
                    }}
                >
                    Remove
                </Button>
            </div>
        </Card>
    ));

    return (
        <Col xs="auto" className="p-sm-2 p-0 mb-3">
            <Card className="inner-card">
                <Card.Header className="d-flex p-md-3 p-2 text-inverse align-items-center">
                    <Container className="m-0 p-0 col-8 col-md-9">
                        <InputGroup className="pe-2">
                            <InputGroup.Text>Name</InputGroup.Text>
                            <FormControl
                                placeholder="Descriptive title"
                                value={variation.name}
                                onChange={(event: any) => {
                                    const newName = event.target.value.replace(
                                        " ",
                                        ""
                                    );
                                    if (validateBuildSetName(newName)) {
                                        updateVariation({
                                            ...variation,
                                            name: event.target.value
                                        });
                                    }
                                }}
                                onBlur={() => {
                                    updateVariations();
                                }}
                                onClick={(event: any) =>
                                    event.stopPropagation()
                                }
                            />
                        </InputGroup>
                    </Container>
                    <Container className="d-flex align-items-center m-0 p-0 ps-1 col-4 col-md-3">
                        <div className="checkbox-width mb-1 me-2">
                            {hasBlacklistedSet ? (
                                <OverlayBadge
                                    badgeVariant="danger"
                                    className="ms-2"
                                    badgeHeader="!"
                                    overlayText="Variation has blacklisted set(s)!"
                                />
                            ) : (
                                <InputGroup className="m-0 p-0 custom-input-background">
                                    <InputGroup.Checkbox
                                        aria-label="Include in the next Build set"
                                        title="Include in the next Build set"
                                        checked={variation.include}
                                        onClick={(event: any) => {
                                            event.stopPropagation();
                                        }}
                                        onChange={(event: any) => {
                                            const newVariationData = {
                                                ...variation,
                                                include: event.target.checked
                                            };
                                            updateVariation(newVariationData);
                                            updateVariations(newVariationData);
                                        }}
                                    />
                                </InputGroup>
                            )}
                        </div>
                        <FontAwesomeIcon
                            icon={faPlus}
                            className="mouseHover mx-2"
                            onClick={(event: any) => {
                                event.stopPropagation();
                                addSet(variation);
                            }}
                        />
                        <FontAwesomeIcon
                            icon={show ? faChevronUp : faChevronDown}
                            className="mouseHover mx-2"
                            onClick={() => {
                                toggleShow(!show);
                            }}
                            title={
                                show
                                    ? "Hide Variation details"
                                    : "Show Variation details"
                            }
                        />
                    </Container>
                </Card.Header>
                <Collapse in={show}>
                    <div>
                        <Card.Body className="p-md-3 p-2 d-flex flex-column">
                            <div>
                                <Container className="m-0 p-0">
                                    {sets}
                                </Container>
                            </div>
                            <InputGroup className="mb-3 mt-3">
                                <InputGroup.Text>Description</InputGroup.Text>
                                <FormControl
                                    placeholder="Describe the variation content(s)"
                                    value={variation.description}
                                    as="textarea"
                                    rows={2}
                                    onChange={(event: any) => {
                                        updateVariation({
                                            ...variation,
                                            description: event.target.value
                                        });
                                    }}
                                    onBlur={() => {
                                        updateVariations();
                                    }}
                                />
                            </InputGroup>
                            <Button className="btn-danger" onClick={remove}>
                                Remove
                            </Button>
                        </Card.Body>
                    </div>
                </Collapse>
            </Card>
        </Col>
    );
};
