import React from "react";
import { Revision } from "../../../models/types";
import { useLocalAnalyticsState } from "../../../graphql/hooks";
import { Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { FunnelRevision } from "../../simple/analytics/FunnelRevision";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAnglesRight } from "@fortawesome/free-solid-svg-icons";

const FunnelRevisionDetailsImplementation = () => {
    const { analyticsRevisions, analyticsProjects, analyticsProducts } =
        useLocalAnalyticsState();

    if (analyticsRevisions.length === 0) {
        return null;
    }

    const revisions = analyticsRevisions
        .map((revision: Revision) => {
            if (
                analyticsProjects.some(
                    project => revision.project?.id === project.id
                )
            ) {
                return null;
            }
            if (
                analyticsProducts.some(
                    product => revision.project?.product?.id === product.id
                )
            ) {
                return null;
            }
            return <FunnelRevision key={revision.id} revision={revision} />;
        })
        .filter(revision => revision !== null);

    return (
        <>
            {revisions.length > 0 && (
                <div className="mb-3">
                    <Row>
                        <Col xs={11} lg={12} className="mb-2">
                            <b>Selected Revisions</b>
                        </Col>
                        <Col
                            xs={1}
                            className="d-lg-none d-flex align-items-center justify-content-end"
                        >
                            <OverlayTrigger
                                placement="left"
                                overlay={
                                    <Tooltip id="show-more">
                                        Expand window to show Analytics data
                                        columns
                                    </Tooltip>
                                }
                            >
                                <FontAwesomeIcon
                                    className="text-inverse-25 me-2"
                                    icon={faAnglesRight}
                                />
                            </OverlayTrigger>
                        </Col>
                    </Row>
                    {revisions}
                </div>
            )}
        </>
    );
};

export const FunnelRevisionDetails = React.memo(
    FunnelRevisionDetailsImplementation
);
