import React, { FC, useState } from "react";
import { useApolloClient, useQuery } from "@apollo/client";
import { Button, Form, FormControl } from "react-bootstrap";
import { GET_FILE_UPLOADS, GET_ME } from "../../graphql/queries";
import { FileUploads, MeData } from "../../models/types";
import { AlertModal } from "../modals/AlertModal";
import { useAuth0 } from "@auth0/auth0-react";

interface FileProps {
    productId: number;
}

export const FileUploadComponent: FC<FileProps> = ({ productId }) => {
    const { getAccessTokenSilently } = useAuth0();
    const client = useApolloClient();
    const { data: { me } = {} } = useQuery<MeData>(GET_ME, {
        fetchPolicy: "cache-only"
    });

    const [alertVisible, setAlertVisible] = useState(false);
    const [supportedImages, setSupportedImages] = useState([
        "Supported image formats jpg, jpeg, png!\n",
        "Invalid files : \n"
    ]);
    const [files, updateFiles] = useState<FileList>();

    const checkImage = (files: FileList): Promise<boolean> => {
        const allowed = ["jpg", "jpeg", "png"];
        const images: Promise<boolean>[] = [];
        const invalidImages: string[] = [];
        Array.from(files).forEach(data => {
            images.push(
                new Promise((res, rej) => {
                    const ext = (
                        data.name.includes(".")
                            ? data.name.split(".").pop()
                            : ""
                    ) as string;
                    const image = new Image();
                    image.onload = () => {
                        if (image.height && allowed.includes(ext)) res(true);
                        else {
                            invalidImages.push(data.name);
                            res(false);
                        }
                    };
                    image.onerror = () => {
                        invalidImages.push(data.name);
                        res(false);
                    };
                    image.src = URL.createObjectURL(data);
                })
            );
        });

        return Promise.all(images).then(values => {
            let pass = true;
            values.forEach(value => {
                if (!value) {
                    pass = false;
                }
            });
            supportedImages[2] = invalidImages.join(", ");
            setSupportedImages(supportedImages);
            return pass;
        });
    };

    const handleFiles = async () => {
        try {
            if (!me) return;
            if (files && files.length) {
                const organization = me?.organization?.id;
                const product = productId;
                if (organization && product) {
                    const data = new FormData();
                    Array.from(files).forEach(file => {
                        data.append("files", file);
                    });
                    data.append("product_id", product.toString());
                    const token = await getAccessTokenSilently();
                    if (!token) {
                        return {};
                    }
                    const tokenString = `Bearer ${token}`;
                    const response = await fetch(
                        `${process.env.REACT_APP_URL}/rest/uploadFiles/`,
                        {
                            method: "POST",
                            body: data,
                            mode: "cors",
                            headers: {
                                Authorization: tokenString
                            }
                        }
                    );

                    const res = await response.json();
                    if (res.success) {
                        const fileuploads = client.cache.readQuery<FileUploads>(
                            {
                                query: GET_FILE_UPLOADS,
                                variables: { productId: product }
                            }
                        );

                        if (fileuploads) {
                            const newUploads = res.message.map(
                                (upload: any) => {
                                    return {
                                        id: upload.id,
                                        key: upload.message.key,
                                        name: upload.message.fileName
                                    };
                                }
                            );
                            client.cache.writeQuery<FileUploads>({
                                query: GET_FILE_UPLOADS,
                                variables: { productId: product },
                                data: {
                                    fileUploads: [
                                        ...newUploads,
                                        ...fileuploads.fileUploads
                                    ]
                                }
                            });
                        }
                    }
                }
            }
        } catch (error) {
            console.log("[DEBUG] handleFiles error ", error);
        }
    };

    return (
        <>
            {alertVisible ? (
                <AlertModal
                    visible={() => setAlertVisible(false)}
                    message={supportedImages.join(" ")}
                />
            ) : null}

            <Form.Group>
                <FormControl
                    size="sm"
                    name="images[]"
                    type="file"
                    multiple
                    accept="image/x-png,image/jpeg"
                    onChange={event => {
                        const target = event.target;
                        const uploadFiles = (
                            event.target as EventTarget & HTMLInputElement
                        ).files;
                        if (uploadFiles) {
                            checkImage(uploadFiles).then(pass => {
                                if (pass) updateFiles(uploadFiles);
                                else {
                                    setAlertVisible(true);
                                    target.value = "";
                                }
                            });
                        }
                    }}
                />
            </Form.Group>
            <Button
                className="ms-2 btn-sm"
                variant="primary"
                type="submit"
                onClick={handleFiles}
            >
                Upload
            </Button>
        </>
    );
};
