import React from "react";
import { useQuery } from "@apollo/client";
import { Card, Col, Row } from "react-bootstrap";
import { GET_ORGANIZATIONS } from "../../../graphql/queries";
import { Organization, OrganizationData } from "../../../models/types";
import { UsersPerOrganization } from "../../lists/admin/UsersPerOrganization";
import { InviteTools } from "./InviteTools";

export const UserTools = () => {
    const { data: { organizations } = {} } =
        useQuery<OrganizationData>(GET_ORGANIZATIONS);

    const alternatives = organizations?.map(
        (organization: Organization, index: number) => (
            <Col key={index} className="col-12">
                <UsersPerOrganization
                    organizationName={organization.name}
                    organizationId={organization.id}
                />
            </Col>
        )
    );

    return (
        <Card className="mt-3">
            <Card.Header>
                <h5 className="text-inverse-50">User Tools</h5>
            </Card.Header>
            <Card.Body>
                <Row className="d-flex justify-content-center align-items-end">
                    <Col className="col-12">{alternatives}</Col>
                </Row>
                <Row className="d-flex justify-content-center align-items-end">
                    <Col className="col-12">
                        <UsersPerOrganization
                            key="users-without-org"
                            organizationName="Users without organization"
                            organizationId={undefined}
                        />
                    </Col>
                </Row>
                <InviteTools />
            </Card.Body>
        </Card>
    );
};
