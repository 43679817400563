import React, { FC, useEffect, useRef, useState } from "react";
import { Card, Col, InputGroup, Row } from "react-bootstrap";
import {
    Network,
    PartialRevisionData,
    PartialRevisionVariables
} from "../../models/types";
import { VariationItemProps } from "../../models/common";
import { useApolloClient, useMutation } from "@apollo/client";
import { PARTIAL_UPDATE_REVISION } from "../../graphql/mutations";
import { updateRevisionPartially, validateNetwork } from "../../common/Helpers";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { AlertModal } from "../modals/AlertModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./variationItems.scss";

interface NetworkProps extends VariationItemProps {
    defaultValue: Network;
}

export const NetworkItemClient: FC<NetworkProps> = ({
    defaultValue,
    revision
}) => {
    const client = useApolloClient();
    const [network] = useState(defaultValue);
    const [useCount, updateUseCount] = useState(
        defaultValue.sizeTargets.length
    );
    const inputRef = useRef<any>(null);
    const [partialUpdateRevision] = useMutation<
        PartialRevisionData,
        PartialRevisionVariables
    >(PARTIAL_UPDATE_REVISION);
    const [showAlertModal, setShowAlertModal] = useState(false);
    const toggleAlertModal = () => {
        setShowAlertModal(!showAlertModal);
    };
    const [showWarning, setShowWarning] = useState(false);

    useEffect(() => {
        inputRef.current.indeterminate = !!(
            inputRef &&
            inputRef.current &&
            useCount > 0 &&
            useCount < 3
        );
    }, [useCount, updateUseCount, inputRef]);

    useEffect(() => {
        const buildValidation = validateNetwork(network, revision?.buildData);
        if (buildValidation.valid === showWarning) {
            setShowWarning(!buildValidation.valid);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [network.sizeTargets]);

    useEffect(() => {
        updateUseCount(defaultValue.sizeTargets.length);
    }, [defaultValue.sizeTargets]);

    const updateNetworkIncludes = async (
        include: boolean,
        size = -1,
        updateAll = false
    ) => {
        let sizeTargets = network.sizeTargets;
        if (updateAll) {
            sizeTargets = include ? [2, 3, 5] : [];
        } else {
            if (include) {
                sizeTargets.push(size);
            } else {
                const index = sizeTargets.indexOf(size);
                if (index > -1) {
                    sizeTargets.splice(index, 1);
                }
            }
        }
        const networkData = { ...network, sizeTargets };
        if (revision) {
            // updateRevision({ networks: [networkData] });
            await updateRevisionPartially(
                client,
                revision,
                partialUpdateRevision,
                { networks: [networkData] }
            );
        }
    };

    const updateAll = (checked: boolean) => {
        updateUseCount(checked ? 3 : 0);
        updateNetworkIncludes(checked, -1, true);
    };

    return (
        <Col className="client-card-small">
            <Card className="m-1 ms-2">
                <Card.Header className="inner-card p-2">
                    <Row
                        className="d-flex text-inverse justify-content-center align-items-center g-0"
                        onClick={() => {
                            if (useCount > 0 && useCount < 3) {
                                updateAll(true);
                            } else {
                                updateAll(!(useCount === 3));
                            }
                        }}
                    >
                        <Col className="col-1 px-1">
                            <InputGroup className="custom-input-background">
                                <input
                                    className="mouseHover"
                                    type="checkbox"
                                    title="Include in next Build set"
                                    aria-label="Include in next Build set"
                                    checked={useCount === 3}
                                    ref={inputRef}
                                    onClick={(event: any) => {
                                        event.stopPropagation();
                                    }}
                                    onChange={(event: any) => {
                                        updateAll(event.target.checked);
                                    }}
                                />
                            </InputGroup>
                        </Col>
                        <Col className="col-9 mb-1 ps-3 bread-text">
                            {network.network}
                        </Col>
                        <Col className="col-2">
                            {showWarning && (
                                <FontAwesomeIcon
                                    icon={faTriangleExclamation}
                                    className="text-warning mouseHover"
                                    onClick={event => {
                                        event.stopPropagation();
                                        setShowAlertModal(true);
                                    }}
                                />
                            )}
                        </Col>
                    </Row>
                </Card.Header>
                {showAlertModal && (
                    <AlertModal
                        visible={toggleAlertModal}
                        header={<h5>Network Configuration Warning</h5>}
                        message={
                            <>
                                <p>
                                    Some of the build versions that have been
                                    selected have sizes that are not supported
                                    by this network.
                                </p>
                                <p>
                                    Those builds will not be generated in the
                                    bundle.
                                </p>
                            </>
                        }
                        footer={null}
                        component={null}
                    />
                )}
            </Card>
        </Col>
    );
};
