import React, { FC } from "react";
import { Modal } from "react-bootstrap";
import { AnalyticsEventSchema } from "../../../models/types";
import { AnalyticsEventLibraryItem } from "../../variationItems/analytics/AnalyticsEventLibraryItem";
import { useLocalState } from "../../../graphql/hooks";

interface Props {
    show: boolean;
    analyticsEvents: AnalyticsEventSchema[] | undefined;
    updateSchema: (schema: AnalyticsEventSchema) => void;
    onClose: () => void;
}

export const AnalyticsEventLibraryModal: FC<Props> = ({
    show,
    analyticsEvents,
    updateSchema,
    onClose
}) => {
    const { useDarkMode } = useLocalState();

    const events = analyticsEvents?.map(
        (event: AnalyticsEventSchema, index: number) =>
            event.frozen ? null : (
                <AnalyticsEventLibraryItem
                    event={event}
                    key={event.eventName + index}
                    updateAnalyticsSchema={updateSchema}
                />
            )
    );

    return (
        <Modal
            show={show}
            onHide={onClose}
            backdrop="static"
            keyboard={false}
            className={useDarkMode ? "modal-dark" : ""}
        >
            <Modal.Header closeButton>
                <Modal.Title>Event Library</Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-2">{events}</Modal.Body>
        </Modal>
    );
};
