import React, { FC, useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { PaginationNavigator } from "./PaginationNavigator";
import { updatePaginatorState } from "../../common/Helpers";
import { useApolloClient } from "@apollo/client";
import { usePaginatorState } from "../../graphql/hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAnglesLeft, faAnglesRight } from "@fortawesome/free-solid-svg-icons";
import cx from "classnames";

interface Props {
    list: any;
    maxElementCount: number;
    activeItem: number | undefined;
}

const PaginatorImplementation: FC<Props> = ({
    list,
    maxElementCount,
    activeItem
}) => {
    const client = useApolloClient();
    const paginationRef = useRef<HTMLDivElement>(null);
    const { currentPage } = usePaginatorState();
    const [maxPages, updateMaxPages] = useState(1);
    const [visibleElements, updateVisibleElements] = useState([]);
    const [canGoBack, updateCanGoBack] = useState(false);
    const [canGoNext, updateCanGoNext] = useState(
        list.length > maxElementCount
    );
    const [startElement, updateStartElement] = useState(activeItem);

    useEffect(() => {
        return () => {
            updatePaginatorState(client, 1);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (list && list.length > 0) {
            updateMaxPages(
                Math.max(1, Math.ceil(list.length / maxElementCount))
            );
            updatePage(currentPage);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [list]);

    useEffect(() => {
        if (startElement) {
            const target = Math.floor(startElement / maxElementCount) + 1;
            updatePage(target);
            updateStartElement(undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startElement]);

    const updatePage = (targetPage: number) => {
        updateVisibleElements(
            list.slice(
                Math.max(0, targetPage * maxElementCount - maxElementCount),
                Math.min(list.length, targetPage * maxElementCount)
            )
        );
        updatePaginatorState(client, targetPage);
        updateCanGoBack(targetPage > 1);
        updateCanGoNext(list.length > targetPage * maxElementCount);
    };

    const scrollToPagination = () => {
        setTimeout(() => {
            paginationRef.current?.scrollIntoView({ behavior: "smooth" });
        }, 0);
    };

    const back = () => {
        if (currentPage - 1 <= 0) {
            return;
        }
        const nextPage = currentPage - 1;
        updatePage(nextPage);
        scrollToPagination();
    };

    const next = () => {
        if (currentPage >= maxPages) {
            return;
        }
        const nextPage = currentPage + 1;
        updatePage(nextPage);
        scrollToPagination();
    };

    return (
        <div>
            {visibleElements}
            {maxPages > 1 ? (
                <div
                    ref={paginationRef}
                    className="d-flex justify-content-center mt-3 mb-2"
                >
                    <Button
                        className={cx({ disabled: !canGoBack })}
                        onClick={() => back()}
                    >
                        <FontAwesomeIcon icon={faAnglesLeft} />
                    </Button>
                    <PaginationNavigator
                        currentPage={currentPage}
                        maxPages={maxPages}
                        toPage={updatePage}
                    />
                    <Button
                        className={cx({ disabled: !canGoNext })}
                        onClick={() => next()}
                    >
                        <FontAwesomeIcon icon={faAnglesRight} />
                    </Button>
                </div>
            ) : null}
        </div>
    );
};

export const Paginator = React.memo(PaginatorImplementation);
