import React, { FC } from "react";
import { Card } from "react-bootstrap";
import { AnalyticsEventSchema, AnalyticsSchema } from "../../../models/types";
import { faClipboard } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props {
    schema?: AnalyticsSchema;
}

export const AnalyticsSchemaSnippets: FC<Props> = ({ schema }) => {
    const customEventSnippet = (
        eventName: string,
        customData?: any[]
    ): string => {
        if (customData && customData.length > 0) {
            const customs = [];
            for (let i = 0; i < customData.length; i++) {
                customs.push(
                    `${i === 0 ? "" : "\n\t"}${customData[i].key}: ${
                        customData[i].key
                    }Variable`
                );
            }

            return `GetAnalyticsClient().sendEvents({
    eventName: "${eventName}",
    eventData: {
        ${customs}
    }
});
`;
        }
        return `GetAnalyticsClient().sendEvents({
    eventName: "${eventName}"
});
        `;
    };

    const getComment = (comment?: string): string => {
        if (!comment) {
            return "";
        }
        const splitComment = comment
            .split(".")
            .filter(element => element !== "");
        const commentLines = splitComment
            .map(
                (line, index) =>
                    `// ${line.trim()} ${
                        index === splitComment.length - 1 ? "" : "\n"
                    }`
            )
            .join("");
        return commentLines + "\n";
    };

    const generateSnippet = (event: AnalyticsEventSchema): string => {
        switch (event.eventName) {
            case "adStart":
                return `${getComment(
                    event.comment
                )}GetAnalyticsClient().initialize(isPortrait);`;
            case "ctaBtnPressed":
                return `${getComment(
                    event.comment
                )}GetAnalyticsClient().sendCtaButtonPressedEvent(normalizedTouchPosition, isEndScreen, isPortrait);`;
            default:
                return `${getComment(event.comment)}${customEventSnippet(
                    event.eventName,
                    event.eventData
                )}`;
        }
    };

    const copyToClipboard = async (snippet: string) => {
        await navigator.clipboard.writeText(snippet);
    };
    const snippetData = schema?.events.filter(
        element => element.eventName !== ""
    );
    const snippets = snippetData?.map(
        (event: AnalyticsEventSchema, index: number) => (
            <Card key={index} className="text-inverse mb-3 inner-card">
                <Card.Header>
                    {event.eventName}
                    <FontAwesomeIcon
                        title="Copy to clipboard"
                        icon={faClipboard}
                        className="text-success ms-2 mouseHover"
                        onClick={(e: any) => {
                            e.preventDefault();
                            e.stopPropagation();
                            copyToClipboard(generateSnippet(event));
                        }}
                    />
                </Card.Header>
                <Card.Body>
                    <pre>
                        <code className="text-inverse">
                            {generateSnippet(event)}
                        </code>
                    </pre>
                </Card.Body>
            </Card>
        )
    );

    return (
        <Card className="w-100 mt-3 mb-1">
            <Card.Header className="text-inverse-50">
                <h5>Snippets</h5>
            </Card.Header>
            <Card.Body>
                {schema ? snippets : <p>"No snippets to generate"</p>}
            </Card.Body>
        </Card>
    );
};
