import React, { FC, useEffect, useState } from "react";
import { Button, Container } from "react-bootstrap";
import { useLazyQuery, useMutation, useSubscription } from "@apollo/client";
import {
    CreateProjectData,
    Project,
    ProjectsData,
    ProjectVariables,
    SortingMode
} from "../../models/types";
import {
    GET_EMPTY_PROJECTS,
    GET_PRODUCT_META_DATA,
    GET_PROJECTS_BY_PRODUCT
} from "../../graphql/queries";
import { REMOVE_PROJECT } from "../../graphql/mutations";
import { ProjectCardItem } from "../simple/ProjectCardItem";
import {
    PROJECT_CHANGED_SUBSCRIPTION,
    PROJECT_METADATA_CHANGED_SUBSCRIPTION
} from "../../graphql/subscriptions";
import { useLocalState, useSortingState } from "../../graphql/hooks";
import { sortItems } from "../../common/Helpers";
import "./views.scss";
import { AlertModal } from "../modals/AlertModal";

interface Props {
    productId: number;
    show: boolean;
    updateLoaded: (loaded: boolean) => void;
    // sortingSettings: SortingData;
}

const ProjectsImplementation: FC<Props> = ({
    productId,
    show,
    updateLoaded
    // sortingSettings
}) => {
    const sortingState = useSortingState();
    const [sortedProjects, updateSortedProjects] = useState<Project[]>([]);
    const [showRemoveAlert, updateShowRemoveAlert] = useState(false);
    const [toBeRemoved, updateToBeRemoved] = useState<number | undefined>(
        undefined
    );
    const state = useLocalState();
    const [
        fetchProjects,
        // @ts-ignore
        { loading, data: { projects } = {}, error }
    ] = useLazyQuery<ProjectsData, ProjectVariables>(GET_PROJECTS_BY_PRODUCT, {
        variables: {
            productId: productId,
            searchTerm: state.searchTerm
        }
    });

    const [removeProject] = useMutation<CreateProjectData, ProjectVariables>(
        REMOVE_PROJECT
    );

    useEffect(() => {
        if (show && !projects) {
            fetchProjects();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show]);

    useEffect(() => {
        if (!loading && projects) {
            updateLoaded(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading, projects]);

    useEffect(() => {
        if (projects) {
            const sortingMode =
                sortingState.sortingPriorities[1] || SortingMode.None;
            if (sortingMode === SortingMode.None) {
                updateSortedProjects(projects);
                return;
            }
            const sorted = [...projects];
            sorted.sort((a, b) => sortItems(a, b, sortingMode));
            updateSortedProjects(sorted);
        }
    }, [sortingState.sortingPriorities, projects, productId]);

    useSubscription(PROJECT_CHANGED_SUBSCRIPTION, {
        variables: { productId: productId },
        onData: ({
            data: { data: { projectChangedNotification } = {} },
            client
        }) => {
            const currentProjectData = client.cache.readQuery<
                ProjectsData,
                ProjectVariables
            >({
                variables: { productId: productId },
                query: GET_PROJECTS_BY_PRODUCT
            });

            const currentProjects = currentProjectData?.projects;

            if (currentProjects && projectChangedNotification) {
                if (projectChangedNotification.action !== 0) {
                    const newProjects = currentProjects.filter(
                        project =>
                            Number(project.id) !==
                            Number(projectChangedNotification.project.id)
                    );

                    if (projectChangedNotification.action === 1) {
                        client.cache.writeQuery<ProjectsData, ProjectVariables>(
                            {
                                query: GET_PROJECTS_BY_PRODUCT,
                                data: {
                                    projects: newProjects
                                        ? [
                                              ...newProjects,
                                              projectChangedNotification.project
                                          ]
                                        : [projectChangedNotification.project]
                                },
                                variables: { productId: productId }
                            }
                        );
                    } else if (projectChangedNotification.action === 2) {
                        client.cache.writeQuery<ProjectsData, ProjectVariables>(
                            {
                                query: GET_PROJECTS_BY_PRODUCT,
                                data: { projects: newProjects },
                                variables: { productId: productId }
                            }
                        );
                    }
                } else {
                    client.cache.writeQuery<ProjectsData, ProjectVariables>({
                        query: GET_PROJECTS_BY_PRODUCT,
                        data: {
                            projects: currentProjects
                                ? [
                                      ...currentProjects,
                                      projectChangedNotification.project
                                  ]
                                : [projectChangedNotification.project]
                        },
                        variables: { productId: productId }
                    });
                }
            }
        }
    });

    useSubscription(PROJECT_METADATA_CHANGED_SUBSCRIPTION, {
        variables: { productId: productId },
        onData: ({
            data: { data: { projectMetaChangedNotification } = {} }
        }) => {
            console.log(
                "[DEBUG] projectMetaChangedNotification ",
                projectMetaChangedNotification
            );
        }
    });

    const removeProjectById = async (id: number) => {
        try {
            await removeProject({
                variables: { projectId: id },
                update: (cache, { data }) => {
                    const currentProjectData = cache.readQuery<ProjectsData>({
                        query: GET_PROJECTS_BY_PRODUCT,
                        variables: { productId: productId }
                    });
                    const currentProjects = currentProjectData?.projects;
                    if (currentProjects && data) {
                        const newProjects = currentProjects.filter(
                            project => project.id !== id
                        );
                        cache.writeQuery<ProjectsData>({
                            query: GET_PROJECTS_BY_PRODUCT,
                            variables: { productId: productId },
                            data: { projects: newProjects }
                        });
                    }
                    const currentEmptyData = cache.readQuery<{
                        emptyProjects: Project[];
                    }>({
                        query: GET_EMPTY_PROJECTS
                    });
                    const currentEmptyProjects =
                        currentEmptyData?.emptyProjects;
                    if (currentEmptyProjects && data) {
                        const newProjects = currentEmptyProjects.filter(
                            project => project.id !== id
                        );
                        cache.writeQuery<{
                            emptyProjects: Project[];
                        }>({
                            query: GET_EMPTY_PROJECTS,
                            data: { emptyProjects: newProjects }
                        });
                    }
                },
                refetchQueries: [
                    {
                        query: GET_PRODUCT_META_DATA,
                        variables: { productId: productId }
                    }
                ]
            });
            if (toBeRemoved) {
                updateToBeRemoved(undefined);
            }
        } catch (error) {
            console.log("[DEBUG] removeProjectById error ", error);
        }
    };

    if (error) {
        return (
            <Container className="fullSize widthProducts p-2 pt-5">
                <p>Error: {error.message}</p>
            </Container>
        );
    }

    const items = sortedProjects?.map((project: Project) => {
        return (
            <ProjectCardItem
                key={project.id}
                productId={productId}
                // sortingSettings={sortingSettings}
                item={project}
                onRemove={() => {
                    updateToBeRemoved(project.id);
                    updateShowRemoveAlert(true);
                }}
            />
        );
    });

    if (!items || items.length === 0) {
        return (
            <div className="text-inverse-50 bread-text text-start m-2 ps-4">
                No projects currently in development.
            </div>
        );
    }

    return (
        <>
            <Container className="fullSizeChild m-0 p-0">{items}</Container>
            {showRemoveAlert ? (
                <AlertModal
                    header={<h5>Remove Project</h5>}
                    component="Removing the project also removes its revisions!"
                    visible={() => updateShowRemoveAlert(false)}
                    footer={
                        <Button
                            variant="danger"
                            className="mt-2"
                            onClick={() => {
                                if (toBeRemoved) {
                                    removeProjectById(toBeRemoved);
                                    updateShowRemoveAlert(false);
                                }
                            }}
                        >
                            Continue to Remove
                        </Button>
                    }
                />
            ) : null}
        </>
    );
};

export const Projects = React.memo(ProjectsImplementation);
