import React, { FC, ReactElement, useState } from "react";
import { Button, Dropdown, DropdownButton } from "react-bootstrap";
import { AlertModal } from "../modals/AlertModal";

interface Props {
    id: string;
    header: string | ReactElement;
    className: string;
    localizations: string[] | undefined;
    callback: (localization?: string) => void;
    showInfoModal?: boolean;
}

export const LanguageTestDropdown: FC<Props> = ({
    id,
    header,
    className,
    localizations,
    callback,
    showInfoModal = false
}) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const handleToggle = (isOpen: boolean, meta: any) => {
        meta.originalEvent.preventDefault();
        if (showInfoModal && !isDropdownOpen) {
            setShowModal(true);
        } else {
            setIsDropdownOpen(!isDropdownOpen);
        }
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setIsDropdownOpen(true);
    };

    const handleButtonClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        if (showInfoModal) {
            setShowModal(true);
        } else {
            callback();
        }
    };

    if (!localizations) {
        return (
            <>
                {showModal && (
                    <AlertModal
                        visible={() => setShowModal(false)}
                        header="Creating Public Link"
                        message={
                            <>
                                <p>
                                    You are about to create a{" "}
                                    <b>permanent public link</b> to the
                                    playable.
                                </p>
                                <p>
                                    Choosing this option will trigger the
                                    creation of a link, which will be saved to
                                    the clipboard. Paste link into a secure text
                                    file for storage or in the browser for
                                    immeadiate use.
                                </p>
                                <p>
                                    Consider using safer share methods, like a
                                    private link or a zip file.
                                </p>
                            </>
                        }
                        footer={
                            <Button onClick={handleCloseModal}>Close</Button>
                        }
                    />
                )}
                <Button className={className} onClick={handleButtonClick}>
                    {header}
                </Button>
            </>
        );
    }

    const generateAlternatives = localizations?.map(
        (localization: string, index: number) => (
            <Dropdown.Item
                key={index}
                eventKey={localization}
                onClick={() => {
                    setIsDropdownOpen(false);
                    callback(localization);
                }}
            >
                {localization}
            </Dropdown.Item>
        )
    );

    return (
        <>
            {showModal && (
                <AlertModal
                    visible={() => setShowModal(false)}
                    header={<h5>Creating Public Link</h5>}
                    message={
                        <>
                            <p>
                                You are about to create a{" "}
                                <b>permanent public link</b> to the playable.
                            </p>
                            <p>
                                Choosing a language localization setting will
                                trigger the creation of a link, which will be
                                saved to the clipboard. Paste link into a secure
                                text file for storage or in the browser for
                                immeadiate use.
                            </p>
                            <p>
                                Consider using safer share methods, like a
                                private link or a zip file.
                            </p>
                        </>
                    }
                    footer={
                        <Button onClick={handleCloseModal}>Continue</Button>
                    }
                />
            )}
            <DropdownButton
                id={id}
                size="sm"
                title={header}
                variant="primary"
                show={isDropdownOpen}
                onToggle={handleToggle}
                className={className}
                onClick={e => {
                    e.preventDefault();
                }}
            >
                <Dropdown.Item
                    key={-1}
                    eventKey="Default"
                    onClick={() => {
                        setIsDropdownOpen(false);
                        callback("Default");
                    }}
                >
                    Default
                </Dropdown.Item>
                {generateAlternatives}
            </DropdownButton>
        </>
    );
};
