import React, { FC, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Button, Card, Container, InputGroup, Modal } from "react-bootstrap";
import { DragAndDrop } from "../../editable/DragAndDrop";
import { AditorOptimizeTaskCard } from "../../cards/aditor/AditorOptimizeTaskCard";
import { useLocalState } from "../../../graphql/hooks";

interface Props {
    revisionId: number;
    onClose: () => void;
}

export const AditorOptimizingModal: FC<Props> = ({ revisionId, onClose }) => {
    const { getAccessTokenSilently } = useAuth0();
    // TODO: show the info at some point
    const [useTinyPng, updateUseTinyPng] = useState(false);
    const [info, updateInfo] = useState("");
    const [files, updateFiles] = useState<File[]>([]);
    const [fileNames, updateFileNames] = useState<string[]>([]);
    const [taskId, updateTaskId] = useState<string | undefined>(undefined);
    const { useDarkMode } = useLocalState();

    console.log("[DEBUG] info ", info);
    const addFiles = (newFiles: File[]) => {
        if (files.length > 0) {
            console.log("[DEBUG] Currently only one is allowed");
            return;
        }
        const currentFiles = files;
        currentFiles.push(...newFiles);
        updateFiles(currentFiles);
        const filenames = currentFiles.map(file => file.name);
        updateFileNames(filenames);
    };

    const removeFile = (name: string) => {
        const currentFiles = files.filter(file => file.name !== name);
        updateFiles(currentFiles);
        const filenames = currentFiles.map(file => file.name);
        updateFileNames(filenames);
    };

    const submit = async () => {
        try {
            const data = new FormData();
            Array.from(files).forEach(file => {
                data.append("files", file);
            });
            data.append("revision_id", revisionId.toString());
            data.append("use_tiny_png", useTinyPng ? "true" : "false");
            const token = await getAccessTokenSilently();
            if (!token) {
                return {};
            }
            const tokenString = `Bearer ${token}`;
            const responseData = await fetch(
                `${process.env.REACT_APP_URL}/rest/aditor/optimizeAssets/`,
                {
                    method: "POST",
                    body: data,
                    mode: "cors",
                    headers: {
                        Authorization: tokenString
                    }
                }
            );

            const response: {
                success: boolean;
                taskIds: string[];
                error?: string;
            } = await responseData.json();
            if (response.success) {
                console.log("[DEBUG] response ", response);
                // TODO: only support for one at a time for now, be sends a list of tasks regardless
                updateTaskId(response.taskIds[0]);
            } else {
                console.log("[DEBUG] error: ", response.error);
                updateInfo(response.error || "Unknown error");
                setTimeout(() => {
                    updateInfo("");
                }, 4000);
            }
        } catch (error) {
            console.log("[DEBUG] submit error ", error);
        }
    };

    return (
        <Modal
            show={true}
            onHide={onClose}
            backdrop="static"
            size="lg"
            onClick={(e: React.MouseEvent<HTMLElement>) => e.stopPropagation()}
            className={useDarkMode ? "modal-dark" : ""}
        >
            <Modal.Header closeButton>
                <h3>Aditor Optimizer (Tester)</h3>
            </Modal.Header>
            <Modal.Body className="p-2">
                <Container>
                    <InputGroup className="d-flex justify-content-end">
                        <InputGroup.Text>Use TinyPng</InputGroup.Text>
                        <InputGroup.Checkbox
                            title="Use TinyPNG rather than internal tools"
                            aria-label="Use TinyPNG rather than internal tools"
                            checked={useTinyPng}
                            onChange={() => {
                                updateUseTinyPng(!useTinyPng);
                            }}
                        />
                    </InputGroup>
                    <Card
                        className="subtle-border mt-3"
                        style={{ minHeight: "100px", padding: 10 }}
                    >
                        {taskId ? (
                            <AditorOptimizeTaskCard taskId={taskId} />
                        ) : (
                            <DragAndDrop
                                instructions="Drag file here to be optimized"
                                fileNames={fileNames}
                                addFiles={addFiles}
                                removeFile={removeFile}
                            />
                        )}
                    </Card>
                </Container>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-center">
                <Button
                    variant="secondary"
                    onClick={(e: React.MouseEvent<HTMLElement>) => {
                        e.stopPropagation();
                        onClose();
                    }}
                >
                    Close
                </Button>
                {taskId ? (
                    <Button
                        variant="info"
                        onClick={(e: React.MouseEvent<HTMLElement>) => {
                            e.stopPropagation();
                            updateTaskId(undefined);
                            updateFiles([]);
                            updateFileNames([]);
                        }}
                    >
                        Clear
                    </Button>
                ) : (
                    <Button
                        variant="primary"
                        onClick={(e: React.MouseEvent<HTMLElement>) => {
                            e.stopPropagation();
                            submit();
                        }}
                        disabled={files.length === 0}
                    >
                        Optimize
                    </Button>
                )}
            </Modal.Footer>
        </Modal>
    );
};
