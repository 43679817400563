import React, { FC } from "react";
import { Dropdown, DropdownButton, InputGroup } from "react-bootstrap";
import { OrganiazationType, Organization } from "../../../models/types";

interface Props {
    organization: Organization;
    updateType: (type: number) => void;
}

export const SelectableOrganizationType: FC<Props> = ({
    organization,
    updateType
}) => {
    const getTypeFromName = (name: string): number | undefined => {
        try {
            return OrganiazationType[
                name as keyof typeof OrganiazationType
            ] as number;
        } catch (error) {
            console.log("[DEBUG] failed to get type from enum ", error);
        }
    };

    const alternatives = Object.keys(OrganiazationType)
        .filter(x => !(parseInt(x) >= 0))
        .map((key: string, index: number) => (
            <Dropdown.Item key={index} eventKey={key}>
                {key}
            </Dropdown.Item>
        ));

    return (
        <div className="my-3">
            <InputGroup className="mb-2">
                <InputGroup.Text>Organization Type</InputGroup.Text>
                <DropdownButton
                    id="selectable-base-modules-dropdown"
                    title={OrganiazationType[organization.organizationType]}
                    variant="analytics-alt"
                    className="text-center"
                    onSelect={(selectedItem: string | null | undefined) => {
                        if (selectedItem) {
                            const newType = getTypeFromName(selectedItem);
                            if (newType !== undefined) {
                                updateType(newType);
                            }
                        }
                    }}
                >
                    {alternatives}
                </DropdownButton>
            </InputGroup>
        </div>
    );
};
