import React, { FC } from "react";
import { Dropdown, DropdownButton, InputGroup } from "react-bootstrap";
import {
    BaseModule,
    BaseProject,
    CreateProjectConfig,
    Submodule
} from "../../models/types";

interface Props {
    project: CreateProjectConfig;
    projects: BaseProject[];
    setProject: (project: CreateProjectConfig) => void;
    modules: BaseModule[];
}
export const SelectableBaseProject: FC<Props> = ({
    project,
    projects,
    setProject,
    modules
}) => {
    const alternatives = projects?.map(
        (baseProject: BaseProject, index: number) => (
            <Dropdown.Item key={index} eventKey={baseProject.name}>
                {baseProject.name}
            </Dropdown.Item>
        )
    );

    const handleSelection = (selectedItem: string) => {
        const getSubmodule = (path: string): Submodule | undefined => {
            for (let i = 0; i < modules.length; i++) {
                if (modules[i].path === path) {
                    return modules[i];
                }
            }
            return undefined;
        };
        const getProject = (projectName: string) => {
            for (let i = 0; i < projects.length; i++) {
                if (projects[i].name === projectName) {
                    return projects[i];
                }
            }
            return undefined;
        };
        const newBaseProject = getProject(selectedItem);
        if (!newBaseProject) {
            console.log("[DEBUG] Failed to get project data");
            return;
        }
        const newProject = {
            ...project,
            baseProject: newBaseProject,
            keepBaseSets:
                selectedItem === "Build Testbed" ? false : project.keepBaseSets
        };
        newProject.gitModules = [];
        for (let i = 0; i < newBaseProject.requiredSubmodules.length; i++) {
            const subModule = getSubmodule(
                newBaseProject.requiredSubmodules[i].path
            );
            if (subModule) {
                subModule.branch = newBaseProject.requiredSubmodules[i].branch;
                newProject.gitModules.push(subModule);
            }
        }

        setProject(newProject);
    };

    return (
        <InputGroup className="mb-3">
            <InputGroup.Text>Base Project</InputGroup.Text>
            <DropdownButton
                id="selectable-base-project-dropdown"
                title={project.baseProject?.name || "Select Base Project"}
                variant="success"
                className="text-center"
                onSelect={(selectedItem: string | null | undefined) => {
                    if (selectedItem) {
                        handleSelection(selectedItem);
                    }
                }}
            >
                {alternatives}
            </DropdownButton>
        </InputGroup>
    );
};
