import React, { FC } from "react";
import { Col, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { AnalyticsManager } from "../panels/Analytics/AnalyticsManager";
import { updateSearchTermInCache } from "../../common/Helpers";
import { useApolloClient } from "@apollo/client";
import { useLocalAnalyticsState } from "../../graphql/hooks";
interface Props {
    useDarkMode: boolean;
    setSearchValue: (value: string) => void;
    searchValue: string;
}

export const ActionPanel: FC<Props> = ({ setSearchValue, searchValue }) => {
    const client = useApolloClient();
    const { analyticsEnabled } = useLocalAnalyticsState();
    return (
        <>
            <Col className="d-flex ms-2 justify-content-start">
                <Form
                    className="w-100"
                    onSubmit={event => {
                        event.preventDefault();
                    }}
                >
                    <Form.Text
                        className="m-0 mb-2"
                        onClick={(event: any) => {
                            event.preventDefault();
                            event.stopPropagation();
                        }}
                    >
                        <OverlayTrigger
                            overlay={
                                <Tooltip id="tooltip-product-search">
                                    Search product, project or revision by title
                                </Tooltip>
                            }
                        >
                            <Form.Control
                                onChange={(event: any) => {
                                    setSearchValue(event.target.value);
                                    updateSearchTermInCache(
                                        client,
                                        event.target.value
                                    );
                                }}
                                type="text"
                                id="searcFormInput"
                                className="search-products-form"
                                value={searchValue}
                                placeholder="Search"
                            />
                        </OverlayTrigger>
                    </Form.Text>
                </Form>
            </Col>
            {analyticsEnabled && (
                <Col
                    xs={6}
                    sm={6}
                    md={6}
                    lg={7}
                    xl={8}
                    className="d-flex justify-content-start justify-content-lg-end"
                >
                    <AnalyticsManager />
                </Col>
            )}
        </>
    );
};
