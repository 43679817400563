import React, { FC, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faAngleDoubleDown,
    faAngleDoubleUp,
    faChevronDown,
    faChevronUp,
    faCircleInfo,
    faPlus
} from "@fortawesome/free-solid-svg-icons";
import {
    VariationCollapseState,
    VariationViewType
} from "../../../models/common";
import { Button, Card, Collapse, Container } from "react-bootstrap";
import { useLocalState } from "../../../graphql/hooks";
import { Variations } from "./Variations";
import { Revision } from "../../../models/types";
import {
    getVariationCollapseState,
    saveVariationCollapseState
} from "../../../common/Helpers";
import cx from "classnames";

interface Props {
    revision: Revision;
    openNewItemModal: (type: VariationViewType) => void;
}

const VariationsManagerImplementation: FC<Props> = ({
    revision,
    openNewItemModal
}) => {
    const state = useLocalState();
    const [showHelp, setShowHelp] = useState(false);
    const [showVariations, setShowVariations] = useState(
        getVariationCollapseState(VariationCollapseState.Variations)
    );
    const [showVariationsDetails, setShowVariationsDetails] = useState(
        getVariationCollapseState(VariationCollapseState.VariationDetails)
    );

    const updateShowVariations = (newState: boolean) => {
        saveVariationCollapseState(VariationCollapseState.Variations, newState);
        setShowVariations(newState);
    };

    const updateShowVariationDetails = (newState: boolean) => {
        saveVariationCollapseState(
            VariationCollapseState.VariationDetails,
            newState
        );
        setShowVariationsDetails(newState);
    };
    const hasVariations =
        revision.buildData &&
        Array.isArray(revision.buildData.variations) &&
        revision.buildData.variations.length > 0;

    const toggleShowHelp = () => setShowHelp(!showHelp);

    return (
        <>
            <Container
                className={cx("mb-4 p-0", {
                    editPanelClientView: !state.developerMode
                })}
            >
                <Card>
                    <Card.Header className="d-flex align-items-center">
                        <h5 className="text-inverse-50 mb-1 me-2">
                            Variations
                        </h5>
                        {hasVariations && (
                            <div
                                onClick={() => {
                                    updateShowVariations(!showVariations);
                                }}
                                data-toggle="tooltip"
                                title="Toggle Variations visibility"
                                className="ms-1 mb-1 text-inverse-50 mouseHover"
                            >
                                <FontAwesomeIcon
                                    icon={
                                        showVariations
                                            ? faChevronUp
                                            : faChevronDown
                                    }
                                />
                            </div>
                        )}
                        {state.developerMode ? (
                            <div className="float-end ms-auto">
                                <Button
                                    onClick={() =>
                                        openNewItemModal(
                                            VariationViewType.Variations
                                        )
                                    }
                                    className="btn-sm me-2"
                                    data-toggle="tooltip"
                                    title="Add new Variation"
                                >
                                    <FontAwesomeIcon icon={faPlus} size="lg" />
                                </Button>

                                <Button
                                    onClick={() => {
                                        updateShowVariationDetails(
                                            !showVariationsDetails
                                        );
                                    }}
                                    data-toggle="tooltip"
                                    title={
                                        showVariationsDetails
                                            ? "Hide all Variations details"
                                            : "Show all Variations details"
                                    }
                                    className="btn-sm"
                                    disabled={!hasVariations || !showVariations}
                                >
                                    <FontAwesomeIcon
                                        size="lg"
                                        icon={
                                            showVariationsDetails
                                                ? faAngleDoubleUp
                                                : faAngleDoubleDown
                                        }
                                    />
                                </Button>
                            </div>
                        ) : (
                            <div className="float-end ms-auto">
                                <FontAwesomeIcon
                                    icon={faCircleInfo}
                                    onClick={toggleShowHelp}
                                    className={`mouseHover ${
                                        showHelp
                                            ? "text-inverse-50"
                                            : "text-inverse-25"
                                    }`}
                                    title={showHelp ? "Hide Help" : "Show Help"}
                                />
                            </div>
                        )}
                    </Card.Header>
                    {hasVariations && (
                        <Collapse in={showVariations}>
                            <div>
                                <Card.Body className="p-3">
                                    <Variations
                                        revision={revision}
                                        developerMode={state.developerMode}
                                        shouldBeVisible={showVariationsDetails}
                                    />
                                </Card.Body>
                            </div>
                        </Collapse>
                    )}
                    {!state.developerMode ? (
                        <Collapse in={showHelp}>
                            <div>
                                <Card.Footer className="bread-text text-inverse-50">
                                    <p>
                                        <strong>Variations</strong> are
                                        alternative versions generated from base
                                        versions. Usually, selecting one
                                        variation will create one new build per
                                        selected base version, with an
                                        alternative or additional feature
                                        compared to the base.
                                    </p>{" "}
                                    <p>
                                        However, it is possible that one
                                        variation item includes several
                                        variations in itself, which will result
                                        in a comparative amount of new builds.
                                        See the description of any given
                                        variation for more details about what
                                        that specific variation includes, and
                                        how it will affect the number of new
                                        builds generated.
                                    </p>
                                </Card.Footer>
                            </div>
                        </Collapse>
                    ) : null}
                </Card>
            </Container>
        </>
    );
};

export const VariationsManager = React.memo(VariationsManagerImplementation);
