import React from "react";
import { Card } from "react-bootstrap";

export const QueryGuide = () => {
    return (
        <Card className="mx-0 mx-lg-5 mt-3 mb-1">
            <Card.Header>
                <h5 className="text-inverse-50">Guide</h5>
            </Card.Header>
            <Card.Body>
                <Card className="inner-card">
                    <Card.Header className="text-inverse-50">
                        <h5>Basics</h5>
                    </Card.Header>
                    <Card.Body className="text-inverse bread-text">
                        <p>
                            In Funnel view, events from the schema of the
                            selected items are used to query the analytics data
                            collected from the ads. Funnels and other charts are
                            drawn from the data for visual representation.{" "}
                        </p>
                        <p>
                            Items selected on the previous pages can be viewed
                            in more detail; which builds and schemas they
                            contain, and which of them have enough data to build
                            charts from. This way irrelevant builds can be
                            excluded from the analysis.
                        </p>
                    </Card.Body>
                </Card>
                <Card className="mt-2 inner-card">
                    <Card.Header className="text-inverse-50">
                        <h5>Query Selection</h5>
                    </Card.Header>
                    <Card.Body className="text-inverse bread-text">
                        <p>
                            Items selected with toggle button on the previous
                            pages are already added in the query (purple color
                            button) AND in comparison mode (green scales-icon
                            button).
                        </p>
                        <p>
                            Toggle collapse to show lower level items up to the
                            network builds.
                        </p>
                        <p>
                            Daily and user queried analytics numbers related to
                            products, projects, revisions, builds and network
                            builds are shown on the row in question if
                            available.
                        </p>
                        <p>
                            Green scales-icon means the item and all its
                            children (unless manually excluded) are selected for
                            comparison. (The funnel selection toggle has to be
                            also turned on to actually retrieve data for the
                            selection.)
                        </p>
                        <p>
                            Check-mark indicates manual selection state even if
                            higher level item is excluded from selection AND
                            allows to select the item for comparison.
                        </p>
                        <p>
                            Purple color generally means that the item's data is
                            selected for query.
                        </p>
                        <p>
                            HOWEVER, if an item is set to <i>comparison mode</i>
                            , only the lower levels of that item and any other
                            items set to <i>comparison mode</i> are queried. (A
                            warning about non-included data should be shown.)
                        </p>
                        <p>
                            Notice that it is possible to de-select all actual
                            data-containing lower level items manually. Buttons
                            can be used to automatically select and de-select
                            all their lower level items that are not in manual
                            selection mode (indicated by check or x -marks).
                        </p>

                        <p>
                            Go to Schema -link and returning to funnel view via
                            Back -button does not reset the funnel selection.{" "}
                        </p>
                    </Card.Body>
                </Card>
                <Card className="mt-2 inner-card">
                    <Card.Header className="text-inverse-50">
                        <h5>Funnel</h5>
                    </Card.Header>
                    <Card.Body className="text-inverse bread-text">
                        <p>
                            Funnel can be built here based on previously defined
                            (build specific) analytics schemas and the events
                            included in them.
                        </p>
                        <p>
                            Add events to funnel from the provided dropdown
                            lists, and add more empty items via "Add event...".
                        </p>
                        <p>
                            Funnel should contain at least two events, so
                            removal becomes available only after adding a third
                            item. Event items can be rearranged by dragging.{" "}
                        </p>
                        <p>
                            If query selection contains items with conflicting
                            schemas, only the events shared between them become
                            available in the dropdown.{" "}
                        </p>
                        <p>
                            In schema conflict situations, the adStart and
                            ctaBtnPressed -events are often only available
                            events since they are both required in a schema.
                        </p>
                        <p>
                            Add additional "Event Data" via plus button to
                            further define the event. If event data contains a
                            comment, it is shown here in the question mark
                            icon's tooltip. (Comments can be used to describe
                            the type of variables for keys and/or values or when
                            event data should be used.)
                        </p>
                        <p>
                            An event data item with key/value -pair "portrait"
                            and "true" defines the event so that the query
                            fetches adStart data accumulated in portrait mode
                            only.
                        </p>
                        <p>
                            Check "Exclude" to not show the event in funnel
                            charts as a step.
                        </p>
                    </Card.Body>
                </Card>
                <Card className="mt-2 inner-card">
                    <Card.Header className="text-inverse-50">
                        <h5>Query Details</h5>
                    </Card.Header>
                    <Card.Body className="text-inverse bread-text">
                        {" "}
                        <p>
                            Select a time range from which the data is
                            retrieved; use calendar tool or quick selection from
                            "Time Range" -dropdown list.
                        </p>
                        <p>
                            Check "Include day funnels" to produce day by day
                            funnels (each step in their own line chart) for
                            selected events.
                        </p>
                        <p>
                            "Query Time Range" indicates the selected time range
                            to be used for query.
                        </p>
                    </Card.Body>
                </Card>
                <Card className="mt-2 inner-card">
                    <Card.Header className="text-inverse-50">
                        <h5>Charts</h5>
                    </Card.Header>
                    <Card.Body className="text-inverse bread-text">
                        <p>
                            <b>Funnel</b> is comprised of the selected events in
                            procession, where number of users getting through
                            each step to next one is visualized with bar and
                            line charts.
                        </p>
                        <p>
                            <b>Time medians</b> present the median amount of
                            time users spend between the steps. Cumulative time
                            -line indicates time used so far in total.
                        </p>
                        <p>
                            <b>Time distribution</b> charts compare amount of
                            users to time (s) used between the steps in the
                            funnel.
                        </p>
                        <p>
                            To prevent idle users distorting the charts, uncheck
                            "Show +60" to not include users that spend over a
                            minute in the ad.
                        </p>
                        <p>
                            Items selected for comparison produce parallel
                            charts from the items' data. Please note, that
                            scaling can be drastically different between the
                            charts!
                        </p>
                        <p>
                            <b>Day funnels</b> show each selected event on a
                            time-line with percentage of users from previous
                            step for each day.
                        </p>
                    </Card.Body>
                </Card>
                <Card className="mt-2 inner-card">
                    <Card.Header className="text-inverse-50">
                        <h5>Heatmaps</h5>
                    </Card.Header>
                    <Card.Body className="text-inverse bread-text">
                        <p>
                            Currently, heatmaps are produced only when
                            ctaBtnPressed is selected as an event.
                        </p>
                        <p>
                            The heatmaps show counts of taps on user screens
                            that have happened during the event.
                        </p>
                        <p>
                            Screens sizes are normalized to portrait and
                            landscape grids with coordinates indicating an area
                            of screen.
                        </p>
                        <p>
                            Colors indicate the intensity ie. the count of taps
                            on the specific coordinates.
                        </p>
                    </Card.Body>
                </Card>
            </Card.Body>
        </Card>
    );
};
