import React, { FC, useEffect, useRef } from "react";
import {
    BarController,
    BarElement,
    CategoryScale,
    Chart,
    ChartConfiguration,
    Legend,
    LinearScale,
    LineController,
    LineElement,
    PointElement,
    ScatterController,
    Tooltip
} from "chart.js";
import { useLocalState } from "../../graphql/hooks";

Chart.register(
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Tooltip,
    Legend,
    LineController,
    BarController,
    ScatterController
);

interface Props {
    config: ChartConfiguration;
}

export const ChartJsWrapper: FC<Props> = ({ config }) => {
    const chartRef = useRef<HTMLCanvasElement | null>(null);
    const chartInstanceRef = useRef<Chart | null>(null);
    const { useDarkMode } = useLocalState();

    useEffect(() => {
        const themeColors = {
            color: useDarkMode ? "#D3D3D3" : "#000000",
            axisColor: useDarkMode ? "#CCCCCC" : "#333333"
        };

        const themedConfig = {
            ...config,
            options: {
                ...config.options,
                scales: {
                    x: {
                        ...config.options?.scales?.x,
                        ticks: {
                            ...config.options?.scales?.x?.ticks,
                            color: themeColors.axisColor
                        }
                    },
                    y: {
                        ...config.options?.scales?.y,
                        ticks: {
                            ...config.options?.scales?.y?.ticks,
                            color: themeColors.axisColor
                        }
                    }
                },
                plugins: {
                    ...config.options?.plugins,
                    legend: {
                        ...config.options?.plugins?.legend,
                        labels: {
                            ...config.options?.plugins?.legend?.labels,
                            color: themeColors.color
                        }
                    }
                }
            }
        };

        if (chartInstanceRef.current) {
            chartInstanceRef.current.destroy();
        }

        if (chartRef.current) {
            chartInstanceRef.current = new Chart(
                chartRef.current,
                themedConfig
            );
        }

        return () => {
            if (chartInstanceRef.current) {
                chartInstanceRef.current.destroy();
            }
        };
    }, [config, useDarkMode]);

    return <canvas ref={chartRef} />;
};
