import React, { FC } from "react";
import { Badge, Card, Col, Row } from "react-bootstrap";
import {
    EventType,
    EventTypeVariant,
    RevisionHistoryItem
} from "../../models/types";
import { getFormattedTime } from "../../common/Helpers";
import { AttachmentItem } from "./AttachmentItem";
import { Link, useParams } from "react-router-dom";

interface Props {
    event: RevisionHistoryItem;
    developerMode: boolean;
    revisionName: string | undefined;
}

export const RevisionHistoryEventItem: FC<Props> = ({
    event,
    developerMode,
    revisionName
}) => {
    const { productId, projectId, revisionId } = useParams<{
        productId: string;
        projectId: string;
        revisionId: string;
    }>();
    const historyPath = `/products/${productId}/projects/${projectId}/revisions/${revisionId}/history`;
    const generateAttachments = (keys: string[]) => {
        return keys.map((key, index) => (
            <AttachmentItem productId={productId} key={index} value={key} />
        ));
    };

    return (
        <Card
            key={event.id}
            className="no-border minWidthEvents inner-card bread-text text-inverse mb-2"
        >
            <Card.Header>
                <Row className="g-0">
                    <Col className="col-1 analytics-font pe-2">
                        {getFormattedTime(event.dateCreated)}
                    </Col>
                    <Col className="col-5 text-start">
                        <span className="pe-2">{revisionName}</span>
                        <Badge bg={EventTypeVariant[event.eventType]}>
                            {EventType[event.eventType]}
                        </Badge>
                    </Col>
                    <Col className="col-5 ps-2">
                        {event.description ? (
                            <p className="m-0">{event.description}</p>
                        ) : null}
                        {event.user ? (
                            <p className="m-0">
                                Created by: <i>{event.user.displayName}</i>
                            </p>
                        ) : null}
                    </Col>
                    <Col className="col-1 text-end">
                        {developerMode && event.build ? (
                            <>
                                <Link to={`${historyPath}/${event.build.id}`}>
                                    Build
                                </Link>
                            </>
                        ) : null}
                    </Col>
                </Row>
            </Card.Header>
            {event.keys ? (
                <Card.Body className="p-3">
                    <span className="bread-text ms-2">Attachments:</span>
                    {generateAttachments(event.keys)}
                </Card.Body>
            ) : null}
        </Card>
    );
};
