import React, { FC, useEffect, useState } from "react";
import {
    Button,
    Col,
    Container,
    FormControl,
    InputGroup,
    Modal,
    Row
} from "react-bootstrap";
import { FeedbackType, ReleaseType, Revision } from "../../models/types";
import { DragAndDrop } from "../editable/DragAndDrop";
import { useMutation } from "@apollo/client";
import { UPDATE_REVISION } from "../../graphql/mutations";
import { GET_PRODUCT_META_DATA } from "../../graphql/queries";
import { useAuth0 } from "@auth0/auth0-react";
import { useLocalState } from "../../graphql/hooks";
import "./Modals.scss";

interface Props {
    title: string;
    type: FeedbackType;
    revision: Revision;
    buildId?: number;
    show: boolean;
    onClose: () => void;
}

export const FeedbackModal: FC<Props> = ({
    title,
    type,
    show,
    buildId,
    onClose,
    revision
}) => {
    const { getAccessTokenSilently } = useAuth0();
    const [info, updateInfo] = useState("");
    const [text, updateText] = useState("");
    const [files, updateFiles] = useState<File[]>([]);
    const [fileNames, updateFileNames] = useState<string[]>([]);
    const [submitting, updateSubmitting] = useState(false);
    const [unMount, setMountingStatus] = useState(false);
    const [updateRevision] = useMutation(UPDATE_REVISION);
    const { useDarkMode } = useLocalState();

    useEffect(() => {
        if (unMount) {
            onClose();
        }
    }, [unMount, onClose]);

    const addFiles = (newFiles: File[]) => {
        const currentFiles = files;
        currentFiles.push(...newFiles);
        updateFiles(currentFiles);
        const filenames = currentFiles.map(file => file.name);
        updateFileNames(filenames);
    };

    const removeFile = (name: string) => {
        const currentFiles = files.filter(file => file.name !== name);
        updateFiles(currentFiles);
        const filenames = currentFiles.map(file => file.name);
        updateFileNames(filenames);
    };

    const submit = async () => {
        try {
            if (revision) {
                const data = new FormData();
                Array.from(files).forEach(file => {
                    data.append("files", file);
                });
                data.append("description", text);
                data.append("revision_id", revision.id.toString());
                data.append("event_type", type.toString());

                if (buildId) {
                    data.append("build_id", String(buildId));
                }
                const token = await getAccessTokenSilently();
                if (!token) {
                    return {};
                }
                const tokenString = `Bearer ${token}`;
                const responseData = await fetch(
                    `${process.env.REACT_APP_URL}/rest/createRevisionEvent/`,
                    {
                        method: "POST",
                        body: data,
                        mode: "cors",
                        headers: {
                            Authorization: tokenString
                        }
                    }
                );

                const response = await responseData.json();
                if (response.success) {
                    if (type !== FeedbackType.TestFeedback) {
                        try {
                            const newType =
                                type === FeedbackType.Accept
                                    ? ReleaseType.ReviewAccepted
                                    : ReleaseType.ReviewRejected;
                            await updateRevision({
                                variables: {
                                    revisionId: revision.id,
                                    releaseType: newType
                                },
                                refetchQueries: [
                                    {
                                        query: GET_PRODUCT_META_DATA,
                                        variables: {
                                            productId:
                                                revision.project.product?.id
                                        }
                                    }
                                ]
                            });
                            updateText("");
                            updateFiles([]);
                            updateFileNames([]);
                            updateSubmitting(false);
                            setMountingStatus(true);
                        } catch (error) {
                            console.log("[DEBUG] error: ", error);
                            updateSubmitting(false);
                            updateInfo(response.message);
                            setTimeout(() => {
                                updateInfo("");
                            }, 4000);
                        }
                    } else {
                        updateText("");
                        updateFiles([]);
                        updateFileNames([]);
                        updateSubmitting(false);
                        setMountingStatus(true);
                    }
                } else {
                    console.log("[DEBUG] error: ", response.message);
                    updateSubmitting(false);
                    updateInfo(response.message);
                    setTimeout(() => {
                        updateInfo("");
                    }, 4000);
                }
            }
        } catch (error) {
            console.log("[DEBUG] submit error ", error);
        }
    };

    return (
        <Modal
            show={show}
            onHide={onClose}
            backdrop="static"
            keyboard={false}
            className={useDarkMode ? "modal-dark" : ""}
        >
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-body p-2">
                <Container>
                    <Row className="p-2">
                        <InputGroup>
                            <Row className="m-0 p-0 w-100 h-100">
                                <Col className="m-0 p-0 w-100 col-12">
                                    <h5>Description</h5>
                                </Col>
                                <Col className="m-0 p-0 w-100 col-12">
                                    <FormControl
                                        as="textarea"
                                        placeholder="Description required"
                                        aria-label="Description"
                                        defaultValue={text}
                                        onChange={(event: any) => {
                                            updateText(event.target.value);
                                        }}
                                    />
                                </Col>
                            </Row>
                        </InputGroup>
                    </Row>
                    <Row className="my-3">
                        <DragAndDrop
                            instructions={
                                <span>
                                    You can add screenshots and screen
                                    recordings by{" "}
                                    <span className="text-info">
                                        dragging them here
                                    </span>{" "}
                                    or using the upload button.
                                </span>
                            }
                            fileNames={fileNames}
                            addFiles={addFiles}
                            removeFile={removeFile}
                        />
                    </Row>
                    <Row className="p-2">
                        <FormControl
                            id="feedback-file-upload"
                            className="text-center mt-2"
                            type="file"
                            accept="image/*, video/*"
                            onChange={(
                                event: React.ChangeEvent<HTMLInputElement>
                            ) => {
                                if (event.target.files) {
                                    addFiles(Array.from(event.target.files));
                                }
                            }}
                            multiple
                        />
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer className="d-flex flex-column justify-content-center align-content-center">
                <>
                    <h5 className="text-center text-danger">{info}</h5>
                    <Button
                        disabled={submitting || text.length === 0}
                        onClick={() => {
                            updateSubmitting(true);
                            submit();
                        }}
                    >
                        {submitting ? "Submitting..." : "Submit"}
                    </Button>
                </>
            </Modal.Footer>
        </Modal>
    );
};
