import React, { FC, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import {
    Button,
    Card,
    Col,
    Container,
    Form,
    InputGroup,
    Modal,
    Row
} from "react-bootstrap";
import { GET_USER, GET_USERS } from "../../../graphql/queries";
import { REMOVE_USER, UPDATE_USER } from "../../../graphql/mutations";
import { UserData } from "../../../models/types";
import { SelectableScopes } from "../../selectable/admin/SelectableScopes";
import { UserOrganizationDropdown } from "../../dropdowns/admin/UserOrganizationDropdown";
import { SelectableProducts } from "../../selectable/admin/SelectableProducts";
import { SelectableProjects } from "../../selectable/admin/SelectableProjects";
import { useLocalState } from "../../../graphql/hooks";
import cx from "classnames";

interface Props {
    userId: number;
    visible: () => void;
}

export const UserModal: FC<Props> = ({ userId, visible }) => {
    const [userRemove, updateUserRemove] = useState("");
    const { useDarkMode } = useLocalState();
    const { data: { user } = {} } = useQuery<UserData>(GET_USER, {
        variables: {
            userId: userId
        }
    });
    const [updateUser] = useMutation(UPDATE_USER);
    const [removeUser] = useMutation(REMOVE_USER);

    const tryRemoveUser = async () => {
        try {
            if (user && userRemove === user.email) {
                const result = await removeUser({
                    variables: {
                        userId: user.id
                    },
                    refetchQueries: [
                        {
                            query: GET_USERS,
                            variables: {
                                organizationId: Number(user.organization?.id)
                            }
                        }
                    ]
                });

                if (result.data.removeUser) {
                    visible();
                }
            }
        } catch (error) {
            console.log("[DEBUG] tryRemoveUser error ", error);
        }
    };

    const updateScopes = async (scopes: string[]) => {
        try {
            await updateUser({
                variables: {
                    userId: user?.id,
                    scopes: scopes
                },
                refetchQueries: [
                    {
                        query: GET_USER,
                        variables: { userId: userId }
                    }
                ]
            });
        } catch (error) {
            console.log("[DEBUG] updateScopes error ", error);
        }
    };

    const updateOrganization = async (newOrganization: number) => {
        try {
            const oldOrganizationId = Number(user?.organization?.id);
            await updateUser({
                variables: {
                    userId: user?.id,
                    organizationId: newOrganization
                },
                refetchQueries: [
                    {
                        query: GET_USER,
                        variables: { userId: userId }
                    },
                    {
                        query: GET_USERS,
                        variables: { organizationId: oldOrganizationId }
                    },
                    {
                        query: GET_USERS,
                        variables: { organizationId: newOrganization }
                    }
                ]
            });
        } catch (error) {
            console.log("[DEBUG] updateOrganization error ", error);
        }
    };

    const updateProducts = async (
        productId: number,
        handleProjects: boolean
    ) => {
        try {
            await updateUser({
                variables: {
                    userId: user?.id,
                    productId: productId,
                    handleProjects: handleProjects
                },
                refetchQueries: [
                    {
                        query: GET_USER,
                        variables: { userId: userId }
                    }
                ]
            });
        } catch (error) {
            console.log("[DEBUG] updateProducts error ", error);
        }
    };

    const updateProjects = async (projectId: number) => {
        try {
            await updateUser({
                variables: {
                    userId: user?.id,
                    projectId: projectId
                },
                refetchQueries: [
                    {
                        query: GET_USER,
                        variables: { userId: userId }
                    }
                ]
            });
        } catch (error) {
            console.log("[DEBUG] updateProjects error ", error);
        }
    };

    if (!user) {
        return null;
    }

    return (
        <Modal
            show={true}
            onHide={visible}
            backdrop="static"
            size="lg"
            className={useDarkMode ? "modal-dark" : ""}
        >
            <Modal.Header closeButton>
                <h3>User Info</h3>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Row>
                        <Col className="col-12 ms-2">
                            <h4>Base Info</h4>
                            <b>Display Name:</b> {user.displayName}
                        </Col>
                        <Col className="col-12 ms-2">
                            <b>Email:</b> {user.email}
                        </Col>
                        {user.authType ? (
                            <Col className="col-12 ms-2">
                                <b>Auth Type:</b> {user.authType}
                            </Col>
                        ) : null}
                        <Col className="col-12 my-3 ms-2">
                            <h4>Organization</h4>
                            <UserOrganizationDropdown
                                user={user}
                                updateOrganization={updateOrganization}
                            />
                        </Col>
                        <Col className="col-12 mb-3 ms-2">
                            <h4>Permission Scopes</h4>
                            {user ? (
                                <SelectableScopes
                                    user={user}
                                    updateScopes={updateScopes}
                                />
                            ) : null}
                        </Col>
                        <Col className="col-12 mb-3">
                            <SelectableProducts
                                user={user}
                                updateProducts={updateProducts}
                            />
                        </Col>
                        <Col className="col-12 mb-3">
                            <SelectableProjects
                                user={user}
                                updateProjects={updateProjects}
                            />
                        </Col>
                        <Col className="col-12 mb-2 ms-2">
                            <h4>Danger Zone</h4>
                        </Col>
                        <Col>
                            <Card className="border-danger">
                                <Card.Body>
                                    <strong>Remove User</strong>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            type="text"
                                            name="fullName"
                                            placeholder="Write the user's email to remove them"
                                            value={userRemove}
                                            onChange={(event: any) => {
                                                updateUserRemove(
                                                    event.target.value
                                                );
                                            }}
                                        />
                                        <Button
                                            className={cx("btn-danger", {
                                                disabled:
                                                    userRemove !== user.email
                                            })}
                                            onClick={tryRemoveUser}
                                        >
                                            Remove
                                        </Button>
                                    </InputGroup>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-center">
                <Button
                    variant="secondary"
                    onClick={(e: React.MouseEvent<HTMLElement>) => {
                        e.stopPropagation();
                        visible();
                    }}
                >
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
