import React from "react";
import { Toast } from "react-bootstrap";
import { useLocalNotificationState } from "../../graphql/hooks";
import { updateNotificationState } from "../../common/Helpers";
import { useApolloClient } from "@apollo/client";
import styles from "../panels/CurrentBuildsPanel.module.scss";
import cx from "classnames";

export const SimpleToast = () => {
    const client = useApolloClient();
    const { showToast, toastData } = useLocalNotificationState();

    const onClose = () => {
        updateNotificationState(client, false, false, undefined);
    };

    if (!toastData) {
        return null;
    }

    return (
        <Toast
            show={showToast}
            onClose={() => onClose()}
            delay={toastData.delay}
            autohide
            className={cx(
                "m-auto text-center primary-background position-fixed",
                styles.buildToast
            )}
        >
            <Toast.Header
                className={cx("d-flex justify-content-center", {
                    "text-danger": !toastData.success,
                    "text-success": toastData.success
                })}
            >
                {toastData.header}
            </Toast.Header>
            <Toast.Body>
                <span className="text-inverse">{toastData.message}</span>
            </Toast.Body>
        </Toast>
    );
};
