import React, { FC, useState } from "react";
import { Container, Dropdown, DropdownButton, Form } from "react-bootstrap";
import { State } from "../../models/common";
import { useAuth0 } from "@auth0/auth0-react";
import { User } from "../../models/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import styles from "./UserDropdown.module.scss";
import cx from "classnames";

interface Props {
    me: User;
    state: State;
    updateDeveloperState: (newState: boolean) => void;
    logoutUser: () => void;
}

export const UserDropdown: FC<Props> = ({
    me,
    state,
    updateDeveloperState,
    logoutUser
}) => {
    const { user } = useAuth0();
    const [show, setShow] = useState(false);

    if (!me) {
        return null;
    }

    const handleToggle = (isOpen: boolean) => {
        setShow(isOpen);
    };

    const handleModeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        updateDeveloperState(event.target.checked);
        setShow(false);
    };

    return (
        <Dropdown
            align="end"
            className="align-center"
            show={show}
            onToggle={handleToggle}
        >
            <Dropdown.Toggle
                id="user-dropdown-toggle"
                variant={cx({ dark: state.useDarkMode })}
                className="d-flex align-items-center justify-content-between p-1 text-inverse-50"
            >
                {user && user.picture ? (
                    <div>
                        <img
                            src={user.picture}
                            className={styles.userPicture}
                            alt="User"
                        />
                    </div>
                ) : (
                    <FontAwesomeIcon icon={faUser} size="lg" />
                )}
            </Dropdown.Toggle>
            <Dropdown.Menu
                className={cx(`primary-background ${styles.menuAlignment}`)}
            >
                <Dropdown.Item className="text-center" disabled>
                    <p className="text-inverse-50 m-0">{me.displayName}</p>
                </Dropdown.Item>
                {state.isDeveloper && (
                    <Dropdown.ItemText as={Container}>
                        <Form className="m-0 p-0">
                            <Form.Check
                                type="switch"
                                id="dev-mode-switch"
                                label="Dev Mode"
                                className="text-inverse-50 m-0"
                                checked={state.developerMode}
                                onChange={handleModeChange}
                            />
                        </Form>
                    </Dropdown.ItemText>
                )}
                <Dropdown.Divider />
                <Dropdown.Item
                    className="text-center"
                    onClick={() => {
                        logoutUser();
                        setShow(false);
                    }}
                >
                    Logout
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );
};
