import React, { FC, ReactElement } from "react";
import {
    faCheckCircle,
    faCircleXmark,
    faQuestionCircle,
    faSpinner,
    IconDefinition
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    BatchBuild,
    BatchBuildState,
    BuildPipelineEventStatus,
    NetworkBuildState
} from "../../../models/types";
import { Button } from "react-bootstrap";
import cx from "classnames";

interface Props {
    build: BatchBuild;
}

export const StepProgressBar: FC<Props> = ({ build }) => {
    const buildCount = build.networkBuilds.filter(
        event => event.state === NetworkBuildState.Build
    ).length;

    const getColor = (state: BuildPipelineEventStatus) => {
        switch (state) {
            case BuildPipelineEventStatus.InProgress:
                return "info";
            case BuildPipelineEventStatus.Completed:
                return "success";
            case BuildPipelineEventStatus.Failed:
                return "danger";
            default:
                return "secondary";
        }
    };

    const isSuccess = (state: NetworkBuildState) => {
        const event = build.networkBuilds.find(event => {
            return event.state === state && !event.errors;
        });
        return !!event;
    };

    const batchStateToEventType = (
        batchState: BatchBuildState
    ): NetworkBuildState | undefined => {
        switch (batchState) {
            case BatchBuildState.JobCreated:
                return NetworkBuildState.Create;
            case BatchBuildState.JobStarted:
                return NetworkBuildState.Start;
            case BatchBuildState.JobBuilt:
                return NetworkBuildState.Build;
            case BatchBuildState.JobCleaned:
                return NetworkBuildState.Clean;
            case BatchBuildState.Completed:
                return NetworkBuildState.Completed;
            default:
                return undefined;
        }
    };

    const getInternalState = (
        index: number,
        currentState: number,
        nextState: number
    ): BuildPipelineEventStatus => {
        if (index > nextState) {
            return BuildPipelineEventStatus.InQueue;
        }
        if (index === nextState) {
            return BuildPipelineEventStatus.InProgress;
        }
        if (index <= currentState && currentState !== BatchBuildState.Failed) {
            return BuildPipelineEventStatus.Completed;
        }
        const eventType = batchStateToEventType(index);
        return eventType && isSuccess(eventType)
            ? BuildPipelineEventStatus.Completed
            : BuildPipelineEventStatus.Failed;
    };

    const getIconByInternalStatus = (
        status: BuildPipelineEventStatus
    ): IconDefinition => {
        switch (status) {
            case BuildPipelineEventStatus.InProgress:
                return faSpinner;
            case BuildPipelineEventStatus.Completed:
                return faCheckCircle;
            case BuildPipelineEventStatus.Failed:
                return faCircleXmark;
            default:
                return faQuestionCircle;
        }
    };

    const getNextState = (
        currentState: number,
        singleBuild: boolean
    ): number => {
        if (singleBuild) {
            if (currentState === BatchBuildState.JobCreated) {
                return BatchBuildState.JobBuilt;
            }
            if (currentState === BatchBuildState.JobBuilt) {
                return BatchBuildState.Completed;
            }
        }
        return currentState + 1;
    };

    const isSingleBuild = build.buildJobCount === 1;
    const currentState =
        build.state !== BatchBuildState.JobBuilt
            ? build.state
            : buildCount === build.buildJobCount
              ? build.state
              : build.state - 1;
    const nextState = getNextState(currentState, isSingleBuild);
    const stateItems: ReactElement[] = [];
    for (let i = 1; i <= BatchBuildState.Completed; i++) {
        if (
            build.buildJobCount === 1 &&
            (i === BatchBuildState.JobStarted ||
                i === BatchBuildState.JobCleaned)
        ) {
            continue;
        }

        const internalState = getInternalState(i, currentState, nextState);
        const icon = getIconByInternalStatus(internalState);
        const color = getColor(internalState);
        if (i !== 1 || (isSingleBuild && i > 2)) {
            stateItems.push(<div key={`line-${i}`} className="hr-line"></div>);
        }
        if (i === BatchBuildState.JobBuilt) {
            stateItems.push(
                <Button
                    key={`button-${i}`}
                    variant={`${color}`}
                    size="sm"
                    title={BatchBuildState[i]}
                    className={cx({
                        disabled: currentState <= BatchBuildState.Completed
                    })}
                >
                    <FontAwesomeIcon
                        key={`icon-${i}`}
                        icon={icon}
                        className={cx(`me-1 ${color}`, {
                            "fa-spin":
                                internalState ===
                                BuildPipelineEventStatus.InProgress
                        })}
                    />
                    {buildCount}/{build.buildJobCount}
                </Button>
            );
        } else {
            stateItems.push(
                <FontAwesomeIcon
                    key={`icon-${i}`}
                    icon={icon}
                    className={cx(`text-${color}`, {
                        "fa-spin":
                            internalState ===
                            BuildPipelineEventStatus.InProgress
                    })}
                    title={BatchBuildState[i]}
                />
            );
        }
    }

    return (
        <div className="ms-2 d-inline-flex align-middle align-items-center">
            {stateItems}
        </div>
    );
};
