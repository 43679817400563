import React from "react";
import { Nav } from "react-bootstrap";
import { Link } from "react-router-dom";

export const AdminPanelNavLink = () => {
    return (
        <Nav.Link eventKey="admin" as={Link} to="/admin">
            Admin
        </Nav.Link>
    );
};
