import { useMutation, useQuery } from "@apollo/client";
import React, { FC, useEffect, useState } from "react";
import {
    Badge,
    Button,
    Card,
    Collapse,
    FormControl,
    InputGroup
} from "react-bootstrap";
import {
    Project,
    ProjectConfigData,
    ProjectConfigVariables,
    SoftLimitsData,
    UpdateProjectConfigData
} from "../../../models/types";
import { GET_PROJECT_CONFIG } from "../../../graphql/queries";
import { EditableJsonView } from "../../editable/EditableJsonView";
import { UPDATE_PROJECT_CONFIG } from "../../../graphql/mutations";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

interface Props {
    project: Project;
}

export const ProjectConfig: FC<Props> = ({ project }) => {
    const [show, toggleShow] = useState(false);
    const [updating, updateUpdating] = useState(false);
    const [hasUnsaved, updateHasUnsaved] = useState(false);
    const { data: { projectConfig } = {} } = useQuery<ProjectConfigData>(
        GET_PROJECT_CONFIG,
        {
            variables: {
                projectId: project.id
            }
        }
    );
    const [currentNetworks, updateCurrentNetworks] = useState<string[] | null>(
        []
    );

    const [projectConfigMutation] = useMutation<
        UpdateProjectConfigData,
        ProjectConfigVariables
    >(UPDATE_PROJECT_CONFIG);

    useEffect(() => {
        if (projectConfig && projectConfig.analyticsNetworks) {
            updateCurrentNetworks(projectConfig.analyticsNetworks);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectConfig]);

    const createSoftLimits = () => {
        updateSoftLimits({ global: [] });
    };

    const createAnalyticsNetworks = () => {
        updateAnalyticsNetworks([]);
    };

    const updateSoftLimits = async (newLimits: SoftLimitsData | null) => {
        updateUpdating(true);
        try {
            await projectConfigMutation({
                variables: {
                    input: {
                        projectId: project.id,
                        softLimits: newLimits
                    }
                },
                refetchQueries: [
                    {
                        query: GET_PROJECT_CONFIG,
                        variables: { projectId: project.id }
                    }
                ]
            });
        } catch (error) {
            console.log("[DEBUG] error ", error);
        }
        updateUpdating(false);
        updateHasUnsaved(false);
    };

    const updateAnalyticsNetworks = async (newNetworks: string[] | null) => {
        if (projectConfig?.analyticsNetworks === newNetworks) return;
        try {
            await projectConfigMutation({
                variables: {
                    input: {
                        projectId: project.id,
                        analyticsNetworks: newNetworks
                    }
                },
                refetchQueries: [
                    {
                        query: GET_PROJECT_CONFIG,
                        variables: { projectId: project.id }
                    }
                ]
            });
        } catch (error) {
            console.log("[DEBUG] error ", error);
        }
    };

    const cleanupCommaSeparatedString = (raw: string): string[] => {
        return raw
            .trim()
            .replace(" ", "")
            .split(",")
            .filter(element => element !== "");
    };

    return (
        <>
            <Card className="mt-2">
                <Card.Header>
                    Project Config
                    <FontAwesomeIcon
                        icon={show ? faChevronUp : faChevronDown}
                        className="mouseHover mx-2"
                        onClick={() => {
                            toggleShow(!show);
                        }}
                        title={show ? "Hide details" : "Show details"}
                    />
                    {hasUnsaved ? (
                        <div className="d-flex float-end ms-auto">
                            <Badge bg="warning">Changes Not Saved</Badge>
                        </div>
                    ) : null}
                </Card.Header>
                <Collapse in={show}>
                    <div>
                        <Card.Body>
                            <Card>
                                <Card.Header>Soft Limits</Card.Header>
                                <Card.Body className="d-flex justify-content-center p-0">
                                    {projectConfig === undefined ||
                                    projectConfig?.softLimits === null ? (
                                        <Button
                                            className="my-2"
                                            onClick={createSoftLimits}
                                        >
                                            Create Soft Limits Config
                                        </Button>
                                    ) : (
                                        <EditableJsonView
                                            jsonData={JSON.stringify(
                                                projectConfig.softLimits,
                                                null,
                                                2
                                            )}
                                            updateJsonData={updateSoftLimits}
                                            updating={updating}
                                            updateHasUnsavedData={
                                                updateHasUnsaved
                                            }
                                            removeJsonData={() =>
                                                updateSoftLimits(null)
                                            }
                                        />
                                    )}
                                </Card.Body>
                            </Card>
                            <Card className="mt-3">
                                <Card.Header>Analytics Networks</Card.Header>
                                <Card.Body className="d-flex justify-content-center m-2 p-0">
                                    {projectConfig === undefined ||
                                    projectConfig?.analyticsNetworks ===
                                        null ? (
                                        <Button
                                            onClick={createAnalyticsNetworks}
                                        >
                                            Create Analytics Networks
                                        </Button>
                                    ) : (
                                        <>
                                            <InputGroup>
                                                <InputGroup.Text>
                                                    Analytics Networks
                                                </InputGroup.Text>
                                                <FormControl
                                                    value={currentNetworks?.join(
                                                        ","
                                                    )}
                                                    onChange={(event: any) => {
                                                        const newAnalyticsNetworks =
                                                            cleanupCommaSeparatedString(
                                                                event.target
                                                                    .value
                                                            );
                                                        updateCurrentNetworks(
                                                            newAnalyticsNetworks
                                                        );
                                                    }}
                                                    onBlur={() => {
                                                        updateAnalyticsNetworks(
                                                            currentNetworks
                                                        );
                                                    }}
                                                />
                                                <Button
                                                    variant="danger"
                                                    onClick={() => {
                                                        updateAnalyticsNetworks(
                                                            null
                                                        );
                                                    }}
                                                >
                                                    Remove
                                                </Button>
                                            </InputGroup>
                                        </>
                                    )}
                                </Card.Body>
                            </Card>
                        </Card.Body>
                    </div>
                </Collapse>
            </Card>
        </>
    );
};
