import { useMutation, useQuery } from "@apollo/client";
import React, { FC, useState } from "react";
import { Badge, Button, Card, Collapse } from "react-bootstrap";
import { UPDATE_ORGANIZATION_CONFIG } from "../../../graphql/mutations";
import {
    Organization,
    OrganizationConfigData,
    OrganizationConfigVariables,
    SoftLimitsData,
    UpdateOrganizationConfigData
} from "../../../models/types";
import { GET_ORGANIZATION_CONFIG } from "../../../graphql/queries";
import { EditableJsonView } from "../../editable/EditableJsonView";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

interface Props {
    organization: Organization;
}

export const OrganizationConfig: FC<Props> = ({ organization }) => {
    const [show, toggleShow] = useState(false);
    const [updating, updateUpdating] = useState(false);
    const [hasUnsaved, updateHasUnsaved] = useState(false);
    const { data: { organizationConfig } = {} } =
        useQuery<OrganizationConfigData>(GET_ORGANIZATION_CONFIG, {
            variables: {
                organizationId: organization.id
            }
        });
    const [organizationConfigMutation] = useMutation<
        UpdateOrganizationConfigData,
        OrganizationConfigVariables
    >(UPDATE_ORGANIZATION_CONFIG);

    const createOrganizationConfig = () => {
        updateOrganizationConfig({ global: [] });
    };

    const updateOrganizationConfig = async (newLimits: SoftLimitsData) => {
        updateUpdating(true);
        try {
            await organizationConfigMutation({
                variables: {
                    input: {
                        organizationId: organization.id,
                        softLimits: newLimits
                    }
                },
                refetchQueries: [
                    {
                        query: GET_ORGANIZATION_CONFIG,
                        variables: { organizationId: organization.id }
                    }
                ]
            });
        } catch (error) {
            console.log("[DEBUG] error ", error);
        }
        updateUpdating(false);
    };

    return (
        <Card className="inner-card">
            <Card.Header className="text-inverse">
                Organization Config
                <FontAwesomeIcon
                    size="xs"
                    icon={show ? faChevronUp : faChevronDown}
                    className="mouseHover mx-2"
                    onClick={() => {
                        toggleShow(!show);
                    }}
                    title={show ? "Hide details" : "Show details"}
                />
                {hasUnsaved ? (
                    <div className="d-flex float-end ms-auto">
                        <Badge bg="warning">Changes Not Saved</Badge>
                    </div>
                ) : null}
            </Card.Header>
            <Collapse in={show}>
                <div>
                    <Card.Body>
                        {organizationConfig === undefined ? (
                            <Button onClick={createOrganizationConfig}>
                                Create Config
                            </Button>
                        ) : (
                            <EditableJsonView
                                jsonData={JSON.stringify(
                                    organizationConfig.softLimits,
                                    null,
                                    2
                                )}
                                updateJsonData={updateOrganizationConfig}
                                updating={updating}
                                updateHasUnsavedData={updateHasUnsaved}
                            />
                        )}
                    </Card.Body>
                </div>
            </Collapse>
        </Card>
    );
};
