import React, { FC } from "react";
import { useQuery } from "@apollo/client";
import { Card } from "react-bootstrap";
import { GET_ANALYTICS_PROJECTS } from "../../../graphql/queries";
import {
    AnalyticsProjectData,
    AnalyticsProjectVariables,
    AnalyticsQuerySet,
    Product,
    Project
} from "../../../models/types";
import { FunnelProject } from "../../simple/analytics/FunnelProject";

interface Props {
    product: Product;
    collapseTree?: AnalyticsQuerySet;
}

export const AnalyticsProjects: FC<Props> = ({ product, collapseTree }) => {
    const { loading, data, error } = useQuery<
        AnalyticsProjectData,
        AnalyticsProjectVariables
    >(GET_ANALYTICS_PROJECTS, {
        variables: {
            productId: product.id
        }
    });

    const renderList = () => {
        if (loading) {
            return <div>Loading...</div>;
        }
        if (error) {
            return <div>Error: {error.message}</div>;
        }
        if (!data) {
            return null;
        }
        if (data.analyticsProjects.length === 0) {
            return (
                <Card.Body className="p-2 bread-text text-inverse-50">
                    No projects with analytics enabled.
                </Card.Body>
            );
        }

        return data.analyticsProjects.map((project: Project) => (
            <FunnelProject
                key={project.id}
                project={project}
                collapseTree={collapseTree}
            />
        ));
    };

    return <div>{renderList()}</div>;
};
