import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { Badge, Button, Card, FormControl, InputGroup } from "react-bootstrap";
import { GET_PLATFORM_CONFIG } from "../../../graphql/queries";
import { PlatformConfig, PlatformConfigData } from "../../../models/types";
import { UPDATE_PLATFORM_CONFIG } from "../../../graphql/mutations";

export const PlatformConfigTools = () => {
    const initialConfig = {
        id: -1,
        useBuildRunner: false,
        supportedNetworks: [],
        buildRunnerNetworks: [],
        analyticsNetworks: [],
        qrNetworks: [],
        networkTestingNetworks: [],
        networkTestingMinimumState: 0,
        analyticsApiVersion: ""
    };
    const [currentConfig, updateCurrentConfig] =
        useState<PlatformConfig>(initialConfig);
    const [lastSaved, updateLastSavedConfig] = useState<
        PlatformConfig | undefined
    >(undefined);
    const [saved, updateSaved] = useState(true);
    const { data: { getPlatformConfig } = {} } =
        useQuery<PlatformConfigData>(GET_PLATFORM_CONFIG);
    const [platformConfigMutation] = useMutation<PlatformConfigData>(
        UPDATE_PLATFORM_CONFIG
    );

    useEffect(() => {
        if (getPlatformConfig) {
            updateLastSavedConfig(getPlatformConfig);
            updateCurrentConfig(getPlatformConfig);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getPlatformConfig]);

    useEffect(() => {
        if (JSON.stringify(currentConfig) !== JSON.stringify(lastSaved)) {
            if (saved) {
                updateSaved(false);
            }
        } else if (!saved) {
            updateSaved(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentConfig]);

    const updatePlatformConfig = () => {
        const newConfig = { ...currentConfig };
        // @ts-ignore
        delete newConfig["__typename"];
        console.log("[DEBUG] update platform config with variables ", {
            variables: {
                input: newConfig
            }
        });
        platformConfigMutation({
            variables: {
                input: newConfig
            }
        })
            .then(result => {
                console.log("[DEBUG] result ", result);
                // @ts-ignore
                updateLastSavedConfig(result.updatePlatformConfig);
                updateSaved(true);
            })
            .catch(error => console.log("[DEBUG] error ", error));
    };

    const cleanupCommaSeparatedString = (raw: string): string[] => {
        return raw.trim().replace(" ", "").split(",");
    };

    return (
        <>
            <Card className="my-3">
                <Card.Header>
                    <h5 className="text-inverse-50">Platform Config Tools</h5>
                </Card.Header>
                <Card.Body>
                    <InputGroup className="m-0 p-0">
                        <InputGroup.Text>useBuildRunner</InputGroup.Text>
                        <InputGroup.Checkbox
                            title="Allow build-runner to build single builds"
                            aria-label="Allow build-runner to build single builds"
                            checked={currentConfig.useBuildRunner}
                            onChange={(event: any) => {
                                const newConfig = {
                                    ...currentConfig,
                                    useBuildRunner: event.target.checked
                                };
                                updateCurrentConfig(newConfig);
                            }}
                        />
                    </InputGroup>
                    <InputGroup className="m-0 p-0 mt-2">
                        <InputGroup.Text>supportedNetworks</InputGroup.Text>
                        <FormControl
                            value={currentConfig.supportedNetworks.join(",")}
                            onChange={(event: any) => {
                                const newConfig = {
                                    ...currentConfig,
                                    supportedNetworks:
                                        cleanupCommaSeparatedString(
                                            event.target.value
                                        )
                                };
                                updateCurrentConfig(newConfig);
                            }}
                        />
                    </InputGroup>
                    <InputGroup className="m-0 p-0 mt-2">
                        <InputGroup.Text>buildRunnerNetworks</InputGroup.Text>
                        <FormControl
                            value={currentConfig.buildRunnerNetworks.join(",")}
                            onChange={(event: any) => {
                                const newConfig = {
                                    ...currentConfig,
                                    buildRunnerNetworks:
                                        cleanupCommaSeparatedString(
                                            event.target.value
                                        )
                                };
                                updateCurrentConfig(newConfig);
                            }}
                        />
                    </InputGroup>
                    <InputGroup className="m-0 p-0 mt-2">
                        <InputGroup.Text>analyticsNetworks</InputGroup.Text>
                        <FormControl
                            value={currentConfig.analyticsNetworks.join(",")}
                            onChange={(event: any) => {
                                const newConfig = {
                                    ...currentConfig,
                                    analyticsNetworks:
                                        cleanupCommaSeparatedString(
                                            event.target.value
                                        )
                                };
                                updateCurrentConfig(newConfig);
                            }}
                        />
                    </InputGroup>
                    <InputGroup className="m-0 p-0 mt-2">
                        <InputGroup.Text>qrNetworks</InputGroup.Text>
                        <FormControl
                            value={currentConfig.qrNetworks.join(",")}
                            onChange={(event: any) => {
                                const newConfig = {
                                    ...currentConfig,
                                    qrNetworks: cleanupCommaSeparatedString(
                                        event.target.value
                                    )
                                };
                                updateCurrentConfig(newConfig);
                            }}
                        />
                    </InputGroup>
                    <InputGroup className="m-0 p-0 mt-2">
                        <InputGroup.Text>
                            networkTestingNetworks
                        </InputGroup.Text>
                        <FormControl
                            value={currentConfig.networkTestingNetworks.join(
                                ","
                            )}
                            onChange={(event: any) => {
                                const newConfig = {
                                    ...currentConfig,
                                    networkTestingNetworks:
                                        cleanupCommaSeparatedString(
                                            event.target.value
                                        )
                                };
                                updateCurrentConfig(newConfig);
                            }}
                        />
                    </InputGroup>
                    <InputGroup className="m-0 p-0 mt-2">
                        <InputGroup.Text>
                            networkTestingMinimumState
                        </InputGroup.Text>
                        <FormControl
                            value={currentConfig.networkTestingMinimumState}
                            onChange={(event: any) => {
                                const newConfig = {
                                    ...currentConfig,
                                    networkTestingMinimumState: Number(
                                        event.target.value
                                    )
                                };
                                updateCurrentConfig(newConfig);
                            }}
                        />
                    </InputGroup>
                    <InputGroup className="m-0 p-0 mt-2">
                        <InputGroup.Text>analyticsApiVersion</InputGroup.Text>
                        <FormControl
                            value={currentConfig.analyticsApiVersion}
                            onChange={(event: any) => {
                                const newConfig = {
                                    ...currentConfig,
                                    analyticsApiVersion: event.target.value
                                };
                                updateCurrentConfig(newConfig);
                            }}
                        />
                    </InputGroup>
                    <Button
                        className="mt-2"
                        onClick={() => updatePlatformConfig()}
                    >
                        Save
                    </Button>
                    {!saved ? (
                        <Badge
                            className="d-flex justify-content-center m-2"
                            bg="warning"
                        >
                            Changes Not Saved
                        </Badge>
                    ) : null}
                </Card.Body>
            </Card>
        </>
    );
};
