import React, { FC, SyntheticEvent, useEffect } from "react";
import { useApolloClient } from "@apollo/client";
import { faCheck, faFilter, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useLocalAnalyticsState } from "../../../graphql/hooks";
import { GET_LOCAL_ANALYTICS_STATE } from "../../../graphql/queries";
import {
    AnalyticsStateData,
    BatchBuild,
    NetworkBuild,
    Product,
    Project,
    Revision
} from "../../../models/types";
import { getParentSelection } from "../../../common/AnalyticsHelpers";
import styles from "../../buttons/analytics/SelectionToggle.module.scss";

interface IncludeState {
    include: boolean;
    manualSelection: boolean;
}

interface Props {
    reference: string;
    product?: Product;
    project?: Project;
    revision?: Revision;
    build?: BatchBuild;
    networkBuild?: NetworkBuild;
    className?: string;
}

const AnalyticsFunnelViewSelectionToggleImplementation: FC<Props> = ({
    reference,
    product,
    project,
    revision,
    build,
    networkBuild,
    className
}) => {
    const analyticsState = useLocalAnalyticsState();
    const client = useApolloClient();

    const isSelected = (): IncludeState => {
        const handleResult = (
            result: boolean | undefined,
            selected: boolean
        ): boolean => {
            if (result !== undefined) {
                return result;
            }
            return selected;
        };
        switch (reference) {
            case "product":
                if (!product) {
                    return {
                        include: false,
                        manualSelection: false
                    };
                }
                const manuallySelectedProduct =
                    analyticsState.analyticsProducts.find(
                        element => element.id === product.id
                    );
                return {
                    include: manuallySelectedProduct?.selected || false,
                    manualSelection: true
                };
            case "project": {
                if (!project) {
                    return {
                        include: false,
                        manualSelection: false
                    };
                }
                const manuallySelectedProject =
                    analyticsState.analyticsProjects.find(
                        element => element.id === project.id
                    );
                if (manuallySelectedProject) {
                    return {
                        include: manuallySelectedProject.selected || false,
                        manualSelection: true
                    };
                }
                const selected =
                    getParentSelection(
                        analyticsState,
                        "product",
                        project.product
                    )?.selected || false;
                return {
                    include: selected,
                    manualSelection: false
                };
            }
            case "revision": {
                if (!revision) {
                    return {
                        include: false,
                        manualSelection: false
                    };
                }
                const manuallySelectedRevision =
                    analyticsState.analyticsRevisions.find(
                        element => element.id === revision.id
                    );
                if (manuallySelectedRevision) {
                    return {
                        include: manuallySelectedRevision.selected || false,
                        manualSelection: true
                    };
                }
                let selected = false;
                selected = handleResult(
                    getParentSelection(
                        analyticsState,
                        "product",
                        revision.project.product
                    )?.selected,
                    selected
                );
                selected = handleResult(
                    getParentSelection(
                        analyticsState,
                        "project",
                        revision.project
                    )?.selected,
                    selected
                );
                return {
                    include: selected,
                    manualSelection: false
                };
            }
            case "build": {
                if (!build) {
                    return {
                        include: false,
                        manualSelection: false
                    };
                }
                const manuallySelectedBuild =
                    analyticsState.analyticsBuilds.find(
                        element => element.id === build.id
                    );
                if (manuallySelectedBuild) {
                    return {
                        include: manuallySelectedBuild.selected || false,
                        manualSelection: true
                    };
                }
                let selected = false;
                selected = handleResult(
                    getParentSelection(
                        analyticsState,
                        "product",
                        build.revision.project.product
                    )?.selected,
                    selected
                );
                selected = handleResult(
                    getParentSelection(
                        analyticsState,
                        "project",
                        build.revision.project
                    )?.selected,
                    selected
                );
                selected = handleResult(
                    getParentSelection(
                        analyticsState,
                        "revision",
                        build.revision
                    )?.selected,
                    selected
                );
                return {
                    include: selected,
                    manualSelection: false
                };
            }
            case "networkbuild": {
                if (!networkBuild) {
                    return {
                        include: false,
                        manualSelection: false
                    };
                }
                const manuallySelectedNetworkBuild =
                    analyticsState.analyticsNetworkBuilds.find(
                        element => element.id === networkBuild.id
                    );
                if (manuallySelectedNetworkBuild) {
                    return {
                        include: manuallySelectedNetworkBuild.selected || false,
                        manualSelection: true
                    };
                }
                let selected = false;
                selected = handleResult(
                    getParentSelection(
                        analyticsState,
                        "product",
                        networkBuild.build?.revision?.project.product
                    )?.selected,
                    selected
                );
                selected = handleResult(
                    getParentSelection(
                        analyticsState,
                        "project",
                        networkBuild.build?.revision.project
                    )?.selected,
                    selected
                );
                selected = handleResult(
                    getParentSelection(
                        analyticsState,
                        "revision",
                        networkBuild.build?.revision
                    )?.selected,
                    selected
                );
                selected = handleResult(
                    getParentSelection(
                        analyticsState,
                        "build",
                        networkBuild.build
                    )?.selected,
                    selected
                );
                return {
                    include: selected,
                    manualSelection: false
                };
            }
            default:
                return {
                    include: false,
                    manualSelection: false
                };
        }
    };

    useEffect(() => {
        isSelected();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        analyticsState.analyticsNetworkBuilds,
        analyticsState.analyticsBuilds,
        analyticsState.analyticsRevisions,
        analyticsState.analyticsProjects,
        analyticsState.analyticsProducts
    ]);

    const selectedState = isSelected();

    const toggleSelection = () => {
        const updateListSelected = (
            list: any[],
            element: any,
            parents: any[]
        ) => {
            if (!list.find(item => item.id === element.id)) {
                const newElement = {
                    ...element,
                    selected: !selectedState.include
                };
                return [...list, newElement];
            } else {
                for (let i = 0; i < list.length; i++) {
                    if (list[i].id === element.id) {
                        if (!list[i].selected) {
                            list[i].selected = true;
                        } else {
                            let canBeCleared = false;
                            for (let j = 0; j < parents.length; j++) {
                                if (
                                    getParentSelection(
                                        analyticsState,
                                        parents[j][0],
                                        parents[j][1]
                                    )
                                ) {
                                    canBeCleared = true;
                                    break;
                                }
                            }
                            if (canBeCleared) {
                                list.splice(i, 1);
                            } else {
                                list[i].selected = false;
                            }
                        }
                        break;
                    }
                }
            }
            return list;
        };

        const currentCache = client.readQuery<AnalyticsStateData>({
            query: GET_LOCAL_ANALYTICS_STATE
        });
        if (currentCache) {
            const newState = JSON.parse(
                JSON.stringify(currentCache.analyticsState)
            );
            switch (reference) {
                case "product":
                    newState.analyticsProducts = updateListSelected(
                        newState.analyticsProducts,
                        product,
                        []
                    );
                    break;
                case "project":
                    newState.analyticsProjects = updateListSelected(
                        newState.analyticsProjects,
                        project,
                        [["product", project?.product]]
                    );
                    break;
                case "revision":
                    newState.analyticsRevisions = updateListSelected(
                        newState.analyticsRevisions,
                        revision,
                        [
                            ["project", revision?.project],
                            ["product", revision?.project?.product]
                        ]
                    );
                    break;
                case "build":
                    newState.analyticsBuilds = updateListSelected(
                        newState.analyticsBuilds,
                        build,
                        [
                            ["revision", build?.revision],
                            ["project", build?.revision?.project],
                            ["product", build?.revision?.project?.product]
                        ]
                    );
                    break;
                case "networkbuild":
                    newState.analyticsNetworkBuilds = updateListSelected(
                        newState.analyticsNetworkBuilds,
                        networkBuild,
                        [
                            ["build", networkBuild?.build],
                            ["revision", networkBuild?.build?.revision],
                            ["project", networkBuild?.build?.revision.project],
                            [
                                "product",
                                networkBuild?.build?.revision?.project.product
                            ]
                        ]
                    );
                    break;
                default:
                    break;
            }
            client.writeQuery<AnalyticsStateData, AnalyticsStateData>({
                query: GET_LOCAL_ANALYTICS_STATE,
                data: {
                    analyticsState: newState
                }
            });
        }
    };

    const getTooltip = () => {
        if (selectedState.manualSelection) {
            if (selectedState.include) {
                return "Remove manual inclusion state";
            } else {
                return "Include in query";
            }
        }
        if (selectedState.include) {
            return "Remove from query";
        } else {
            return "Select for query";
        }
    };

    return (
        <>
            <OverlayTrigger
                placement="top"
                delay={{ show: 200, hide: 100 }}
                overlay={<Tooltip id="tooltip-top">{getTooltip()}</Tooltip>}
            >
                <Button
                    className={`py-0 px-1 ${className || ""} ${
                        styles.toggleButton
                    }`}
                    variant={selectedState.include ? "analytics" : "secondary"}
                    onClick={(event: SyntheticEvent) => {
                        event.stopPropagation();
                        toggleSelection();
                    }}
                >
                    <FontAwesomeIcon
                        icon={
                            selectedState.manualSelection
                                ? selectedState.include
                                    ? faCheck
                                    : faXmark
                                : faFilter
                        }
                        size="xs"
                    />
                </Button>
            </OverlayTrigger>
        </>
    );
};

export const AnalyticsFunnelViewSelectionToggle = React.memo(
    AnalyticsFunnelViewSelectionToggleImplementation
);
