import React, { FC } from "react";
import { Button } from "react-bootstrap";
import { LoadingSpinner } from "../../simple/LoadingSpinner";

interface Props {
    title: string;
}

export const AnalyticsLoadingButton: FC<Props> = ({ title }) => {
    return (
        <Button className="disabled">
            <LoadingSpinner header={title} size="sm" />
        </Button>
    );
};
