import React, { Component } from "react";
import { DSVRowArray } from "d3";
import { loadCsv, pastelStepColor } from "../../common/AnalyticsHelpers";
import { Col, Row } from "react-bootstrap";
import { AnalyticsQueryType, AthenaDatasetResult } from "../../models/types";
import { ChartConfiguration } from "chart.js";
import { ChartJsWrapper } from "./ChartJsWrapper";

interface State {
    csvData: DSVRowArray | undefined;
}

interface Props {
    data: AthenaDatasetResult[];
}

export class TimeLineChart extends Component<Props> {
    state: State = {
        csvData: undefined
    };

    shouldComponentUpdate(_: Readonly<Props>, nextState: Readonly<State>) {
        return this.state.csvData !== nextState.csvData;
    }

    componentDidMount(): void {
        const { data } = this.props;
        const funnelData = data[0].dataset.find(
            element => element.type === AnalyticsQueryType.TimeAverages
        );
        if (funnelData?.success && funnelData.message) {
            loadCsv(funnelData.message)
                .then(result => {
                    this.setState({ csvData: result });
                })
                .catch(error => console.log("[DEBUG] error ", error));
        }
    }

    render() {
        const { csvData } = this.state;
        if (!csvData) {
            return null;
        }
        const labels: string[] = [];
        const averages: number[] = [];
        const colours: string[] = [];
        const runningTotals: number[] = [];

        let currentSum = 0;
        for (let i = 0; i < csvData.length; i++) {
            if (csvData[i]) {
                const eventName = csvData[i].eventName;
                const difference = Number(csvData[i].difference);
                const sequence = Number(csvData[i].sequence);
                if (
                    eventName !== undefined &&
                    difference !== undefined &&
                    sequence !== undefined
                ) {
                    if (!labels.includes(eventName)) {
                        labels.push(eventName);
                        averages.push(difference);
                        const runningTotal = currentSum + difference;
                        runningTotals.push(runningTotal);
                        currentSum = runningTotal;
                    }
                }
            }
        }

        for (let i = 0; i < averages.length; i++) {
            i === 0
                ? runningTotals.push(0)
                : runningTotals.push(averages[i - 1] + averages[i]);
            colours.push(pastelStepColor(i / (labels.length - 1)));
            labels[i] = `${labels[i]}, ${averages[i].toFixed(1)}s`;
        }

        const config: ChartConfiguration = {
            type: "bar",
            data: {
                labels: labels,
                datasets: [
                    {
                        type: "line",
                        label: "Cumulative time (s)",
                        data: runningTotals,
                        backgroundColor: "rgb(15,255,214)"
                    },
                    {
                        type: "bar",
                        label: "Time between steps (s)",
                        data: averages,
                        backgroundColor: colours
                    }
                ]
            },
            options: {
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        display: false
                    }
                }
            }
        };

        return (
            <Row>
                <Col md={12} className="d-flex justify-content-around">
                    <div className="mx-5 d-flex justify-content-center align-items-center">
                        <div className="analytics-circle"></div>
                        <div className="ps-2 text-inverse-50 analytics-font">
                            Cumulative time (s)
                        </div>
                    </div>
                    <div className="d-flex justify-content-center align-items-center">
                        <div className="analytics-square"></div>
                        <div className="ps-2 text-inverse-50 analytics-font">
                            Time between steps (s)
                        </div>
                    </div>
                </Col>
                <Col>
                    <ChartJsWrapper config={config} />
                </Col>
            </Row>
        );
    }
}
