import React, { FC, useState } from "react";
import { useSubscription } from "@apollo/client";
import { Card } from "react-bootstrap";
import { LoadingSpinner } from "./LoadingSpinner";
import { CREATE_PROJECT_SUBSCRIPTION } from "../../graphql/subscriptions";
import { BasicResult } from "../../models/types";

interface Props {
    taskId: string;
    updateResult: (result: BasicResult) => void;
}

const getTitleFromState = (state: string): string => {
    switch (state) {
        case "create":
            return "Create Project Pipeline Created...";
        case "start":
            return "Create Project Pipeline Started...";
        case "gitlab":
            return "Gitlab Project Created...";
        case "bootstrap":
            return "Project Bootstrapped...";
        case "boilerplate":
            return "Project Boilerplate Generated...";
        case "complete":
            return "Project Creation Completed...";
        case "error":
            return "Project Creation Failed...";
        default:
            console.log("[DEBUG] No such state ", state);
            return "";
    }
};

export const CreateProjectResultWaiter: FC<Props> = ({
    taskId,
    updateResult
}) => {
    const [header, updateHeader] = useState("Starting Project Creation...");
    useSubscription(CREATE_PROJECT_SUBSCRIPTION, {
        variables: { taskId: taskId },
        onData: ({ data: { data: { createProjectNotification } = {} } }) => {
            console.log(
                "[DEBUG] createProjectNotification ",
                createProjectNotification
            );
            if (
                createProjectNotification.success !== undefined &&
                createProjectNotification.success !== null
            ) {
                console.log("[DEBUG] updateResult");
                updateResult(createProjectNotification);
                return;
            }
            console.log("[DEBUG] updateHeader");
            const newHeader = getTitleFromState(
                createProjectNotification.state
            );
            if (newHeader) {
                updateHeader(newHeader);
            }
        }
    });

    return (
        <Card className="border-analytics text-inverse p-2">
            <LoadingSpinner header={header} />
        </Card>
    );
};
