import React, { FC, useState } from "react";
import {
    Button,
    Card,
    Container,
    Dropdown,
    DropdownButton,
    FormControl,
    InputGroup,
    Modal
} from "react-bootstrap";
import { useLocation } from "react-router-dom";
import "./Modals.scss";
import {
    CreateProductData,
    Organization,
    OrganizationData,
    ProductsData,
    ProductVariables,
    MeData,
    Product
} from "../../models/types";
import {
    GET_ME,
    GET_ORGANIZATIONS,
    GET_PRODUCTS_BY_USER_ORGANIZATION
} from "../../graphql/queries";
import { useMutation, useQuery, useSubscription } from "@apollo/client";
import { CREATE_PRODUCT } from "../../graphql/mutations";
import { ORGANIZATIONS_SUBSCRIPTION } from "../../graphql/subscriptions";
import { useLocalState } from "../../graphql/hooks";

interface Props {
    isDeveloperMode: boolean;
    show: boolean;
    onClose: (newProduct: Product | undefined) => void;
    defaultOrganization?: Organization;
}

// const adminGroups = ["seepia-admin", "admin"];

export const NewProductModal: FC<Props> = ({
    isDeveloperMode,
    show,
    onClose,
    defaultOrganization
}) => {
    const location = useLocation();
    const { useDarkMode } = useLocalState();
    const { data: { me } = {} } = useQuery<MeData>(GET_ME, {
        fetchPolicy: "cache-only"
    });
    const { data: { organizations } = {} } = useQuery<OrganizationData>(
        GET_ORGANIZATIONS,
        {
            fetchPolicy: "network-only"
        }
    );
    const [organization, setCurrentOrganization] = useState(
        defaultOrganization ? defaultOrganization : me?.organization
    );
    const [info, updateInfo] = useState<string>("");
    const [product, setProduct] = useState<ProductVariables>({
        name: ""
    });
    const [createProduct] = useMutation<CreateProductData, ProductVariables>(
        CREATE_PRODUCT
    );

    useSubscription(ORGANIZATIONS_SUBSCRIPTION, {
        onData: ({
            data: { data: { organizationNotification } = {} },
            client
        }) => {
            const currentOrganizationData = client.cache.readQuery<{
                organizations: Organization[];
            }>({
                query: GET_ORGANIZATIONS
            });

            const currentOrganizations = currentOrganizationData?.organizations;

            if (currentOrganizations && organizationNotification) {
                console.log(
                    "[DEBUG] organizationNotification ",
                    organizationNotification
                );
                const newOrganizationList = currentOrganizations.filter(
                    element => element.id !== organizationNotification.id
                );
                newOrganizationList.push(organizationNotification);
                client.cache.writeQuery<{
                    organizations: Organization[];
                }>({
                    query: GET_ORGANIZATIONS,
                    data: {
                        organizations: newOrganizationList
                    }
                });
            }
        }
    });

    // const isAdmin =
    //     isDeveloperMode &&
    //     me?.groupList.some(group => adminGroups.includes(group));
    const isAdmin = isDeveloperMode;

    const createNewProduct = async () => {
        if (organization) {
            product.organizationId = Number(organization.id);
        } else {
            console.log("[DEBUG] No organization selected!");
            return;
        }

        try {
            let newProduct = undefined;
            await createProduct({
                variables: product,
                update: (cache, { data }) => {
                    const currentProductsData = cache.readQuery<ProductsData>({
                        query: GET_PRODUCTS_BY_USER_ORGANIZATION
                    });
                    const currentProducts = currentProductsData?.products;
                    if (currentProducts && data) {
                        newProduct = data.createProduct;
                        const newProducts = [newProduct, ...currentProducts];
                        cache.writeQuery<ProductsData>({
                            query: GET_PRODUCTS_BY_USER_ORGANIZATION,
                            data: { products: newProducts }
                        });
                    }
                }
            });
            onClose(newProduct);
        } catch (error) {
            console.log("[DEBUG] createNewProduct error ", error);
            // @ts-ignore
            updateInfo(error.message);
            setTimeout(() => {
                updateInfo("");
            }, 4000);
        }
    };

    const getOrganizationById = (id: number) => {
        if (organizations) {
            for (let i = 0; i < organizations?.length; i++) {
                if (Number(organizations[i].id) === id) {
                    return organizations[i];
                }
            }
        }
        return undefined;
    };

    const generateAlternatives = organizations?.map(
        (organization: Organization, index: number) => (
            <Dropdown.Item key={index} eventKey={organization.id.toString()}>
                {organization.name}
            </Dropdown.Item>
        )
    );

    return (
        <Modal
            show={show}
            onHide={() => onClose(undefined)}
            backdrop="static"
            keyboard={false}
            className={useDarkMode ? "modal-dark" : ""}
        >
            <Modal.Header closeButton>
                <Modal.Title>Add New Product</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container className="d-flex justify-content-center align-items-center">
                    <span className="me-2">Add to Organization: </span>
                    {isAdmin && location.pathname !== "/createNewProject" ? (
                        <DropdownButton
                            title={organization ? organization.name : "Select"}
                            variant="primary"
                            onSelect={(selectedItem: string | null) => {
                                if (selectedItem) {
                                    setCurrentOrganization(
                                        getOrganizationById(
                                            Number(selectedItem)
                                        )
                                    );
                                }
                            }}
                        >
                            {generateAlternatives}
                        </DropdownButton>
                    ) : (
                        <span className="font-weight-bold">
                            {organization?.name}
                        </span>
                    )}
                </Container>
                <Card className="m-2 p-2 no-border">
                    <InputGroup className="mb-3">
                        <InputGroup.Text>Name</InputGroup.Text>
                        <FormControl
                            placeholder="Product name required"
                            onChange={(event: any) => {
                                setProduct({
                                    ...product,
                                    name: event.target.value
                                });
                            }}
                        />
                    </InputGroup>
                    <div className="align-content-center text-center pt-2">
                        <Button
                            onClick={createNewProduct}
                            disabled={!product.name?.trim()}
                            className="w-75"
                        >
                            Create
                        </Button>
                    </div>
                </Card>
            </Modal.Body>
            {info ? (
                <Modal.Footer>
                    <Container className="text-center text-danger">
                        {info}
                    </Container>
                </Modal.Footer>
            ) : null}
        </Modal>
    );
};
