import React, { FC } from "react";
import { Button, Card, Modal } from "react-bootstrap";
import { useQuery } from "@apollo/client";
import {
    BatchBuild,
    BatchBuildData,
    BatchBuildVariables
} from "../../models/types";
import { GET_RESULT } from "../../graphql/queries";
import { Link } from "react-router-dom";
import { SimpleDate } from "../simple/SimpleDate";
import { useLocalState } from "../../graphql/hooks";

interface Props {
    showModal: boolean;
    batchBuild: BatchBuild;
    hideModal: () => void;
}

export const QueryDetailsBuildModal: FC<Props> = ({
    showModal,
    batchBuild,
    hideModal
}) => {
    const { useDarkMode } = useLocalState();
    const { data: { build } = {} } = useQuery<
        BatchBuildData,
        BatchBuildVariables
    >(GET_RESULT, {
        variables: {
            id: Number(batchBuild.id)
        }
    });

    const url = `/products/${build?.revision.project.product?.id}/projects/${build?.revision.project.id}/revisions/${build?.revision.id}/history/${build?.id}/analyticsSchema`;

    return (
        <Modal
            size="xl"
            show={showModal}
            onHide={hideModal}
            backdrop="static"
            className={useDarkMode ? "modal-dark" : ""}
        >
            <Modal.Header closeButton>
                <h5>
                    Build {batchBuild?.id} of {build?.revision.name}
                </h5>
            </Modal.Header>
            <Modal.Body>
                <Card.Body className="p-2">
                    {build && build.analyticsSchema ? (
                        <div className="bread-text px-2">
                            <h6>Schema description</h6>{" "}
                            {build.analyticsSchema?.description ||
                                "No description for schema found"}
                        </div>
                    ) : null}
                    <div className="float-start me-auto p-2 bread-text">
                        Build created:{" "}
                        <SimpleDate dateUpdated={batchBuild.dateCreated} />
                    </div>
                </Card.Body>
            </Modal.Body>
            <Modal.Footer className="justify-content-center">
                <Link className="me-2" to={url}>
                    <Button
                        title="Go to Schema editor - this does not reset funnel selection"
                        variant="primary"
                    >
                        Go to Schema Editor
                    </Button>
                </Link>
                <Button variant="secondary" onClick={hideModal}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
