import React, { FC } from "react";
import { Card, Col } from "react-bootstrap";
import {
    CreateProjectConfig,
    NewProjectConfig,
    TechStackConfig
} from "../../models/types";
import { SelectableTechStack } from "../selectable/SelectableTechStack";
import { TechStackType } from "../../models/common";

interface Props {
    project: CreateProjectConfig;
    getCreateProjectConfig: NewProjectConfig;
    setProject: (project: CreateProjectConfig) => void;
}

export const CreateProjectTechStack: FC<Props> = ({
    project,
    getCreateProjectConfig,
    setProject
}) => {
    const { techStack } = getCreateProjectConfig;
    if (!techStack) {
        return null;
    }

    const techStackConfig: TechStackConfig = JSON.parse(
        JSON.stringify(techStack)
    );
    let currentTechStack: TechStackConfig;
    if (!project.techStack) {
        currentTechStack = JSON.parse(JSON.stringify(techStack));
        currentTechStack.frameworks.selection = [];
        currentTechStack.sounds.selection = [];
        currentTechStack.particles.selection = [];
        currentTechStack.physics.selection = [];
        currentTechStack.animations.selection = [];
    } else {
        currentTechStack = { ...project.techStack };
    }
    const updateProject = (type: TechStackType, selection: string[]) => {
        const newTechStack = { ...currentTechStack };
        switch (type) {
            case TechStackType.Frameworks:
                newTechStack.frameworks = {
                    ...newTechStack.frameworks,
                    selection: techStackConfig.frameworks.selection.filter(
                        element => selection.includes(element.name)
                    )
                };
                break;
            case TechStackType.Sounds:
                newTechStack.sounds = {
                    ...newTechStack.sounds,
                    selection: techStackConfig.sounds.selection.filter(
                        element => selection.includes(element.name)
                    )
                };
                break;
            case TechStackType.Particles:
                newTechStack.particles = {
                    ...newTechStack.particles,
                    selection: techStackConfig.particles.selection.filter(
                        element => selection.includes(element.name)
                    )
                };
                break;
            case TechStackType.Physics:
                newTechStack.physics = {
                    ...newTechStack.physics,
                    selection: techStackConfig.physics.selection.filter(
                        element => selection.includes(element.name)
                    )
                };
                break;
            case TechStackType.Animations:
                newTechStack.animations = {
                    ...newTechStack.animations,
                    selection: techStackConfig.animations.selection.filter(
                        element => selection.includes(element.name)
                    )
                };
                break;
            case TechStackType.Networks:
                newTechStack.networks = {
                    ...newTechStack.networks,
                    selection: techStackConfig.networks.selection.filter(
                        element => selection.includes(element.name)
                    )
                };
                break;
            default:
                console.log("[DEBUG] Type not implemented yet ", type);
                break;
        }
        const newProject: CreateProjectConfig = {
            ...project,
            techStack: newTechStack
        };
        setProject(newProject);
    };

    const modulesNames = project.gitModules.map(element => {
        // const split = element.split("/");
        // return split[split.length - 1].replace(".git", "");
        return element.path;
    });
    return (
        <Card className="border-primary p-2 mt-2 text-inverse">
            <Col md={12} className="m-0 ps-2 mb-2 text-inverse">
                <h5>Tech Stack</h5>
            </Col>
            <h6>Framework(s)</h6>
            <SelectableTechStack
                type={TechStackType.Frameworks}
                modules={modulesNames}
                baseTechStackConfig={techStackConfig}
                currentTechStackConfig={currentTechStack}
                updateProject={updateProject}
            />
            <h6>Sound</h6>
            <SelectableTechStack
                type={TechStackType.Sounds}
                modules={modulesNames}
                baseTechStackConfig={techStackConfig}
                currentTechStackConfig={currentTechStack}
                updateProject={updateProject}
            />
            <h6>Particles</h6>
            <SelectableTechStack
                type={TechStackType.Particles}
                modules={modulesNames}
                baseTechStackConfig={techStackConfig}
                currentTechStackConfig={currentTechStack}
                updateProject={updateProject}
            />
            <h6>Physics</h6>
            <SelectableTechStack
                type={TechStackType.Physics}
                modules={modulesNames}
                baseTechStackConfig={techStackConfig}
                currentTechStackConfig={currentTechStack}
                updateProject={updateProject}
            />
            <h6>Animations</h6>
            <SelectableTechStack
                type={TechStackType.Animations}
                modules={modulesNames}
                baseTechStackConfig={techStackConfig}
                currentTechStackConfig={currentTechStack}
                updateProject={updateProject}
            />
            <h6>Networks</h6>
            <SelectableTechStack
                type={TechStackType.Networks}
                modules={modulesNames}
                baseTechStackConfig={techStackConfig}
                currentTechStackConfig={currentTechStack}
                updateProject={updateProject}
            />
        </Card>
    );
};
