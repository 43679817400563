import React, { FC } from "react";
import { DailyBuildAnalytics } from "../../../models/types";
import { useLocalAnalyticsState } from "../../../graphql/hooks";
import { formatDateRange } from "../../../common/AnalyticsHelpers";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

interface Props {
    userQueryBuildAnalytic: DailyBuildAnalytics;
    big?: boolean;
}

const UserQueryBadgesNetworkBuildImplementation: FC<Props> = ({
    userQueryBuildAnalytic,
    big = false
}) => {
    const { analyticsTimestamps } = useLocalAnalyticsState();
    const formattedDateRange = analyticsTimestamps
        ? formatDateRange(
              analyticsTimestamps.startTimestamp,
              analyticsTimestamps.endTimestamp
          )
        : "";

    if (!userQueryBuildAnalytic || userQueryBuildAnalytic.impressions <= 0) {
        return null;
    }
    const gameplay = userQueryBuildAnalytic.gameplay || 0;
    const endScreen = userQueryBuildAnalytic.endScreen || 0;
    const ctr = (
        ((gameplay + endScreen) / userQueryBuildAnalytic.impressions) *
        100
    ).toFixed(1);

    return (
        <>
            <div className="d-flex analytics-font float-end px-2">
                <OverlayTrigger
                    overlay={
                        <Tooltip id="network-impressions-tooltip">
                            {`Impressions - ${formattedDateRange}`}
                        </Tooltip>
                    }
                    delay={{
                        show: 100,
                        hide: 200
                    }}
                >
                    <div className="me-2">
                        {userQueryBuildAnalytic.impressions.toLocaleString()}
                    </div>
                </OverlayTrigger>
                <div className="text-secondary">|</div>
                <OverlayTrigger
                    overlay={
                        <Tooltip id="network-ctr-tooltip">
                            {`CTR - ${formattedDateRange}`}
                        </Tooltip>
                    }
                    delay={{
                        show: 100,
                        hide: 200
                    }}
                >
                    <div style={{ whiteSpace: "nowrap" }} className="ms-2">
                        {ctr} %
                    </div>
                </OverlayTrigger>
            </div>
        </>
    );
};

export const UserQueryBadgesNetworkBuild = React.memo(
    UserQueryBadgesNetworkBuildImplementation
);
