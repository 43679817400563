import React, { FC } from "react";
import {
    FileUploadsVariables,
    Product,
    FileUploads,
    CreateFileUploadsData
} from "../../models/types";
import { Col, Container, ListGroup, Row } from "react-bootstrap";
import { ImageItem } from "./ImageItem";
import { GET_FILE_UPLOADS } from "../../graphql/queries";
import { useMutation, useQuery } from "@apollo/client";
import { REMOVE_FILE_UPLOADS } from "../../graphql/mutations";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props {
    product: Product;
}

export const FileUploadsItem: FC<Props> = ({ product }) => {
    const { loading, data, error } = useQuery<FileUploads>(GET_FILE_UPLOADS, {
        variables: { productId: product.id }
    });

    const [removeFileUploads] = useMutation<
        CreateFileUploadsData,
        FileUploadsVariables
    >(REMOVE_FILE_UPLOADS);

    if (loading || !data || !data.fileUploads) {
        return (
            <Container className="fullSize p-2 pt-5 d-flex justify-content-center text-inverse">
                <p>Loading...</p>
            </Container>
        );
    }
    if (error) {
        return (
            <Container className="fullSize p-2 pt-5 d-flex justify-content-center text-inverse">
                <p>Error: {error.message}</p>
            </Container>
        );
    }

    const removeFileUpload = async (id: number) => {
        try {
            await removeFileUploads({
                variables: { id: id },
                update: (cache, { data }) => {
                    const currentFileUploads = cache.readQuery<FileUploads>({
                        query: GET_FILE_UPLOADS,
                        variables: { productId: product.id }
                    });
                    if (currentFileUploads && data) {
                        const newFileUploads =
                            currentFileUploads.fileUploads.filter(
                                file => file.id !== id
                            );
                        cache.writeQuery<FileUploads>({
                            query: GET_FILE_UPLOADS,
                            variables: { productId: product.id },
                            data: { fileUploads: newFileUploads }
                        });
                    }
                }
            });
        } catch (error) {
            console.log("[DEBUG] removeFileUpload error ", error);
        }
    };

    const fileItems = data.fileUploads.map(({ id, name, key }) => {
        if (name && key) {
            return (
                <ListGroup.Item
                    className="py-2 border-secondary primary-background"
                    key={id}
                >
                    <Row
                        key={id}
                        className="align-items-center text-inverse image-fullsize"
                    >
                        <Col md={1} className="text-center">
                            #{id}
                        </Col>
                        <Col md={7}>{key}</Col>
                        <Col md={2} className="p-1 mt-2 mt-md-0">
                            <ImageItem productId={product.id} imageKey={key} />
                        </Col>
                        <Col md={1}></Col>
                        <Col md={1}>
                            <FontAwesomeIcon
                                onClick={(event: any) => {
                                    event.stopPropagation();
                                    removeFileUpload(id);
                                }}
                                className="text-center p-3 mouseHover"
                                icon={faXmark}
                                color="white"
                                size="lg"
                                title="Delete"
                            />
                        </Col>
                    </Row>
                </ListGroup.Item>
            );
        }
        return null;
    });

    return <Container className="m-0 p-0">{fileItems}</Container>;
};
