import React, { FC, ReactElement, useEffect, useState } from "react";
import { loadCsv } from "../../common/AnalyticsHelpers";
import { AnalyticsQueryType, AthenaDatasetResult } from "../../models/types";
import { DSVRowArray } from "d3";
import { Card, Col, Row, Spinner } from "react-bootstrap";
import { ScatterChart } from "./ScatterChart";
import { useLocalState } from "../../graphql/hooks";
import { OverlayBadge } from "../simple/OverlayBadge";

interface Props {
    data: AthenaDatasetResult[];
}

const BothHeatmapsImplementation: FC<Props> = ({ data }) => {
    const { useDarkMode } = useLocalState();
    const [isLoading, setIsLoading] = useState(false);
    const [heatmapPortraitCsvData, updateHeatmapPortraitCsvData] = useState<
        DSVRowArray[]
    >([]);
    const [heatmapLandscapeCsvData, updateHeatmapLandscapeCsvData] = useState<
        DSVRowArray[]
    >([]);

    useEffect(() => {
        setIsLoading(true);
        const fetchCsvData = async () => {
            const csvDatasPortrait: DSVRowArray[] = [];
            const csvDatasLandscape: DSVRowArray[] = [];
            for (let i = 0; i < data.length; i++) {
                const element = data[i];

                for (let j = 0; j < element.dataset.length; j++) {
                    const dataset = element.dataset[j];
                    if (dataset.success && dataset.message) {
                        if (
                            dataset.type === AnalyticsQueryType.HeatmapPortrait
                        ) {
                            csvDatasPortrait.push(
                                await loadCsv(dataset.message)
                            );
                        } else if (
                            dataset.type === AnalyticsQueryType.HeatmapLandscape
                        ) {
                            csvDatasLandscape.push(
                                await loadCsv(dataset.message)
                            );
                        }
                    }
                }
            }
            updateHeatmapPortraitCsvData(csvDatasPortrait);
            updateHeatmapLandscapeCsvData(csvDatasLandscape);
            setIsLoading(false);
        };
        fetchCsvData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (isLoading) {
        return (
            <div className="text-center my-4 text-inverse-50">
                <span className="me-2">Loading Heatmaps...</span>
                <Spinner animation="border" role="status"></Spinner>
            </div>
        );
    }

    if (
        heatmapPortraitCsvData.length === 0 ||
        heatmapLandscapeCsvData.length === 0
    ) {
        return null;
    }

    const getChartPortrait = (index: number) => {
        return (
            <ScatterChart
                key={"portrait" + index}
                data={heatmapPortraitCsvData[index]}
                darkMode={useDarkMode}
                showLegend={true}
                aspectRatio={0.75}
                title="Portrait"
            />
        );
    };

    const getChartLandscape = (index: number) => {
        return (
            <ScatterChart
                key={"landscape" + index}
                data={heatmapLandscapeCsvData[index]}
                darkMode={useDarkMode}
                showLegend={true}
                aspectRatio={1.6}
                title="Landscape"
            />
        );
    };

    const elements: ReactElement[] = [];
    const showName = data.length > 1;

    for (let i = 0; i < data.length; i++) {
        elements.push(
            <Card key={i} className="text-inverse mt-4">
                <Card.Header>
                    {showName ? data[i].datasetName + " " : ""}
                    Heatmaps
                    <OverlayBadge
                        badgeHeader="?"
                        badgeVariant={useDarkMode ? "dark" : "light"}
                        className="float-end"
                        overlayText="Heatmaps currently show data points only from ctaBtnPressed event"
                    ></OverlayBadge>
                </Card.Header>
                <Card.Body>
                    <Row className="m-0 p-0">
                        <Col md={5} className="m-0 p-0">
                            {getChartPortrait(i)}
                        </Col>
                        <Col
                            md={7}
                            className="m-0 p-0 d-flex justify-content-center align-items-center"
                        >
                            {getChartLandscape(i)}
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        );
    }

    return <>{elements}</>;
};

export const BothHeatmaps = React.memo(BothHeatmapsImplementation);
