import React from "react";
import { Card, Container } from "react-bootstrap";
import { useQuery } from "@apollo/client";
import { GET_REVISION, GET_REVISION_HISTORY } from "../../graphql/queries";
import {
    RevisionData,
    RevisionHistoryData,
    RevisionHistoryItem,
    RevisionVariables
} from "../../models/types";
import { useParams } from "react-router-dom";
import { BackButton } from "../simple/BackButton";
import { RevisionHistoryEventItem } from "../simple/RevisionHistoryEventItem";
import "../pages/pages.scss";
import { useLocalState } from "../../graphql/hooks";

export const RevisionHistory = () => {
    const state = useLocalState();
    const { revisionId } = useParams<{ revisionId: string }>();
    const { data } = useQuery<RevisionHistoryData, RevisionVariables>(
        GET_REVISION_HISTORY,
        {
            variables: { revisionId: Number(revisionId) },
            fetchPolicy: "network-only"
        }
    );
    const { data: { revision } = {} } = useQuery<
        RevisionData,
        RevisionVariables
    >(GET_REVISION, { variables: { revisionId: Number(revisionId) } });

    if (!data || !revision) {
        return null;
    }

    const items = data.revisionEvents?.map((event: RevisionHistoryItem) => (
        <RevisionHistoryEventItem
            key={event.id}
            event={event}
            developerMode={state.developerMode}
            revisionName={revision.name}
        />
    ));

    return (
        <Container className="fullSize p-2">
            <Container title="Back to Products" className="p-0 pt-2 m-0 ms-1">
                <BackButton target={"/products"} size={"lg"} />
            </Container>
            <Card className="text-inverse mt-3">
                <Card.Header>
                    <h4>Event history of {revision.name}</h4>
                </Card.Header>
                <Card.Body>
                    {items && items.length > 0 ? (
                        <>{items}</>
                    ) : (
                        <h5 className="text-center text-inverse-50 m-4">
                            No revision events
                        </h5>
                    )}
                </Card.Body>
            </Card>
        </Container>
    );
};
