import React, { FC } from "react";
import { AnalyticsLoadingButtonWithProgress } from "./AnalyticsLoadingButtonWithProgress";
import { AnalyticsLoadingButton } from "./AnalyticsLoadingButton";
import { AnalyticsQueryButton } from "./AnalyticsQueryButton";
import { useLocalAnalyticsState } from "../../../graphql/hooks";
import { AnalyticsQueryType } from "../../../models/types";

interface Props {
    taskQuery: () => void;
    taskLoading: boolean;
}

export const AnalyticsQueryButtonWrapper: FC<Props> = ({
    taskQuery,
    taskLoading
}) => {
    const { analyticsTask } = useLocalAnalyticsState();
    if (analyticsTask !== undefined) {
        if (analyticsTask.type === AnalyticsQueryType.Funnel) {
            return <AnalyticsLoadingButtonWithProgress />;
        } else {
            return <AnalyticsLoadingButton title="Querying..." />;
        }
    }
    return (
        <>
            {taskLoading ? (
                <AnalyticsLoadingButton title="Starting..." />
            ) : (
                <AnalyticsQueryButton onQuery={taskQuery} />
            )}
        </>
    );
};
