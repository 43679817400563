import React, { FC, useEffect, useState } from "react";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, Collapse, FormControl, InputGroup } from "react-bootstrap";

interface Props {
    updateCurrentSchema: (description: string) => void;
    defaultValue: string | undefined;
}

export const SchemaDescription: FC<Props> = ({
    updateCurrentSchema,
    defaultValue
}) => {
    const [showDescription, updateShowDescription] = useState(true);
    const [description, setDescription] = useState(defaultValue || "");

    useEffect(() => {
        setDescription(defaultValue || "");
    }, [defaultValue]);

    return (
        <>
            <Card className="inner-card mx-2 mt-3 mb-2">
                <Card.Body className="no-border text-inverse-50 px-3 py-2">
                    <div
                        className="d-flex align-items-center mouseHover"
                        onClick={() => updateShowDescription(!showDescription)}
                    >
                        <h6>Schema Description</h6>
                        <FontAwesomeIcon
                            className="mb-1 ms-2"
                            size="xs"
                            icon={showDescription ? faChevronUp : faChevronDown}
                        />
                    </div>
                </Card.Body>
                <Collapse in={showDescription}>
                    <div>
                        <Card.Body className="no-border pt-0 px-3">
                            <InputGroup>
                                <FormControl
                                    as="textarea"
                                    rows={2}
                                    placeholder="Describe the purpose of this schema..."
                                    value={description}
                                    onChange={(
                                        event: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                        const description = event.target.value;
                                        setDescription(description);
                                    }}
                                    onBlur={() => {
                                        updateCurrentSchema(description);
                                    }}
                                />
                            </InputGroup>
                        </Card.Body>
                    </div>
                </Collapse>
            </Card>
        </>
    );
};
