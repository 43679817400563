import React, { FC, useState } from "react";
import { AnalyticsQueryType, PlatformConfigData } from "../../../models/types";
import { Card, Collapse } from "react-bootstrap";
import { QueryHeader } from "../../headers/QueryHeader";
import { QueryOptionsWrapper } from "./QueryOptionsWrapper";
import { GET_PLATFORM_CONFIG } from "../../../graphql/queries";
import { useQuery } from "@apollo/client";

interface Props {
    queryType: AnalyticsQueryType;
}

export const QueryControllerViewAdvanced: FC<Props> = ({ queryType }) => {
    const [showQuerySettings, updateShowQuerySettings] = useState(true);
    const { data: { getPlatformConfig } = {} } =
        useQuery<PlatformConfigData>(GET_PLATFORM_CONFIG);

    if (!getPlatformConfig) {
        return null;
    }

    return (
        <>
            <Card className="w-100 mt-3 mb-1 text-inverse align-text-center">
                <QueryHeader
                    showQuerySettings={showQuerySettings}
                    updateShowQuerySettings={updateShowQuerySettings}
                />
                <Collapse in={showQuerySettings}>
                    <div>
                        <QueryOptionsWrapper
                            queryType={queryType}
                            apiVersion={getPlatformConfig.analyticsApiVersion}
                        />
                    </div>
                </Collapse>
            </Card>
        </>
    );
};
