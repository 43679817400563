import React, { FC, useEffect, useState } from "react";
import { BatchBuildVariables, DownloadLinkData } from "../../models/types";
import { Button } from "react-bootstrap";
import { useApolloClient, useMutation } from "@apollo/client";
import { LoadingSpinner } from "../simple/LoadingSpinner";
import { GET_DOWNLOAD_ZIP } from "../../graphql/mutations";
import { GET_RESULT } from "../../graphql/queries";
import { updateNotificationState } from "../../common/Helpers";

interface Props {
    buildId: number;
    zip: string | undefined;
    isLargeBuild: boolean;
}

export const ZipDownloadButton: FC<Props> = ({
    buildId,
    zip,
    isLargeBuild
}) => {
    const client = useApolloClient();
    const [isGenerating, updateGenerating] = useState(false);
    const [generateDownloadZip] = useMutation<
        DownloadLinkData,
        BatchBuildVariables
    >(GET_DOWNLOAD_ZIP, {
        fetchPolicy: "network-only"
    });

    useEffect(() => {
        if (isGenerating && zip !== "loading") {
            updateGenerating(false);
        }
    }, [isGenerating, zip]);

    const showToastLargeZipGenerationStarted = () => {
        updateNotificationState(client, false, true, {
            success: true,
            header: "Zip Generation Started",
            message:
                "A large number of builds detected. Zip generation might take a few minutes.",
            delay: 5000
        });
    };

    const downloadZip = async () => {
        try {
            if (!zip && isLargeBuild) {
                showToastLargeZipGenerationStarted();
            }
            updateGenerating(true);
            const { data } = await generateDownloadZip({
                variables: {
                    id: buildId
                },
                refetchQueries: [
                    {
                        query: GET_RESULT,
                        variables: { id: buildId }
                    }
                ]
            });
            if (data?.generateDownloadLink?.link !== "loading") {
                updateGenerating(false);
                const win = window.open(
                    data?.generateDownloadLink?.link,
                    "_blank"
                );
                if (win !== null) {
                    win.focus();
                }
            }
        } catch (error) {
            console.log("[DEBUG] downloadZip error ", error);
        }
    };

    const loading = zip && zip === "loading";

    return (
        <Button
            onClick={event => {
                event.stopPropagation();
                if (isGenerating || loading) {
                    return;
                }
                downloadZip();
            }}
        >
            {isGenerating || loading ? (
                <LoadingSpinner header="Generating zip" size="sm" />
            ) : !zip ? (
                "Generate Zip"
            ) : (
                "Download All"
            )}
        </Button>
    );
};
