import React, { FC } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useLocalAnalyticsState } from "../../../graphql/hooks";
import { formatDateRange } from "../../../common/AnalyticsHelpers";
import styles from "./DailyBadges.module.scss";

interface Props {
    impressions?: number;
}

const UserQueryImpressionsImplementation: FC<Props> = ({ impressions }) => {
    const { analyticsEnabled, analyticsTimestamps } = useLocalAnalyticsState();

    const formattedDateRange = analyticsTimestamps
        ? formatDateRange(
              analyticsTimestamps.startTimestamp,
              analyticsTimestamps.endTimestamp
          )
        : "";

    if (!analyticsEnabled || !impressions) {
        return null;
    }

    return (
        <>
            <div className={`${styles.analyticsText} text-inverse`}>
                <OverlayTrigger
                    placement="top"
                    delay={{ show: 200, hide: 100 }}
                    overlay={
                        <Tooltip id="user-badge-tooltip-impressions">
                            {`Impressions - ${formattedDateRange}`}
                        </Tooltip>
                    }
                >
                    <span>{impressions.toLocaleString()} </span>
                </OverlayTrigger>
            </div>
        </>
    );
};

export const UserQueryImpressions = React.memo(
    UserQueryImpressionsImplementation
);
