import React, { FC, useEffect, useState } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";

interface Props {
    header: string;
    eventNames: string[];
    updateSelection: (eventName: string) => void;
    id?: string;
}

export const AnalyticsEventNameDropdown: FC<Props> = ({
    header,
    eventNames,
    updateSelection,
    id
}) => {
    const [selected, setSelected] = useState("");

    useEffect(() => {
        setSelected(header);
    }, [header]);

    const generateAlternatives = eventNames.map(name => (
        <Dropdown.Item key={name} eventKey={name} className="text-inverse">
            {name}
        </Dropdown.Item>
    ));

    return (
        <>
            <DropdownButton
                id="timeselect-dropdown"
                title={selected}
                onTouchStart={(event: React.TouchEvent) =>
                    event.stopPropagation()
                }
                onSelect={(eventKey: string | null) => {
                    if (eventKey) {
                        setSelected(eventKey);
                        updateSelection(eventKey);
                    }
                }}
            >
                {generateAlternatives}
            </DropdownButton>
        </>
    );
};
