import {
    faLock,
    faArrowsUpDown,
    faChevronDown,
    faChevronUp,
    faXmark
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC, useEffect, useState, SyntheticEvent } from "react";
import {
    Button,
    Card,
    Col,
    Collapse,
    FormControl,
    InputGroup,
    Row
} from "react-bootstrap";
import {
    AnalyticsEventDataSchema,
    AnalyticsEventSchema
} from "../../../models/types";
import { AnalyticsEventDataItem } from "./AnalyticsEventDataItem";
import cx from "classnames";

interface Props {
    index: number;
    hasDuplicatesEvents: boolean;
    isEqualToLib: boolean;
    defaultValue: AnalyticsEventSchema;
    updateAnalyticsSchema: (
        index: number,
        event?: AnalyticsEventSchema
    ) => void;
    saveToLibrary: (eventData: AnalyticsEventSchema) => void;
    dragStart: () => void;
    dragEnter: () => void;
    dragEnd: () => void;
    isDragged: boolean;
}

export const AnalyticsEventItemImplementation: FC<Props> = ({
    index,
    hasDuplicatesEvents,
    isEqualToLib,
    defaultValue,
    saveToLibrary,
    updateAnalyticsSchema,
    dragStart,
    dragEnter,
    dragEnd,
    isDragged
}) => {
    const [event, updateEvent] = useState(defaultValue);
    const [onEditing, updateOnEditing] = useState(false);
    const [draggable, setDraggable] = useState(false);
    const [showEventData, updateShowEventData] = useState(false);
    const updateSchema = () => {
        if (JSON.stringify(event) !== JSON.stringify(defaultValue)) {
            updateAnalyticsSchema(index, event);
        }
    };
    const remove = () => {
        updateAnalyticsSchema(index, undefined);
    };
    const [isDragging, setIsDragging] = useState(false);

    useEffect(() => {
        if (JSON.stringify(event) !== JSON.stringify(defaultValue)) {
            updateEvent(defaultValue);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultValue]);

    const updateAnalyticsDataSchema = (
        dataIndex: number,
        eventData?: AnalyticsEventDataSchema | undefined
    ) => {
        if (eventData) {
            const newEvents = [...event.eventData];
            newEvents[dataIndex] = eventData;
            updateAnalyticsSchema(index, { ...event, eventData: newEvents });
        } else {
            const newEvents = [
                ...event.eventData.slice(0, dataIndex),
                ...event.eventData.slice(dataIndex + 1)
            ];
            updateAnalyticsSchema(index, { ...event, eventData: newEvents });
        }
    };

    const addNewEventData = () => {
        const newEvents = [
            ...event.eventData,
            {
                key: "new key...",
                values: [1, 2]
            }
        ];
        updateAnalyticsSchema(index, { ...event, eventData: newEvents });
    };

    const findDuplicatesEventData = (eventData: AnalyticsEventDataSchema) => {
        if (!event.eventData) return false;
        const duplicates = event.eventData.filter(element => {
            return element.key === eventData.key;
        });
        return duplicates.length > 1;
    };

    const eventData = event.eventData?.map(
        (eventData: AnalyticsEventDataSchema, index: number) => (
            <AnalyticsEventDataItem
                frozen={event.frozen}
                index={index}
                hasDuplicatesKey={findDuplicatesEventData(eventData)}
                key={index}
                defaultValue={eventData}
                updateAnalyticsDataSchema={updateAnalyticsDataSchema}
            />
        )
    );

    useEffect(() => {
        const handleMouseUp = () => {
            if (isDragging) {
                setIsDragging(false);
                setDraggable(false);
                dragEnd();
            }
        };
        window.addEventListener("mouseup", handleMouseUp);
        return () => {
            window.removeEventListener("mouseup", handleMouseUp);
        };
    }, [isDragging, dragEnd]);

    const getSaveButtonTitle = () => {
        if (hasDuplicatesEvents) {
            return "Cannot save: Duplicate events";
        } else if (onEditing) {
            return "Cannot save: Currently editing";
        } else if (isEqualToLib) {
            return "Cannot save: Event already exists in the library";
        }
        return "";
    };

    return (
        <Card
            className={cx(
                "m-2 inner-card",
                { "ms-3 dragging-border": !event.frozen && isDragged },
                { "border-danger": hasDuplicatesEvents }
            )}
            draggable={!event.frozen && draggable}
            onDragStart={(event: any) => {
                const ghostElement = document.createElement("div");
                event.dataTransfer.setDragImage(ghostElement, 0, 0);
                updateShowEventData(false);
                dragStart();
                setIsDragging(true);
            }}
            onDragEnter={() => {
                updateShowEventData(false);
                dragEnter();
            }}
            onDrop={() => {
                setIsDragging(false);
                dragEnd();
                const ghostElement = document.querySelector(".ghostElement");
                if (ghostElement) {
                    ghostElement.remove();
                }
            }}
            onDragOver={(e: SyntheticEvent) => e.preventDefault()}
        >
            <Card.Header
                className="no-border"
                onMouseDown={(event: SyntheticEvent) => {
                    setDraggable(true);
                }}
                onMouseUp={(event: SyntheticEvent) => setDraggable(false)}
            >
                <Row className="d-flex align-items-center g-2">
                    <Col className="d-flex align-items-center col-10">
                        {!event.frozen ? (
                            <FontAwesomeIcon
                                title="Drag and drop to re-order"
                                icon={faArrowsUpDown}
                                size="lg"
                                className={
                                    isDragged
                                        ? "text-primary me-3"
                                        : "text-inverse-50 me-3  mouse-grab"
                                }
                            />
                        ) : (
                            <FontAwesomeIcon
                                title="Position locked"
                                icon={faLock}
                                className="text-inverse-50 me-3 cursor-pointer"
                            />
                        )}
                        <InputGroup>
                            <InputGroup.Text
                                className={
                                    hasDuplicatesEvents ? "text-danger" : ""
                                }
                                title={
                                    hasDuplicatesEvents
                                        ? "Duplicate Event Name!"
                                        : ""
                                }
                            >
                                Event Name
                            </InputGroup.Text>
                            <FormControl
                                title={
                                    hasDuplicatesEvents
                                        ? "Duplicate Event Name!"
                                        : ""
                                }
                                className={
                                    hasDuplicatesEvents ? "text-danger" : ""
                                }
                                value={event.eventName}
                                disabled={event.frozen}
                                onClick={(e: React.MouseEvent<HTMLElement>) => {
                                    e.stopPropagation();
                                }}
                                onMouseDown={(
                                    e: React.MouseEvent<HTMLElement>
                                ) => e.stopPropagation()}
                                onChange={(changeEvent: any) => {
                                    const newName = changeEvent.target.value;
                                    updateOnEditing(true);
                                    updateEvent({
                                        ...event,
                                        eventName: newName
                                    });
                                }}
                                onBlur={() => {
                                    updateSchema();
                                }}
                                placeholder="Enter new event name"
                            />
                        </InputGroup>
                    </Col>
                    <Col
                        className="col-1 ps-2 ps-lg-3"
                        onClick={() => updateShowEventData(!showEventData)}
                    >
                        <FontAwesomeIcon
                            icon={showEventData ? faChevronUp : faChevronDown}
                            title={
                                showEventData
                                    ? "Hide Event data"
                                    : "Show Event data"
                            }
                            className="text-inverse-50 mouseHover"
                        />
                    </Col>
                    <Col className="col-1 d-flex align-items-center justify-content-end pe-1">
                        {event.frozen ? (
                            <FontAwesomeIcon
                                icon={faLock}
                                className="text-info"
                                title="Event frozen"
                            />
                        ) : (
                            <FontAwesomeIcon
                                icon={faXmark}
                                title="Remove Event"
                                size="lg"
                                className="text-inverse-50 mouseHover"
                                onClick={(event: SyntheticEvent) => {
                                    event.stopPropagation();
                                    remove();
                                }}
                            />
                        )}
                    </Col>
                </Row>
            </Card.Header>
            <Collapse in={showEventData}>
                <div>
                    <Card.Body className="p-2">
                        <Row className="d-flex justify-content-start ms-3 g-0">
                            {eventData}
                        </Row>
                        <div className="px-4 py-2 mx-3 mt-2">
                            <InputGroup>
                                <InputGroup.Text>Comment</InputGroup.Text>
                                <FormControl
                                    as="textarea"
                                    rows={2}
                                    value={event.comment ? event.comment : ""}
                                    placeholder="Description of the event and/or event data"
                                    disabled={event.frozen}
                                    onChange={(changeEvent: any) => {
                                        const newComment =
                                            changeEvent.target.value;
                                        updateEvent({
                                            ...event,
                                            comment: newComment
                                        });
                                    }}
                                    onBlur={() => {
                                        updateSchema();
                                    }}
                                />
                            </InputGroup>
                        </div>
                    </Card.Body>
                </div>
            </Collapse>
            {event.frozen ? null : (
                <Card.Footer className="no-border pt-1">
                    <Col className="col-10 d-flex justify-content-end mb-2">
                        <Button
                            onClick={() => {
                                if (!showEventData) {
                                    updateShowEventData(true);
                                }
                                addNewEventData();
                            }}
                            className="btn-success"
                        >
                            Add Event Data
                        </Button>
                        <Button
                            onClick={() => {
                                saveToLibrary(event);
                            }}
                            className="btn-primary ms-4"
                            disabled={
                                hasDuplicatesEvents || onEditing || isEqualToLib
                            }
                            title={getSaveButtonTitle()}
                        >
                            Save to Library
                        </Button>
                    </Col>
                    <Col className="col-2"></Col>
                </Card.Footer>
            )}
        </Card>
    );
};

export const AnalyticsEventItem = React.memo(AnalyticsEventItemImplementation);
