import React, { FC, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import { TempLink, TempLinkVariables } from "../../models/types";
import { GET_TEMP_LINK } from "../../graphql/queries";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

interface Props {
    productId?: string;
    value: string;
}

export const AttachmentItem: FC<Props> = ({ productId, value }) => {
    const [getTempLink, { data }] = useLazyQuery<TempLink, TempLinkVariables>(
        GET_TEMP_LINK,
        { fetchPolicy: "network-only" }
    );

    useEffect(() => {
        if (data && data.generateLink) {
            const win = window.open(data.generateLink.link, "_blank");
            if (win !== null) {
                win.focus();
            }
        }
    }, [data]);

    if (!productId) {
        return null;
    }

    return (
        <Button
            style={{
                maxWidth: "100%",
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "block",
                whiteSpace: "nowrap"
            }}
            className="btn-sm m-1 float-end"
            title="Download the attachment"
            onClick={() =>
                getTempLink({
                    variables: {
                        productId: Number(productId),
                        key: value
                    }
                })
            }
        >
            <FontAwesomeIcon icon={faDownload} size="xs" />{" "}
            <span className="analytics-font">{value.split("/").pop()}</span>
        </Button>
    );
};
