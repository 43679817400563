import React, { FC, useState } from "react";
import {
    Button,
    Card,
    Container,
    FormControl,
    InputGroup,
    Modal,
    Form
} from "react-bootstrap";
import "./Modals.scss";
import {
    CopyRevisionData,
    CopyRevisionVariables,
    CreateProjectData,
    Project,
    ProjectsData,
    ProjectVariables,
    ReleaseType,
    RevisionsData,
    RevisionsVariables
} from "../../models/types";
import { useMutation, useQuery, useApolloClient } from "@apollo/client";
import { CREATE_PROJECT, COPY_REVISION } from "../../graphql/mutations";
import {
    GET_EMPTY_PROJECTS,
    GET_PRODUCT_META_DATA,
    GET_PROJECTS_BY_PRODUCT,
    GET_SIMPLE_PROJECTS,
    GET_REVISIONS
} from "../../graphql/queries";
import { SlackInfoMessage } from "../simple/SlackInfoMessage";
import * as Yup from "yup";
import { FormikValues, useFormik } from "formik";
import { CreateProjectDropdown } from "../dropdowns/CreateProjectDropdown";
import { useLocalState } from "../../graphql/hooks";

interface Props {
    productId: number;
    show: boolean;
    developerMode: boolean;
    onClose: () => void;
}

export const NewProjectModal: FC<Props> = props => {
    const client = useApolloClient();
    const { useDarkMode } = useLocalState();
    const [info, updateInfo] = useState<string>("");
    const [project, setProject] = useState<ProjectVariables>({
        name: "",
        gitProjectId: undefined,
        gitProjectName: undefined,
        gitProjectBranch: "master",
        slackChannel: undefined
    });

    const [revisionId, setRevisionId] = useState<number>();

    const { data: { projects } = {} } = useQuery<
        ProjectsData,
        ProjectVariables
    >(GET_SIMPLE_PROJECTS, { variables: { productId: props.productId } });

    const [createProject] = useMutation<CreateProjectData, ProjectVariables>(
        CREATE_PROJECT
    );

    const [copyRevision] = useMutation<CopyRevisionData, CopyRevisionVariables>(
        COPY_REVISION
    );

    const createNewProject = async () => {
        try {
            project.productId = props.productId;
            // @ts-ignore
            const response = await createProject({
                variables: project,
                update: (cache, { data }) => {
                    const currentProjectData = cache.readQuery<ProjectsData>({
                        query: GET_PROJECTS_BY_PRODUCT,
                        variables: { productId: props.productId }
                    });

                    const currentProjects = currentProjectData?.projects;
                    if (currentProjects && data) {
                        const newProjects = [
                            data.createProject,
                            ...currentProjects
                        ];
                        cache.writeQuery<ProjectsData>({
                            query: GET_PROJECTS_BY_PRODUCT,
                            variables: { productId: props.productId },
                            data: { projects: newProjects }
                        });
                    }

                    const currentEmptyData = cache.readQuery<{
                        emptyProjects: Project[];
                    }>({
                        query: GET_EMPTY_PROJECTS
                    });

                    const currentEmptyProjects =
                        currentEmptyData?.emptyProjects;
                    if (currentEmptyProjects && data) {
                        const newProjects = [
                            data.createProject,
                            ...currentEmptyProjects
                        ];
                        cache.writeQuery<{
                            emptyProjects: Project[];
                        }>({
                            query: GET_EMPTY_PROJECTS,
                            data: { emptyProjects: newProjects }
                        });
                    }
                },
                refetchQueries: !revisionId
                    ? [
                          {
                              query: GET_PRODUCT_META_DATA,
                              variables: { productId: props.productId }
                          }
                      ]
                    : []
            });

            if (response.data?.createProject.id && revisionId) {
                await copyRevision({
                    variables: {
                        projectId: response.data?.createProject.id,
                        revisionId: revisionId
                    },
                    update: (cache, { data }) => {
                        const currentRevisionsData = cache.readQuery<
                            RevisionsData,
                            RevisionsVariables
                        >({
                            query: GET_REVISIONS,
                            variables: {
                                projectId:
                                    // @ts-ignore
                                    response.data.createProject.id
                            }
                        });
                        const currentRevisions =
                            currentRevisionsData?.revisions;
                        if (currentRevisions && data && response.data) {
                            const newRevisionData = [
                                ...currentRevisions,
                                data.copyRevision
                            ];
                            cache.writeQuery<RevisionsData, RevisionsVariables>(
                                {
                                    query: GET_REVISIONS,
                                    variables: {
                                        // @ts-ignore
                                        projectId:
                                            response.data.createProject.id
                                    },
                                    data: { revisions: newRevisionData }
                                }
                            );
                        }
                    },
                    refetchQueries: [
                        {
                            query: GET_PRODUCT_META_DATA,
                            variables: { productId: props.productId }
                        }
                    ]
                });
            }

            setProject({
                name: "",
                gitProjectId: undefined,
                gitProjectName: undefined,
                gitProjectBranch: "master",
                slackChannel: undefined
            });
            setRevisionId(undefined);
            props.onClose();
        } catch (error: any) {
            console.log("[DEBUG] createNewProject error ", error);
            updateInfo(error.message);
            setTimeout(() => {
                updateInfo("");
            }, 4000);
        }
    };

    const clearCopiedSettings = () => {
        setProject({
            ...project,
            gitProjectId: undefined,
            gitProjectName: "",
            gitProjectBranch: "master",
            slackChannel: undefined
        });
        setRevisionId(undefined);
    };

    const updateSelectedProject = (projectName: string | null | undefined) => {
        if (!projects) {
            return;
        }
        for (let i = 0; i < projects.length; i++) {
            if (projects[i].name === projectName) {
                const newProjectSettings = {
                    ...project,
                    gitProjectId: projects[i].gitProjectId,
                    gitProjectName: projects[i].gitProjectName,
                    gitProjectBranch: projects[i].gitProjectBranch,
                    slackChannel: projects[i].slackChannel
                };

                setProject(newProjectSettings);
                const revisions = client.cache.readQuery<
                    RevisionsData,
                    RevisionsVariables
                >({
                    query: GET_REVISIONS,
                    variables: { projectId: projects[i].id as number }
                });
                const devRevision = revisions?.revisions?.filter(
                    r => r.releaseType === ReleaseType.Development
                );
                if (devRevision !== undefined && devRevision.length > 0) {
                    setRevisionId(devRevision[0].id);
                } else if (
                    revisions?.revisions !== undefined &&
                    revisions?.revisions.length > 0
                ) {
                    setRevisionId(revisions?.revisions[0].id);
                }
                break;
            }
        }
    };

    const nameSchema = Yup.object().shape({
        projectName: Yup.string().required("Required")
    });

    const initialValues: ProjectVariables = {
        name: "",
        gitProjectId: undefined,
        gitProjectName: undefined,
        gitProjectBranch: "master",
        slackChannel: undefined
    };

    const formik = useFormik<FormikValues>({
        initialValues: initialValues,
        onSubmit: createNewProject,
        validationSchema: nameSchema
    });

    return (
        <Modal
            show={props.show}
            onHide={props.onClose}
            backdrop="static"
            keyboard={false}
            className={useDarkMode ? "modal-dark" : ""}
        >
            <Modal.Header closeButton>
                <Modal.Title>Add New Project</Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-body d-flex justify-content-center align-items-center">
                <Card className="editable-card-modal no-border">
                    <Form onSubmit={formik.handleSubmit}>
                        <Form.Group controlId="projectName">
                            <InputGroup className="mb-3">
                                <InputGroup.Text>Name</InputGroup.Text>
                                <Form.Control
                                    type="text"
                                    name="projectName"
                                    value={project.name ?? ""}
                                    onChange={(event: any) => {
                                        formik.handleChange(event);
                                        setProject({
                                            ...project,
                                            name: event.target.value
                                        });
                                    }}
                                    onBlur={formik.handleBlur}
                                    isInvalid={Boolean(
                                        formik.touched.projectName &&
                                            formik.errors.projectName
                                    )}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.Feedback?.toString()}
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                        {props.developerMode ? (
                            <>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text>
                                        Gitlab Project ID
                                    </InputGroup.Text>
                                    <FormControl
                                        value={project.gitProjectId ?? ""}
                                        disabled={revisionId !== undefined}
                                        onChange={(event: any) => {
                                            setProject({
                                                ...project,
                                                gitProjectId: Number(
                                                    event.target.value
                                                )
                                            });
                                        }}
                                    />
                                </InputGroup>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text>
                                        Gitlab Project Name
                                    </InputGroup.Text>
                                    <FormControl
                                        value={project.gitProjectName ?? ""}
                                        disabled={revisionId !== undefined}
                                        onChange={(event: any) => {
                                            setProject({
                                                ...project,
                                                gitProjectName:
                                                    event.target.value
                                            });
                                        }}
                                    />
                                </InputGroup>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text>
                                        Gitlab Project Branch
                                    </InputGroup.Text>
                                    <FormControl
                                        value={project.gitProjectBranch ?? ""}
                                        onChange={(event: any) => {
                                            setProject({
                                                ...project,
                                                gitProjectBranch:
                                                    event.target.value
                                            });
                                        }}
                                    />
                                </InputGroup>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text>
                                        Slack Channel
                                    </InputGroup.Text>
                                    <FormControl
                                        value={project.slackChannel ?? ""}
                                        onChange={(event: any) => {
                                            setProject({
                                                ...project,
                                                slackChannel: event.target.value
                                            });
                                        }}
                                    />
                                </InputGroup>
                                <SlackInfoMessage />
                            </>
                        ) : null}
                        {props.developerMode &&
                        projects &&
                        projects.length > 0 ? (
                            <div className="align-content-center pt-2">
                                <CreateProjectDropdown
                                    projects={projects}
                                    updateSelectedProject={
                                        updateSelectedProject
                                    }
                                />
                            </div>
                        ) : null}
                        {revisionId !== undefined ? (
                            <div className="align-content-center text-center">
                                <Button
                                    variant="danger"
                                    className="text-center mb-2"
                                    onClick={clearCopiedSettings}
                                >
                                    Clear Copied Settings
                                </Button>
                            </div>
                        ) : null}
                        <div className="align-content-center text-center pt-2">
                            <Button
                                className="text-center w-75"
                                type="submit"
                                disabled={
                                    !(formik.dirty && formik.isValid) ||
                                    formik.isSubmitting
                                }
                            >
                                Create
                            </Button>
                        </div>
                    </Form>
                </Card>
            </Modal.Body>
            {info ? (
                <Modal.Footer>
                    <Container className="text-center text-danger">
                        {info}
                    </Container>
                </Modal.Footer>
            ) : null}
        </Modal>
    );
};
