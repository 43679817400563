import React, { FC, useState } from "react";
import { Button, Col, FormControl, InputGroup, Modal } from "react-bootstrap";
import { handleSetUpdate } from "../../common/Helpers";
import { useLocalState } from "../../graphql/hooks";

interface Props {
    showModal: boolean;
    hide: () => void;
    addSet: (set: string) => void;
}

export const AddSetModal: FC<Props> = ({ showModal, hide, addSet }) => {
    const { useDarkMode } = useLocalState();
    const [setName, updateSetName] = useState("");
    const regex = new RegExp("^[a-zA-ZäÄöÖåÅ0-9_.-]*$");
    const updateSetData = () => {
        addSet(setName);
        updateSetName("");
    };

    return (
        <Modal
            show={showModal}
            onHide={hide}
            backdrop="static"
            keyboard={false}
            size="sm"
            className={useDarkMode ? "modal-dark" : ""}
        >
            <Modal.Header closeButton>
                <Modal.Title>Add Set</Modal.Title>
            </Modal.Header>
            <Modal.Body className="row">
                <Col md={12}>
                    <InputGroup>
                        <InputGroup.Text>Set</InputGroup.Text>
                        <FormControl
                            placeholder="Set name"
                            value={setName}
                            onChange={(event: any) => {
                                updateSetName(
                                    handleSetUpdate(event.target.value)
                                );
                            }}
                        />
                    </InputGroup>
                </Col>
                <Col md={12} className="text-center my-2">
                    <Button
                        disabled={!setName || !regex.test(setName)}
                        onClick={() => {
                            updateSetData();
                            hide();
                        }}
                    >
                        Add
                    </Button>
                </Col>
            </Modal.Body>
        </Modal>
    );
};
