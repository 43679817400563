import React, { FC } from "react";
import { Modal } from "react-bootstrap";
import { EditableJsonView } from "../../editable/EditableJsonView";
import { useLocalState } from "../../../graphql/hooks";

interface Props {
    configKey: string;
    configValue: string;
    show: boolean;
    onClose: () => void;
    updateConfig: (data: any) => void;
    updating: boolean;
}

export const NewProjectConfigModal: FC<Props> = ({
    configKey,
    configValue,
    show,
    onClose,
    updateConfig,
    updating
}) => {
    const { useDarkMode } = useLocalState();

    return (
        <Modal
            show={show}
            onHide={onClose}
            backdrop="static"
            keyboard={false}
            size="xl"
            className={useDarkMode ? "modal-dark" : ""}
        >
            <Modal.Header closeButton>
                <Modal.Title>{configKey}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-body p-2">
                <EditableJsonView
                    jsonData={JSON.stringify(configValue, null, 4)}
                    updateJsonData={updateConfig}
                    updating={updating}
                />
            </Modal.Body>
        </Modal>
    );
};
