import React, { FC, useEffect, useRef, useState } from "react";
import { Accordion, Badge, Card } from "react-bootstrap";
import { BuildLogsModalLogCard } from "../BuildLogsModalLogCard";
import { LogCard } from "../../../models/types";

interface Props {
    url: string;
    index: number;
    hasError: boolean;
    hasWarning: boolean;
    focusAtStart?: boolean;
}

export const BuildLogCard: FC<Props> = ({
    url,
    index,
    hasError,
    hasWarning,
    focusAtStart = false
}) => {
    const elementReference = useRef<HTMLDivElement | null>(null);
    const [loading, updateLoading] = useState(false);
    const [logData, updateLogData] = useState<string | undefined>(undefined);

    const load = async () => {
        if (!loading && !logData) {
            updateLoading(true);
            try {
                const response = await fetch(url);
                const data = await response.text();
                updateLogData(data);
                updateLoading(false);
            } catch (error) {
                console.log("[DEBUG] error: ", error);
            }
        }
    };

    useEffect(() => {
        if (focusAtStart && elementReference && elementReference.current) {
            // @ts-ignore
            elementReference.current.scrollIntoView({ behavior: "smooth" });
        }
        load();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const buildLogModalCard: LogCard = BuildLogsModalLogCard(logData, index);

    return (
        <Accordion.Item eventKey={index.toString()} key={index}>
            <div onClick={() => load()}>
                <Accordion.Header className="mouseHover" ref={elementReference}>
                    {`Build ${index}`}
                    {buildLogModalCard.warnings > 0 || hasWarning ? (
                        <Badge className="m-0 ms-2" bg="warning">
                            Warning
                        </Badge>
                    ) : null}
                    {buildLogModalCard.errors > 0 || hasError ? (
                        <Badge className="ms-2" bg="danger">
                            Error
                        </Badge>
                    ) : null}
                </Accordion.Header>
                <Accordion.Body className="p-1 pt-2">
                    {loading || !logData || !buildLogModalCard ? (
                        <p>loading...</p>
                    ) : (
                        buildLogModalCard.card
                    )}
                </Accordion.Body>
            </div>
        </Accordion.Item>
    );
};
