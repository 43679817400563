import React, { FC, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { AnalyticsEventSchema } from "../../../models/types";
import { useLocalState } from "../../../graphql/hooks";
import Prism from "prismjs";
import "prismjs/components/prism-json.min";
import "prismjs/themes/prism.css";
import "../Modals.scss";

interface Props {
    show: boolean;
    analyticsEvent?: AnalyticsEventSchema;
    updateSchemaEvent: () => void;
    onClose: () => void;
}

export const AnalyticsEventUploadModal: FC<Props> = ({
    show,
    analyticsEvent,
    updateSchemaEvent,
    onClose
}) => {
    const codeBlock = JSON.stringify(analyticsEvent, null, 2);
    const { useDarkMode } = useLocalState();

    useEffect(() => {
        if (codeBlock) {
            Prism.highlightAll();
        }
    }, [codeBlock]);

    if (!analyticsEvent) {
        return null;
    }

    return (
        <Modal
            size="xl"
            show={show}
            onHide={onClose}
            backdrop="static"
            keyboard={false}
            className={useDarkMode ? "modal-dark" : ""}
        >
            <Modal.Header closeButton>
                <Modal.Title>{`Save to library: ${analyticsEvent?.eventName}`}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-2">
                <div>
                    <pre>
                        <code className="language-json">{codeBlock}</code>
                    </pre>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="danger"
                    className="mt-2"
                    onClick={updateSchemaEvent}
                >
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
