import React from "react";
import { OverlayBadge } from "./OverlayBadge";
import { useEditPanelState } from "../../graphql/hooks";

export const PathTemplateCheckOverlayBadge = () => {
    const { pathTemplateCheck } = useEditPanelState();

    if (!pathTemplateCheck) {
        return null;
    }

    return (
        <OverlayBadge
            badgeHeader="Missing Path Template!"
            badgeVariant="danger"
            overlayText="Path template must be set in the Settings"
            className="mt-2 p-1 me-2"
        />
    );
};
