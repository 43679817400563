import React from "react";
import { Container } from "react-bootstrap";
import { UserTools } from "../../views/admin/UserTools";
import { OrganizationTools } from "../../views/admin/OrganizationTools";
import { ProtectedComponent } from "../../../auth/ProtectedComponent";
import { PlatformConfigTools } from "../../views/admin/PlatformConfigTools";
import { AnalyticsLibraryEventTools } from "../../views/admin/AnalyticsLibraryEventTools";
import { CreateProjectConfigTools } from "../../views/admin/CreateProjectConfigTools";
import styles from "./AdminPage.module.scss";

const AdminPageImplementation = () => {
    return (
        <Container className={`${styles.responsiveContainer} text-center mt-5`}>
            <ProtectedComponent scopes={["admin"]}>
                <OrganizationTools />
            </ProtectedComponent>
            <UserTools />
            <AnalyticsLibraryEventTools />
            <ProtectedComponent scopes={["admin"]}>
                <PlatformConfigTools />
            </ProtectedComponent>
            <ProtectedComponent scopes={["admin"]}>
                <CreateProjectConfigTools />
            </ProtectedComponent>
        </Container>
    );
};

export const AdminPage = React.memo(AdminPageImplementation);
