import React from "react";
import { Project } from "../../../models/types";
import { useLocalAnalyticsState } from "../../../graphql/hooks";
import { Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { FunnelProject } from "../../simple/analytics/FunnelProject";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAnglesRight } from "@fortawesome/free-solid-svg-icons";

const FunnelProjectDetailsImplementation = () => {
    const { analyticsProjects, analyticsProducts } = useLocalAnalyticsState();
    if (analyticsProjects.length === 0) {
        return null;
    }

    const projects = analyticsProjects
        .map((project: Project, index: number) => {
            if (
                analyticsProducts.some(
                    product => project.product?.id === product.id
                )
            ) {
                return null;
            }
            return <FunnelProject key={index} project={project} />;
        })
        .filter(project => project !== null);

    return (
        <>
            {projects.length > 0 && (
                <div className="mb-3">
                    <Row>
                        <Col xs={11} lg={12} className="mb-2">
                            <b>Selected Projects</b>
                        </Col>
                        <Col
                            xs={1}
                            className="d-lg-none d-flex align-items-center justify-content-end"
                        >
                            <OverlayTrigger
                                placement="left"
                                overlay={
                                    <Tooltip id="show-more">
                                        Expand window to show Analytics data
                                        columns
                                    </Tooltip>
                                }
                            >
                                <FontAwesomeIcon
                                    className="text-inverse-25 me-2"
                                    icon={faAnglesRight}
                                />
                            </OverlayTrigger>
                        </Col>
                    </Row>
                    {projects}
                </div>
            )}
        </>
    );
};

export const FunnelProjectDetails = React.memo(
    FunnelProjectDetailsImplementation
);
