import React, { FC, useState } from "react";
import { Button, Card, FormControl, InputGroup } from "react-bootstrap";
import { Localization } from "../../models/types";
import { VariationItemProps } from "../../models/common";
import { DetectedSetsPopover } from "../selectable/DetectedSetsPopover";
import { handleSetUpdate } from "../../common/Helpers";
import "./variationItems.scss";

interface LocalizationProps extends VariationItemProps {
    defaultValue: Localization;
}

export const NewLegacyLocalizationItem: FC<LocalizationProps> = ({
    revision,
    defaultValue,
    callback
}) => {
    const [localization, setLocalization] = useState(defaultValue);

    const updateLocalizationAndSave = (newLocalization: string) => {
        const newLocalizationData = {
            ...localization,
            localization: newLocalization
        };
        setLocalization(newLocalizationData);
    };

    return (
        <Card className="no-border">
            <Card.Body className="p-2 d-flex flex-column">
                <InputGroup className="d-flex text-inverse justify-content-end mb-3">
                    <InputGroup.Text>Include in next Build set</InputGroup.Text>

                    <InputGroup.Checkbox
                        title="Include in the next Build set"
                        aria-label="Include in the next build set"
                        className="mb-1"
                        checked={localization.include}
                        onChange={(event: any) => {
                            const newLocalizationData = {
                                ...localization,
                                include: event.target.checked
                            };
                            setLocalization(newLocalizationData);
                        }}
                    />
                </InputGroup>
                <InputGroup className="mb-2">
                    <InputGroup.Text>Name</InputGroup.Text>
                    <FormControl
                        placeholder="Localization name"
                        defaultValue={localization.name}
                        onChange={(event: any) => {
                            setLocalization({
                                ...localization,
                                name: event.target.value
                            });
                        }}
                    />
                </InputGroup>
                <InputGroup className="mb-4">
                    <DetectedSetsPopover
                        onToggle={set => updateLocalizationAndSave(set)}
                        allSets={revision?.revisionConfig?.sets}
                        usedSets={[localization.localization]}
                    />
                    <FormControl
                        placeholder="Set name"
                        value={localization.localization}
                        onChange={(event: any) => {
                            setLocalization({
                                ...localization,
                                localization: handleSetUpdate(
                                    event.target.value
                                )
                            });
                        }}
                    />
                </InputGroup>
                <Button
                    onClick={() => (callback ? callback(localization) : null)}
                >
                    Save
                </Button>
            </Card.Body>
        </Card>
    );
};
