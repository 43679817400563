import React, { FC, useEffect, useState } from "react";
import {
    Button,
    Card,
    Collapse,
    Container,
    FormControl,
    InputGroup
} from "react-bootstrap";
import { Variation, VariationSet } from "../../models/types";
import { VariationItemProps } from "../../models/common";
import { DetectedSetsPopover } from "../selectable/DetectedSetsPopover";
import { OverlayBadge } from "../simple/OverlayBadge";
import {
    faCaretDown,
    faChevronDown,
    faChevronUp,
    faPlus
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { handleSetUpdate } from "../../common/Helpers";
import cx from "classnames";
import "./variationItems.scss";

interface VariationProps extends VariationItemProps {
    defaultValue: Variation;
    allowedSets: string[] | undefined;
}

export const NewVariationItem: FC<VariationProps> = ({
    defaultValue,
    allowedSets,
    revision,
    callback
}) => {
    const [showSets, setShowSets] = useState(true);
    const [variation, updateVariation] = useState(defaultValue);
    const [hasBlacklistedSet, setHasBlacklistedSet] = useState(false);

    const blacklist = revision?.revisionConfig?.setBlacklist;

    const disableVariation = () => {
        const newBuildData = {
            ...variation,
            include: false
        };
        updateVariation(newBuildData);
    };

    useEffect(() => {
        if (blacklist && variation.sets) {
            let hasSet = false;
            for (const item of variation.sets) {
                if (blacklist.includes(item.set)) {
                    if (variation.include) {
                        disableVariation();
                    }
                    hasSet = true;
                    break;
                }
            }
            setHasBlacklistedSet(hasSet);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [variation.sets, variation.include, blacklist]);

    const addSet = (variation: Variation) => {
        const newSet = { name: "", set: "" };
        const newSets = [...variation.sets, newSet];
        const updatedVariation = { ...variation, sets: newSets };
        updateVariation(updatedVariation);
    };

    const removeSet = (index: number) => {
        const newSets = variation.sets.filter((item, i) => i !== index);
        const updatedVariation = { ...variation, sets: newSets };
        updateVariation(updatedVariation);
    };

    const updateVariationSet = (variationSet: VariationSet, index: number) => {
        const newSets = variation.sets.map((item, i) =>
            i === index ? variationSet : item
        );
        updateVariation({ ...variation, sets: newSets });
    };

    const updateVariationSetAndSave = (
        variationSet: VariationSet,
        index: number
    ) => {
        const newSets = variation.sets.map((item, i) =>
            i === index ? variationSet : item
        );
        const updatedVariation = { ...variation, sets: newSets };
        updateVariation(updatedVariation);
    };

    const sets = variation.sets.map((item: VariationSet, index) => (
        <Card
            key={index}
            className="m-0 ms-2 me-2 p-3 d-flex justify-content-center"
        >
            <InputGroup>
                <InputGroup.Text>Name</InputGroup.Text>
                <FormControl
                    placeholder="Descriptive title"
                    value={item.name}
                    onChange={(event: any) => {
                        const newName = event.target.value.replace(" ", "");
                        updateVariationSet({ ...item, name: newName }, index);
                    }}
                />
            </InputGroup>
            <InputGroup className="mt-1 mb-1">
                <DetectedSetsPopover
                    title={
                        <>
                            <span className="pe-2">Set</span>
                            <FontAwesomeIcon size="xs" icon={faCaretDown} />
                        </>
                    }
                    onToggle={set => {
                        updateVariationSetAndSave({ ...item, set: set }, index);
                    }}
                    allSets={allowedSets}
                    usedSets={[item.set]}
                />
                <FormControl
                    value={item.set}
                    onChange={(event: any) =>
                        updateVariationSet(
                            {
                                ...item,
                                set: handleSetUpdate(event.target.value)
                            },
                            index
                        )
                    }
                />
            </InputGroup>
            <Button
                className={cx("btn-danger mt-2 w-33 float-end ms-auto", {
                    disabled: variation.sets.length <= 1
                })}
                onClick={() => {
                    if (variation.sets.length <= 1) {
                        return;
                    }
                    removeSet(index);
                }}
            >
                Remove
            </Button>
        </Card>
    ));

    return (
        <Card className="no-border fullSize">
            <span className="me-3">
                <InputGroup className="d-flex text-inverse justify-content-end align-items-center mt-1 me-2">
                    <InputGroup.Text>Include in next Build set</InputGroup.Text>
                    {hasBlacklistedSet ? (
                        <OverlayBadge
                            badgeVariant="danger"
                            className="ms-3"
                            badgeHeader="!"
                            overlayText="Variation has blacklisted set(s)!"
                        />
                    ) : (
                        <InputGroup.Checkbox
                            className="mouseHover mb-1"
                            title="Include in next Build set"
                            checked={variation.include}
                            onClick={(event: any) => {
                                event.stopPropagation();
                            }}
                            onChange={(event: any) => {
                                const newVariationData = {
                                    ...variation,
                                    include: event.target.checked
                                };
                                updateVariation(newVariationData);
                            }}
                        />
                    )}
                </InputGroup>
            </span>
            <Card.Body className="d-flex flex-column">
                <InputGroup className="mb-3">
                    <InputGroup.Text>Name</InputGroup.Text>
                    <FormControl
                        placeholder="Descriptive title"
                        value={variation.name}
                        onChange={(event: any) => {
                            const newName = event.target.value.replace(" ", "");
                            updateVariation({
                                ...variation,
                                name: newName
                            });
                        }}
                    />
                    <div className="mouseHover mt-2">
                        <FontAwesomeIcon
                            className="ms-4"
                            onClick={(event: any) => {
                                event.stopPropagation();
                                addSet(variation);
                            }}
                            icon={faPlus}
                            title="Add new Addset"
                        />
                        <FontAwesomeIcon
                            className="ms-4 me-3"
                            icon={showSets ? faChevronUp : faChevronDown}
                            onClick={() => setShowSets(!showSets)}
                        />
                    </div>
                </InputGroup>
                <>
                    <Collapse in={showSets}>
                        <div>
                            <Container className="m-0 p-0">{sets}</Container>
                        </div>
                    </Collapse>
                </>
                <InputGroup className="mb-4 mt-3">
                    <InputGroup.Text>Description</InputGroup.Text>
                    <FormControl
                        placeholder="Describe the variation content(s)"
                        defaultValue={variation.description}
                        as="textarea"
                        rows={1}
                        onChange={(event: any) => {
                            updateVariation({
                                ...variation,
                                description: event.target.value
                            });
                        }}
                    />
                </InputGroup>
                <Button
                    className={cx({ disabled: variation.sets.length === 0 })}
                    onClick={() =>
                        callback && variation.sets.length > 0
                            ? callback(variation)
                            : null
                    }
                >
                    Save
                </Button>
            </Card.Body>
        </Card>
    );
};
