import { Container } from "react-bootstrap";
import React, { FC } from "react";

interface Props {
    text: string;
    textCss?: string;
}

export const InfoMessage: FC<Props> = ({ text, textCss }) => {
    return (
        <span className="small-font text-inverse ">
            <p className={"m-0 " + textCss ? textCss : ""}>{text}</p>
        </span>
    );
};
