import React, { FC, useEffect, useState } from "react";
import {
    Button,
    Col,
    FormControl,
    InputGroup,
    Modal,
    Row
} from "react-bootstrap";
import {
    FeedbackType,
    Revision,
    RevisionEventData,
    RevisionEventVariables
} from "../../models/types";
import { useMutation } from "@apollo/client";
import { CREATE_REVISION_EVENT } from "../../graphql/mutations";
import { GET_REVISION_HISTORY } from "../../graphql/queries";
import { useLocalState } from "../../graphql/hooks";
import "./Modals.scss";

interface Props {
    title: string;
    type: FeedbackType;
    revision?: Revision;
    show: boolean;
    onClose: () => void;
}

export const EventModal: FC<Props> = ({
    title,
    type,
    show,
    onClose,
    revision
}) => {
    const [info, updateInfo] = useState("");
    const [text, updateText] = useState("");
    const [submitting, updateSubmitting] = useState(false);
    const [unMount, setMountingStatus] = useState(false);
    const { useDarkMode } = useLocalState();
    const [updateRevision] = useMutation<
        RevisionEventData,
        RevisionEventVariables
    >(CREATE_REVISION_EVENT);

    useEffect(() => {
        if (unMount) {
            onClose();
        }
    }, [unMount, onClose]);

    const submit = async () => {
        if (revision) {
            try {
                await updateRevision({
                    variables: {
                        revisionId: revision.id,
                        eventType: type,
                        description: text
                    },
                    refetchQueries: [
                        {
                            query: GET_REVISION_HISTORY,
                            variables: { revisionId: Number(revision?.id) }
                        }
                    ]
                });

                updateText("");
                setMountingStatus(true);
            } catch (error) {
                console.log("[DEBUG] error: ", error);
                // @ts-ignore
                updateInfo(error.message);
                setTimeout(() => {
                    updateInfo("");
                }, 4000);
            }
        }
        updateSubmitting(false);
    };

    return (
        <Modal
            show={show}
            onHide={onClose}
            backdrop="static"
            keyboard={false}
            className={useDarkMode ? "modal-dark" : ""}
        >
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="px-3">
                <h5>Description</h5>
                <FormControl
                    placeholder="Test request description (Optional)"
                    as="textarea"
                    rows={4}
                    aria-label="Description"
                    defaultValue={text}
                    onChange={(event: any) => {
                        updateText(event.target.value);
                    }}
                />
            </Modal.Body>
            <Modal.Footer className="d-flex flex-column justify-content-center align-content-center">
                <>
                    <h5 className="text-center text-danger">{info}</h5>
                    <Button
                        disabled={submitting}
                        onClick={() => {
                            updateSubmitting(true);
                            submit();
                        }}
                    >
                        {submitting ? "Submitting..." : "Submit"}
                    </Button>
                </>
            </Modal.Footer>
        </Modal>
    );
};
