import React, { FC } from "react";
import { Badge, OverlayTrigger, Tooltip } from "react-bootstrap";

interface Props {
    badgeHeader: string;
    badgeVariant: string;
    overlayText: string;
    className: string;
}

export const OverlayBadge: FC<Props> = ({
    badgeHeader,
    badgeVariant,
    overlayText,
    className
}) => {
    const renderTooltip = (props: any) => (
        <Tooltip id={badgeHeader + "-tooltip"} {...props}>
            {overlayText}
        </Tooltip>
    );

    // OverlayTrigger type seems to be bugged currently and requires bunch of undefined stuff to stop throwing tantrums
    return (
        <OverlayTrigger
            placement="top"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip}
        >
            <Badge bg={badgeVariant} className={"mouseHover " + className}>
                {badgeHeader}
            </Badge>
        </OverlayTrigger>
    );
};
