const arrayBufferToBase64 = (arrayBuffer: ArrayBuffer) => {
    const uint8Array = new Uint8Array(arrayBuffer);
    let binaryString = "";
    for (let i = 0; i < uint8Array.length; i++) {
        binaryString += String.fromCharCode(uint8Array[i]);
    }
    return btoa(binaryString);
};

export const getSceneAsBase64 = async (url: string): Promise<string> => {
    try {
        const response = await fetch(url);
        const arrayBuffer = await response.arrayBuffer();
        const base64String = arrayBufferToBase64(arrayBuffer);
        return base64String;
    } catch (error) {
        console.log("[DEBUG] getSceneAsBase64 with error ", error);
        throw error;
    }
};
