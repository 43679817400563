import React, { FC, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faChevronDown,
    faChevronUp,
    faCircleInfo
} from "@fortawesome/free-solid-svg-icons";
import { VariationCollapseState } from "../../../models/common";
import { Card, Collapse, Container } from "react-bootstrap";
import { Revision } from "../../../models/types";
import { Builds } from "./Builds";
import {
    getVariationCollapseState,
    saveVariationCollapseState
} from "../../../common/Helpers";

interface Props {
    revision: Revision;
}

const ClientBuildManagerImplementation: FC<Props> = ({ revision }) => {
    const [showBuilds, setShowBuilds] = useState<boolean>(
        getVariationCollapseState(VariationCollapseState.Builds)
    );
    const [showHelp, setShowHelp] = useState(false);
    const hasBuilds =
        revision.buildData &&
        Array.isArray(revision.buildData.builds) &&
        revision.buildData.builds.length > 0;

    const updateShowBuilds = (newState: boolean) => {
        saveVariationCollapseState(VariationCollapseState.Builds, newState);
        setShowBuilds(newState);
    };

    const toggleShowHelp = () => setShowHelp(!showHelp);

    return (
        <>
            <Container className="mt-4 mb-4 p-0 editPanelClientView">
                <Card>
                    <Card.Header className="d-flex align-items-center">
                        <h5 className="text-inverse-50 mb-1 me-1">
                            Base Versions
                        </h5>
                        {hasBuilds && (
                            <div className="d-flex">
                                <div
                                    className="ms-2 mouseHover text-inverse-50"
                                    onClick={() => {
                                        updateShowBuilds(!showBuilds);
                                    }}
                                    data-toggle="tooltip"
                                    title="Toggle build visibility"
                                >
                                    <FontAwesomeIcon
                                        icon={
                                            showBuilds
                                                ? faChevronUp
                                                : faChevronDown
                                        }
                                    />
                                </div>
                            </div>
                        )}
                        <div className="float-end ms-auto">
                            <FontAwesomeIcon
                                icon={faCircleInfo}
                                onClick={toggleShowHelp}
                                className={`mouseHover ${
                                    showHelp
                                        ? "text-inverse-50"
                                        : "text-inverse-25"
                                }`}
                                title={showHelp ? "Hide Help" : "Show Help"}
                            />
                        </div>
                    </Card.Header>
                    {hasBuilds && (
                        <Collapse in={showBuilds}>
                            <div>
                                <Card.Body className="p-3">
                                    <Builds
                                        revision={revision}
                                        developerMode={false}
                                        shouldBeVisible={false}
                                    />
                                </Card.Body>
                            </div>
                        </Collapse>
                    )}
                    <Collapse in={showHelp}>
                        <div>
                            <Card.Footer className="bread-text text-inverse-50">
                                <p>
                                    <strong>Base versions</strong> items each
                                    contain settings for the size and duration
                                    of one playable ad. You can select any or
                                    all of them to be generated as builds in a
                                    shareable build report.
                                </p>
                                <p>
                                    Variations in the next section are used to
                                    add and/or modify certain features in the
                                    base versions.
                                </p>
                            </Card.Footer>
                        </div>
                    </Collapse>
                </Card>
            </Container>
        </>
    );
};

export const ClientBuildManager = React.memo(ClientBuildManagerImplementation);
