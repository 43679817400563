import React, {
    FC,
    SyntheticEvent,
    useCallback,
    useEffect,
    useRef,
    useState
} from "react";
import {
    BuildType,
    CancelNetworkTestData,
    CancelNetworkTestVariables,
    NetworkBuild,
    NetworkTestData,
    NetworkTestState,
    NetworkTestVariables,
    PlatformConfigData,
    ShareLink,
    ShareLinkVariables,
    TempLink,
    TempLinkVariables
} from "../../models/types";
import {
    Badge,
    Button,
    Card,
    Col,
    Collapse,
    Container,
    Row
} from "react-bootstrap";
import { AlertModal } from "../modals/AlertModal";
import { useLocalAnalyticsState, useLocalState } from "../../graphql/hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faChartLine,
    faExclamationCircle,
    faExclamationTriangle,
    faFileArrowDown,
    faFilter,
    faPlay,
    faQrcode,
    faShareSquare
} from "@fortawesome/free-solid-svg-icons";
import {
    useApolloClient,
    useLazyQuery,
    useMutation,
    useQuery
} from "@apollo/client";
import {
    GET_NETWORK_TEST,
    GET_PLATFORM_CONFIG,
    GET_PUBLIC_LINK,
    GET_TEMP_LINK
} from "../../graphql/queries";
import { LanguageTestDropdown } from "../dropdowns/LanguageTestDropdown";
import { BuildAnalyticsInfo } from "./BuildAnalyticsInfo";
import { NetworkTestResult } from "./NetworkTestResult";
import {
    CANCEL_NETWORK_TEST,
    CREATE_NETWORK_TEST
} from "../../graphql/mutations";
import { NetworkTestProgressBar } from "./pipeline/NetworkTestProgressBar";
import { UserQueryBadgesNetworkBuild } from "./analytics/UserQueryBadgesNetworkBuild";
import { DailyBadgesNetworkBuild } from "./analytics/DailyBadgesNetworkBuild";
import { updateNotificationState } from "../../common/Helpers";
import { useParams } from "react-router-dom";
import cx from "classnames";
import styles from "./BatchBuildReport.module.scss";

interface Props {
    buildId: number;
    networkBuild: NetworkBuild;
    duplicates: string[] | undefined;
    localizations: string[] | undefined;
    statsKey: string;
    s3bucket: string;
    s3Key: string;
    openModal: (job: string, id: number, buildId: number | undefined) => void;
    arrayIndex: number;
    isInFunnel: boolean;
    toggleFunnelSelection: () => void;
    buildType: BuildType;
    showFullBuildName: boolean;
}

const getBadgeVariant = (size: number | undefined) => {
    if (typeof size === "number") {
        switch (size) {
            case 2:
                return "density-1";
            case 3:
                return "density-2";
            case 5:
                return "density-3";
            default:
                return "info";
        }
    }
    return "info";
};

export const NetworkBuildReport: FC<Props> = ({
    buildId,
    networkBuild,
    statsKey,
    duplicates,
    localizations,
    s3bucket,
    s3Key,
    openModal,
    arrayIndex,
    isInFunnel,
    toggleFunnelSelection,
    buildType,
    showFullBuildName
}) => {
    const { data: { getPlatformConfig } = {} } =
        useQuery<PlatformConfigData>(GET_PLATFORM_CONFIG);
    const buildToolWarningsRef = useRef<HTMLDivElement | null>(null);
    const currentLocalization = useRef<string | undefined>(undefined);
    const client = useApolloClient();
    const state = useLocalState();
    const { analyticsEnabled, analyticsFunnelSelectionMode } =
        useLocalAnalyticsState();
    const { productId } = useParams<{
        productId: string;
    }>();
    const [fetchTempLink, updateFetchTempLink] = useState<{
        secureLink: boolean;
        qrCode: boolean;
    }>({ secureLink: false, qrCode: false });
    const [showDetails, setDetails] = useState(false);
    const [showQR, setShowQR] = useState(false);

    const { data: { networkTest } = {} } = useQuery<
        NetworkTestData,
        { networkBuildId: number }
    >(GET_NETWORK_TEST, {
        variables: { networkBuildId: Number(networkBuild.id) },
        fetchPolicy: "network-only"
    });

    // @ts-ignore
    const [getShareLink, { data: { generatePublicLink } = {} }] = useLazyQuery<
        ShareLink,
        ShareLinkVariables
    >(GET_PUBLIC_LINK, {
        fetchPolicy: "no-cache",
        variables: { buildId: buildId, fileName: networkBuild.filename }
    });

    // @ts-ignore
    const [getTempLink, { data: { generateLink } = {} }] = useLazyQuery<
        TempLink,
        TempLinkVariables
    >(GET_TEMP_LINK, {
        fetchPolicy: "no-cache",
        refetchWritePolicy: "overwrite"
    });

    const [testMutation] = useMutation<NetworkTestData, NetworkTestVariables>(
        CREATE_NETWORK_TEST
    );

    const [cancelNetworkTest] = useMutation<
        CancelNetworkTestData,
        CancelNetworkTestVariables
    >(CANCEL_NETWORK_TEST);

    const memoizedShowToast = useCallback((message: string) => {
        showToast(message);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (
            (fetchTempLink.secureLink || fetchTempLink.qrCode) &&
            generateLink &&
            generateLink.link
        ) {
            if (fetchTempLink.secureLink) {
                const win = window.open(generateLink.link, "_blank");
                if (win !== null) {
                    win.focus();
                }
            } else {
                setShowQR(true);
            }
            updateFetchTempLink({ secureLink: false, qrCode: false });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [generateLink]);

    useEffect(() => {
        const copy = async (link: string) => {
            try {
                if (currentLocalization.current !== undefined) {
                    link = `${link}?test_lang=${currentLocalization.current}`;
                }
                await navigator.clipboard.writeText(link);
                const message =
                    currentLocalization.current !== undefined
                        ? `Link Copied to Clipboard with localization ${currentLocalization.current}`
                        : "Link Copied to Clipboard";
                memoizedShowToast(message);

                client.cache.writeQuery({
                    query: GET_PUBLIC_LINK,
                    variables: {
                        buildId: buildId,
                        fileName: networkBuild.filename
                    },
                    data: {
                        generatePublicLink: { ...generatePublicLink, link: "" }
                    }
                });
            } catch (error) {
                console.log("[DEBUG] copy error ", error);
            }
        };
        if (generatePublicLink && generatePublicLink.link) {
            copy(generatePublicLink.link);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [generatePublicLink]);

    const showToast = (message: string) => {
        updateNotificationState(client, false, true, {
            success: true,
            header: "Generate Link",
            message: message,
            delay: 2000
        });
    };

    const getStatsFile = () => {
        currentLocalization.current = undefined;
        updateFetchTempLink({
            secureLink: true,
            qrCode: false
        });
        getTempLink({
            variables: {
                productId: Number(productId),
                key: statsKey
            }
        });
    };

    const startTestJob = async () => {
        try {
            await testMutation({
                variables: {
                    networkBuildId: networkBuild.id
                },
                refetchQueries: [
                    {
                        query: GET_NETWORK_TEST,
                        variables: { networkBuildId: Number(networkBuild.id) }
                    }
                ]
            });
        } catch (error) {
            console.log("[DEBUG] startTestJob error ", error);
        }
    };

    const startCancelTest = async () => {
        try {
            await cancelNetworkTest({
                variables: { networkTestId: Number(networkTest?.id) },
                refetchQueries: [
                    {
                        query: GET_NETWORK_TEST,
                        variables: { networkBuildId: Number(networkBuild.id) }
                    }
                ]
            });
        } catch (error) {
            console.log("[DEBUG] startCancelTest error ", error);
        }
    };

    const isTestSupported = getPlatformConfig?.networkTestingNetworks.includes(
        networkBuild.network.toLowerCase()
    );

    const isQrSupported = getPlatformConfig?.qrNetworks.includes(
        networkBuild.network.toLowerCase()
    );

    const handleQrLink = (link: string) => {
        const splitLink = link.split("?");
        return `${unescape(splitLink[0])}?${splitLink[1]}`;
    };

    const Placeholder = () => (
        <div className="m-2 text-inverse-25">Mutator build</div>
    );

    const linkElements = () => {
        if (buildType === BuildType.Mutator) {
            return <Placeholder />;
        }
        if (networkBuild.filename?.includes(".zip")) {
            return (
                <Button
                    size="sm"
                    className="mb-2 mt-2 ms-1"
                    title="Download ZIP-file"
                    onClick={(event: any) => {
                        event.stopPropagation();
                        currentLocalization.current = undefined;
                        updateFetchTempLink({
                            secureLink: true,
                            qrCode: false
                        });
                        getTempLink({
                            variables: {
                                productId: Number(productId),
                                key: s3Key
                            }
                        });
                    }}
                >
                    <FontAwesomeIcon icon={faFileArrowDown} size="lg" />
                </Button>
            );
        } else {
            return (
                <>
                    {isQrSupported ? (
                        <Button
                            size="sm"
                            className="mb-2 mt-2 ms-1"
                            title="Generate QR-code for temporary link"
                            onClick={(event: React.MouseEvent<HTMLElement>) => {
                                event.stopPropagation();
                                updateFetchTempLink({
                                    secureLink: false,
                                    qrCode: true
                                });
                                currentLocalization.current = undefined;
                                getTempLink({
                                    variables: {
                                        productId: Number(productId),
                                        key: s3Key
                                    }
                                });
                            }}
                        >
                            <FontAwesomeIcon icon={faQrcode} size="lg" />
                        </Button>
                    ) : null}
                    <div title="Create permanent link and copy on clipboard">
                        <LanguageTestDropdown
                            id={`language-dropdown-${networkBuild.id}`}
                            header={
                                <FontAwesomeIcon
                                    icon={faShareSquare}
                                    size="1x"
                                />
                            }
                            className="mb-2 mt-2 ms-1"
                            localizations={localizations}
                            callback={(localization: string | undefined) => {
                                if (
                                    localization &&
                                    localization !== "Default"
                                ) {
                                    currentLocalization.current = localization;
                                } else {
                                    currentLocalization.current = undefined;
                                }
                                getShareLink();
                            }}
                            showInfoModal={true}
                        />
                    </div>
                    <div title="Open the ad with temporary link">
                        <LanguageTestDropdown
                            id={`language-dropdown-temp-${networkBuild.id}`}
                            header={<FontAwesomeIcon icon={faPlay} size="1x" />}
                            className="mb-2 mt-2 ms-1"
                            localizations={localizations}
                            callback={(localization: string | undefined) => {
                                if (
                                    localization &&
                                    localization !== "Default"
                                ) {
                                    currentLocalization.current = localization;
                                } else {
                                    currentLocalization.current = undefined;
                                }
                                updateFetchTempLink({
                                    secureLink: true,
                                    qrCode: false
                                });
                                getTempLink({
                                    variables: {
                                        productId: Number(productId),
                                        key: s3Key,
                                        localization:
                                            currentLocalization.current !==
                                            undefined
                                                ? localization
                                                : undefined
                                    }
                                });
                            }}
                        />
                    </div>
                </>
            );
        }
    };
    const isDuplicate =
        networkBuild.fileHash &&
        duplicates &&
        duplicates.includes(networkBuild.fileHash);
    const logIndex = arrayIndex;

    return (
        <Card className="text-inverse inner-card m-1">
            {showQR ? (
                <AlertModal
                    visible={() => {
                        setShowQR(false);
                    }}
                    component={
                        <img
                            alt={`https://api.qrserver.com/v1/create-qr-code/?size=350x350&data=${escape(
                                generateLink && generateLink.link
                                    ? handleQrLink(generateLink.link)
                                    : ""
                            )}`}
                            src={`https://api.qrserver.com/v1/create-qr-code/?size=350x350&data=${escape(
                                generateLink && generateLink.link
                                    ? handleQrLink(generateLink.link)
                                    : ""
                            )}`}
                        />
                    }
                    header={<h5>QR Code</h5>}
                    footer={
                        <>
                            <p className="text-center">
                                <b>Please note!</b> Unity Playable Tester App
                                might misread the QR link. You might have to try
                                multiple times to get the correct url.
                            </p>
                            <p>This link is temporary and valid for 1 hour.</p>
                        </>
                    }
                />
            ) : null}
            <Card.Header
                className={cx("pb-1 pt-1 px-3", {
                    mouseHover: state.developerMode
                })}
                onClick={event => {
                    if (event.defaultPrevented) {
                        return;
                    }
                    if (state.developerMode) {
                        setDetails(!showDetails);
                    }
                }}
            >
                <Row className="d-flex align-items-center g-0">
                    <Col
                        className={cx("text-start", {
                            "col-6": showFullBuildName && !analyticsEnabled,
                            "col-3":
                                !showFullBuildName ||
                                (showFullBuildName && analyticsEnabled)
                        })}
                    >
                        <span>
                            {showFullBuildName ? (
                                <span className={styles.fullBuildName}>
                                    {networkBuild.buildName}
                                </span>
                            ) : (
                                `${networkBuild.variationName} ${
                                    networkBuild.variation || ""
                                }`
                            )}
                        </span>
                    </Col>
                    <Col
                        className={cx("text-start", {
                            "col-5": !showFullBuildName && !analyticsEnabled,
                            "col-3": showFullBuildName && !analyticsEnabled
                        })}
                    >
                        <Badge
                            title="Network"
                            bg="primary"
                            className={cx("ms-1", styles.bigBadge)}
                        >
                            {networkBuild.network}
                        </Badge>
                        {networkTest &&
                        networkTest.state !== NetworkTestState.Completed ? (
                            <NetworkTestProgressBar test={networkTest} />
                        ) : null}
                        <Badge
                            title="Size"
                            bg={getBadgeVariant(networkBuild.size)}
                            className={cx("ms-2 text-white", styles.bigBadge)}
                        >
                            {networkBuild.size}MB
                        </Badge>
                        {networkBuild.analyticsEnabled && (
                            <Badge
                                title="Analytics enabled"
                                bg="analytics"
                                className={cx("ms-2 text-white")}
                            >
                                <FontAwesomeIcon icon={faChartLine} size="1x" />
                            </Badge>
                        )}
                        {networkBuild.buildToolWarnings &&
                        networkBuild.buildToolWarnings.length > 0 &&
                        state.developerMode ? (
                            <FontAwesomeIcon
                                title="Show build tool warnings"
                                icon={faExclamationTriangle}
                                size={"lg"}
                                className={"text-warning ms-2 mouseHover"}
                                onClick={(event: any) => {
                                    event.stopPropagation();
                                    if (!showDetails) {
                                        setDetails(true);
                                    }
                                    setTimeout(
                                        () =>
                                            buildToolWarningsRef.current?.scrollIntoView(
                                                { behavior: "smooth" }
                                            ),
                                        400
                                    );
                                }}
                            />
                        ) : null}
                        {networkBuild.warnings?.includes(logIndex) ? (
                            <FontAwesomeIcon
                                title="Show warnings"
                                icon={faExclamationTriangle}
                                size={"lg"}
                                className={"text-warning ms-2 mouseHover"}
                                onClick={(event: any) => {
                                    event.stopPropagation();
                                    openModal("build_job", -1, logIndex);
                                }}
                            />
                        ) : null}
                        {networkBuild.errors?.includes(logIndex) ? (
                            <FontAwesomeIcon
                                title="Show errors"
                                icon={faExclamationCircle}
                                size={"lg"}
                                className={"text-danger ms-2 mouseHover"}
                                onClick={(event: any) => {
                                    event.stopPropagation();
                                    openModal("build_job", -1, logIndex);
                                }}
                            />
                        ) : null}
                        {isDuplicate ? (
                            <Badge
                                bg="danger"
                                title="Duplicate build"
                                className={cx("ms-2", styles.bigBadge)}
                            >
                                DUP
                            </Badge>
                        ) : null}
                    </Col>
                    <Col
                        className={cx({
                            "col-3":
                                !showFullBuildName &&
                                analyticsEnabled &&
                                networkBuild.analyticsEnabled,
                            "col-0":
                                showFullBuildName &&
                                (!analyticsEnabled ||
                                    !networkBuild.analyticsEnabled)
                        })}
                    >
                        {analyticsEnabled ? (
                            <>
                                {networkBuild.userQueryBuildAnalytic &&
                                networkBuild.userQueryBuildAnalytic?.length >
                                    0 ? (
                                    <div className="float-end ms-2">
                                        <UserQueryBadgesNetworkBuild
                                            userQueryBuildAnalytic={
                                                networkBuild
                                                    .userQueryBuildAnalytic[0]
                                            }
                                        />
                                    </div>
                                ) : null}
                                {networkBuild.dailyBuildAnalytic &&
                                networkBuild.dailyBuildAnalytic?.length > 0 ? (
                                    <div className="float-end">
                                        <DailyBadgesNetworkBuild
                                            dailyBuildAnalytic={
                                                networkBuild
                                                    .dailyBuildAnalytic[0]
                                            }
                                        />
                                    </div>
                                ) : null}
                            </>
                        ) : null}
                    </Col>
                    <Col className="col-3 d-flex justify-content-end">
                        {analyticsEnabled &&
                        analyticsFunnelSelectionMode &&
                        networkBuild.analyticsEnabled ? (
                            <Button
                                size="sm"
                                onClick={(event: SyntheticEvent) => {
                                    event.stopPropagation();
                                    toggleFunnelSelection();
                                }}
                                variant={isInFunnel ? "analytics" : "secondary"}
                                className="mb-2 mt-2 btn-sm"
                            >
                                <FontAwesomeIcon icon={faFilter} />
                            </Button>
                        ) : null}
                        {linkElements()}
                    </Col>
                </Row>
            </Card.Header>
            {state.developerMode ? (
                <Collapse in={showDetails}>
                    <div>
                        <Card.Body>
                            <Card className="mb-1">
                                <Card.Header>
                                    <strong className="text-inverse-50">
                                        Basic Info
                                    </strong>
                                </Card.Header>
                                <Card.Body className="text-inverse bread-text">
                                    <p
                                        className={cx({
                                            "text-danger": isDuplicate
                                        })}
                                    >
                                        <b className="text-inverse-50">Hash:</b>{" "}
                                        {networkBuild.fileHash}
                                    </p>
                                    <p>
                                        <b className="text-inverse-50">
                                            ByteSize:
                                        </b>{" "}
                                        {networkBuild.byteSize}
                                    </p>
                                    {networkBuild.customPath !== "" ? (
                                        <p>
                                            <b className="text-inverse-50">
                                                Path:
                                            </b>{" "}
                                            {networkBuild.customPath}
                                        </p>
                                    ) : null}
                                    <p>
                                        <b className="text-inverse-50">
                                            Included Sets:
                                        </b>{" "}
                                        {!networkBuild.includedSets ||
                                        networkBuild.includedSets.length === 0
                                            ? "-"
                                            : networkBuild.includedSets?.join(
                                                  ", "
                                              )}
                                    </p>
                                    <p>
                                        <b className="text-inverse-50">
                                            Excluded Sets:
                                        </b>{" "}
                                        {!networkBuild.excludedSets ||
                                        networkBuild.excludedSets.length === 0
                                            ? "-"
                                            : networkBuild.excludedSets?.join(
                                                  ", "
                                              )}
                                    </p>
                                </Card.Body>
                            </Card>
                            {networkBuild.analyticsEnabled ? (
                                <BuildAnalyticsInfo
                                    batchId={networkBuild.analyticsBatchId}
                                    buildId={networkBuild.analyticsBuildId}
                                    analyticsVersion={
                                        networkBuild.analyticsVersion
                                    }
                                />
                            ) : null}
                            {networkBuild.buildToolWarnings &&
                            networkBuild.buildToolWarnings.length > 0 &&
                            state.developerMode ? (
                                <Card className="mt-2">
                                    <Card.Header ref={buildToolWarningsRef}>
                                        <strong className="text-inverse-50">
                                            Build Tool Warnings
                                        </strong>
                                    </Card.Header>
                                    <Card.Body className="bread-text">
                                        <p className="text-warning">
                                            {networkBuild.buildToolWarnings.join(
                                                ", "
                                            )}
                                        </p>
                                    </Card.Body>
                                </Card>
                            ) : null}
                            {networkBuild.warnings?.length > 0 &&
                            !networkBuild.warnings.includes(logIndex) &&
                            !networkBuild.errors.includes(logIndex) ? (
                                <Card className="mt-2">
                                    <Card.Header>
                                        <strong className="text-inverse-50">
                                            Build Warnings
                                        </strong>
                                    </Card.Header>
                                    <Card.Body>
                                        <p className="text-warning">
                                            {networkBuild.warnings.join(", ")}
                                        </p>
                                    </Card.Body>
                                </Card>
                            ) : null}
                            {networkBuild.statsFile && state.developerMode ? (
                                <Container className="m-0 mt-2 p-0">
                                    <span
                                        className="ps-2 mouseHover mouseHoverLink text-primary"
                                        onClick={(event: SyntheticEvent) => {
                                            event.preventDefault();
                                            getStatsFile();
                                        }}
                                    >
                                        <Button>Build Stats</Button>
                                    </span>
                                </Container>
                            ) : null}
                            {isTestSupported ? (
                                <NetworkTestResult
                                    test={networkTest}
                                    startTest={startTestJob}
                                    startCancelTest={startCancelTest}
                                />
                            ) : null}
                        </Card.Body>
                    </div>
                </Collapse>
            ) : null}
        </Card>
    );
};
