import React, { FC } from "react";
import { Card, Col, Dropdown, DropdownButton } from "react-bootstrap";
import { Organization, OrganizationData } from "../../models/types";
import { useQuery } from "@apollo/client";
import { GET_ORGANIZATIONS } from "../../graphql/queries";

interface Props {
    organization: Organization | undefined;
    updateOrganization: (selectedOrganization: Organization) => void;
}

export const OrganizationSelectionView: FC<Props> = ({
    organization,
    updateOrganization
}) => {
    const { data: { organizations } = {} } =
        useQuery<OrganizationData>(GET_ORGANIZATIONS);
    const generateOrganizationAlternatives = organizations?.map(
        (organization: Organization, index: number) => (
            <Dropdown.Item key={index} eventKey={organization.id.toString()}>
                {organization.name}
            </Dropdown.Item>
        )
    );

    return (
        <Card className="border-secondary">
            <Col className="m-0 ps-2 mb-2" md={12}>
                <h5 className="text-inverse p-2">Organization</h5>
            </Col>
            <Col md={12} className="d-flex justify-content-center">
                <DropdownButton
                    title={
                        organization === undefined
                            ? "Select Organization"
                            : organization.name
                    }
                    variant="secondary"
                    className="text-center m-2"
                    onSelect={(selectedItem: string | null | undefined) => {
                        if (selectedItem) {
                            const selectedOrganization = organizations?.find(
                                element =>
                                    Number(element.id) === Number(selectedItem)
                            );
                            if (selectedOrganization) {
                                updateOrganization(selectedOrganization);
                            }
                        }
                    }}
                >
                    {generateOrganizationAlternatives}
                </DropdownButton>
            </Col>
        </Card>
    );
};
