import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { useLazyQuery, useQuery } from "@apollo/client";
import { GET_RESULTS, GET_REVISION } from "../../graphql/queries";
import {
    BatchBuild,
    ReleaseType,
    RevisionData,
    RevisionVariables
} from "../../models/types";
import { useParams } from "react-router-dom";
import { BuildReport } from "./BuildReport";
import { useLocalState } from "../../graphql/hooks";
import "../pages/pages.scss";

export const BuildReportWrapper = () => {
    const state = useLocalState();
    const { revisionId } = useParams<{ revisionId: string }>();
    const [queryBatchBuilds, { loading, data, error }] = useLazyQuery<{
        builds: BatchBuild[];
    }>(GET_RESULTS, { fetchPolicy: "network-only" });
    const { loading: revisionLoading, data: { revision } = {} } = useQuery<
        RevisionData,
        RevisionVariables
    >(GET_REVISION, { variables: { revisionId: Number(revisionId) } });

    useEffect(() => {
        if (!revisionLoading && revision) {
            queryBatchBuilds({
                variables: {
                    revisionId: revision.id,
                    clientTest:
                        !state.developerMode &&
                        revision.releaseType === ReleaseType.Development
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [revisionLoading, state.developerMode, queryBatchBuilds]);

    if (loading) {
        return (
            <Container className="fullSize p-2 pt-5 text-center text-inverse">
                <h5>Loading...</h5>
            </Container>
        );
    }

    if (error) {
        return (
            <Container className="fullSize p-2 pt-5 text-center">
                <h5 className="text-danger">{error.message}</h5>
            </Container>
        );
    }

    if (!revision || !data || data.builds.length <= 0) {
        return (
            <Container className="fullSize p-2 pt-5 text-center text-inverse">
                <h5>No report found</h5>
            </Container>
        );
    }

    return <BuildReport latest={data.builds[0]} revision={revision} />;
};
