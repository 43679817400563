import React, { FC, useState } from "react";
import {
    faChevronDown,
    faChevronUp,
    faTrashAlt
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    Card,
    Collapse,
    Form,
    FormControl,
    InputGroup,
    Row,
    Col
} from "react-bootstrap";
import {
    AnalyticsEventDataSchema,
    AnalyticsEventSchema
} from "../../../models/types";
import { AnalyticsEventDataLibraryItem } from "../../variationItems/analytics/AnalyticsEventDataLibraryItem";

interface Props {
    organizationName: string;
    event: AnalyticsEventSchema;
    applyLibEventUpdate: (eventId: number, frozen: boolean) => void;
    removeLibEvent: (eventId: number) => void;
}

export const AdminAnalyticsEventLibraryItemImplementation: FC<Props> = ({
    organizationName,
    event,
    applyLibEventUpdate,
    removeLibEvent
}) => {
    const [showEventData, updateShowEventData] = useState(false);

    const eventData = event.eventData.map(
        (eventData: AnalyticsEventDataSchema, index: number) => (
            <AnalyticsEventDataLibraryItem
                frozen={true}
                index={index}
                key={index}
                defaultValue={eventData}
                updateAnalyticsDataSchema={() =>
                    console.log("[DEBUG] Nothing should happen")
                }
            />
        )
    );

    return (
        <Card className="m-3">
            <Card.Header
                onClick={() => {
                    updateShowEventData(!showEventData);
                }}
                className="p-3"
            >
                <Row className="d-flex text-inverse justify-content-end align-items-center g-0">
                    <Col className="col-10">
                        <InputGroup className="m-0 p-0">
                            <InputGroup.Text>EventName</InputGroup.Text>
                            <FormControl
                                className="pe-5"
                                value={event.eventName}
                                readOnly={true}
                                onClick={(e: React.MouseEvent<HTMLElement>) => {
                                    e.stopPropagation();
                                }}
                            />
                        </InputGroup>
                    </Col>
                    <Col className="col-2 ps-2 d-flex align-items-center">
                        <div className="ps-2">
                            <FontAwesomeIcon
                                icon={
                                    showEventData ? faChevronUp : faChevronDown
                                }
                                size="1x"
                                className="mouseHover"
                            />
                        </div>
                    </Col>
                </Row>
            </Card.Header>
            <Collapse in={showEventData}>
                <div>
                    <Card.Body>
                        {eventData}
                        <Card className="m-1 p-2 no-border d-flex justify-content-end align-items-center">
                            <InputGroup>
                                <InputGroup.Text>Comment</InputGroup.Text>
                                <FormControl
                                    as="textarea"
                                    value={event.comment ? event.comment : ""}
                                    readOnly={true}
                                />
                            </InputGroup>
                        </Card>
                    </Card.Body>
                </div>
            </Collapse>
            <Card.Footer>
                <Row>
                    <Col className="col-6 d-flex align-items-center">
                        <Form.Check
                            className="me-3"
                            type="checkbox"
                            label="Frozen"
                            aria-label="Frozen"
                            title="Freeze event to unchangeable state"
                            checked={event.frozen}
                            disabled={
                                !event.organization &&
                                organizationName !== "Seepia"
                            }
                            onChange={(changeEvent: any) => {
                                applyLibEventUpdate(
                                    event.id,
                                    changeEvent.target.checked
                                );
                            }}
                        />
                    </Col>
                    <Col className="col-6 d-flex justify-content-end align-items-center">
                        {!event.frozen &&
                            (event.organization ||
                                organizationName === "Seepia") && (
                                <FontAwesomeIcon
                                    icon={faTrashAlt}
                                    size="lg"
                                    title="Remove event"
                                    className="text-primary ms-2 mouseHover"
                                    onClick={() => removeLibEvent(event.id)}
                                />
                            )}
                    </Col>
                </Row>
            </Card.Footer>
        </Card>
    );
};

export const AdminAnalyticsEventLibraryItem = React.memo(
    AdminAnalyticsEventLibraryItemImplementation
);
