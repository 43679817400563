import React, { FC } from "react";
import { useQuery } from "@apollo/client";
import { Card, Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { GET_ANALYTICS_NETWORK_BUILDS } from "../../../graphql/queries";
import {
    AnalyticsNetworkBuildData,
    AnalyticsNetworkBuildVariables,
    BatchBuild,
    NetworkBuild
} from "../../../models/types";
import { AnalyticsFunnelViewSelectionToggle } from "../../buttons/analytics/AnalyticsFunnelViewSelectionToggle";
import { DailyNetworkBuildAnalytics } from "../../simple/analytics/DailyNetworkBuildAnalytics";
import { UserQueryNetworkBuildAnalytics } from "../../simple/analytics/UserQueryNetworkBuildAnalytics";
import { AnalyticsFunnelViewComparisonToggle } from "../../buttons/analytics/AnalyticsFunnelViewComparisonToggle";

interface Props {
    build: BatchBuild;
}

export const AnalyticsNetworkBuilds: FC<Props> = ({ build }) => {
    const { loading, data, error } = useQuery<
        AnalyticsNetworkBuildData,
        AnalyticsNetworkBuildVariables
    >(GET_ANALYTICS_NETWORK_BUILDS, {
        variables: {
            buildId: build.id
        }
    });

    const renderList = () => {
        if (loading) {
            return <div>Loading...</div>;
        }
        if (error) {
            return <div>Error: {error.message}</div>;
        }
        if (!data) {
            return null;
        }
        if (data.analyticsNetworkBuilds.length === 0) {
            return (
                <Card.Body className="p-2 bread-text">
                    No network builds with analytics enabled.
                </Card.Body>
            );
        }
        return data.analyticsNetworkBuilds.map((networkBuild: NetworkBuild) => (
            <Row className="g-0 list-row ms-3 pb-1" key={networkBuild.id}>
                <Col className="d-flex align-items-center col-11 col-lg-5">
                    <AnalyticsFunnelViewSelectionToggle
                        className="mx-2"
                        reference="networkbuild"
                        networkBuild={networkBuild}
                    />

                    <OverlayTrigger
                        placement="top"
                        delay={{ show: 100, hide: 100 }}
                        overlay={
                            <Tooltip id={"networkbuild-filename"}>
                                {`Filename: ${networkBuild.filename}`}
                            </Tooltip>
                        }
                    >
                        <span className="ms-2 bread-text">
                            {networkBuild.network} {networkBuild.variationName}{" "}
                            {networkBuild.variation || ""}{" "}
                            {networkBuild.duration || ""}{" "}
                            {networkBuild.size ? `${networkBuild.size}MB` : ""}
                        </span>
                    </OverlayTrigger>

                    <AnalyticsFunnelViewComparisonToggle
                        reference="networkbuild"
                        className="mx-2"
                        networkBuild={networkBuild}
                    />
                </Col>
                <Col
                    style={{
                        paddingTop: "5px"
                    }}
                    className="col-3 d-none d-lg-block"
                >
                    <DailyNetworkBuildAnalytics id={networkBuild.id} />
                </Col>
                <Col
                    style={{
                        paddingTop: "5px"
                    }}
                    className="align-items-center col-3 d-none d-lg-block"
                >
                    <UserQueryNetworkBuildAnalytics id={networkBuild.id} />
                </Col>
                <Col className="col-1"></Col>
            </Row>
        ));
    };

    return (
        <>
            <Card.Body className="p-0 ps-2 pb-1">{renderList()}</Card.Body>
        </>
    );
};
