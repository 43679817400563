import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import {
    useApolloClient,
    useLazyQuery,
    useMutation,
    useSubscription
} from "@apollo/client";
import {
    CreateProductData,
    Product,
    ProductQueryVariables,
    ProductsData,
    ProductVariables,
    SortingMode,
    StateData
} from "../../models/types";
import {
    GET_LOCAL_STATE,
    GET_PRODUCTS_BY_USER_ORGANIZATION
} from "../../graphql/queries";
import { REMOVE_PRODUCT } from "../../graphql/mutations";
import { NewProductModal } from "../modals/NewProductModal";
import { ProductCardItem } from "../simple/ProductCardItem";
import {
    useLocalAnalyticsState,
    useLocalState,
    useSortingState
} from "../../graphql/hooks";
import { SortableTitles } from "../simple/SortableTitles";
import { loadCollapseState, sortItems } from "../../common/Helpers";
import {
    PRODUCT_CHANGED_SUBSCRIPTION,
    PRODUCT_METADATA_CHANGED_SUBSCRIPTION
} from "../../graphql/subscriptions";
import { NewProjectModal } from "../modals/NewProjectModal";
import { useNavigate } from "react-router-dom";
import { CreateNewDropdown } from "../dropdowns/CreateNewDropdown";
import { clearFunnelSelection } from "../../common/AnalyticsHelpers";
import { ActionPanel } from "../panels/ActionPanel";
import { AlertModal } from "../modals/AlertModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { UpButton } from "../buttons/UpButton";
import "../views/views.scss";

const ProductsPageImplementation = () => {
    const client = useApolloClient();
    const state = useLocalState();
    const navigate = useNavigate();
    const [currentProductId, updateProductId] = useState(-1);
    const [isModalVisible, setModalVisibleState] = useState(false);
    const [isCreateProjectVisible, setCreateProjectVisible] = useState(false);
    const [showRemoveAlert, updateShowRemoveAlert] = useState(false);
    const [toBeRemoved, updateToBeRemoved] = useState<number | undefined>(
        undefined
    );
    const [searchValue, setSearchValue] = useState<string>(
        state.searchTerm ? state.searchTerm : ""
    );
    const [sortedProducts, updateSortedProducts] = useState<Product[]>([]);
    const { useDarkMode } = useLocalState();
    const analyticsState = useLocalAnalyticsState();
    const sortingState = useSortingState();
    const scrollableRef = useRef(null);
    const [
        fetchProducts,
        {
            loading,
            // @ts-ignore
            data: { products } = {},
            error
        }
    ] = useLazyQuery<ProductsData, ProductQueryVariables>(
        GET_PRODUCTS_BY_USER_ORGANIZATION
    );

    useEffect(() => {
        const productTier = loadCollapseState(0);
        const projectTier = loadCollapseState(1);
        client.writeQuery<StateData, StateData>({
            query: GET_LOCAL_STATE,
            data: {
                state: {
                    ...state,
                    productTier: productTier,
                    projectTier: projectTier
                }
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        fetchProducts();
        clearFunnelSelection(client);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        fetchProducts({
            variables: {
                searchTerm: state.searchTerm
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.searchTerm]);

    useSubscription(PRODUCT_CHANGED_SUBSCRIPTION, {
        onData: ({
            data: { data: { productChangedNotification } = {} },
            client
        }) => {
            console.log(
                "PRODUCT_CHANGED_SUBSCRIPTION: ",
                productChangedNotification
            );
            const currentProductData = client.cache.readQuery<
                ProductsData,
                ProductVariables
            >({ query: GET_PRODUCTS_BY_USER_ORGANIZATION });

            const currentProducts = currentProductData?.products;
            if (currentProducts && productChangedNotification) {
                if (productChangedNotification.action !== 0) {
                    const newProducts = currentProducts.filter(
                        product =>
                            product.id !== productChangedNotification.product.id
                    );

                    if (productChangedNotification.action === 1) {
                        const currentProduct = currentProducts.find(
                            product =>
                                product.id ===
                                productChangedNotification.product.id
                        );
                        const newProduct = {
                            ...currentProduct,
                            ...productChangedNotification.product
                        };
                        client.cache.writeQuery<ProductsData, ProductVariables>(
                            {
                                query: GET_PRODUCTS_BY_USER_ORGANIZATION,
                                data: {
                                    products: [...newProducts, newProduct]
                                }
                            }
                        );
                    } else if (productChangedNotification.action === 2) {
                        client.cache.writeQuery<ProductsData, ProductVariables>(
                            {
                                query: GET_PRODUCTS_BY_USER_ORGANIZATION,
                                data: { products: newProducts }
                            }
                        );
                    }
                } else {
                    client.cache.writeQuery<ProductsData, ProductVariables>({
                        query: GET_PRODUCTS_BY_USER_ORGANIZATION,
                        data: {
                            products: [
                                ...currentProducts,
                                productChangedNotification.product
                            ]
                        }
                    });
                }
            }
        }
    });

    useSubscription(PRODUCT_METADATA_CHANGED_SUBSCRIPTION, {
        onData: ({
            data: { data: { productMetaChangedNotification } = {} }
        }) => {
            console.log(
                "PRODUCT_CHANGED_SUBSCRIPTION: ",
                productMetaChangedNotification
            );
        }
    });

    const [removeProduct] = useMutation<CreateProductData, ProductVariables>(
        REMOVE_PRODUCT
    );

    useEffect(() => {
        if (!loading && products) {
            const sortingMode =
                sortingState.sortingPriorities[0] || SortingMode.None;
            if (
                sortingMode === SortingMode.None &&
                !sortingState.organizationSorting
            ) {
                updateSortedProducts(products);
                return;
            }
            const sorted: Product[] = [...products];
            sorted.sort((a, b) =>
                sortItems(a, b, sortingMode, sortingState.organizationSorting)
            );
            updateSortedProducts(sorted);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading, sortingState, products, state.developerMode]);

    const items = sortedProducts?.map((product: Product) => (
        <ProductCardItem
            key={product.id}
            product={product}
            // sortingSettings={sortingSettings}
            // onRemove={() => removeProductById(product.id)}
            onRemove={() => {
                updateToBeRemoved(product.id);
                updateShowRemoveAlert(true);
            }}
            onCreate={
                state.developerMode
                    ? () => {
                          updateProductId(product.id);
                          setCreateProjectVisible(true);
                      }
                    : undefined
            }
        />
    ));

    const removeProductById = async (id: number) => {
        try {
            await removeProduct({
                variables: { productId: id },
                update: (cache, { data }) => {
                    const currentProductData = cache.readQuery<ProductsData>({
                        query: GET_PRODUCTS_BY_USER_ORGANIZATION
                    });
                    const currentProducts = currentProductData?.products;
                    if (currentProducts && data) {
                        const newProducts = currentProducts.filter(
                            product => product.id !== id
                        );
                        cache.writeQuery<ProductsData>({
                            query: GET_PRODUCTS_BY_USER_ORGANIZATION,
                            data: { products: newProducts }
                        });
                    }
                }
            });
            if (toBeRemoved) {
                updateToBeRemoved(undefined);
            }
        } catch (error) {
            console.log("[DEBUG] removeProductById error ", error);
        }
    };

    const onCreateDeveloperProject = () => {
        navigate("/createNewProject");
    };

    if (error) {
        return (
            <Container className="fullSize widthProducts p-2 pt-5 d-flex justify-content-center text-inverse">
                <p>Error: {error.message}</p>
            </Container>
        );
    }

    return (
        <Container
            className={`pb-0 fullSize ${
                analyticsState.analyticsEnabled
                    ? "widthProductsAnalytics px-2 pt-3"
                    : "widthProducts p-3 "
            }`}
        >
            <div
                className={`sticky-header ${
                    useDarkMode ? "sticky-header-dark" : ""
                }`}
            >
                <Row className="p-2 mb-2">
                    <ActionPanel
                        useDarkMode={useDarkMode}
                        setSearchValue={setSearchValue}
                        searchValue={searchValue}
                    />
                    <Col xs="auto">
                        <CreateNewDropdown
                            onNewProduct={() => setModalVisibleState(true)}
                            onCreateDeveloperProject={() =>
                                onCreateDeveloperProject()
                            }
                            isDeveloperMode={state.developerMode}
                        />
                    </Col>
                </Row>
                <SortableTitles />
            </div>

            <div
                className="justify-content-center scrollable-container"
                ref={scrollableRef}
            >
                {items && items.length > 0 ? (
                    <div className="p-1">{items}</div>
                ) : (
                    <div>
                        <h6 className="text-inverse-50 mt-4 text-center">
                            <FontAwesomeIcon icon={faMagnifyingGlass} />
                            <p>No items found. </p>
                            <p className="mt-4 bread-text">
                                Try searching with a product, project, revision
                                or organization title.
                            </p>
                        </h6>
                    </div>
                )}
                {items && items.length > 15 && (
                    <Col className="d-flex justify-content-center mt-1">
                        <UpButton scrollableRef={scrollableRef} />
                    </Col>
                )}
            </div>
            <NewProductModal
                show={isModalVisible}
                isDeveloperMode={state.developerMode}
                onClose={() => {
                    setModalVisibleState(false);
                }}
            />
            {isCreateProjectVisible && state.developerMode ? (
                <NewProjectModal
                    productId={currentProductId}
                    show={isCreateProjectVisible}
                    onClose={() => {
                        setCreateProjectVisible(false);
                    }}
                    developerMode={state.developerMode}
                />
            ) : null}
            {showRemoveAlert ? (
                <AlertModal
                    header={<h5>Remove Product</h5>}
                    component="Removing the product also removes its projects and revisions!"
                    visible={() => updateShowRemoveAlert(false)}
                    footer={
                        <Button
                            variant="danger"
                            className="m-2"
                            onClick={() => {
                                if (toBeRemoved) {
                                    removeProductById(toBeRemoved);
                                    updateShowRemoveAlert(false);
                                }
                            }}
                        >
                            Continue to Remove
                        </Button>
                    }
                />
            ) : null}
        </Container>
    );
};

export const ProductsPage = React.memo(ProductsPageImplementation);
