import React, { FC } from "react";
import { BuildJobData } from "../../models/types";
import { Button } from "react-bootstrap";
import styles from "./ToggleButtonComponent.module.scss";
import cx from "classnames";

interface ToggleButtonProps {
    jobData: BuildJobData;
    onToggle?: (newState: BuildJobData) => void;
}

export const ToggleButtonComponent: FC<ToggleButtonProps> = ({
    jobData,
    onToggle
}) => {
    const onClick = () => {
        if (onToggle) {
            const newState = { ...jobData, include: !jobData.include };
            onToggle(newState);
        }
    };

    return (
        <Button
            value={jobData.name}
            className={cx(styles.resultButton, {
                "btn-success": jobData.include,
                "btn-secondary": !jobData.include
            })}
            onClick={() => onClick()}
        >
            {jobData.name}
        </Button>
    );
};
