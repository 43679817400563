import React, { FC } from "react";
import { Product } from "../../models/types";
import { Card, Col, Container, ListGroup, Row } from "react-bootstrap";
import { FileUploadsItem } from "./FileUploadsItem";
import { FileUploadComponent } from "../editable/FileUploadComponent";

interface Props {
    product: Product;
    organizationName: (currentOrg: string) => string;
}

export const AssetsItem: FC<Props> = ({ product, organizationName }) => {
    const title = organizationName(product.organization.name);
    return (
        <>
            <Container className="text-inverse p-0" key={product.id}>
                {title && <h4 className="text-inverse-50 p-2 mt-3">{title}</h4>}
                <Card className="mb-2" key={product.id}>
                    <Card.Header className="py-2">
                        <Row className="d-flex align-items-center">
                            <Col className="d-flex pt-1 text-inverse-50">
                                <h5>{product.name}</h5>
                            </Col>
                            <Col className="d-flex justify-content-end align-items-center">
                                <FileUploadComponent productId={product.id} />
                            </Col>
                        </Row>
                    </Card.Header>
                    <ListGroup variant="flush">
                        <FileUploadsItem key={product.id} product={product} />
                    </ListGroup>
                </Card>
            </Container>
        </>
    );
};
