import React, { FC } from "react";
import { Modal } from "react-bootstrap";
import { VariationViewType } from "../../models/common";
import { Revision } from "../../models/types";
import { NewNetworkItem } from "../variationItems/NewNetworkItem";
import { NewBuildItem } from "../variationItems/NewBuildItem";
import { NewVariationItem } from "../variationItems/NewVariationItem";
import { NewLegacyLocalizationItem } from "../variationItems/NewLegacyLocalizationItem";
import { useLocalState } from "../../graphql/hooks";
import "./Modals.scss";

interface Props {
    show: boolean;
    type: VariationViewType;
    revision: Revision;
    onSave: (item: any) => void;
    onClose: () => void;
}

export const NewItemModal: FC<Props> = ({
    show,
    type,
    revision,
    onClose,
    onSave
}) => {
    const { useDarkMode } = useLocalState();
    if (!revision || !revision.buildData) {
        return null;
    }

    const allowedSets = revision?.revisionConfig?.sets?.filter(
        set => !revision?.revisionConfig?.setBlacklist?.includes(set)
    );

    const getItemByType = (type: VariationViewType) => {
        switch (type) {
            case VariationViewType.Builds:
                return (
                    <NewBuildItem
                        defaultValue={{
                            id: -1,
                            name: "",
                            sets: [],
                            duration: "15s",
                            path: "",
                            description: "",
                            include: false,
                            size: 2
                        }}
                        revision={revision}
                        callback={onSave}
                        allowedSets={allowedSets}
                    />
                );
            case VariationViewType.Variations:
                return (
                    <NewVariationItem
                        defaultValue={{
                            id: -1,
                            name: "",
                            description: "",
                            sets: [{ name: "", set: "" }],
                            include: false
                        }}
                        revision={revision}
                        callback={onSave}
                        allowedSets={allowedSets}
                    />
                );
            case VariationViewType.Networks:
                return (
                    <NewNetworkItem
                        defaultValue={{
                            id: -1,
                            network: "",
                            sizeTargets: [],
                            analytics: false
                        }}
                        revision={revision}
                        callback={onSave}
                    />
                );
            case VariationViewType.LegacyLocalizations:
                return (
                    <NewLegacyLocalizationItem
                        defaultValue={{
                            id: -1,
                            name: "",
                            localization: "",
                            include: false
                        }}
                        callback={onSave}
                    />
                );
            default:
                break;
        }
    };

    return (
        <Modal
            show={show}
            onHide={onClose}
            backdrop="static"
            keyboard={false}
            className={useDarkMode ? "modal-dark" : ""}
        >
            <Modal.Header closeButton>
                <Modal.Title>Add new item to {type}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-body d-flex justify-content-center align-items-center">
                {getItemByType(type)}
            </Modal.Body>
        </Modal>
    );
};
