import React, { FC } from "react";
import { Dropdown } from "react-bootstrap";
import { Project, RevisionsData, RevisionsVariables } from "../../models/types";
import { useQuery } from "@apollo/client";
import { GET_REVISIONS } from "../../graphql/queries";

interface Props {
    project: Project;
}

const CreateProjectDropdownItemImplementation: FC<Props> = ({ project }) => {
    const { loading } = useQuery<RevisionsData, RevisionsVariables>(
        GET_REVISIONS,
        {
            variables: { projectId: project.id },
            fetchPolicy: "cache-first"
        }
    );
    return (
        <Dropdown.Item eventKey={project.name} disabled={loading}>
            {project.name}
        </Dropdown.Item>
    );
};

export const CreateProjectDropdownItem = React.memo(
    CreateProjectDropdownItemImplementation
);
