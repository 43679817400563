import React, { FC } from "react";
import { useQuery } from "@apollo/client";
import { GET_ME } from "../graphql/queries";
import { MeData } from "../models/types";

interface Props {
    scopes: string[];
    children: any;
}

// @ts-ignore
const ProtectedComponentImplementation: FC<Props> = ({ scopes, children }) => {
    const { data: { me } = {} } = useQuery<MeData>(GET_ME, {
        fetchPolicy: "cache-first"
    });

    if (!me || !me.scopes) {
        return null;
    }
    if (!me.scopes.some((element: string) => scopes.indexOf(element) >= 0)) {
        return null;
    }

    return <>{children}</>;
};

export const ProtectedComponent = React.memo(ProtectedComponentImplementation);
