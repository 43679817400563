import { useMutation, useQuery } from "@apollo/client";
import React, { FC } from "react";
import { Button, Modal } from "react-bootstrap";
import {
    ADMIN_DELETE_ANALYTICS_LIB_EVENT,
    ADMIN_UPDATE_ANALYTICS_LIB_EVENT
} from "../../../graphql/mutations";
import { GET_ANALYTICS_EVENTS_BY_ORGANIZATION } from "../../../graphql/queries";
import {
    AnalyticsEventByOrgVariables,
    AnalyticsEventsById,
    AnalyticsEventSchema,
    AnalyticsEventVariables,
    UpdateAnalyticsLibEventResult
} from "../../../models/types";
import { AdminAnalyticsEventLibraryItem } from "../../views/admin/AdminAnalyticsEventLibraryItem";
import { useLocalState } from "../../../graphql/hooks";

interface Props {
    organizationName: string;
    organizationId: number;
    show: boolean;
    onClose: () => void;
}

export const AnalyticsLibEventsPerOrganizationModal: FC<Props> = ({
    organizationName,
    organizationId,
    show,
    onClose
}) => {
    const { useDarkMode } = useLocalState();
    const { data: { getEventsByOrgId } = {} } = useQuery<
        AnalyticsEventsById,
        AnalyticsEventByOrgVariables
    >(GET_ANALYTICS_EVENTS_BY_ORGANIZATION, {
        fetchPolicy: "network-only",
        variables: {
            organizationId: organizationId
        },
        skip: organizationId === 0
    });

    const [removeAnalyticsLibEventMutation] = useMutation<
        { adminDeleteAnalyticsLibEvent: { success: boolean } },
        { eventId: number }
    >(ADMIN_DELETE_ANALYTICS_LIB_EVENT);

    const [analyticsLibEventMutation] = useMutation<
        UpdateAnalyticsLibEventResult,
        AnalyticsEventVariables
    >(ADMIN_UPDATE_ANALYTICS_LIB_EVENT);

    const removeLibEvent = async (eventId: number) => {
        try {
            await removeAnalyticsLibEventMutation({
                variables: {
                    eventId: eventId
                },
                update: (cache, { data }) => {
                    const currentSavedLibEventsData =
                        cache.readQuery<AnalyticsEventsById>({
                            query: GET_ANALYTICS_EVENTS_BY_ORGANIZATION,
                            variables: {
                                organizationId: organizationId
                            }
                        });
                    const currentSavedLibEvents =
                        currentSavedLibEventsData?.getEventsByOrgId;
                    if (
                        currentSavedLibEvents &&
                        data &&
                        data.adminDeleteAnalyticsLibEvent
                    ) {
                        const newQueries = currentSavedLibEvents.filter(
                            element => element.id !== eventId
                        );
                        cache.writeQuery<AnalyticsEventsById>({
                            query: GET_ANALYTICS_EVENTS_BY_ORGANIZATION,
                            data: { getEventsByOrgId: newQueries },
                            variables: {
                                organizationId: organizationId
                            }
                        });
                    }
                }
            });
        } catch (error) {
            console.log("[DEBUG] removeLibEvent error: ", error);
        }
    };

    const applyLibEventUpdate = async (eventId: number, frozen: boolean) => {
        try {
            await analyticsLibEventMutation({
                variables: {
                    eventId: eventId,
                    frozen: frozen
                }
            });
        } catch (error) {
            console.log("[DEBUG] applyLibEventUpdate error ", error);
        }
    };

    const eventItems = getEventsByOrgId?.map(
        (event: AnalyticsEventSchema, index: number) => (
            <AdminAnalyticsEventLibraryItem
                organizationName={organizationName}
                event={event}
                key={event.eventName + index}
                applyLibEventUpdate={applyLibEventUpdate}
                removeLibEvent={removeLibEvent}
            />
        )
    );

    return (
        <Modal
            show={show}
            onHide={onClose}
            backdrop="static"
            keyboard={false}
            className={useDarkMode ? "modal-dark" : ""}
        >
            <Modal.Header closeButton>
                <Modal.Title>{organizationName}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-body p-2">
                {eventItems && eventItems.length > 0 ? (
                    eventItems
                ) : (
                    <div className="d-flex justify-content-center">
                        <p>No events saved in the library.</p>
                    </div>
                )}
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-center">
                <Button
                    variant="secondary"
                    onClick={(e: React.MouseEvent<HTMLElement>) => {
                        e.stopPropagation();
                        onClose();
                    }}
                >
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
