import React, { FC } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Revision } from "../../../models/types";
import { DetectedSetsPopover } from "../../selectable/DetectedSetsPopover";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../views.scss";

interface setBlacklistProps {
    revision: Revision;
    developerMode: boolean;
    updateRevisionConfig: (setBlacklist: string[]) => void;
}

export const SetBlacklist: FC<setBlacklistProps> = props => {
    const { revision, updateRevisionConfig } = props;

    const toggleSet = (set: string) => {
        const blacklist = revision.revisionConfig?.setBlacklist;

        if (!blacklist) {
            updateRevisionConfig([set]);
            return;
        }

        if (blacklist.includes(set)) {
            const list = blacklist.filter(currSet => currSet !== set);
            updateRevisionConfig(list);
            return;
        } else {
            updateRevisionConfig([...blacklist, set]);
            return;
        }
    };

    return (
        <Container className="my-2">
            <Row>
                <Col className="mb-1">
                    {revision?.revisionConfig?.sets &&
                    revision?.revisionConfig?.sets.length > 0 ? (
                        <DetectedSetsPopover
                            title={
                                <>
                                    <span className="pe-2">Sets</span>
                                    <FontAwesomeIcon
                                        size="sm"
                                        icon={faCaretDown}
                                    />
                                </>
                            }
                            onToggle={set => toggleSet(set)}
                            allSets={revision?.revisionConfig?.sets}
                            usedSets={revision?.revisionConfig?.setBlacklist}
                        />
                    ) : null}
                </Col>
            </Row>
            <Row>
                <Col>
                    {revision.revisionConfig?.setBlacklist?.map(set => {
                        return (
                            <Button
                                key={set}
                                className="mt-1 me-1 btn-sm btn-secondary"
                                onClick={() => toggleSet(set)}
                            >
                                {set}
                            </Button>
                        );
                    })}
                </Col>
            </Row>
        </Container>
    );
};
