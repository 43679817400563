import React, { FC, useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { Alert, Button, Card, FormControl, InputGroup } from "react-bootstrap";
import { validateBuildSetName, validateUuid } from "../../../common/Helpers";
import { UPDATE_ORGANIZATION } from "../../../graphql/mutations";
import {
    OrganiazationType,
    Organization,
    OrganizationVariables,
    UpdateOrganizationData
} from "../../../models/types";
import { SelectableOrganizationType } from "../../selectable/admin/SelectableOrganizationType";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboard } from "@fortawesome/free-solid-svg-icons";
import { OrganizationConfig } from "./OrganizationConfig";

interface Props {
    defaultValue: Organization;
}

export const EditableOrganization: FC<Props> = ({ defaultValue }) => {
    const [updating, updateUpdating] = useState(false);
    const [organization, updateOrganization] =
        useState<Organization>(defaultValue);
    const [identifier, updateIdentifier] = useState("");
    const [organizationMutation] = useMutation<
        UpdateOrganizationData,
        OrganizationVariables
    >(UPDATE_ORGANIZATION);

    useEffect(() => {
        if (organization.id !== defaultValue.id) {
            updateOrganization(defaultValue);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultValue]);

    const applyOrganizationUpdate = async (
        updatedOrganization: Organization
    ) => {
        try {
            if (updatedOrganization !== organization) {
                updateOrganization(updatedOrganization);
            }
            updateUpdating(true);
            await organizationMutation({
                variables: {
                    organizationId: Number(updatedOrganization.id),
                    name: updatedOrganization.name,
                    type: updatedOrganization.organizationType
                }
            });
            updateUpdating(false);
        } catch (error) {
            console.log("[DEBUG] applyOrganizationUpdate error ", error);
        }
    };

    const createIdentifierAndBucket = async () => {
        if (validateUuid(identifier)) {
            updateUpdating(true);
            try {
                const result = await organizationMutation({
                    variables: {
                        organizationId: Number(organization.id),
                        organizationIdentifier: identifier
                    }
                });
                if (result.data) {
                    updateOrganization(result.data.updateOrganization);
                }
                console.log("[DEBUG] result ", result);
            } catch (error) {
                console.log(
                    "[DEBUG] createIdentifierAndBucket failed with error: ",
                    error
                );
            }
            updateUpdating(false);
        }
    };

    const copyToClipboard = async (identifier?: string) => {
        if (identifier === undefined) {
            return;
        }
        await navigator.clipboard.writeText(identifier);
    };

    return (
        <Card className="mt-2 border-secondary w-100">
            <Card.Header className="text-inverse">
                {organization.name}
            </Card.Header>
            <Card.Body>
                <InputGroup className="m-0 p-0">
                    <InputGroup.Text>Name</InputGroup.Text>
                    <FormControl
                        value={organization.name}
                        onChange={(event: any) => {
                            const newName = event.target.value;
                            if (validateBuildSetName(newName)) {
                                updateOrganization({
                                    ...organization,
                                    name: newName
                                });
                            }
                        }}
                        onBlur={() => {
                            applyOrganizationUpdate(organization);
                        }}
                    />
                </InputGroup>
                <SelectableOrganizationType
                    organization={organization}
                    updateType={(newType: OrganiazationType) => {
                        applyOrganizationUpdate({
                            ...organization,
                            organizationType: newType
                        });
                    }}
                />
                {organization.organizationIdentifier ? (
                    <div className="d-flex justify-content-center align-items-center mb-3">
                        <InputGroup>
                            <InputGroup.Text>Identifier</InputGroup.Text>
                            <FormControl
                                value={organization.organizationIdentifier}
                                disabled={true}
                            />
                        </InputGroup>
                        <FontAwesomeIcon
                            icon={faClipboard}
                            className="mouseHover ms-2 text-success"
                            onClick={(event: any) => {
                                event.preventDefault();
                                event.stopPropagation();
                                copyToClipboard(
                                    organization.organizationIdentifier
                                );
                            }}
                        />
                    </div>
                ) : (
                    <>
                        <Card className="inner-card">
                            <Card.Header className="text-inverse">
                                Identifier and bucket
                            </Card.Header>
                            <Card.Body>
                                <InputGroup>
                                    <InputGroup.Text>
                                        New Identifier
                                    </InputGroup.Text>
                                    <FormControl
                                        value={identifier}
                                        onChange={(event: any) => {
                                            const newIdentifier =
                                                event.target.value;
                                            if (validateUuid(newIdentifier)) {
                                                updateIdentifier(newIdentifier);
                                            }
                                        }}
                                    />
                                </InputGroup>
                                <Button
                                    className="mt-3"
                                    disabled={updating}
                                    onClick={createIdentifierAndBucket}
                                >
                                    Create Bucket
                                </Button>
                            </Card.Body>
                        </Card>
                        <Alert variant="danger" className="mt-2">
                            Missing identifier and bucket!
                        </Alert>
                    </>
                )}
                <OrganizationConfig organization={organization} />
            </Card.Body>
        </Card>
    );
};
