import React from "react";
import { useLocation } from "react-router-dom";
import { Button } from "react-bootstrap";
import { useAuth0 } from "@auth0/auth0-react";

export const ErrorPage = () => {
    const { logout } = useAuth0();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const error = queryParams.get("error");
    const errorDescription = queryParams.get("error_description");

    if (
        error === "access_denied" &&
        errorDescription === "email_not_verified"
    ) {
        return (
            <div className="d-flex flex-column justify-content-center align-items-center pt-5">
                <h1>Email not verified</h1>
                <p>
                    Before logging in you are required to verify your email
                    address. Verification email has been already sent to you.
                </p>
                <Button onClick={() => logout()}>Login</Button>
            </div>
        );
    }

    if (
        error === "access_denied" &&
        errorDescription === "missing_invitation"
    ) {
        return (
            <div className="d-flex flex-column justify-content-center align-items-center pt-5">
                <h1>Missing invitation</h1>
                <p>
                    Signups without invitations are not supported currently.
                    Please wait to be invited before retrying to signup.
                </p>
                <Button onClick={() => logout()}>Retry</Button>
            </div>
        );
    }

    if (
        error === "access_denied" &&
        (errorDescription === "user_initialization_not_complete" ||
            errorDescription === "user_initialization_failed")
    ) {
        return (
            <div className="d-flex flex-column justify-content-center align-items-center pt-5">
                <h1>Missing User Error</h1>
                <p>Please contact platform admins.</p>
                <Button onClick={() => logout()}>Retry</Button>
            </div>
        );
    }

    return (
        <div className="d-flex flex-column justify-content-center align-items-center pt-5">
            <h1>{error || "Something went wrong!"}</h1>
            <p>
                {errorDescription ||
                    "We're sorry, but we couldn't log you in. Please try again later."}
            </p>
            <Button onClick={() => logout()}>Retry</Button>
        </div>
    );
};
