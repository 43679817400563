import { useQuery } from "@apollo/client";
import React, { FC, useState } from "react";
import { Dropdown, DropdownButton, InputGroup } from "react-bootstrap";
import { GET_ORGANIZATIONS } from "../../../graphql/queries";
import { Organization, OrganizationData, User } from "../../../models/types";

interface Props {
    user: User;
    updateOrganization: (organizationId: number) => void;
}

export const UserOrganizationDropdown: FC<Props> = ({
    user,
    updateOrganization
}) => {
    const { data: { organizations } = {} } =
        useQuery<OrganizationData>(GET_ORGANIZATIONS);

    const generateAlternatives = organizations?.map(
        (organization: Organization, index: number) => (
            <Dropdown.Item key={index} eventKey={organization.id.toString()}>
                {organization.name}
            </Dropdown.Item>
        )
    );

    const [showWarning, setShowWarning] = useState(false);

    return (
        <div>
            {showWarning && (
                <div className="bread-text mb-2">
                    Please note! Selecting an organization will reset user
                    rights.
                </div>
            )}
            <InputGroup>
                <InputGroup.Text>Organization</InputGroup.Text>
                <DropdownButton
                    title={user?.organization?.name}
                    id="user-organization-dropdown"
                    variant="primary"
                    onToggle={isOpen => setShowWarning(isOpen)}
                    onSelect={(selectedItem: string | null) => {
                        if (selectedItem) {
                            updateOrganization(Number(selectedItem));
                        }
                    }}
                >
                    {generateAlternatives}
                </DropdownButton>
            </InputGroup>
        </div>
    );
};
