import React, { FC, useEffect, useRef, useState } from "react";
import {
    Badge,
    Card,
    Collapse,
    FormControl,
    InputGroup
} from "react-bootstrap";
import { MutatorProperty } from "../../../models/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { useMutatorState } from "../../../graphql/hooks";
import styles from "./CustomTypeCard.module.scss";
import cx from "classnames";

interface Props {
    property: MutatorProperty;
    getVariantByType: (type: string) => string;
    onChange: (name: string, value: number) => void;
}

const MutatorNumberCardImplementation: FC<Props> = ({
    property,
    getVariantByType,
    onChange
}) => {
    const inputRef = useRef(null);
    const mutatorState = useMutatorState();
    const [value, updateValue] = useState(property.value);
    const [showMeta, updateShowMeta] = useState(mutatorState.showMeta);
    const [notification, updateNotification] = useState<string | undefined>(
        undefined
    );

    useEffect(() => {
        if (showMeta !== mutatorState.showMeta) {
            updateShowMeta(mutatorState.showMeta);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mutatorState.showMeta]);

    useEffect(() => {
        if (property.value && property.value !== value) {
            updateValue(property.value);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [property.value]);

    const forceClickBehaviour = () => {
        // Some browers do not focus the form properly when clicking increase / decrease
        if (inputRef.current) {
            // @ts-ignore
            inputRef.current.focus();
        }
    };

    return (
        <Card className="inner-card text-inverse m-2">
            <Card.Header
                className={`d-flex align-items-center ${styles.cardHeader}`}
            >
                <span className={styles.headerText}>{property.name}</span>
                <Badge className="mx-2" bg={getVariantByType(property.type)}>
                    {property.type}
                </Badge>
                {property.meta ? (
                    <div
                        className="float-end ms-auto mouseHover"
                        onClick={() => updateShowMeta(!showMeta)}
                        title="Help and additional info"
                    >
                        <FontAwesomeIcon
                            icon={faCircleInfo}
                            className={cx({
                                "text-inverse-25": !showMeta,
                                "text-inverse-50": showMeta
                            })}
                        />
                    </div>
                ) : null}
            </Card.Header>
            <div>
                <Card.Body className={`${styles.cardBody} ms-1`}>
                    <InputGroup>
                        <InputGroup.Text>Value</InputGroup.Text>
                        <FormControl
                            ref={inputRef}
                            className={styles.inputGroup}
                            type="number"
                            value={value}
                            onClick={forceClickBehaviour}
                            onChange={e => {
                                const newValueAsNumber = Number(e.target.value);
                                const trimmedInput = e.target.value.trim();
                                if (
                                    // @ts-ignore
                                    !(
                                        !isNaN(parseFloat(trimmedInput)) &&
                                        // @ts-ignore
                                        isFinite(trimmedInput)
                                    )
                                ) {
                                    updateNotification(
                                        "Valid number is required"
                                    );
                                } else if (
                                    property.meta?.min &&
                                    newValueAsNumber < property.meta.min
                                ) {
                                    updateNotification(
                                        `Minimum allowed value ${property.meta.min}`
                                    );
                                } else if (
                                    property.meta?.max &&
                                    newValueAsNumber > property.meta.max
                                ) {
                                    updateNotification(
                                        `Maximum allowed value ${property.meta.max}`
                                    );
                                } else {
                                    updateNotification(undefined);
                                }
                                updateValue(e.target.value);
                            }}
                            onBlur={e => {
                                const newValue = Number(e.target.value);
                                const trimmedInput = e.target.value.trim();
                                if (
                                    // @ts-ignore
                                    !(
                                        !isNaN(parseFloat(trimmedInput)) &&
                                        // @ts-ignore
                                        isFinite(trimmedInput)
                                    )
                                ) {
                                    updateNotification(
                                        "Valid number is required"
                                    );
                                    return;
                                } else if (
                                    property.meta?.min &&
                                    newValue < property.meta.min
                                ) {
                                    updateNotification(
                                        `Minimum allowed value ${property.meta.min}`
                                    );
                                    return;
                                } else if (
                                    property.meta?.max &&
                                    newValue > property.meta.max
                                ) {
                                    updateNotification(
                                        `Maximum allowed value ${property.meta.max}`
                                    );
                                    return;
                                } else {
                                    updateNotification(undefined);
                                }
                                onChange(property.name, value);
                            }}
                        />
                    </InputGroup>
                </Card.Body>
            </div>
            {property.meta ? (
                <Collapse in={showMeta}>
                    <div>
                        <Card.Footer className={styles.footerInfo}>
                            {property.meta.description ? (
                                <div className="bread-text text-inverse-50">
                                    {property.meta.description}
                                </div>
                            ) : (
                                <span className="bread-text text-inverse-50">
                                    No description provided.
                                </span>
                            )}
                            {property.meta.min || property.meta.max ? (
                                <div className="bread-text text-info">
                                    {property.meta.min ? (
                                        <div>Min: {property.meta.min} </div>
                                    ) : null}
                                    {property.meta.max ? (
                                        <div>Max: {property.meta.max} </div>
                                    ) : null}
                                </div>
                            ) : null}
                        </Card.Footer>
                    </div>
                </Collapse>
            ) : null}
            {notification ? (
                <Card.Footer className={styles.footerInfo}>
                    <div className="bread-text text-danger">{notification}</div>
                </Card.Footer>
            ) : null}
        </Card>
    );
};

export const MutatorNumberCard = React.memo(MutatorNumberCardImplementation);
