import React from "react";
import { Card } from "react-bootstrap";

export const ExperimentalDeveloperProjectWarningBoxCLI = () => {
    return (
        <Card className="mt-2 border-info">
            <Card.Body className="text-inverse">
                This base project requires Seepia CLI to work as intended.
                <br />
                More information about the CLI and it's usage...
                <ul>
                    <li>
                        <a
                            href="https://app.clickup.com/20449045/v/dc/kg1rn-15944/kg1rn-14564"
                            target="_blank noopener noreffer"
                        >
                            Wiki
                        </a>
                    </li>
                    <li>
                        <a
                            href="https://app.clickup.com/20449045/v/dc/kg1rn-15944/kg1rn-15784"
                            target="_blank noopener noreffer"
                        >
                            Installation
                        </a>
                    </li>
                    <li>
                        <a
                            href="https://app.clickup.com/20449045/v/dc/kg1rn-15944/kg1rn-15764"
                            target="_blank noopener noreffer"
                        >
                            Usage
                        </a>
                    </li>
                    <li>
                        <a
                            href="https://app.clickup.com/t/20449045/INT_TASK-922"
                            target="_blank noopener noreffer"
                        >
                            Bugs Ticket for Early Testers
                        </a>
                    </li>
                    <li>
                        <a
                            href="https://git.seepiagames.com/technology/platform-developer-cli"
                            target="_blank noopener noreffer"
                        >
                            Gitlab Project
                        </a>
                    </li>
                    <li>
                        <a
                            href="https://git.seepiagames.com/technology/platform-developer-cli/-/releases"
                            target="_blank noopener noreffer"
                        >
                            Gitlab Releases / Changelog
                        </a>
                    </li>
                </ul>
            </Card.Body>
        </Card>
    );
};
