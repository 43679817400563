import React, { FC } from "react";
import { Row } from "react-bootstrap";
import { Build } from "../../../models/types";
import { BuildItem } from "../../variationItems/BuildItem";
import { VariationViewItemProps } from "../../../models/common";
import { SimpleToast } from "../../simple/SimpleToast";
import { BuildItemList } from "../../variationItems/BuildItemList";
import "../views.scss";

export const Builds: FC<VariationViewItemProps> = ({
    revision,
    developerMode,
    shouldBeVisible
}) => {
    if (!revision || !revision.buildData) {
        return null;
    }
    const allowedSets = revision?.revisionConfig?.sets?.filter(
        set => !revision?.revisionConfig?.setBlacklist?.includes(set)
    );

    const version = revision?.buildData?.version || -1;

    const items = revision?.buildData?.builds.map((object: Build) =>
        developerMode ? (
            <BuildItem
                key={object.id}
                defaultValue={object}
                revision={revision}
                allowedSets={allowedSets}
                version={version}
                shouldBeVisible={shouldBeVisible}
            />
        ) : (
            <BuildItemList
                key={object.id}
                defaultValue={object}
                revision={revision}
                version={version}
            />
        )
    );
    return (
        <Row className="fullSize">
            {items}
            <SimpleToast />
        </Row>
    );
};
