import React, { FC, useState } from "react";
import { Dropdown, FormControl } from "react-bootstrap";
import { MutatorEnums, MutatorProperty } from "../../../models/types";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import styles from "./MutatorDropdown.module.scss";

interface Props {
    isOptional: boolean;
    property: MutatorProperty | any;
    enums: MutatorEnums;
    getVariantByType: (type: string) => string;
    onChange: (value: string) => void;
}

const EnumDropdownImplementation: FC<Props> = ({
    isOptional,
    property,
    enums,
    getVariantByType,
    onChange
}) => {
    const [searchString, updateSearchString] = useState<string>("");
    const showSearch = enums[property.type].options.length > 8;
    const sortedOptions =
        showSearch && searchString !== ""
            ? enums[property.type].options.filter(element =>
                  element.name
                      .toLowerCase()
                      .includes(searchString.toLowerCase())
              )
            : enums[property.type].options;
    const options = sortedOptions.map((element: any, index: number) => (
        <Dropdown.Item
            key={index}
            onClick={() => {
                onChange(element.name);
            }}
        >
            {element.name}
        </Dropdown.Item>
    ));
    if (isOptional) {
        options.unshift(
            <Dropdown.Item
                key={-1}
                onClick={() => {
                    onChange("None");
                }}
            >
                None
            </Dropdown.Item>
        );
    }
    return (
        <>
            <div>
                <Dropdown id={property.name}>
                    <Dropdown.Toggle
                        variant={getVariantByType(property.type)}
                        id="dropdown-basic"
                        className={styles.toggleButton}
                    >
                        {property.value}
                    </Dropdown.Toggle>
                    <DropdownMenu
                        style={{
                            maxHeight: "400px",
                            overflowY: "auto"
                        }}
                    >
                        {showSearch ? (
                            <div className="sticky-top mt-0 px-2 pt-0 pb-1 w-100 bg-dark">
                                <FormControl
                                    type="text"
                                    placeholder="Search..."
                                    value={searchString}
                                    onChange={e =>
                                        updateSearchString(e.target.value)
                                    }
                                />
                            </div>
                        ) : null}
                        {options}
                    </DropdownMenu>
                </Dropdown>
            </div>
        </>
    );
};

export const EnumDropdown = React.memo(EnumDropdownImplementation);
