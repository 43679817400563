import React, { useEffect } from "react";
import { MainPage } from "./components/pages/MainPage";
import { AuthorizedApolloProvider } from "./graphql/client";
import { useAuth0 } from "@auth0/auth0-react";
import { LoadingSpinner } from "./components/simple/LoadingSpinner";
import { ScrollRestoration } from "react-router-dom";
import { ErrorPage } from "./components/pages/ErrorPage";
import { ConsentBanner } from "./components/warnings/ConsentBanner";

const App: React.FC = () => {
    const { isLoading, isAuthenticated, error } = useAuth0();

    const loadGAScript = () => {
        const { REACT_APP_GA_ID } = process.env;
        if (REACT_APP_GA_ID === undefined) {
            return;
        }

        const script1 = document.createElement("script");
        script1.async = true;
        script1.src = `https://www.googletagmanager.com/gtag/js?id=${REACT_APP_GA_ID}`;
        document.head.appendChild(script1);

        const script2 = document.createElement("script");
        script2.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', '${REACT_APP_GA_ID}');
    `;
        document.head.appendChild(script2);
    };

    const handleConsent = (consent: boolean) => {
        if (consent) {
            loadGAScript();
        }
    };

    useEffect(() => {
        const consentGiven = localStorage.getItem("consentGiven");
        if (consentGiven === "true") {
            loadGAScript();
        }
    }, []);

    if (isLoading) {
        return (
            <div className="d-flex justify-content-center my-5">
                <LoadingSpinner header="Loading... " color="text-black-50" />
            </div>
        );
    }

    const alwaysScrollToTop = ["/admin", "/organization"];

    if (error) {
        return <ErrorPage />;
    }

    return (
        <AuthorizedApolloProvider>
            <ConsentBanner onConsent={handleConsent} />
            {isAuthenticated ? (
                <>
                    <MainPage />
                    <ScrollRestoration
                        getKey={location => {
                            if (alwaysScrollToTop.includes(location.pathname)) {
                                return "scroll-to-top";
                            }
                            return location.pathname;
                        }}
                    />
                </>
            ) : (
                <div className="d-flex justify-content-center my-5">
                    <LoadingSpinner
                        header="Authenticating... "
                        color="text-black-50"
                    />
                </div>
            )}
        </AuthorizedApolloProvider>
    );
};

export default App;
