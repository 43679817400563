import React from "react";
import { Container, Row, Card, Button, Col } from "react-bootstrap";
import { useQuery } from "@apollo/client";
import { MeData } from "../../models/types";
import { GET_ME } from "../../graphql/queries";
import { Link } from "react-router-dom";
import "./pages.scss";

export const OrganizationPage = () => {
    // const [user, updateUser] = useState("");
    // const [info, updateInfo] = useState("");

    const { data: { me } = {} } = useQuery<MeData>(GET_ME, {
        fetchPolicy: "cache-first"
    });
    // const [addUserToOrganization] = useMutation(ADD_USER_TO_ORGANIZATION);

    if (!me || !me.organization) {
        return (
            <Container className="main text-center mt-5 text-inverse-50">
                <Row>
                    <Col>
                        <h5>You are not part of any organization</h5>
                    </Col>
                </Row>
            </Container>
        );
    }

    // const addUser = async () => {
    //     updateInfo("Adding user...");
    //     try {
    //         const response = await addUserToOrganization({
    //             variables: { email: user }
    //         });
    //         if (response.data.addUserToOrganization.success) {
    //             updateInfo("Success!");
    //             setTimeout(() => {
    //                 updateUser("");
    //                 updateInfo("");
    //             }, 4000);
    //         }
    //     } catch (error) {
    //         // @ts-ignore
    //         updateInfo(error.message);
    //         setTimeout(() => {
    //             updateInfo("");
    //         }, 4000);
    //     }
    // };

    return (
        <Container className="mt-5 text-center text-inverse-50">
            <Row>
                <Col>
                    <h5>Your organization: {me.organization.name}</h5>
                </Col>
            </Row>
            {/* <Row className="w-100">
                <Col>
                    <ProtectedComponent scopes={["admin"]}>
                        <Container className="d-flex justify-content-center mt-4">
                            <Card>
                                <Card.Header>
                                    Add user to current organization
                                </Card.Header>
                                <Card.Body>
                                    <InputGroup className="mb-3">
                                        <FormControl
                                            value={user}
                                            placeholder="email..."
                                            onChange={(event: any) => {
                                                const searchValue = event.target.value.toLowerCase();
                                                updateUser(searchValue);
                                            }}
                                        />
                                    </InputGroup>
                                    <Button onClick={addUser}>Add</Button>
                                </Card.Body>
                                {info ? (
                                    <Card.Footer className="text-danger">
                                        {info}
                                    </Card.Footer>
                                ) : null}
                            </Card>
                        </Container>
                    </ProtectedComponent>
                </Col>
            </Row> */}
            <Row className="d-flex justify-content-center mt-3">
                <Col className="col-12 col-md-6 col-xl-4">
                    <Card>
                        <Card.Header className="text-inverse">
                            Assets Management
                        </Card.Header>
                        <Card.Body>
                            <Link to={"/assets"}>
                                <Button>File Uploads</Button>
                            </Link>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};
