import React, { FC } from "react";
import { Button, Card } from "react-bootstrap";
import { AditorOptimizeTask } from "../../../models/aditor";

interface Props {
    task: AditorOptimizeTask;
}

const AditorOptimizeResultCardImplementation: FC<Props> = ({ task }) => {
    console.log("[DEBUG] !task! ", task);
    if (!task.result) {
        return null;
    }

    if (!task.result.success) {
        return (
            <Card className="mt-2">
                <Card.Header className="mx-0">Optimize Task</Card.Header>
                <Card.Body>
                    <p>
                        Optimizing {task.taskId} failed: {task.result.error}
                    </p>
                </Card.Body>
            </Card>
        );
    }
    const sizeReduction =
        task.result.originalSize && task.result.optimizedSize
            ? task.result.originalSize - task.result.optimizedSize
            : "unknown";
    const sizeReductionPercentage =
        task.result.originalSize && task.result.optimizedSize
            ? Number(
                  (
                      ((task.result.originalSize - task.result.optimizedSize) /
                          task.result.originalSize) *
                      100
                  ).toFixed(2)
              )
            : "unknown";

    const downloadFile = () => {
        const win = window.open(task.result?.url, "_blank");
        if (win !== null) {
            win.focus();
        }
    };

    return (
        <Card className="mt-2">
            <Card.Header className="mx-0">Optimize Task</Card.Header>
            <Card.Body>
                <h5>{task.taskId}</h5>
                <p>
                    <b>Success:</b> {task.result.success}
                </p>
                <p>
                    <b>Original size:</b> {task.result.originalSize}
                </p>
                <p>
                    <b>Optimized size:</b> {task.result.optimizedSize}
                </p>
                <p>
                    <b>Size reduction:</b> {sizeReduction}
                </p>
                <p>
                    <b>Size reduction:</b> {sizeReductionPercentage}%
                </p>
                <Button onClick={downloadFile}>Download</Button>
            </Card.Body>
        </Card>
    );
};

export const AditorOptimizeResultCard = React.memo(
    AditorOptimizeResultCardImplementation
);
