import React, { FC, SyntheticEvent } from "react";
import { AnalyticsEventNameDropdown } from "../../dropdowns/analytics/AnalyticsEventNameDropdown";
import { Col, Form, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { AnalyticsSchema, EventData, FunnelData } from "../../../models/types";

interface Props {
    element: EventData;
    index: number;
    analyticsSchema: AnalyticsSchema | undefined;
    keys: string[];
    funnelItem: FunnelData;
    updateEventData: (eventData: EventData, updateIndex: number) => void;
    removeEventData: (index: number) => void;
}

export const FunnelEventData: FC<Props> = ({
    element,
    index,
    analyticsSchema,
    keys,
    funnelItem,
    updateEventData,
    removeEventData
}) => {
    const values: any[] = [];

    if (analyticsSchema) {
        for (let i = 0; i < analyticsSchema.events.length; i++) {
            if (analyticsSchema.events[i].eventName === funnelItem.eventName) {
                if (analyticsSchema.events[i].eventData) {
                    for (
                        let j = 0;
                        j < analyticsSchema.events[i].eventData.length;
                        j++
                    ) {
                        if (
                            element.key ===
                            analyticsSchema.events[i].eventData[j].key
                        ) {
                            for (
                                let k = 0;
                                k <
                                analyticsSchema.events[i].eventData[j].values
                                    .length;
                                k++
                            ) {
                                if (
                                    !values.includes(
                                        analyticsSchema.events[i].eventData[j]
                                            .values[k]
                                    )
                                ) {
                                    if (
                                        analyticsSchema.events[i].eventData[j]
                                            .values[k] !== "variable"
                                    ) {
                                        values.push(
                                            analyticsSchema.events[i].eventData[
                                                j
                                            ].values[k]
                                        );
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    return (
        <Row className="d-flex align-items-center justify-content-start p-0 mb-1 g-0">
            <Col xs="auto" className="">
                {analyticsSchema && keys.length > 0 ? (
                    <div className="me-2 mb-1">
                        <AnalyticsEventNameDropdown
                            key={element.key}
                            header={
                                element.key === "" ? "Select Key" : element.key
                            }
                            eventNames={keys}
                            updateSelection={selection => {
                                updateEventData(
                                    { key: selection, value: "" },
                                    index
                                );
                            }}
                        />
                    </div>
                ) : (
                    <div className="me-2 mb-1">
                        <Form
                            onSubmit={(event: any) => {
                                event.preventDefault();
                            }}
                        >
                            <Form.Control
                                placeholder="Key"
                                title="Enter a key defined in the schema"
                                type="text"
                                value={element.key}
                                onChange={(event: any) => {
                                    updateEventData(
                                        {
                                            ...element,
                                            key: event.target.value
                                        },
                                        index
                                    );
                                }}
                            />
                        </Form>
                    </div>
                )}
            </Col>
            <Col
                xs="auto"
                className="d-flex align-items-center justify-content-start"
            >
                {values && values.length > 0 ? (
                    <div className="mb-1">
                        <AnalyticsEventNameDropdown
                            header={
                                element.value === ""
                                    ? "Select Value"
                                    : element.value
                            }
                            eventNames={values}
                            updateSelection={selection => {
                                updateEventData(
                                    { ...element, value: selection },
                                    index
                                );
                            }}
                        />
                    </div>
                ) : (
                    <div className="mb-1">
                        <Form
                            className=""
                            onSubmit={(event: any) => {
                                event.preventDefault();
                            }}
                        >
                            <Form.Control
                                placeholder="Value"
                                title="Enter a value defined in the schema"
                                type="text"
                                value={element.value}
                                onChange={(event: any) => {
                                    updateEventData(
                                        {
                                            ...element,
                                            value: event.target.value
                                        },
                                        index
                                    );
                                }}
                            />
                        </Form>
                    </div>
                )}
                <FontAwesomeIcon
                    icon={faTrashAlt}
                    size="lg"
                    className="text-primary mouseHover ms-3"
                    onClick={(event: SyntheticEvent) => {
                        event.stopPropagation();
                        removeEventData(index);
                    }}
                />
            </Col>
        </Row>
    );
};
