import React from "react";
import { Card } from "react-bootstrap";

export const AnalyticsSchemaGuide = () => {
    return (
        <Card className="w-100 mt-3 mb-1">
            <Card.Header>
                <h5 className="text-inverse-50">Guide</h5>
            </Card.Header>
            <Card.Body>
                <Card className="inner-card">
                    <Card.Header className="text-inverse-50">
                        <h5>Basics</h5>
                    </Card.Header>
                    <Card.Body className="text-inverse">
                        <ul>
                            <li>
                                The idea is to standardize analytics design,
                                implementation and schema for the platform
                            </li>
                            <li>
                                Schemas can be found (and edited) in the{" "}
                                <i>Edit panel ➝ Schema</i>, or through{" "}
                                <i>Build report ➝ Analytic Schema</i>
                            </li>
                            <li>
                                Schema must contain <b>adStart</b>,{" "}
                                <b>firstTouch</b> and <b>ctaBtnPressed</b>{" "}
                                events
                            </li>
                            <li>
                                <b>Event Library</b> includes events that you
                                can add and modify after adding to schema
                            </li>
                            <li>
                                You can also add empty events and manually edit
                                relevant event data
                            </li>
                        </ul>
                    </Card.Body>
                </Card>
                <Card className="mt-2 inner-card">
                    <Card.Header className="text-inverse-50">
                        <h5>Naming Scheme</h5>
                    </Card.Header>
                    <Card.Body className="text-inverse">
                        <i>Within reason</i> every event, event name, key and
                        value should use <b>camelCase</b> (specifically
                        lowerCamelCase).
                        <br />
                        <div className="text-success mt-2">
                            <h6>Good Examples</h6>
                            <ul>
                                <li>initialize</li>
                                <li>exampleName</li>
                                <li>adStart</li>
                                <li>adEnd</li>
                                <li>ctaBtnPressed</li>
                            </ul>
                        </div>
                        <div className="text-danger">
                            <h6>Bad Examples</h6>
                            <ul>
                                <li>example Name</li>
                                <li>ad_start</li>
                                <li>AdEnd</li>
                                <li>cta_Btn Pressed</li>
                            </ul>
                        </div>
                    </Card.Body>
                </Card>
                <Card className="mt-2 inner-card">
                    <Card.Header className="text-inverse-50">
                        <h5>Event</h5>
                    </Card.Header>
                    <Card.Body className="text-inverse">
                        <p>
                            At minimum, an event consist of <b>event name</b>{" "}
                            (Event Name in the designer and eventName in the
                            json schema), which is the name of the event and the
                            identifier used for the event in the analytics
                            pipeline and in the platform.
                        </p>
                        <p>
                            Some data is collected automatically from all
                            events, such as time stamp and count.
                        </p>
                        <p>
                            Events can also be made to include more information;
                            this is done using eventData array (see next section
                            for more info).
                        </p>
                    </Card.Body>
                </Card>
                <Card className="mt-2 inner-card">
                    <Card.Header className="text-inverse-50">
                        <h5>Optional Event Data</h5>
                    </Card.Header>
                    <Card.Body className="text-inverse">
                        <h6>Basics</h6>
                        <p>
                            Event's eventData is an array of objects. In the
                            schema this is mapped using key and value pairs,
                            named <b>key</b> and <b>values</b>. Where <b>key</b>{" "}
                            is the name and <b>values</b> is the comma separated
                            list of expected values.
                        </p>
                        <p>
                            Even though <b>values</b> are comma separated in the
                            designer, they are split in to an array in the json
                            schema.
                        </p>
                        <h6>Values and their types</h6>
                        <p>
                            In the end, expected values are information for the
                            platform analytics, hence they are mostly expected
                            to be strings, even if their types are something
                            else.
                        </p>
                        <h6>Exceptions</h6>
                        <p>
                            If the expected values are not known beforehand or
                            generated dynamically, you can set values as{" "}
                            <b>variable</b>. This allows users to input values
                            manually in the analytics funnel view later.
                        </p>
                        <p>Some general examples:</p>
                        <ul>
                            <li>
                                Expected values for networks (strings):{" "}
                                <b>unity,vungle</b>
                            </li>
                            <li>
                                Expected values for level (integers):{" "}
                                <b>0,1,2</b>
                            </li>
                            <li>
                                Expected values for portait (boolean):{" "}
                                <b>true,false</b>
                            </li>
                            <li>
                                Force users to input values manually:{" "}
                                <b>variable</b>
                            </li>
                        </ul>
                    </Card.Body>
                </Card>
                <Card className="mt-2 inner-card">
                    <Card.Header className="text-inverse-50">
                        <h5>Comments</h5>
                    </Card.Header>
                    <Card.Body className="text-inverse">
                        <p>
                            Comments are optional but recommended, since they
                            can explain what is the purpose of the event, which
                            event data items (key-value pairs) it contains and
                            where and how they should be implemented.
                        </p>
                        <p>
                            Comments can be accessed in the funnel view via help
                            tooltips.
                        </p>
                        <p>
                            Comments are also included in the code snippets,
                            where they are separated in to their own lines by
                            dots ( . ).
                        </p>
                        <p>
                            Feel free to include all the information you think
                            is necessary in the comment, since they do not have
                            any effect on the implementation.
                        </p>
                    </Card.Body>
                </Card>
                <Card className="mt-2 inner-card">
                    <Card.Header className="text-inverse-50">
                        <h5>Schema Description</h5>
                    </Card.Header>
                    <Card.Body className="text-inverse">
                        <p>
                            Schema Description is a description of the purpose
                            of the schema; what does it try to achieve and what
                            analytical hypotheses are sought to be answered with
                            the schema design.
                        </p>
                        <p>
                            Description is optional, but recommended so the
                            schema can easily be re-used and re-designed.
                        </p>
                        <p>
                            The schema description is accessible in the funnel
                            view in Query details.
                        </p>
                    </Card.Body>
                </Card>
                <Card className="mt-2 inner-card">
                    <Card.Header className="text-inverse-50">
                        <h5>Importing and Exporting</h5>
                    </Card.Header>
                    <Card.Body className="text-inverse">
                        <p>
                            You can share the whole schema by exporting it as a
                            json file.
                        </p>
                        <p>
                            You can re-use the schema by importing the schema
                            json file.
                        </p>
                    </Card.Body>
                </Card>
                <Card className="mt-2 inner-card">
                    <Card.Header className="text-inverse-50">
                        <h5>FAQs</h5>
                    </Card.Header>
                    <Card.Body className="text-inverse">
                        <p>
                            <b>
                                Why are the events I made not showing up in
                                Funnel view?
                            </b>
                        </p>
                        <p>
                            In the funnel view, only the events that are{" "}
                            <b>shared</b> by all the selected builds' schemas
                            are available to create charts with; configure the
                            schemas to use same events if you want to compare
                            events from multiple builds.
                        </p>
                    </Card.Body>
                </Card>
                <Card className="mt-2 border-danger inner-card">
                    <Card.Header className="text-inverse-50">
                        <h5>Source Code Implementation</h5>
                    </Card.Header>
                    <Card.Body className="text-inverse">
                        <p>
                            CLI should synchronize this schema to{" "}
                            <i>src/config/analyticsSchema.json</i> for local
                            testing purposes.
                        </p>
                        <p>
                            CLI Analytics Runner feature is currently in
                            development, but syncing should already work.
                        </p>
                    </Card.Body>
                </Card>
            </Card.Body>
        </Card>
    );
};
