import React, { FC } from "react";
import { useQuery } from "@apollo/client";
import { Card } from "react-bootstrap";
import { GET_ANALYTICS_REVISIONS } from "../../../graphql/queries";
import {
    AnalyticsRevisionData,
    AnalyticsRevisionVariables,
    Project
} from "../../../models/types";
import { FunnelRevision } from "../../simple/analytics/FunnelRevision";

interface Props {
    project: Project;
}

export const AnalyticsRevisions: FC<Props> = ({ project }) => {
    const { loading, data, error } = useQuery<
        AnalyticsRevisionData,
        AnalyticsRevisionVariables
    >(GET_ANALYTICS_REVISIONS, {
        variables: {
            projectId: project.id
        }
    });

    const renderList = () => {
        if (loading) {
            return <div>Loading...</div>;
        }
        if (error) {
            return <div>Error: {error.message}</div>;
        }
        if (!data) {
            return null;
        }

        if (data.analyticsRevisions.length === 0) {
            return (
                <Card.Body className="p-2 bread-text">
                    No revisions with analytics enabled.
                </Card.Body>
            );
        }

        return data.analyticsRevisions.map(revision => (
            <FunnelRevision key={revision.id} revision={revision} />
        ));
    };

    return <div>{renderList()}</div>;
};
