import React, { FC, useState } from "react";
import { Accordion, Badge, Card, Collapse, Container } from "react-bootstrap";
import { Paginator } from "../lists/Paginator";
import { BuildLogCard } from "../simple/pipeline/BuildLogCard";
import {
    BatchBuild,
    PipelineLogs,
    ReportData,
    ReportDataV2
} from "../../models/types";

const paginationCount = 5;

interface Props {
    job: string | undefined;
    targetIndex: number | undefined;
    buildReport: ReportDataV2 | ReportData | undefined;
    build: BatchBuild | undefined;
    logs: PipelineLogs | undefined;
}

const BuildLogsCardImplementation: FC<Props> = ({
    job,
    targetIndex,
    buildReport,
    build,
    logs
}) => {
    const [showBuildJobs, updateShowBuildJobs] = useState(
        job === "build_job" ?? false
    );

    if (!logs) {
        return null;
    }

    const getBuildErrorCount = (data: ReportDataV2 | undefined) => {
        if (data && data.buildErrors) {
            return data.buildErrors.length;
        }
        return 0;
    };

    const getBuildWarningCount = (data: ReportDataV2 | undefined) => {
        if (data && data.buildWarnings) {
            return data.buildWarnings.length;
        }
        return 0;
    };

    const hasError = (index: number, data: ReportDataV2 | undefined) => {
        if (data && data.buildErrors) {
            return data.buildErrors.includes(index);
        }
        return false;
    };

    const hasWarning = (index: number, data: ReportDataV2 | undefined) => {
        if (data && data.buildWarnings) {
            return data.buildWarnings.includes(index);
        }
        return false;
    };

    let totalBuildWarnings = 0;
    let totalBuildErrors = 0;

    const data: ReportDataV2 =
        (buildReport as ReportDataV2) ||
        (build &&
            build.reportData &&
            JSON.parse(JSON.stringify(build.reportData)));
    if (buildReport) {
        totalBuildErrors = getBuildErrorCount(data);
        totalBuildWarnings = getBuildWarningCount(data);
    } else if (build && build.reportData) {
        totalBuildErrors = getBuildErrorCount(data);
        totalBuildWarnings = getBuildWarningCount(data);
    } else {
        if (
            data?.builds &&
            build &&
            data.builds.length !== build.buildJobCount
        ) {
            let errorCount = build.buildJobCount - data.builds.length;
            if (data.buildErrors) {
                errorCount += errorCount;
            }
            totalBuildErrors += errorCount;
        }
        if (!data?.builds && build) {
            totalBuildErrors += build.buildJobCount;
        }
    }

    const buildLogCards = logs?.buildJobs.map((buildJob, index) => {
        return (
            <BuildLogCard
                key={index}
                url={buildJob}
                index={index}
                hasError={hasError(index, data)}
                hasWarning={hasWarning(index, data)}
                focusAtStart={index === targetIndex}
            />
        );
    });
    return (
        <Card className="mt-2">
            <Card.Header
                className="mouseHover"
                onClick={() => {
                    updateShowBuildJobs(!showBuildJobs);
                }}
            >
                <h6>
                    Build Stage
                    {totalBuildWarnings > 0 ? (
                        <Badge className="ms-2" bg="warning">
                            Warning
                        </Badge>
                    ) : null}
                    {totalBuildErrors > 0 ? (
                        <Badge className="ms-2" bg="danger">
                            Error
                        </Badge>
                    ) : null}
                </h6>
            </Card.Header>
            <Collapse in={showBuildJobs} dimension="height">
                <div>
                    <Accordion defaultActiveKey={targetIndex?.toString()}>
                        {buildLogCards && buildLogCards.length > 0 ? (
                            <Paginator
                                list={buildLogCards}
                                maxElementCount={paginationCount}
                                activeItem={targetIndex}
                            />
                        ) : (
                            <p className="m-3 bread-text">No log found</p>
                        )}
                    </Accordion>
                </div>
            </Collapse>
        </Card>
    );
};

export const BuildLogsCard = React.memo(BuildLogsCardImplementation);
