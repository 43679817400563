import React, { FC } from "react";
import { useLocalAnalyticsState } from "../../../graphql/hooks";
import { useQuery } from "@apollo/client";
import {
    DailyAnalyticData,
    DailyAnalyticVariables
} from "../../../models/types";
import { GET_DAILY_ANALYTIC } from "../../../graphql/queries";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

interface Props {
    reference: string;
    referenceId: number;
    big?: boolean;
}

const DailyBadgesImplementation: FC<Props> = ({
    reference,
    referenceId,
    big = false
}) => {
    const { analyticsEnabled } = useLocalAnalyticsState();
    const { data: { getDailyAnalytic } = {} } = useQuery<
        DailyAnalyticData,
        DailyAnalyticVariables
    >(GET_DAILY_ANALYTIC, {
        variables: { referenceId: referenceId, reference: reference },
        skip: !analyticsEnabled
    });

    if (!getDailyAnalytic || getDailyAnalytic.impressions <= 0) {
        return null;
    }
    const gameplay = getDailyAnalytic.gameplay || 0;
    const endScreen = getDailyAnalytic.endScreen || 0;
    const ctr = (
        ((gameplay + endScreen) / getDailyAnalytic.impressions) *
        100
    ).toFixed(1);

    return (
        <div className="d-flex analytics-font float-end px-2">
            <OverlayTrigger
                overlay={
                    <Tooltip id="daily-impressions-tooltip">
                        Impressions - previous day
                    </Tooltip>
                }
                delay={{
                    show: 100,
                    hide: 200
                }}
            >
                <div className="me-2">
                    {getDailyAnalytic.impressions.toLocaleString()}
                </div>
            </OverlayTrigger>
            <div className="text-secondary">|</div>
            <OverlayTrigger
                overlay={
                    <Tooltip id="daily-ctr-tooltip">CTR - previous day</Tooltip>
                }
                delay={{
                    show: 100,
                    hide: 200
                }}
            >
                <div style={{ whiteSpace: "nowrap" }} className="ms-2">
                    {ctr} %
                </div>
            </OverlayTrigger>
        </div>
    );
};

export const DailyBadges = React.memo(DailyBadgesImplementation);
