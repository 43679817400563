import React, { FC, useEffect, useState } from "react";
import { Badge, Card, Collapse } from "react-bootstrap";
import { MutatorEnums, MutatorProperty } from "../../../models/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { EnumDropdown } from "../../dropdowns/mutator/EnumDropdown";
import { useMutatorState } from "../../../graphql/hooks";
import styles from "./CustomTypeCard.module.scss";
import cx from "classnames";

interface Props {
    fieldProperty: MutatorProperty;
    typeProperty: MutatorProperty;
    enums: MutatorEnums;
    customType: any;
    getVariantByType: (type: string) => string;
    onChange: (name: string, value: any) => void;
}

const MutatorEnumFieldCardImplementation: FC<Props> = ({
    fieldProperty,
    typeProperty,
    enums,
    getVariantByType,
    onChange
}) => {
    const mutatorState = useMutatorState();
    const [showMeta, updateShowMeta] = useState(mutatorState.showMeta);

    useEffect(() => {
        if (showMeta !== mutatorState.showMeta) {
            updateShowMeta(mutatorState.showMeta);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mutatorState.showMeta]);

    return (
        <Card className="text-inverse inner-card">
            <Card.Header
                className={`d-flex align-items-center ${styles.cardHeader}`}
            >
                {fieldProperty.name}
                <Badge
                    bg={getVariantByType(fieldProperty.type)}
                    className="mx-2"
                >
                    {fieldProperty.type}
                </Badge>
                {typeProperty.meta ? (
                    <div
                        className="float-end ms-auto mouseHover"
                        onClick={() => updateShowMeta(!showMeta)}
                        title="Help and additional info"
                    >
                        <FontAwesomeIcon
                            icon={faCircleInfo}
                            className={cx({
                                "text-inverse-25": !showMeta,
                                "text-inverse-50": showMeta
                            })}
                        />
                    </div>
                ) : null}
            </Card.Header>
            <Card.Body className={`${styles.fieldCardBody} ms-2`}>
                <EnumDropdown
                    isOptional={typeProperty.meta?.optional || false}
                    property={fieldProperty}
                    enums={enums}
                    getVariantByType={getVariantByType}
                    onChange={element => {
                        onChange(fieldProperty.name, element);
                    }}
                />
            </Card.Body>
            {typeProperty.meta ? (
                <Collapse in={showMeta}>
                    <div>
                        <Card.Footer className={styles.footerInfo}>
                            {typeProperty.meta.description ? (
                                <div className="bread-text text-inverse-50">
                                    {typeProperty.meta.description}
                                </div>
                            ) : (
                                <span className="bread-text text-inverse-50">
                                    No description provided.
                                </span>
                            )}
                            {typeProperty.meta.optional ? (
                                <div className="bread-text text-success">
                                    Value is optional!
                                </div>
                            ) : null}
                        </Card.Footer>
                    </div>
                </Collapse>
            ) : null}
        </Card>
    );
};

export const MutatorEnumFieldCard = React.memo(
    MutatorEnumFieldCardImplementation
);
