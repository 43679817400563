import React, { Component } from "react";
import { DSVRowArray } from "d3";
import { stepColor } from "../../common/AnalyticsHelpers";
import { ChartConfiguration } from "chart.js";
import { ChartJsWrapper } from "./ChartJsWrapper";

interface Props {
    data?: DSVRowArray;
    darkMode: boolean;
    showLegend: boolean;
    aspectRatio: number;
    title?: string;
}
export class ScatterChart extends Component<Props> {
    shouldComponentUpdate() {
        return false;
    }

    render() {
        const { data, darkMode, showLegend, aspectRatio, title } = this.props;
        if (!data) {
            return null;
        }
        const { columns, ...cleanData } = data;

        const dataSet: {
            x: number;
            y: number;
            count: number;
            color: string;
        }[] = [];
        let maxValue = 0;
        let totalCount = 0;

        Object.keys(cleanData).forEach((key: any) => {
            const point: { x: number; y: number } = {
                x: Number(cleanData[key].x),
                y: Math.floor((1.0 - Number(cleanData[key].y)) * 100) / 100
            };
            if (
                !dataSet.find(
                    element => element.x === point.x && element.y === point.y
                )
            ) {
                dataSet.push({ x: point.x, y: point.y, count: 1, color: "" });
            } else {
                for (let i = 0; i < dataSet.length; i++) {
                    if (dataSet[i].x === point.x && dataSet[i].y === point.y) {
                        dataSet[i].count++;
                        break;
                    }
                }
            }
        });

        dataSet.forEach(item => {
            if (item.count > maxValue) {
                maxValue = item.count;
            }
            totalCount += item.count;
        });

        for (let i = 0; i < dataSet.length; i++) {
            if (dataSet[i].count > maxValue) {
                maxValue = dataSet[i].count;
            }
        }

        for (let i = 0; i < dataSet.length; i++) {
            dataSet[i].color = stepColor(dataSet[i].count / maxValue, darkMode);
        }
        const sortedDataSet = dataSet.sort(
            (item1, item2) => item1.count - item2.count
        );
        if (!sortedDataSet) {
            return null;
        }

        const maxColor = stepColor(maxValue / maxValue, darkMode);

        const chartData = {
            datasets: [
                {
                    label: `Max Value: ${maxValue} | Data Points: ${totalCount}`,
                    data: sortedDataSet,
                    backgroundColor: maxColor,
                    pointBackgroundColor: sortedDataSet.map(
                        element => element.color
                    ),
                    pointRadius: 2
                }
            ]
        };

        const options = {
            plugins: {
                legend: {
                    display: showLegend,
                    labels: {
                        generateLabels: function (chart: any) {
                            const datasets = chart.data.datasets;
                            return datasets.map((dataset: any, i: number) => {
                                const meta = chart.getDatasetMeta(i);
                                const style = meta.controller.getStyle();
                                return {
                                    text: dataset.label,
                                    fontColor: "#CCCCCC",
                                    fillStyle: maxColor,
                                    hidden: !chart.isDatasetVisible(i),
                                    lineCap: style.borderCapStyle,
                                    lineDash: style.borderDash,
                                    lineDashOffset: style.borderDashOffset,
                                    lineJoin: style.borderJoinStyle,
                                    strokeStyle: style.borderColor,
                                    pointStyle: style.pointStyle,
                                    rotation: style.rotation,
                                    datasetIndex: i
                                };
                            });
                        }
                    }
                },
                tooltip: {
                    callbacks: {
                        label: (context: any) => {
                            return [
                                `count: ${context.raw.count}`,
                                `x, y: ${context.raw.x}, ${context.raw.y}`
                            ];
                        }
                    }
                },
                title: {
                    display: true,
                    text: title,
                    font: { size: 12 },
                    color: darkMode ? "#FFFFFF" : "#000000"
                }
            },
            maintainAspectRatio: true,
            responsive: true,
            aspectRatio: aspectRatio,
            scales: {
                y: {
                    beginAtZero: true,
                    min: 0,
                    max: 1
                },
                x: {
                    beginAtZero: true,
                    min: 0,
                    max: 1
                }
            }
        };

        const config: ChartConfiguration = {
            type: "scatter",
            data: chartData,
            options: options
        };

        return <ChartJsWrapper config={config} />;
    }
}
