import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { Button, Container } from "react-bootstrap";

interface State {
    drag: boolean;
}

interface Props {
    instructions: React.ReactNode;
    fileNames: string[];
    addFiles: (newFiles: File[]) => void;
    removeFile: (name: string) => void;
}

export class DragAndDrop extends Component<Props, State> {
    dropReference: React.RefObject<HTMLDivElement> = React.createRef();
    dragCounter = 0;

    state: State = {
        drag: false
    };

    handleDrag = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
    };

    handleDragIn = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
        this.dragCounter++;
        if (event.dataTransfer.items && event.dataTransfer.items.length > 0) {
            this.setState({ drag: true });
        }
    };

    handleDragOut = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
        this.dragCounter--;
        if (this.dragCounter === 0) {
            this.setState({ drag: false });
        }
    };

    handleDrop = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
        this.setState({ drag: false });
        if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
            const files: File[] = [];
            for (let i = 0; i < event.dataTransfer.files.length; i++) {
                files.push(event.dataTransfer.files[i]);
            }
            this.props.addFiles(files);
            // event.dataTransfer.clearData();
            this.dragCounter = 0;
        }
    };

    componentDidMount() {
        const element: any = this.dropReference.current;
        element.addEventListener("dragenter", this.handleDragIn);
        element.addEventListener("dragleave", this.handleDragOut);
        element.addEventListener("dragover", this.handleDrag);
        element.addEventListener("drop", this.handleDrop);
    }

    componentWillUnmount() {
        const element: any = this.dropReference.current;
        element.removeEventListener("dragenter", this.handleDragIn);
        element.removeEventListener("dragleave", this.handleDragOut);
        element.removeEventListener("dragover", this.handleDrag);
        element.removeEventListener("drop", this.handleDrop);
    }

    render() {
        return (
            <div className="m-0 p-0 w-100 h-100" ref={this.dropReference}>
                {this.state.drag && (
                    <div
                        style={{
                            border: "dashed grey 4px",
                            backgroundColor: "rgba(255,255,255,.8)",
                            position: "absolute",
                            top: 0,
                            bottom: 0,
                            left: 0,
                            right: 0,
                            zIndex: 9999
                        }}
                    >
                        <div
                            style={{
                                position: "absolute",
                                top: "50%",
                                right: 0,
                                left: 0,
                                textAlign: "center",
                                color: "grey",
                                fontSize: 36
                            }}
                        >
                            <div>Drag Files Here</div>
                        </div>
                    </div>
                )}
                <Container>
                    <h5 className="text-center mb-3">
                        {this.props.instructions}
                    </h5>
                    {this.props.fileNames && this.props.fileNames.length > 0
                        ? this.props.fileNames.map((name, index) => (
                              <Button
                                  style={{
                                      maxWidth: "100%",
                                      overflow: "hidden"
                                  }}
                                  key={index}
                                  onClick={() => this.props.removeFile(name)}
                                  className="d-flex btn-info m-1 no-wrap align-items-center"
                              >
                                  <FontAwesomeIcon
                                      className="me-2"
                                      icon={faTrashCan}
                                  />
                                  {name}
                              </Button>
                          ))
                        : null}
                </Container>
            </div>
        );
    }
}
