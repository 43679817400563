import React, { FC, useEffect, useState } from "react";
import {
    useApolloClient,
    useLazyQuery,
    useMutation,
    useQuery
} from "@apollo/client";
import {
    GenerateLocalizationData,
    GenerateLocalizationVariables,
    Localization,
    PartialRevisionData,
    PartialRevisionVariables,
    Revision,
    RevisionConfig,
    RevisionVariables,
    Types,
    UploadLocalizationVariables
} from "../../models/types";
import {
    GET_LOCALIZATIONS_JSON,
    GET_REVISION_CONFIG_LOCALIZATIONS
} from "../../graphql/queries";
import { Button, Card, Collapse, Container, Row } from "react-bootstrap";
import { LocalizationViewModal } from "../modals/LocalizationViewModal";
import { LocalizationUploadModal } from "../modals/LocalizationUploadModal";
import {
    PARTIAL_UPDATE_REVISION,
    REMOVE_LOCALIZATION
} from "../../graphql/mutations";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faChevronDown,
    faChevronUp,
    faCircleInfo,
    faDownload,
    faPlus
} from "@fortawesome/free-solid-svg-icons";
import { AlertModal } from "../modals/AlertModal";
import {
    getVariationCollapseState,
    saveVariationCollapseState,
    updateNotificationState,
    updateRevisionPartially
} from "../../common/Helpers";
import { VariationCollapseState } from "../../models/common";
import { EditableLocalization } from "../editable/EditableLocalization";
import { useLocalState } from "../../graphql/hooks";
import cx from "classnames";

interface Props {
    revision: Revision;
    developerMode: boolean;
}

export const LocalizationManagement: FC<Props> = ({
    revision,
    developerMode
}) => {
    const client = useApolloClient();
    const [showLocalizations, setShowLocalizations] = useState(
        getVariationCollapseState(VariationCollapseState.Localizations)
    );
    const [showLocalization, updateShowLocalization] = useState(false);
    const [showFileUpload, updateShowFileUpload] = useState(false);
    const [showRemoveAlert, updateShowRemoveAlert] = useState(false);
    const state = useLocalState();
    const [showHelp, setShowHelp] = useState(false);
    const [selectedLocalization, updateSelectedLocalization] = useState<{
        localization: string;
        s3Key: string;
    }>({ localization: "", s3Key: "" });
    const [toBeRemoved, updateToBeRemoved] = useState<Localization | undefined>(
        undefined
    );
    const { data: { revisionConfig } = {} } = useQuery<
        {
            revisionConfig: RevisionConfig;
        },
        RevisionVariables
    >(GET_REVISION_CONFIG_LOCALIZATIONS, {
        variables: { revisionId: revision.id }
    });
    const [getLocalizationsJson, { data }] = useLazyQuery<
        GenerateLocalizationData,
        GenerateLocalizationVariables
    >(GET_LOCALIZATIONS_JSON, { fetchPolicy: "network-only" });
    const [partialUpdateRevision, { error }] = useMutation<
        PartialRevisionData,
        PartialRevisionVariables
    >(PARTIAL_UPDATE_REVISION);
    const [removeLocalizationMutation] = useMutation<
        RevisionConfig,
        UploadLocalizationVariables
    >(REMOVE_LOCALIZATION);

    useEffect(() => {
        if (data) {
            const link = document.createElement("a");
            link.href = `data:text/json;charset=utf-8,${encodeURIComponent(
                JSON.stringify(data.getLocalizationsJson.localizationJson)
            )}`;
            link.download = "localizations.json";
            link.click();
        }
    }, [data]);

    useEffect(() => {
        if (error) {
            updateNotificationState(client, false, true, {
                success: false,
                header: "Localization(s) not updated!",
                message: error.message,
                delay: 5000
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error]);

    const updateShowLocalizations = (newState: boolean) => {
        saveVariationCollapseState(
            VariationCollapseState.Localizations,
            newState
        );
        setShowLocalizations(newState);
    };

    const updateRevision = async (newBuildData?: Types, operation?: string) => {
        if (revision) {
            if (!newBuildData) {
                return;
            }
            // updateRevision({ variations: [newVariationData] });
            await updateRevisionPartially(
                client,
                revision,
                partialUpdateRevision,
                newBuildData,
                operation
            );
        }
    };

    const removeLocalization = async (localization: Localization) => {
        try {
            const response = await removeLocalizationMutation({
                variables: {
                    revisionId: revision.id,
                    localization: localization.localization
                }
            });

            if (!response.errors) {
                // updateRevision(
                //     { localizations: [localization] },
                //     undefined,
                //     "delete"
                // );
                await updateRevisionPartially(
                    client,
                    revision,
                    partialUpdateRevision,
                    { localizations: [localization] },
                    "delete"
                );
                if (toBeRemoved) {
                    updateToBeRemoved(undefined);
                }
            }
        } catch (error) {
            console.log("[DEBUG] removeLocalization error ", error);
        }
    };

    const toggleShowHelp = () => setShowHelp(!showHelp);

    const buildDataLocalization =
        revision && revision.buildData && revision.buildData.localizations
            ? revision.buildData.localizations
            : undefined;

    const configLocalizations =
        revisionConfig && revisionConfig.localizations
            ? revisionConfig.localizations
            : undefined;

    const localizations: React.ReactElement[] = [];
    const version = revision?.buildData?.version;
    const hasLocalizations =
        revision &&
        revision.buildData &&
        revision.buildData.localizations &&
        revision.buildData.localizations.length > 0;

    if (configLocalizations && buildDataLocalization) {
        for (let i = 0; i < configLocalizations?.length; i++) {
            for (let j = 0; j < buildDataLocalization.length; j++)
                if (
                    configLocalizations[i] ===
                    buildDataLocalization[j].localization
                ) {
                    localizations.push(
                        <EditableLocalization
                            key={j}
                            revision={revision}
                            defaultValue={buildDataLocalization[j]}
                            version={version}
                            developerMode={developerMode}
                            remove={() => {
                                updateToBeRemoved(buildDataLocalization[j]);
                                updateShowRemoveAlert(true);
                            }}
                            view={(localizationData: {
                                localization: string;
                                s3Key: string;
                            }) => {
                                updateSelectedLocalization(localizationData);
                                updateShowLocalization(true);
                            }}
                            updateRevision={updateRevision}
                        />
                    );
                }
        }
    }

    return (
        <>
            <Container
                className={cx("mb-4 p-0", {
                    editPanelClientView: !state.developerMode
                })}
            >
                <Card>
                    <Card.Header className="d-flex align-items-center">
                        <h5 className="text-inverse-50 mb-1 me-2">
                            Localizations
                        </h5>
                        {hasLocalizations && (
                            <div
                                className="ms-1 text-inverse-50"
                                onClick={() => {
                                    updateShowLocalizations(!showLocalizations);
                                }}
                                data-toggle="tooltip"
                                title="Toggle localizations visibility"
                            >
                                <FontAwesomeIcon
                                    className="mouseHover"
                                    icon={
                                        !showLocalizations
                                            ? faChevronDown
                                            : faChevronUp
                                    }
                                />
                            </div>
                        )}
                        <div className="float-end ms-auto">
                            <Button
                                className="btn-sm me-2"
                                onClick={() => updateShowFileUpload(true)}
                                title="Add new Localization"
                            >
                                <FontAwesomeIcon icon={faPlus} size="lg" />
                            </Button>
                            <Button
                                className={cx("btn-sm", {
                                    "me-3": !state.developerMode
                                })}
                                onClick={() => {
                                    getLocalizationsJson({
                                        variables: {
                                            revisionId: revision.id,
                                            localizationData: JSON.stringify(
                                                revision.buildData.localizations
                                            )
                                        }
                                    });
                                }}
                                data-toggle="tooltip"
                                title="Download localizations.json"
                                disabled={!hasLocalizations}
                            >
                                <FontAwesomeIcon icon={faDownload} />
                            </Button>
                            {!state.developerMode ? (
                                <div className="float-end mt-1 ms-auto">
                                    <FontAwesomeIcon
                                        icon={faCircleInfo}
                                        onClick={toggleShowHelp}
                                        className={`mouseHover ${
                                            showHelp
                                                ? "text-inverse-50"
                                                : "text-inverse-25"
                                        }`}
                                        title={
                                            showHelp ? "Hide Help" : "Show Help"
                                        }
                                    />
                                </div>
                            ) : null}
                        </div>
                    </Card.Header>
                    {hasLocalizations && (
                        <Collapse in={showLocalizations}>
                            <div>
                                <Card.Body className="p-2 p-sm-3">
                                    <div>
                                        <Row className="d-flex m-auto justify-content-start">
                                            {localizations}
                                        </Row>
                                    </div>
                                </Card.Body>
                            </div>
                        </Collapse>
                    )}
                    {!state.developerMode ? (
                        <Collapse in={showHelp}>
                            <div>
                                <Card.Footer className="bread-text text-inverse-50">
                                    <p>
                                        <strong>Localizations</strong> include
                                        the available language settings for any
                                        text elements in the playable ad. All
                                        selected localizations will be included
                                        in the generated ad builds, so this does
                                        not produce any additional builds.
                                    </p>{" "}
                                    <p>
                                        Set the <i>Default localization</i> to
                                        be the fallback language for the ad in
                                        case of non-supported local language.
                                        Here you can also view the language
                                        files, download them as a single json
                                        file, upload new localizations in the
                                        same format, or remove any unnecessary
                                        localizations.
                                    </p>
                                    <p>
                                        Localizations should be named with
                                        official language abbreviations.
                                    </p>
                                </Card.Footer>
                            </div>
                        </Collapse>
                    ) : null}
                </Card>
                {showLocalization && revisionConfig ? (
                    <LocalizationViewModal
                        localizationData={selectedLocalization}
                        revisionConfigId={revisionConfig.id}
                        onClose={() => updateShowLocalization(false)}
                        show={showLocalization}
                    />
                ) : null}
                {showFileUpload ? (
                    <LocalizationUploadModal
                        title="Add New Localization"
                        localization={null}
                        updateRevision={updateRevision}
                        projectLocalizations={revisionConfig?.localizations}
                        onClose={() => updateShowFileUpload(false)}
                        revision={revision}
                        show={showFileUpload}
                    />
                ) : null}
                {showRemoveAlert ? (
                    <AlertModal
                        header={<h5>Remove Localization</h5>}
                        component="Removing localization removes it from the whole project and from other revisions that might be using it. If you wish to remove it from this revision, please just disable it!"
                        visible={() => updateShowRemoveAlert(false)}
                        footer={
                            <Button
                                variant="danger"
                                className="mt-2"
                                onClick={() => {
                                    if (toBeRemoved) {
                                        removeLocalization(toBeRemoved);
                                        updateShowRemoveAlert(false);
                                    }
                                }}
                            >
                                Continue to Remove
                            </Button>
                        }
                    />
                ) : null}
            </Container>
        </>
    );
};
