import React, { FC } from "react";
import { DropdownButton } from "react-bootstrap";
import { Project } from "../../models/types";
import { CreateProjectDropdownItem } from "./CreateProjectDropdownItem";

interface Props {
    projects: Project[];
    updateSelectedProject: (newSelection: string) => void;
}

const CreateProjectDropdownImplementation: FC<Props> = ({
    projects,
    updateSelectedProject
}) => {
    const generateAlternatives = projects?.map(
        (project: Project, index: number) => (
            <CreateProjectDropdownItem key={index} project={project} />
        )
    );

    return (
        <DropdownButton
            title="Copy Settings for Re-skin"
            variant="info"
            className="text-center mb-2 mt-2"
            onSelect={(selectedItem: string | null | undefined) => {
                if (selectedItem) {
                    updateSelectedProject(selectedItem);
                }
            }}
            id="copy-settings-for-re-skin"
        >
            {generateAlternatives}
        </DropdownButton>
    );
};

export const CreateProjectDropdown = React.memo(
    CreateProjectDropdownImplementation
);
