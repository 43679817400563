import React, { FC, SyntheticEvent } from "react";
import { faFilter, faPlay } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useLocalAnalyticsState } from "../../../graphql/hooks";
import {
    BatchBuild,
    NetworkBuild,
    Product,
    Project,
    Revision
} from "../../../models/types";
import {
    setupFunnel,
    toggleFunnelSelectionMode
} from "../../../common/AnalyticsHelpers";
import { useApolloClient } from "@apollo/client";
import { useNavigate } from "react-router-dom";

interface Props {
    funnelSelection: any[];
    reference: string;
    className?: string;
}

const AnalyticsFunnelModeSelectionToggleImplementation: FC<Props> = ({
    funnelSelection,
    reference,
    className = ""
}) => {
    const client = useApolloClient();
    const navigate = useNavigate();
    const { analyticsEnabled, analyticsFunnelSelectionMode } =
        useLocalAnalyticsState();

    if (!analyticsEnabled) {
        return null;
    }

    const getSelection = () => {
        if (funnelSelection.length > 0) {
            switch (reference) {
                case "product":
                    const products: Product[] = funnelSelection.map(
                        (product: Product) => ({
                            ...product,
                            selected: true,
                            comparison: `product-${product.id}`
                        })
                    );
                    return {
                        products: products
                    };
                case "project":
                    const projects: Project[] = funnelSelection.map(
                        (project: Project) => ({
                            ...project,
                            selected: true,
                            comparison: `project-${project.id}`
                        })
                    );
                    return {
                        projects: projects
                    };
                case "revision":
                    const revisions: Revision[] = funnelSelection.map(
                        (revision: Revision) => ({
                            ...revision,
                            selected: true,
                            comparison: `revision-${revision.id}`
                        })
                    );
                    return {
                        revisions: revisions
                    };
                case "build":
                    const builds: BatchBuild[] = funnelSelection.map(
                        (build: BatchBuild) => ({
                            ...build,
                            selected: true,
                            comparison: `build-${build.id}`
                        })
                    );
                    return {
                        builds: builds
                    };
                case "networkbuild":
                    const networkBuilds: NetworkBuild[] = funnelSelection.map(
                        (networkBuild: NetworkBuild) => ({
                            ...networkBuild,
                            selected: true,
                            comparison: `networkBuild-${networkBuild.id}`
                        })
                    );
                    return {
                        networkBuilds: networkBuilds
                    };
                default:
                    return undefined;
            }
        }
        return undefined;
    };

    return (
        <Col xs="auto" className={className}>
            {analyticsFunnelSelectionMode ? (
                <OverlayTrigger
                    overlay={
                        <Tooltip id="button-tooltip">
                            Go to funnel view with the selected items
                        </Tooltip>
                    }
                    delay={{
                        show: 150,
                        hide: 200
                    }}
                >
                    <span
                        onClick={(event: SyntheticEvent) => {
                            event.stopPropagation();
                        }}
                        className="d-inline-block me-2"
                    >
                        {funnelSelection.length === 0 ? (
                            <Button
                                className="btn-sm"
                                variant={
                                    funnelSelection.length === 0
                                        ? "secondary"
                                        : "analytics"
                                }
                                disabled
                            >
                                <FontAwesomeIcon icon={faPlay} size="1x" />
                            </Button>
                        ) : (
                            <Button
                                className="btn-sm"
                                variant="analytics"
                                onClick={(event: SyntheticEvent) => {
                                    event.stopPropagation();
                                    event.preventDefault();
                                    const selection = getSelection();
                                    if (!selection) {
                                        console.log(
                                            "[DEBUG] could not generate selection from funnelSelection"
                                        );
                                        return;
                                    }
                                    setupFunnel(client, selection);
                                    navigate("/analytics");
                                }}
                            >
                                <FontAwesomeIcon icon={faPlay} size="1x" />
                            </Button>
                        )}
                    </span>
                </OverlayTrigger>
            ) : null}
            <OverlayTrigger
                overlay={
                    <Tooltip id="button-tooltip">
                        Toggle Funnel Selection Mode
                    </Tooltip>
                }
                delay={{
                    show: 150,
                    hide: 200
                }}
                rootClose={true}
                rootCloseEvent="click"
            >
                <span className="d-inline-block">
                    <Button
                        className="btn-sm"
                        variant={
                            analyticsFunnelSelectionMode
                                ? "analytics"
                                : "secondary"
                        }
                        onClick={(event: SyntheticEvent) => {
                            event.stopPropagation();
                            toggleFunnelSelectionMode(
                                client,
                                !analyticsFunnelSelectionMode
                            );
                        }}
                    >
                        <FontAwesomeIcon icon={faFilter} size="lg" />
                    </Button>
                </span>
            </OverlayTrigger>
        </Col>
    );
};

export const AnalyticsFunnelModeToggle = React.memo(
    AnalyticsFunnelModeSelectionToggleImplementation
);
