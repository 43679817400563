import React, { FC } from "react";
import { DailyBuildAnalytics } from "../../../models/types";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

interface Props {
    dailyBuildAnalytic: DailyBuildAnalytics;
    funnelView?: boolean;
}

const DailyBadgesNetworkBuildImplementation: FC<Props> = ({
    dailyBuildAnalytic,
    funnelView = false
}) => {
    if (!dailyBuildAnalytic || dailyBuildAnalytic.impressions <= 0) {
        return null;
    }

    const gameplay = dailyBuildAnalytic.gameplay || 0;
    const endScreen = dailyBuildAnalytic.endScreen || 0;
    const ctr = (
        ((gameplay + endScreen) / dailyBuildAnalytic.impressions) *
        100
    ).toFixed(1);

    return (
        <>
            <div className="d-flex analytics-font float-end px-2">
                <OverlayTrigger
                    overlay={
                        <Tooltip id="network-impressions-tooltip">
                            Impressions - previous day
                        </Tooltip>
                    }
                    delay={{
                        show: 100,
                        hide: 200
                    }}
                >
                    <div className="me-2">
                        {dailyBuildAnalytic.impressions.toLocaleString()}
                        {!funnelView && (
                            <span className="ps-1" title="Daily analytics">
                                *
                            </span>
                        )}
                    </div>
                </OverlayTrigger>
                <div className="text-secondary">|</div>
                <OverlayTrigger
                    overlay={
                        <Tooltip id="network-ctr-tooltip">
                            CTR - previous day
                        </Tooltip>
                    }
                    delay={{
                        show: 100,
                        hide: 200
                    }}
                >
                    <div style={{ whiteSpace: "nowrap" }} className="ms-2">
                        {ctr} %
                        {!funnelView && (
                            <span className="ps-1" title="Daily analytics">
                                *
                            </span>
                        )}
                    </div>
                </OverlayTrigger>
            </div>
        </>
    );
};

export const DailyBadgesNetworkBuild = React.memo(
    DailyBadgesNetworkBuildImplementation
);
