import React from "react";
import { Navigate } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_ME } from "../graphql/queries";
import { MeData } from "../models/types";

interface Props {
    scopes: string[];
    children: any;
}

// @ts-ignore
export const ProtectedRoute: FC<Props> = ({ scopes, children }) => {
    const { data: { me } = {} } = useQuery<MeData>(GET_ME, {
        fetchPolicy: "cache-first"
    });

    if (!me || !me.scopes) {
        return <Navigate to="/products" />;
    }

    if (!me.scopes.some((element: string) => scopes.indexOf(element) >= 0)) {
        return <Navigate to="/products" />;
    }

    return <>{children}</>;
};
