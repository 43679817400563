import React, { FC } from "react";
import { OverlayBadge } from "./OverlayBadge";

interface Props {
    useCompression: boolean;
}

export const CompressionCheckOverlayBadge: FC<Props> = ({ useCompression }) => {
    if (useCompression) {
        return null;
    }

    return (
        <OverlayBadge
            badgeVariant="warning"
            className="mt-2 p-1 me-2"
            badgeHeader="Compression not enabled"
            overlayText="Enable gzip in settings tab"
        />
    );
};
