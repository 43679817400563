import React, { FC, SyntheticEvent, useState } from "react";
import { AnalyticsSchema, FunnelData } from "../../../models/types";
import { Button, Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { EditableFunnelComponent } from "../../editable/analytics/EditableFunnelComponent";

interface Props {
    schema: AnalyticsSchema | undefined;
}

export const FunnelElementsPreview: FC<Props> = ({ schema }) => {
    const [funnelItems, updateFunnelItems] = useState<FunnelData[]>([
        { eventName: "adStart", eventData: [] },
        { eventName: "ctaBtnPressed", eventData: [] }
    ]);

    const updateFunnelEvent = (id: number, funnelItem: FunnelData) => {
        const newFunnelEvents = [...funnelItems];
        newFunnelEvents[id] = funnelItem;
        updateFunnelItems(newFunnelEvents);
    };

    const removeFunnelEvent = (id: number) => {
        if (funnelItems.length <= 2) {
            console.log("[DEBUG] Funnel requires 2 items");
            return;
        }
        const newFunnelEvents = [
            ...funnelItems.slice(0, id),
            ...funnelItems.slice(id + 1)
        ];
        updateFunnelItems(newFunnelEvents);
    };

    const funnelElements = funnelItems.map((element, index) => (
        <EditableFunnelComponent
            key={index}
            id={index}
            isRemovable={index > 1}
            funnelItem={element}
            analyticsSchema={schema}
            updateItem={updateFunnelEvent}
            removeText={removeFunnelEvent}
        />
    ));

    return (
        <Card className="w-100 mt-3 mb-4">
            <Card.Header className="text-inverse-50 d-flex">
                <h5>Funnel Preview</h5>
                <Button
                    onClick={(event: SyntheticEvent) => {
                        event.stopPropagation();
                        updateFunnelItems([
                            ...funnelItems,
                            {
                                eventName: "",
                                eventData: []
                            }
                        ]);
                    }}
                    className="float-end ms-auto btn-sm"
                    title="Add New Event"
                >
                    <FontAwesomeIcon icon={faPlus} size="lg" />
                </Button>
            </Card.Header>
            <Card.Body>{funnelElements}</Card.Body>
        </Card>
    );
};
