import React, { FC, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import { TempLink, TempLinkVariables } from "../../models/types";
import { GET_TEMP_LINK } from "../../graphql/queries";
import { Image } from "react-bootstrap";

interface Props {
    productId: number;
    imageKey: string;
}

export const ImageItem: FC<Props> = ({ productId, imageKey }) => {
    const [getTempLink, { data }] = useLazyQuery<TempLink, TempLinkVariables>(
        GET_TEMP_LINK,
        { fetchPolicy: "network-only" }
    );

    useEffect(() => {
        if (imageKey) {
            getTempLink({
                variables: {
                    productId: productId,
                    key: imageKey
                }
            });
        }
    }, [getTempLink, productId, imageKey]);

    return <Image src={data?.generateLink.link} className="imageRow" />;
};
