import React, { Component } from "react";
import { loadCsv, pastelStepColor } from "../../common/AnalyticsHelpers";
import { AnalyticsQueryType, AthenaDatasetResult } from "../../models/types";
import { DSVRowArray } from "d3";
import { ChartConfiguration } from "chart.js";
import { ChartJsWrapper } from "./ChartJsWrapper";

interface State {
    csvData: DSVRowArray | undefined;
}

interface Props {
    data: AthenaDatasetResult[];
}

export class FunnelBarChart extends Component<Props> {
    state: State = {
        csvData: undefined
    };

    shouldComponentUpdate(_: Readonly<Props>, nextState: Readonly<State>) {
        return this.state.csvData !== nextState.csvData;
    }

    componentDidMount(): void {
        const { data } = this.props;
        const funnelData = data[0].dataset.find(
            element => element.type === AnalyticsQueryType.Funnel
        );
        if (funnelData?.success && funnelData.message) {
            loadCsv(funnelData.message)
                .then(result => {
                    this.setState({ csvData: result });
                })
                .catch(error => console.log("[DEBUG] error ", error));
        }
    }

    render() {
        const { csvData } = this.state;
        if (!csvData) {
            return null;
        }

        const { columns, ...cleanData } = csvData;

        const datasets: number[] = [];
        const labels: string[] = [];
        const colours: string[] = [];
        let last = 100;
        let count = 0;
        for (const key in cleanData) {
            const eventCount = Number(cleanData[key].events);
            const percentageString =
                count === 0
                    ? ""
                    : `, ${(
                          (Number(cleanData[key].events) / last) *
                          100
                      ).toFixed(2)}%`;
            last = eventCount;
            labels.push(
                `${cleanData[key].eventName}, ${eventCount}${percentageString}`
            );
            datasets.push(eventCount);
            count++;
        }

        for (let i = 0; i < labels.length; i++) {
            colours.push(pastelStepColor(i / (labels.length - 1)));
        }
        const config: ChartConfiguration = {
            type: "bar",
            data: {
                labels: labels,
                datasets: [
                    {
                        label: "count",
                        data: datasets,
                        backgroundColor: colours
                    }
                ]
            },
            options: {
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        display: false
                    }
                }
            }
        };
        return <ChartJsWrapper config={config} />;
    }
}
