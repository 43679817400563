import React, { FC, useState } from "react";
import { Card } from "react-bootstrap";
import { AditorOptimizeTask } from "../../../models/aditor";
import { AditorOptimizeResultCard } from "./AditorOptimizeResultCard";
import { useSubscription } from "@apollo/client";
import { ADITOR_OPTIMIZE_ASSETS_SUBSCRIPTION } from "../../../graphql/subscriptions";

interface Props {
    taskId: string;
}

const AditorOptimizeTaskCardImplementation: FC<Props> = ({ taskId }) => {
    const [result, updateResult] = useState<AditorOptimizeTask | undefined>(
        undefined
    );
    useSubscription(ADITOR_OPTIMIZE_ASSETS_SUBSCRIPTION, {
        variables: { taskId: taskId },
        onData: ({ data: { data: { aditorAssetReadyNotification } = {} } }) => {
            const result = aditorAssetReadyNotification;
            updateResult({
                taskId: taskId,
                result: result
            });
        }
    });

    return (
        <Card className="mt-2">
            <Card.Header className="mx-0">Optimize Task</Card.Header>
            <Card.Body>
                {result ? (
                    <AditorOptimizeResultCard task={result} />
                ) : (
                    <p>Optimizing {taskId}</p>
                )}
            </Card.Body>
        </Card>
    );
};

export const AditorOptimizeTaskCard = React.memo(
    AditorOptimizeTaskCardImplementation
);
