import React, { FC, SyntheticEvent } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { MeData } from "../../../models/types";
import { GET_ME } from "../../../graphql/queries";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UPDATE_ME } from "../../../graphql/mutations";
import { SizeProp } from "@fortawesome/fontawesome-svg-core";
import cx from "classnames";

interface Props {
    buildId: string;
    size: SizeProp;
}

export const FollowIcon: FC<Props> = ({ buildId, size }) => {
    const { data: { me } = {} } = useQuery<MeData>(GET_ME, {
        fetchPolicy: "cache-first"
    });
    const [updateMe] = useMutation(UPDATE_ME);

    const isInList =
        me &&
        me.analyticsFollowList &&
        me.analyticsFollowList.includes(buildId);

    const updateFollowList = async () => {
        try {
            await updateMe({
                variables: { followListId: buildId }
            });
        } catch (error) {
            console.log("[DEBUG] updateFollowList error ", error);
        }
    };

    return (
        <FontAwesomeIcon
            icon={faStar}
            size={size}
            className={cx(
                "mouseHover",
                { "text-primary": isInList },
                { "text-secondary": !isInList }
            )}
            onClick={(event: SyntheticEvent) => {
                event.stopPropagation();
                updateFollowList();
            }}
        />
    );
};
