import React, { FC, useEffect, useState } from "react";
import { Button, Card, Collapse, Container } from "react-bootstrap";
import { useLazyQuery, useMutation, useSubscription } from "@apollo/client";
import {
    AdsetVariables,
    CreateAdsetData,
    Project as ProjectType,
    ReleaseType,
    Revision,
    RevisionsData,
    RevisionsVariables,
    RevisionVariables,
    SortingMode
} from "../../models/types";
import {
    GET_PRODUCT_META_DATA,
    GET_PROJECT_META_DATA,
    GET_REVISIONS
} from "../../graphql/queries";
import {
    CREATE_ADSET,
    REMOVE_REVISION,
    UPDATE_REVISION
} from "../../graphql/mutations";
import { useLocalState, useSortingState } from "../../graphql/hooks";
import { ReleaseNotesModal } from "../modals/ReleaseNotesModal";
import { AdSets } from "./AdSets";
import { useNavigate } from "react-router-dom";
import { ReleaseItem } from "../simple/ReleaseItem";
import { DevelopmentReleaseItem } from "../simple/DevelopmentReleaseItem";
import { ReviewReleaseItem } from "../simple/ReviewReleaseItem";
import { ClientTestReleaseItem } from "../simple/ClientTestReleaseItem";
import { AlertModal } from "../modals/AlertModal";
import { sortItems } from "../../common/Helpers";
import { REVISION_CHANGED_SUBSCRIPTION } from "../../graphql/subscriptions";
import "./views.scss";

interface Props {
    project: ProjectType;
    showChildren: boolean;
    updateLoaded: (loaded: boolean) => void;
    // sortingSettings: SortingData;
}

const ProjectImplementation: FC<Props> = ({
    project,
    showChildren,
    updateLoaded
    // sortingSettings
}) => {
    const state = useLocalState();
    const navigate = useNavigate();
    const [revisionsLoaded, updateRevisionsLoaded] = useState(false);
    const [sortedRevisions, updateSortedRevisions] = useState<Revision[]>([]);
    const [showAdsets, updateShowAdsets] = useState(true);
    const [showRemoveAlert, updateShowRemoveAlert] = useState(false);
    const [toBeRemoved, updateToBeRemoved] = useState<number | undefined>(
        undefined
    );
    const [releaseNotesRevisionId, updateReleaseNotesRevisionId] = useState(-1);
    const [showReleaseNotes, updateShowReleaseNotes] = useState(false);
    const sortingState = useSortingState();
    const [
        fetchRevisionData,
        // @ts-ignore
        { loading, data: { revisions } = {} }
    ] = useLazyQuery<RevisionsData, RevisionsVariables>(GET_REVISIONS, {
        variables: { projectId: project.id, searchTerm: state.searchTerm },
        fetchPolicy: "network-only"
    });

    const [createAdset] = useMutation<CreateAdsetData, AdsetVariables>(
        CREATE_ADSET
    );
    const [removeRevision] = useMutation<RevisionVariables>(REMOVE_REVISION);
    const [updateRevision] = useMutation(UPDATE_REVISION);

    useEffect(() => {
        if (showChildren && !revisions) {
            fetchRevisionData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showChildren, revisions]);

    useEffect(() => {
        if (!loading && revisions) {
            updateRevisionsLoaded(true);
            updateLoaded(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading, revisions]);

    useEffect(() => {
        if (revisions) {
            const sortingMode =
                sortingState.sortingPriorities[2] || SortingMode.None;
            if (sortingMode === SortingMode.None) {
                updateSortedRevisions(revisions);
                return;
            }
            const sorted = [...revisions];
            sorted.sort((a, b) => sortItems(a, b, sortingMode));
            updateSortedRevisions(sorted);
        }
    }, [sortingState.sortingPriorities, revisions, project]);

    useSubscription(REVISION_CHANGED_SUBSCRIPTION, {
        variables: { projectId: project.id },
        onData: ({
            data: { data: { revisionChangedNotification } = {} },
            client
        }) => {
            console.log(
                "[DEBUG] revisionChangedNotification: ",
                revisionChangedNotification
            );
            const currentRevisionData = client.cache.readQuery<
                RevisionsData,
                RevisionsVariables
            >({ variables: { projectId: project.id }, query: GET_REVISIONS });

            const currentRevisions = currentRevisionData?.revisions;

            if (currentRevisions && revisionChangedNotification) {
                if (revisionChangedNotification.action === 0) {
                    client.cache.writeQuery<RevisionsData, RevisionsVariables>({
                        query: GET_REVISIONS,
                        data: {
                            revisions: [
                                ...currentRevisions,
                                revisionChangedNotification.revision
                            ]
                        },
                        variables: { projectId: project.id }
                    });
                } else {
                    if (revisionChangedNotification.action === 1) {
                        const currentRevision = sortedRevisions.find(
                            revision =>
                                revision.id ===
                                revisionChangedNotification.rId.toString()
                        );
                        console.log(
                            "[DEBUG] currentRevision ",
                            currentRevision
                        );
                        const newRevision = {
                            ...revisionChangedNotification.revision,
                            ...{
                                impressions: currentRevision?.impressions,
                                gameplay: currentRevision?.gameplay,
                                endScreen: currentRevision?.endScreen,
                                userImpressions:
                                    currentRevision?.userImpressions,
                                userGameplay: currentRevision?.userGameplay,
                                userEndScreen: currentRevision?.userEndScreen
                            }
                        };
                        console.log("[DEBUG] newRevision ", newRevision);
                        const newRevisions = currentRevisions.filter(
                            revision =>
                                revision.id !==
                                revisionChangedNotification.rId.toString()
                        );
                        client.cache.writeQuery<
                            RevisionsData,
                            RevisionsVariables
                        >({
                            query: GET_REVISIONS,
                            data: {
                                revisions: [...newRevisions, newRevision]
                            },
                            variables: { projectId: project.id }
                        });
                    } else if (revisionChangedNotification.action === 2) {
                        const newRevisions = currentRevisions.filter(
                            revision =>
                                revision.id !==
                                revisionChangedNotification.rId.toString()
                        );
                        client.cache.writeQuery<
                            RevisionsData,
                            RevisionsVariables
                        >({
                            query: GET_REVISIONS,
                            data: {
                                revisions: newRevisions
                            },
                            variables: { projectId: project.id }
                        });
                    }
                }
            }
        }
    });

    const createFromRevision = async (revision: Revision) => {
        try {
            const productId = revision.project.product?.id;
            const response = await createAdset({
                variables: {
                    projectId: project.id,
                    revisionId: revision.id
                },
                refetchQueries: [
                    {
                        query: GET_PRODUCT_META_DATA,
                        variables: { productId: project.product?.id }
                    },
                    {
                        query: GET_PROJECT_META_DATA,
                        variables: { projectId: project.id }
                    },
                    {
                        query: GET_REVISIONS,
                        variables: { projectId: project.id }
                    }
                ]
            });
            navigate(
                `/products/${productId}/projects/${project.id}/revisions/${response.data?.createAdset.id}/variations`
            );
        } catch (error) {
            console.log("[DEBUG] createFromRevision error: ", error);
        }
    };

    const updateRelease = async (
        id: number,
        productId?: number,
        newType?: ReleaseType,
        archived?: boolean
    ) => {
        try {
            await updateRevision({
                variables: {
                    revisionId: id,
                    releaseType: newType,
                    archived: archived
                },
                refetchQueries: [
                    {
                        query: GET_PRODUCT_META_DATA,
                        variables: { productId: productId }
                    },
                    {
                        query: GET_PROJECT_META_DATA,
                        variables: { projectId: project.id }
                    },
                    {
                        query: GET_REVISIONS,
                        variables: { projectId: project.id }
                    }
                ]
            });
        } catch (error) {
            console.log("[DEBUG] updateRelease error ", error);
        }
    };

    const removeRevisionById = async (id: number) => {
        try {
            await removeRevision({
                variables: { revisionId: id },
                refetchQueries: [
                    {
                        query: GET_PRODUCT_META_DATA,
                        variables: { productId: project.product?.id }
                    },
                    {
                        query: GET_PROJECT_META_DATA,
                        variables: { projectId: project.id }
                    },
                    {
                        query: GET_REVISIONS,
                        variables: { projectId: project.id }
                    }
                ]
            });
            if (toBeRemoved) {
                updateToBeRemoved(undefined);
            }
        } catch (error) {
            console.log("[DEBUG] removeRevisionById error ", error);
        }
    };

    const releaseItem: Revision | undefined = sortedRevisions?.find(
        (element: Revision) => element.releaseType === ReleaseType.Release
    );

    const reviewRelease: Revision | undefined = sortedRevisions?.find(
        (element: Revision) =>
            element.releaseType >= ReleaseType.Review &&
            element.releaseType <= ReleaseType.ReviewAccepted
    );

    const developmentRelease: Revision | undefined = sortedRevisions?.find(
        (element: Revision) => element.releaseType === ReleaseType.Development
    );

    const adSets = sortedRevisions?.filter(
        (element: Revision) => element.releaseType === ReleaseType.ClientRelease
    );

    if (!releaseItem && !developmentRelease && !reviewRelease && !adSets) {
        return (
            <Container className="fullSizeChild m-0 p-0">
                <Card className="text-inverse bread-text">
                    <Card.Header>
                        {state.developerMode
                            ? "No revisions"
                            : "In development"}
                    </Card.Header>
                </Card>
            </Container>
        );
    }

    if (!revisions || !revisionsLoaded) {
        return null;
    }

    return (
        <Container className="fullSizeChild m-0 p-0">
            <Card className="text-inverse no-border bread-text">
                <ReleaseItem
                    revision={releaseItem}
                    developerMode={state.developerMode}
                    projectId={project.id}
                    onClick={() => updateShowAdsets(!showAdsets)}
                    onCreate={
                        releaseItem
                            ? () => createFromRevision(releaseItem)
                            : undefined
                    }
                    showReleaseNotes={() => {
                        updateReleaseNotesRevisionId(releaseItem?.id || -1);
                        updateShowReleaseNotes(true);
                    }}
                    onRemove={
                        releaseItem
                            ? () => {
                                  updateToBeRemoved(releaseItem.id);
                                  updateShowRemoveAlert(true);
                              }
                            : undefined
                    }
                />
                <Collapse in={showAdsets}>
                    <div>
                        <AdSets
                            updateReleaseNotesRevisionId={
                                updateReleaseNotesRevisionId
                            }
                            updateShowReleaseNotes={() =>
                                updateShowReleaseNotes(true)
                            }
                            revisions={adSets}
                            projectId={project.id}
                        />
                    </div>
                </Collapse>
                <ReviewReleaseItem
                    revision={reviewRelease}
                    projectId={project.id}
                    developerMode={state.developerMode}
                    showReleaseNotes={(id: number) => {
                        updateReleaseNotesRevisionId(id);
                        updateShowReleaseNotes(true);
                    }}
                    onPromote={
                        reviewRelease
                            ? () =>
                                  updateRelease(
                                      reviewRelease.id,
                                      reviewRelease.project.product?.id,
                                      reviewRelease.releaseType ===
                                          ReleaseType.ReviewRejected
                                          ? ReleaseType.Review
                                          : reviewRelease.releaseType + 1
                                  )
                            : undefined
                    }
                    onDemote={
                        reviewRelease
                            ? () =>
                                  updateRelease(
                                      reviewRelease.id,
                                      reviewRelease.project.product?.id,
                                      ReleaseType.Development
                                  )
                            : undefined
                    }
                    onRemove={
                        reviewRelease
                            ? () => {
                                  updateToBeRemoved(reviewRelease.id);
                                  updateShowRemoveAlert(true);
                              }
                            : undefined
                    }
                />
                {state.developerMode ? (
                    <DevelopmentReleaseItem
                        revision={developmentRelease}
                        projectId={project.id}
                        showReleaseNotes={(id: number) => {
                            updateReleaseNotesRevisionId(id);
                            updateShowReleaseNotes(true);
                        }}
                        onPromote={
                            developmentRelease
                                ? () =>
                                      updateRelease(
                                          developmentRelease.id,
                                          developmentRelease.project.product
                                              ?.id,
                                          ReleaseType.Review
                                      )
                                : undefined
                        }
                        onRemove={
                            developmentRelease
                                ? () => {
                                      updateToBeRemoved(developmentRelease.id);
                                      updateShowRemoveAlert(true);
                                  }
                                : undefined
                        }
                    />
                ) : (
                    <ClientTestReleaseItem
                        revision={developmentRelease}
                        projectId={project.id}
                    />
                )}
            </Card>
            {showReleaseNotes ? (
                <ReleaseNotesModal
                    onClose={() => updateShowReleaseNotes(false)}
                    show={showReleaseNotes}
                    revisionId={releaseNotesRevisionId}
                />
            ) : null}
            {showRemoveAlert ? (
                <AlertModal
                    header={<h5>Remove Revision</h5>}
                    component="Removing the revision also removes its builds!"
                    visible={() => updateShowRemoveAlert(false)}
                    footer={
                        <Button
                            variant="danger"
                            className="mt-2"
                            onClick={() => {
                                if (toBeRemoved) {
                                    removeRevisionById(toBeRemoved);
                                    updateShowRemoveAlert(false);
                                }
                            }}
                        >
                            Continue to Remove
                        </Button>
                    }
                />
            ) : null}
        </Container>
    );
};

export const Project = React.memo(ProjectImplementation);
