import React, { useEffect, useState } from "react";
import { Alert, Button, Card, Col, Container, Row } from "react-bootstrap";
import {
    useLocation,
    useNavigate,
    useNavigationType,
    useParams
} from "react-router-dom";
import { BackButton } from "../../simple/BackButton";
import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import {
    AnalyticsEventDataSchema,
    AnalyticsEventSchema,
    AnalyticsEventVariables,
    AnalyticsSchema,
    AnalyticsSchemaByIdData,
    AnalyticsSchemaVariables,
    BatchBuild,
    NetworkBuild,
    PlatformConfigData,
    Product,
    Project,
    Revision,
    UpdateAnalyticsLibEventResult
} from "../../../models/types";
import {
    GET_ANALYTICS_EVENTS,
    GET_ANALYTICS_SCHEMA,
    GET_ANALYTICS_SCHEMA_BY_ID,
    GET_PLATFORM_CONFIG
} from "../../../graphql/queries";
import {
    UPDATE_ANALYTICS_LIB_EVENT,
    UPDATE_ANALYTICS_SCHEMA
} from "../../../graphql/mutations";
import { AnalyticsEventItem } from "../../variationItems/analytics/AnalyticsEventItem";
import { updateNotificationState } from "../../../common/Helpers";
import { AnalyticsEventLibraryModal } from "../../modals/analytics/AnalyticsEventLibraryModal";
import { AnalyticsEventUploadModal } from "../../modals/analytics/AnalyticsEventUploadModal";
import { FunnelElementsPreview } from "../../simple/analytics/FunnelElementsPreview";
import { ImportAnalyticsModal } from "../../modals/analytics/ImportAnalyticsModal";
import { SchemaDescription } from "./SchemaDescription";
import { useLocalAnalyticsState } from "../../../graphql/hooks";

export const AnalyticsSchemaEditView = () => {
    const client = useApolloClient();
    const {
        analyticsBuilds,
        analyticsNetworkBuilds,
        analyticsProducts,
        analyticsProjects,
        analyticsRevisions
    } = useLocalAnalyticsState();
    const { data: { getPlatformConfig } = {} } =
        useQuery<PlatformConfigData>(GET_PLATFORM_CONFIG);
    const { jobId } = useParams<{
        jobId: string;
    }>();
    const navigate = useNavigate();
    const location = useLocation();
    const navigationType = useNavigationType();
    const [showImportModal, updateShowImportModal] = useState(false);
    const [showEventLibraryModal, updateShowEventLibraryModal] =
        useState(false);
    const [showAlert, updateShowAlert] = useState(false);
    const { data: { events } = {} } =
        useQuery<AnalyticsSchema>(GET_ANALYTICS_EVENTS);
    const [analyticsLibEventMutation] = useMutation<
        UpdateAnalyticsLibEventResult,
        AnalyticsEventVariables
    >(UPDATE_ANALYTICS_LIB_EVENT);
    const [toBeSaved, updateToBeSaved] = useState<
        AnalyticsEventSchema | undefined
    >(undefined);
    const [updating, updateUpdating] = useState(false);
    const [currentSchema, updateCurrentSchema] = useState<
        AnalyticsSchema | undefined
    >(undefined);
    const dragItem = React.useRef<any>(null);
    const dragOverItem = React.useRef<any>(null);

    const { data } = useQuery<
        AnalyticsSchemaByIdData,
        AnalyticsSchemaVariables
    >(GET_ANALYTICS_SCHEMA_BY_ID, {
        fetchPolicy: "network-only",
        variables: {
            buildId: Number(jobId)
        }
    });
    const [updateSchema] = useMutation(UPDATE_ANALYTICS_SCHEMA);

    useEffect(() => {
        if (data) {
            if (data?.getAnalyticsSchemaById?.success) {
                const schemaData = JSON.parse(
                    JSON.stringify(data.getAnalyticsSchemaById.schema)
                );
                updateCurrentSchema(schemaData);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const save = async (schema: AnalyticsSchema | undefined) => {
        updateUpdating(true);
        try {
            await updateSchema({
                variables: {
                    buildId: Number(jobId),
                    schema: JSON.stringify(schema) // values
                },
                refetchQueries: [
                    {
                        query: GET_ANALYTICS_SCHEMA,
                        variables: {
                            analyticsProducts: analyticsProducts.flatMap(
                                (product: Product) =>
                                    product.selected ? Number(product.id) : []
                            ),
                            analyticsProjects: analyticsProjects.flatMap(
                                (project: Project) =>
                                    project.selected ? Number(project.id) : []
                            ),
                            analyticsRevisions: analyticsRevisions.flatMap(
                                (revision: Revision) =>
                                    revision.selected ? Number(revision.id) : []
                            ),
                            analyticsBuilds: analyticsBuilds.flatMap(
                                (build: BatchBuild) =>
                                    build.selected ? Number(build.id) : []
                            ),
                            analyticsNetworkBuilds:
                                analyticsNetworkBuilds.flatMap(
                                    (networkBuild: NetworkBuild) =>
                                        networkBuild.selected
                                            ? Number(networkBuild.id)
                                            : []
                                ),
                            apiVersion: getPlatformConfig?.analyticsApiVersion
                        }
                    }
                ]
            });
        } catch (error) {
            console.log("[DEBUG] save error ", error);
        }
        updateUpdating(false);
    };

    const updateLibraryEvent = async (event?: AnalyticsEventSchema) => {
        if (!event) return;
        try {
            await analyticsLibEventMutation({
                variables: {
                    eventName: event.eventName,
                    eventData: JSON.stringify(event.eventData),
                    comment: event.comment
                },
                update: (cache, { data }) => {
                    const currentLibEvents = events?.filter(element => {
                        return element.eventName !== event.eventName;
                    });
                    if (currentLibEvents && data) {
                        const updatedLibEvents = [
                            ...currentLibEvents,
                            data.updateAnalyticsLibEvent
                        ];
                        cache.writeQuery<AnalyticsSchema>({
                            query: GET_ANALYTICS_EVENTS,
                            data: { events: updatedLibEvents }
                        });
                    }
                }
            });
            if (toBeSaved) {
                updateToBeSaved(undefined);
            }
            updateNotificationState(client, false, true, {
                success: true,
                header: "Analytics Event Updating Status",
                message: `Event saved successfully: ${event.eventName}`,
                delay: 3000
            });
        } catch (error) {
            updateNotificationState(client, false, true, {
                success: false,
                header: "Analytics Event Updating Status",
                // @ts-ignore
                message: error.message,
                delay: 3000
            });
        }
    };

    const addNewEvent = () => {
        const newSchema: AnalyticsSchema = currentSchema
            ? { ...JSON.parse(JSON.stringify(currentSchema)) }
            : { events: [] };

        const newIndex = Math.max(1, newSchema.events.length - 1);
        newSchema.events.splice(newIndex, 0, {
            id: 0,
            eventName: "new...",
            eventData: [],
            comment: "",
            frozen: false
        });

        updateCurrentSchema(newSchema);
    };

    const addNewLibraryEvent = (newEvent: AnalyticsEventSchema) => {
        const newSchema: AnalyticsSchema = currentSchema
            ? { ...JSON.parse(JSON.stringify(currentSchema)) }
            : { events: [] };

        const isNew =
            newSchema.events.findIndex(
                element => element.eventName === newEvent.eventName
            ) === -1;
        if (!isNew) {
            newEvent = JSON.parse(JSON.stringify(newEvent));
            newEvent.eventName = newEvent.eventName + " - DUPLICATE -";
        }
        const newIndex = Math.max(1, newSchema.events.length - 1);
        newSchema.events.splice(newIndex, 0, newEvent);

        updateCurrentSchema(newSchema);
    };

    const updateAnalyticsSchema = (
        index: number,
        event?: AnalyticsEventSchema
    ) => {
        if (!currentSchema?.events) {
            return;
        }

        if (event) {
            const newEvents = [...currentSchema.events];
            newEvents[index] = event;
            updateCurrentSchema({ ...currentSchema, events: newEvents });
        } else {
            const newEvents = [
                ...currentSchema.events.slice(0, index),
                ...currentSchema.events.slice(index + 1)
            ];
            updateCurrentSchema({ ...currentSchema, events: newEvents });
        }
    };

    const saveToLibrary = (event: AnalyticsEventSchema) => {
        updateToBeSaved(event);
        updateShowAlert(true);
    };

    const findDuplicatesEvents = (event: AnalyticsEventSchema) => {
        const findDuplicatesEventData = (
            eventData?: AnalyticsEventDataSchema[]
        ) => {
            const keys = eventData?.map(item => {
                return item.key;
            });
            return keys?.some((item, idx) => {
                return keys.indexOf(item) !== idx;
            });
        };

        if (!currentSchema) {
            return false;
        }
        const duplicates = currentSchema.events.filter(element => {
            return (
                element.eventName === event.eventName ||
                findDuplicatesEventData(event.eventData)
            );
        });
        return duplicates.length > 1;
    };

    const isEqualToLib = (event: AnalyticsEventSchema) => {
        if (!events) {
            return false;
        }

        return events.some(e => {
            const element = JSON.parse(JSON.stringify(e));
            delete element.id;
            delete element.__typename;
            return (
                element.eventName === event.eventName &&
                element.comment === event.comment &&
                JSON.stringify(element.eventData) ===
                    JSON.stringify(event.eventData)
            );
        });
    };
    const updateSchemaDescription = (description: string) => {
        if (currentSchema) {
            updateCurrentSchema({
                ...currentSchema,
                description: description
            });
        }
    };

    const reorder = () => {
        if (
            currentSchema?.events[dragOverItem.current].frozen ||
            !dragOverItem
        ) {
            return;
        }

        const newSchema = { ...JSON.parse(JSON.stringify(currentSchema)) };

        const draggedItemContent = newSchema.events.splice(
            dragItem.current,
            1
        )[0];

        newSchema.events.splice(dragOverItem.current, 0, draggedItemContent);

        dragItem.current = dragOverItem.current;
        dragOverItem.current = null;

        updateCurrentSchema({
            ...currentSchema,
            events: newSchema.events
        });
    };

    const [isDragging, setIsDragging] = useState(false);

    const analyticsEvents = currentSchema?.events.map(
        (event: AnalyticsEventSchema, index: number) => {
            return (
                <AnalyticsEventItem
                    defaultValue={event}
                    index={index}
                    hasDuplicatesEvents={findDuplicatesEvents(event)}
                    isEqualToLib={isEqualToLib(event)}
                    key={event.eventName + index}
                    saveToLibrary={saveToLibrary}
                    updateAnalyticsSchema={updateAnalyticsSchema}
                    dragStart={() => {
                        dragItem.current = index;
                        setIsDragging(true);
                    }}
                    dragEnter={() => {
                        dragOverItem.current = index;
                        if (dragItem.current !== dragOverItem.current) {
                            reorder();
                        }
                    }}
                    dragEnd={() => {
                        dragItem.current = null;
                        setIsDragging(false);
                    }}
                    isDragged={isDragging && dragItem.current === index}
                />
            );
        }
    );

    if (!data) {
        return null;
    }

    return (
        <>
            <Row className="mt-4">
                <Col className="col-1 ms-2">
                    <BackButton
                        hasHistory={!(location.key && navigationType === "POP")}
                        goBack={() => navigate(-1)}
                        size={"lg"}
                    />
                </Col>
                <Col className="col-10 text-center">
                    <Alert variant="danger">
                        You are editing already built schema. You are required
                        to manually save to make the changes persist (even when
                        importing)!
                    </Alert>
                </Col>
                <Col className="col-1"></Col>
            </Row>
            <Container className="designerView px-0 px-sm-3">
                <Card>
                    <Card.Header className="px-3">
                        <Row className="d-flex align-contents-center">
                            <Col className="col-12 col-sm-2 d-flex align-items-center justify-content-center justify-content-sm-start">
                                <h5 className="text-inverse-50">Events</h5>
                            </Col>
                            <Col className="col-12 col-sm-10">
                                <div className="d-flex align-items-center justify-content-center justify-content-sm-end">
                                    <Button
                                        className="btn-info me-2"
                                        onClick={() =>
                                            updateShowImportModal(true)
                                        }
                                    >
                                        Import
                                    </Button>
                                    <Button
                                        className="btn-success me-2"
                                        as={"a"}
                                        href={`data:text/json;charset=utf-8,${encodeURIComponent(
                                            JSON.stringify(
                                                currentSchema,
                                                null,
                                                2
                                            )
                                        )}`}
                                        download={"analyticsSchema.json"}
                                    >
                                        Export
                                    </Button>
                                    <Button
                                        className="btn-analytics me-2"
                                        onClick={() =>
                                            updateShowEventLibraryModal(true)
                                        }
                                    >
                                        Event Library
                                    </Button>
                                    <Button onClick={addNewEvent}>
                                        Add Event
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Card.Header>
                    <Card.Body>
                        {analyticsEvents && analyticsEvents.length > 0 ? (
                            analyticsEvents
                        ) : (
                            <div className="text-inverse-50 text-center">
                                Events list is empty
                            </div>
                        )}
                        <SchemaDescription
                            updateCurrentSchema={updateSchemaDescription}
                            defaultValue={currentSchema?.description}
                        />
                    </Card.Body>
                    <Card.Footer className="d-flex justify-content-center">
                        <Button
                            variant="success"
                            onClick={(e: React.MouseEvent<HTMLElement>) => {
                                e.stopPropagation();
                                save(currentSchema);
                            }}
                            disabled={updating}
                        >
                            {updating ? "Saving..." : "Save"}
                        </Button>
                    </Card.Footer>
                </Card>
            </Container>
            <Row className="d-flex fullSize px-2 justify-content-center text-inverse">
                <Col>
                    <FunnelElementsPreview schema={currentSchema} />
                </Col>
            </Row>
            <ImportAnalyticsModal
                show={showImportModal}
                updateSchema={updateCurrentSchema}
                onClose={() => updateShowImportModal(!showImportModal)}
            />
            <AnalyticsEventLibraryModal
                show={showEventLibraryModal}
                analyticsEvents={events}
                updateSchema={addNewLibraryEvent}
                onClose={() =>
                    updateShowEventLibraryModal(!showEventLibraryModal)
                }
            />
            <AnalyticsEventUploadModal
                key={Date.now()}
                show={showAlert}
                analyticsEvent={toBeSaved}
                updateSchemaEvent={() => {
                    updateLibraryEvent(toBeSaved);
                    updateShowAlert(false);
                }}
                onClose={() => updateShowAlert(false)}
            />
        </>
    );
};
