import React, { FC, useEffect, useState } from "react";
import { Card, FormControl, InputGroup } from "react-bootstrap";
import { AnalyticsEventDataSchema } from "../../../models/types";

interface Props {
    frozen?: boolean;
    index: number;
    defaultValue: AnalyticsEventDataSchema;
    updateAnalyticsDataSchema: (
        index: number,
        eventData?: AnalyticsEventDataSchema
    ) => void;
}

export const AnalyticsEventDataLibraryItem: FC<Props> = ({
    frozen,
    index,
    defaultValue,
    updateAnalyticsDataSchema
}) => {
    const [eventData, updateEventData] = useState(defaultValue);
    const updateSchema = () => {
        updateAnalyticsDataSchema(index, eventData);
    };

    useEffect(() => {
        if (eventData !== defaultValue) {
            updateEventData(defaultValue);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultValue]);

    return (
        <Card className="mb-2">
            <Card.Header>
                <InputGroup className="mb-2">
                    <InputGroup.Text>Key</InputGroup.Text>
                    <FormControl
                        value={eventData.key}
                        disabled={frozen}
                        onChange={(eventChange: any) => {
                            const newKey = eventChange.target.value;
                            updateEventData({
                                ...eventData,
                                key: newKey
                            });
                        }}
                        onBlur={() => {
                            updateSchema();
                        }}
                    />
                </InputGroup>
                <InputGroup>
                    <InputGroup.Text>Values</InputGroup.Text>
                    <FormControl
                        value={
                            eventData.values ? eventData.values.join(",") : ""
                        }
                        disabled={frozen}
                        onChange={(eventChange: any) => {
                            const newValues =
                                eventChange.target.value.split(",");
                            updateEventData({
                                ...eventData,
                                values: newValues
                            });
                        }}
                        onBlur={() => {
                            updateSchema();
                        }}
                    />
                </InputGroup>
            </Card.Header>
        </Card>
    );
};
