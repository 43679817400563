import React, { FC, useEffect, useState } from "react";
import { DSVRowArray } from "d3";
import { loadCsv, pastelStepColor } from "../../common/AnalyticsHelpers";
import { Col, Row } from "react-bootstrap";
import { AnalyticsQueryType, AthenaDatasetResult } from "../../models/types";
import { ChartConfiguration } from "chart.js";
import { ChartJsWrapper } from "./ChartJsWrapper";

interface Props {
    data: AthenaDatasetResult[];
}

const MultiTimeLineChartImplementation: FC<Props> = ({ data }) => {
    const [csvData, updateCsvData] = useState<DSVRowArray[]>([]);

    useEffect(() => {
        const fetchCsvData = async () => {
            const csvDatas: DSVRowArray[] = [];
            for (let i = 0; i < data.length; i++) {
                const element = data[i];

                for (let j = 0; j < element.dataset.length; j++) {
                    const dataset = element.dataset[j];
                    if (
                        dataset.success &&
                        dataset.message &&
                        dataset.type === AnalyticsQueryType.TimeAverages
                    ) {
                        csvDatas.push(await loadCsv(dataset.message));
                    }
                }
            }
            updateCsvData(csvDatas);
        };
        fetchCsvData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (csvData.length === 0) {
        return null;
    }

    const datasets: any[] = [];
    const labels: string[] = [];
    for (let k = 0; k < csvData.length; k++) {
        if (csvData[k] === undefined) {
            continue;
        }
        const element = csvData[k];
        const averages: number[] = [];
        const runningTotals: number[] = [];
        let currentSum = 0;
        for (let i = 0; i < element.length; i++) {
            const eventName = element[i].eventName;
            const difference = Number(element[i].difference);
            const sequence = Number(element[i].sequence);
            if (
                eventName !== undefined &&
                difference !== undefined &&
                sequence !== undefined
            ) {
                if (k === 0 && !labels.includes(eventName)) {
                    labels.push(eventName);
                }
                averages.push(difference);
                const runningTotal = currentSum + difference;
                runningTotals.push(runningTotal);
                currentSum = runningTotal;
            }
        }
        for (let i = 0; i < averages.length; i++) {
            i === 0
                ? runningTotals.push(0)
                : runningTotals.push(averages[i - 1] + averages[i]);
        }
        const color = pastelStepColor(k / (csvData.length - 1));
        datasets.push(
            {
                type: "line",
                label: data[k].datasetName,
                data: runningTotals,
                borderColor: color,
                backgroundColor: color,
                pointBackgroundColor: color,
                pointBorderColor: color
            },
            {
                type: "bar",
                label: data[k].datasetName,
                data: averages,
                backgroundColor: color
            }
        );
    }
    const config: ChartConfiguration = {
        type: "bar",
        data: {
            labels: labels,
            datasets: datasets
        },
        options: {
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    labels: {
                        filter: (label: any) => label.datasetIndex % 2 === 0
                    }
                }
            }
        }
    };
    return (
        <Row>
            <Col md={12} className="d-flex justify-content-around">
                <div className="mx-5 d-flex justify-content-center align-items-center">
                    <div className="analytics-circle-multi"></div>
                    <div className="ps-2 text-inverse-50 analytics-font">
                        Cumulative time (s)
                    </div>
                </div>
                <div className="d-flex justify-content-center align-items-center">
                    <div className="analytics-square-multi"></div>
                    <div className="ps-2 text-inverse-50 analytics-font">
                        Time between steps (s)
                    </div>
                </div>
            </Col>
            <Col>
                <ChartJsWrapper config={config} />
            </Col>
        </Row>
    );
};

export const MultiTimeLineChart = React.memo(MultiTimeLineChartImplementation);
