import React, { FC, useEffect, useState } from "react";
import {
    DropdownAction,
    Project,
    ProjectMetaData,
    ProjectsData,
    ProjectSettings,
    ProjectVariables,
    UpdateProjectData
} from "../../models/types";
import { Card, Col, Collapse, Row } from "react-bootstrap";
import { ProjectSettingsModal } from "../modals/ProjectSettingsModal";
import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import { UPDATE_PROJECT } from "../../graphql/mutations";
import {
    GET_PROJECT_META_DATA,
    GET_PROJECTS_BY_PRODUCT
} from "../../graphql/queries";
import { ActionDropdown } from "../dropdowns/ActionDropdown";
import { Project as ProjectView } from "../views/Project";
import { useLocalAnalyticsState, useLocalState } from "../../graphql/hooks";
import { AnalyticsFunnelSelectionToggle } from "../buttons/analytics/AnalyticsFunnelSelectionToggle";
import {
    saveCollapseState,
    updateCollapseState,
    updateCollapseTiersInCache
} from "../../common/Helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { SimpleDate } from "./SimpleDate";
import { DailyCTR } from "./analytics/DailyCTR";
import { DailyImpressions } from "./analytics/DailyImpressions";
import { UserQueryCTR } from "./analytics/UserQueryCTR";
import { UserQueryImpressions } from "./analytics/UserQueryImpressions";
import { StatusBadges } from "./StatusBadges";
import cx from "classnames";

interface Props {
    item: Project;
    productId: number;
    newItemText?: string;
    onCreate?: (variables?: any) => void;
    onRemove: () => void;
}

const ProjectCardItemImplementation: FC<Props> = ({
    item,
    productId,
    onRemove
}) => {
    const client = useApolloClient();
    const state = useLocalState();
    const { analyticsEnabled, analyticsFunnelSelectionMode } =
        useLocalAnalyticsState();
    const [loaded, updateLoaded] = useState(false);
    const [showChildren, updateShowChildren] = useState(
        state.projectTier && state.projectTier.has(Number(item.id))
    );
    const [showSettingsModal, updateShowSettingsModal] = useState(false);

    const { data: { projectMetaData } = {} } = useQuery<
        ProjectMetaData,
        ProjectVariables
    >(GET_PROJECT_META_DATA, {
        variables: { projectId: Number(item?.id) },
        fetchPolicy: "network-only"
    });

    const [updateProjectMutation] = useMutation<
        UpdateProjectData,
        ProjectSettings
    >(UPDATE_PROJECT);

    useEffect(() => {
        const updatedSet = updateCollapseState(
            state.projectTier,
            Number(item.id),
            showChildren
        );
        saveCollapseState(1, updatedSet);
        updateCollapseTiersInCache(client, undefined, updatedSet);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showChildren]);

    const updateProject = async (setting: ProjectSettings) => {
        try {
            await updateProjectMutation({
                variables: setting,
                update: (cache, { data }) => {
                    const currentProjectsData = cache.readQuery<
                        ProjectsData,
                        ProjectVariables
                    >({
                        query: GET_PROJECTS_BY_PRODUCT,
                        variables: { productId: productId }
                    });
                    const currentProjects = currentProjectsData?.projects;
                    if (currentProjects && data) {
                        const newProjects = currentProjects.map(project =>
                            project.id === data.updateProject.id
                                ? data.updateProject
                                : project
                        );
                        cache.writeQuery<ProjectsData, ProjectVariables>({
                            query: GET_PROJECTS_BY_PRODUCT,
                            data: { projects: newProjects }
                        });
                    }
                }
            });
            updateShowSettingsModal(false);
        } catch (error) {
            console.log("[DEBUG] updateProject error ", error);
        }
    };

    if (!item) {
        return null;
    }

    const actions: DropdownAction[] = [
        {
            header: "Project Settings",
            variant: "inverse",
            callback: () => updateShowSettingsModal(true)
        },
        {
            header: "Remove Project",
            variant: "danger",
            callback: onRemove
        }
    ];

    return (
        <Card className="border-0">
            <Card.Header
                className="text-inverse project-title pb-1 highlight-card btn"
                onClick={e => {
                    if (e.defaultPrevented) return;
                    updateShowChildren(!showChildren);
                }}
            >
                <Row className="d-flex align-items-center no-wrap bread-text">
                    <Col
                        className={cx("text-start", {
                            "col-4": analyticsEnabled,
                            "col-6": !analyticsEnabled
                        })}
                    >
                        <span className="ms-1">{item?.name}</span>
                        <FontAwesomeIcon
                            className={`icon-chevron ms-2 ${
                                showChildren ? "rotate-180" : ""
                            }`}
                            icon={faChevronDown}
                        />
                    </Col>
                    <Col className="col-1 d-flex ps-0 no-wrap xs-font justify-content-center">
                        <SimpleDate dateUpdated={item.dateUpdated} />
                    </Col>
                    <Col
                        className={cx("text-start ps-2", {
                            "col-2": analyticsEnabled,
                            "col-4": !analyticsEnabled
                        })}
                    >
                        {projectMetaData ? (
                            <StatusBadges
                                developerMode={state.developerMode}
                                metaData={projectMetaData}
                            />
                        ) : null}
                    </Col>
                    {analyticsEnabled ? (
                        <>
                            <Col className="col-1 text-start ps-2 pe-0">
                                <DailyImpressions
                                    impressions={item.impressions}
                                />
                            </Col>
                            <Col className="col-1 text-start pe-0">
                                <DailyCTR
                                    gameplay={item.gameplay}
                                    endScreen={item.endScreen}
                                    impressions={item.impressions}
                                />
                            </Col>
                            <Col className="col-1 text-start ms-1 pe-0">
                                <UserQueryImpressions
                                    impressions={item.userImpressions}
                                />
                            </Col>
                            <Col className="col-1 text-start pe-0">
                                <UserQueryCTR
                                    gameplay={item.userGameplay}
                                    endScreen={item.userEndScreen}
                                    impressions={item.userImpressions}
                                />
                            </Col>
                        </>
                    ) : null}
                    <Col className="d-flex col-1 justify-content-end p-0">
                        {analyticsEnabled && analyticsFunnelSelectionMode ? (
                            <AnalyticsFunnelSelectionToggle
                                reference="project"
                                project={{
                                    ...item,
                                    selected: true,
                                    comparison: `project-${item.id}`
                                }}
                                className="custom-funnel-button"
                            />
                        ) : null}

                        {actions ? (
                            <ActionDropdown
                                id={`project-${item.id}`}
                                variant="success"
                                actions={actions}
                            />
                        ) : null}
                    </Col>
                </Row>
            </Card.Header>
            <Collapse in={showChildren && loaded}>
                <div>
                    <Card.Body className="custom-card-body pe-2 fullSizeChild">
                        <ProjectView
                            project={item}
                            showChildren={showChildren}
                            updateLoaded={updateLoaded}
                        />
                    </Card.Body>
                </div>
            </Collapse>
            <ProjectSettingsModal
                project={item}
                show={showSettingsModal}
                onClose={() => updateShowSettingsModal(false)}
                onSave={updateProject}
            />
        </Card>
    );
};

export const ProjectCardItem = React.memo(ProjectCardItemImplementation);
