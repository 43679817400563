import React, { FC, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useQuery } from "@apollo/client";
import {
    LocalizationDataVariables,
    LocalizationQueryData
} from "../../models/types";
import { GET_LOCALIZATION_DATA } from "../../graphql/queries";
import { useLocalState } from "../../graphql/hooks";
import Prism from "prismjs";
import "prismjs/components/prism-json.min";
import "prismjs/themes/prism.css";
import "./Modals.scss";

interface Props {
    localizationData: {
        localization: string;
        s3Key: string;
    };
    revisionConfigId: number;
    show: boolean;
    onClose: () => void;
}

export const LocalizationViewModal: FC<Props> = ({
    localizationData,
    revisionConfigId,
    show,
    onClose
}) => {
    const { useDarkMode } = useLocalState();
    const { data: { getLocalization } = {} } = useQuery<
        LocalizationQueryData,
        LocalizationDataVariables
    >(GET_LOCALIZATION_DATA, {
        variables: {
            revisionConfigId: revisionConfigId,
            localization: localizationData.s3Key
        },
        fetchPolicy: "network-only"
    });

    // const codeBlock =
    //     getLocalization && getLocalization.localizationJson
    //         ? JSON.stringify(
    //               JSON.parse(getLocalization.localizationJson),
    //               null,
    //               2
    //           )
    //         : undefined;
    const codeBlock =
        getLocalization && getLocalization.localizationJson
            ? JSON.stringify(getLocalization.localizationJson, null, 2)
            : undefined;

    useEffect(() => {
        if (codeBlock) {
            Prism.highlightAll();
        }
    }, [codeBlock]);

    return (
        <Modal
            size="xl"
            show={show}
            onHide={onClose}
            backdrop="static"
            keyboard={false}
            className={useDarkMode ? "modal-dark" : ""}
        >
            <Modal.Header closeButton>
                <Modal.Title>{localizationData.localization}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-2">
                <div className="Code">
                    <pre>
                        <code className="language-json">{codeBlock}</code>
                    </pre>
                </div>
            </Modal.Body>
        </Modal>
    );
};
