import React, { FC } from "react";
import {
    BatchBuildsData,
    BatchBuildState,
    BatchBuildsVariables,
    DropdownAction,
    Revision
} from "../../models/types";
import { Card, Col, Container, Row } from "react-bootstrap";
import { useMatch, useNavigate } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_BATCH_BUILDS } from "../../graphql/queries";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import { checkBuildsStatus } from "../../common/Helpers";
import { ActionDropdown } from "../dropdowns/ActionDropdown";
import { UserQueryCTR } from "./analytics/UserQueryCTR";
import { UserQueryImpressions } from "./analytics/UserQueryImpressions";
import { DailyCTR } from "./analytics/DailyCTR";
import { DailyImpressions } from "./analytics/DailyImpressions";
import { useLocalAnalyticsState } from "../../graphql/hooks";
import { SimpleDate } from "./SimpleDate";
import cx from "classnames";
import "./simple.scss";

interface Props {
    revision?: Revision;
    projectId: number;
    showReleaseNotes: (id: number) => void;
    onPromote?: () => void;
    onRemove?: () => void;
}

const DevelopmentReleaseItemImplementation: FC<Props> = ({
    revision,
    projectId,
    showReleaseNotes,
    onPromote,
    onRemove
}) => {
    const navigate = useNavigate();
    const match = useMatch("/:path/*");
    const { analyticsEnabled } = useLocalAnalyticsState();
    const { data } = useQuery<BatchBuildsData, BatchBuildsVariables>(
        GET_BATCH_BUILDS,
        {
            variables: {
                revisionId: revision?.id
            },
            skip: revision === undefined
        }
    );

    if (!revision) {
        return null;
    }

    const productId = revision?.project.product?.id;
    const variationsPath = `${match?.pathname}/${productId}/projects/${projectId}/revisions/${revision?.id}/variations`;
    const eventPath = `${match?.pathname}/${productId}/projects/${projectId}/revisions/${revision?.id}/events`;
    let historyPath = `${match?.pathname}/${productId}/projects/${projectId}/revisions/${revision?.id}/history`;

    const { hasBuilds, hasRunning, latestCompleted } = checkBuildsStatus(data);

    if (latestCompleted) {
        historyPath += `/${latestCompleted.id}`;
    }

    const hasReviewTag =
        data &&
        data.builds.some(build => build.state === BatchBuildState.Review);

    const actions: DropdownAction[] = [
        {
            header: "Edit",
            variant: "inverse",
            callback: () => navigate(variationsPath)
        },
        {
            header: "Events",
            variant: "inverse",
            callback: () => navigate(eventPath)
        },
        {
            header: hasRunning ? (
                <Container className="m-0 p-0">
                    <span className="text-start text-inverse">Builds</span>
                    {hasRunning ? (
                        <FontAwesomeIcon
                            icon={faSyncAlt}
                            size="1x"
                            className="text-inverse float-end ms-2 rotate-image-animation"
                        />
                    ) : null}
                </Container>
            ) : (
                "Builds"
            ),
            variant: hasBuilds ? "inverse" : "disabled",
            className: hasBuilds ? undefined : "disabled",
            callback: () => {
                if (hasBuilds) {
                    navigate(historyPath);
                }
            }
        },
        {
            header: "Changelog",
            variant: "inverse",
            callback: () => showReleaseNotes(revision?.id)
        },
        {
            header: "Promote",
            variant: !hasBuilds || !hasReviewTag ? "disabled" : "inverse",
            className: !hasBuilds || !hasReviewTag ? "disabled" : undefined,
            callback: () => {
                if (onPromote && hasReviewTag) {
                    onPromote();
                }
            }
        },
        {
            header: "Remove",
            variant: "danger",
            callback: () => {
                if (onRemove) {
                    onRemove();
                }
            }
        }
    ];

    return (
        <Card.Header
            onClick={e => {
                if (e.defaultPrevented) {
                    return;
                }
                navigate(variationsPath);
            }}
            className="text-inverse bread-text btn custom-card-body-children bg-info"
        >
            <Row className="d-flex align-items-center">
                <Col
                    className={cx("text-start revision-title", {
                        "col-4": analyticsEnabled,
                        "col-6": !analyticsEnabled
                    })}
                >
                    {revision?.name}
                </Col>
                <Col className="col-1 d-flex ps-0 no-wrap xs-font justify-content-center">
                    <SimpleDate dateUpdated={revision.dateUpdated} />
                </Col>
                <Col
                    className={cx("text-start", {
                        "col-2": analyticsEnabled,
                        "col-4": !analyticsEnabled
                    })}
                ></Col>
                {analyticsEnabled ? (
                    <>
                        <Col className="col-1 text-start ps-1 pe-0">
                            <DailyImpressions
                                impressions={revision.impressions}
                            />
                        </Col>
                        <Col className="col-1 pe-0 text-start">
                            <DailyCTR
                                gameplay={revision.gameplay}
                                endScreen={revision.endScreen}
                                impressions={revision.impressions}
                            />
                        </Col>
                        <Col className="col-1 text-start pe-0">
                            <UserQueryImpressions
                                impressions={revision.userImpressions}
                            />
                        </Col>
                        <Col className="col-1 text-start pe-0">
                            <UserQueryCTR
                                gameplay={revision.userGameplay}
                                endScreen={revision.userEndScreen}
                                impressions={revision.userImpressions}
                            />
                        </Col>
                    </>
                ) : null}
                <Col className="col-1 d-flex pe-1 justify-content-end">
                    <ActionDropdown
                        id={`dev-release-${revision.id}`}
                        variant="info"
                        actions={actions}
                    />
                </Col>
            </Row>
        </Card.Header>
    );
};

export const DevelopmentReleaseItem = React.memo(
    DevelopmentReleaseItemImplementation
);
