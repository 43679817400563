import React, { FC } from "react";
import { Button, Modal } from "react-bootstrap";
import { useLocalState } from "../../graphql/hooks";
import styles from "../../sass/modals/Alert.module.scss";

interface Props {
    visible: () => void;
    header?: React.ReactNode;
    message?: React.ReactNode;
    footer?: any;
    component?: any;
}

export const AlertModal: FC<Props> = ({
    visible,
    header,
    message,
    footer,
    component
}) => {
    const { useDarkMode } = useLocalState();

    return (
        <Modal
            show={true}
            onHide={visible}
            backdrop="static"
            onClick={(e: React.MouseEvent<HTMLElement>) => e.stopPropagation()}
            className={useDarkMode ? "modal-dark" : ""}
        >
            <Modal.Header closeButton>{header ? header : "Alert"}</Modal.Header>
            <Modal.Body className={styles.message}>
                {message ? message : null}
                {component ? (
                    <div className="text-center m-2">{component}</div>
                ) : null}
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-center">
                <Button
                    variant="secondary"
                    onClick={(e: React.MouseEvent<HTMLElement>) => {
                        e.stopPropagation();
                        visible();
                    }}
                >
                    Close
                </Button>
                {footer}
            </Modal.Footer>
        </Modal>
    );
};
