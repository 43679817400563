import React from "react";
import { OrderMode } from "../../models/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faSort,
    faSortDown,
    faSortUp
} from "@fortawesome/free-solid-svg-icons";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

interface Props {
    criteria: string;
    label: string;
    tooltipText: string;
    isSelected: boolean;
    sortDirection: OrderMode | undefined;
    onClick: (criteria: string) => void;
}

export const BuildSortButton: React.FC<Props> = ({
    criteria,
    label,
    tooltipText,
    isSelected,
    sortDirection,
    onClick
}) => {
    const sortIcon = isSelected ? (
        sortDirection === OrderMode.Ascending ? (
            <FontAwesomeIcon icon={faSortUp} size="xs" />
        ) : (
            <FontAwesomeIcon icon={faSortDown} size="xs" />
        )
    ) : (
        <FontAwesomeIcon icon={faSort} size="xs" />
    );

    return (
        <OverlayTrigger
            key={criteria}
            placement="top"
            overlay={
                <Tooltip id={`tooltip-${criteria}`}>{tooltipText}</Tooltip>
            }
        >
            <strong className="mouseHover" onClick={() => onClick(criteria)}>
                <span className="text-inverse-50 bread-text">
                    {label} {sortIcon}
                </span>
            </strong>
        </OverlayTrigger>
    );
};
