import React, { FC } from "react";
import {
    faCheckCircle,
    faTimesCircle
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props {
    report?: string;
}

export const NetworkTestReport: FC<Props> = ({ report }) => {
    if (!report) return null;

    const yes = (
        <FontAwesomeIcon
            icon={faCheckCircle}
            size="1x"
            className="text-success float-end ms-2"
        />
    );

    const no = (
        <FontAwesomeIcon
            icon={faTimesCircle}
            size="1x"
            className="text-danger float-end ms-2"
        />
    );

    const reportObj = JSON.parse(JSON.stringify(report));
    const keys = Object.keys(reportObj);
    const results = keys.map((key, index) => {
        return (
            <p
                key={index}
                className={
                    reportObj[key].success ? "text-success" : "text-danger"
                }
            >
                {reportObj[key].text}
                {reportObj[key].success ? yes : no}
            </p>
        );
    });

    return <>{results}</>;
};
