import React, { useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import { useQuery } from "@apollo/client";
import { MeData } from "../../models/types";
import { GET_ME } from "../../graphql/queries";
import { useNavigate } from "react-router-dom";

export const Signup = () => {
    const navigate = useNavigate();
    const { data: { me } = {} } = useQuery<MeData>(GET_ME);

    useEffect(() => {
        if (me !== undefined) {
            navigate("/products", { replace: true });
        }
    }, [me]);

    return (
        <Container className="d-flex justify-content-center align-items-center align-content-center text-inverse">
            <Row className="d-flex justify-content-center text-center mt-5">
                Starting registering flow...
            </Row>
        </Container>
    );
};
