import React, { useState } from "react";
import { Card, Collapse } from "react-bootstrap";
import { FunnelProductsDetails } from "./FunnelProductsDetails";
import { FunnelProjectDetails } from "./FunnelProjectDetails";
import { FunnelRevisionDetails } from "./FunnelRevisionDetails";
import { FunnelBuildDetails } from "./FunnelBuildDetails";
import { FunnelNetworkBuildDetails } from "./FunnelNetworkBuildDetails";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

export const QuerySelection = () => {
    const [showDetails, updateShowDetails] = useState(true);

    return (
        <Card className="mb-2">
            <Card.Header onClick={() => updateShowDetails(!showDetails)}>
                <h5 className="text-inverse-50 d-flex align-items-center mouseHover">
                    Query Selection
                    <FontAwesomeIcon
                        size="xs"
                        icon={faChevronDown}
                        className="ms-2 mt-1 text-inverse-25"
                    />
                </h5>
            </Card.Header>
            <Collapse in={showDetails}>
                <div>
                    <Card.Body className="text-inverse">
                        <FunnelProductsDetails />
                        <FunnelProjectDetails />
                        <FunnelRevisionDetails />
                        <FunnelBuildDetails />
                        <FunnelNetworkBuildDetails />
                    </Card.Body>
                </div>
            </Collapse>
        </Card>
    );
};
