import React, { useEffect } from "react";
import { Container, Nav, Tab } from "react-bootstrap";
import { FunnelAnalyticsView } from "../views/analytics/FunnelAnalyticsView";
import { useLocalAnalyticsState } from "../../graphql/hooks";
import { useApolloClient } from "@apollo/client";
import { AnalyticsStateData } from "../../models/types";
import { GET_LOCAL_ANALYTICS_STATE } from "../../graphql/queries";
import { BackButton } from "../simple/BackButton";
import { useLocation, useNavigate, useNavigationType } from "react-router-dom";
import { clearFunnelSelection } from "../../common/AnalyticsHelpers";
import { QueryGuide } from "../views/analytics/QueryGuide";
import styles from "../pages/FunnelView.module.scss";

export const AnalyticsPlayground = () => {
    const analyticsState = useLocalAnalyticsState();
    const client = useApolloClient();
    const navigate = useNavigate();
    const location = useLocation();
    const navigationType = useNavigationType();

    const updateCurrentTab = (tab: string) => {
        client.writeQuery<AnalyticsStateData, AnalyticsStateData>({
            query: GET_LOCAL_ANALYTICS_STATE,
            data: {
                analyticsState: {
                    ...analyticsState,
                    analyticsSelectedTab: tab
                }
            }
        });
    };

    useEffect(
        () => {
            return () => {
                if (!location.pathname.endsWith("/analyticsSchema")) {
                    clearFunnelSelection(client);
                }
            };
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    return (
        <>
            <div className="mt-3">
                <BackButton
                    hasHistory={!(location.key && navigationType === "POP")}
                    goBack={() => navigate(-1)}
                    size={"lg"}
                />
            </div>
            <Container className={`${styles.responsiveContainer} mt-3`}>
                <Tab.Container activeKey={analyticsState.analyticsSelectedTab}>
                    <Nav
                        variant="tabs"
                        className="tabs-inverse"
                        onSelect={eventKey => {
                            updateCurrentTab(eventKey || "funnels");
                        }}
                    >
                        <Nav.Item>
                            <Nav.Link eventKey="funnels">Funnels</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="guide">Guide</Nav.Link>
                        </Nav.Item>
                    </Nav>
                    <Tab.Content>
                        <Tab.Pane eventKey="funnels">
                            {analyticsState.analyticsSelectedTab ===
                            "funnels" ? (
                                <FunnelAnalyticsView />
                            ) : null}
                        </Tab.Pane>
                        <Tab.Pane eventKey="guide">
                            <QueryGuide />
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>
            </Container>
        </>
    );
};
