import React, { FC, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faAngleDoubleDown,
    faAngleDoubleUp,
    faChevronDown,
    faChevronUp,
    faPlus
} from "@fortawesome/free-solid-svg-icons";
import {
    VariationCollapseState,
    VariationViewType
} from "../../../models/common";
import { Button, Card, Collapse, Container } from "react-bootstrap";
import { Revision } from "../../../models/types";
import { Builds } from "./Builds";
import {
    getVariationCollapseState,
    saveVariationCollapseState
} from "../../../common/Helpers";

interface Props {
    revision: Revision;
    openNewItemModal: (type: VariationViewType) => void;
}

const BuildsManagerImplementation: FC<Props> = ({
    revision,
    openNewItemModal
}) => {
    const [showBuilds, setShowBuilds] = useState<boolean>(
        getVariationCollapseState(VariationCollapseState.Builds)
    );
    const [showBuildDetails, setShowBuildDetails] = useState<boolean>(
        getVariationCollapseState(VariationCollapseState.BuildDetails)
    );

    const updateShowBuilds = (newState: boolean) => {
        saveVariationCollapseState(VariationCollapseState.Builds, newState);
        setShowBuilds(newState);
    };
    const hasBuilds =
        revision.buildData &&
        Array.isArray(revision.buildData.builds) &&
        revision.buildData.builds.length > 0;

    const updateShowBuildDetails = (newState: boolean) => {
        saveVariationCollapseState(
            VariationCollapseState.BuildDetails,
            newState
        );
        setShowBuildDetails(newState);
    };

    return (
        <>
            <Container className="mt-4 mb-4 p-0 editPanelView">
                <Card>
                    <Card.Header className="d-flex align-items-center">
                        <h5 className="text-inverse-50 mb-1 me-1">Builds</h5>
                        {hasBuilds && (
                            <div className="d-flex">
                                <div
                                    className="ms-2 mb-1 mouseHover text-inverse-50"
                                    onClick={() => {
                                        updateShowBuilds(!showBuilds);
                                    }}
                                    data-toggle="tooltip"
                                    title="Toggle build visibility"
                                >
                                    <FontAwesomeIcon
                                        icon={
                                            showBuilds
                                                ? faChevronUp
                                                : faChevronDown
                                        }
                                    />
                                </div>
                            </div>
                        )}
                        <div className="float-end ms-auto">
                            <Button
                                onClick={() =>
                                    openNewItemModal(VariationViewType.Builds)
                                }
                                data-toggle="tooltip"
                                title="Add new Build"
                                className="btn-sm me-2"
                            >
                                <FontAwesomeIcon icon={faPlus} size="lg" />
                            </Button>
                            <Button
                                onClick={() => {
                                    updateShowBuildDetails(!showBuildDetails);
                                }}
                                data-toggle="tooltip"
                                title={
                                    showBuildDetails
                                        ? "Hide all Builds details"
                                        : "Show all Builds details"
                                }
                                className="btn-sm"
                                disabled={!hasBuilds || !showBuilds}
                            >
                                <FontAwesomeIcon
                                    size="lg"
                                    icon={
                                        showBuildDetails
                                            ? faAngleDoubleUp
                                            : faAngleDoubleDown
                                    }
                                />
                            </Button>
                        </div>
                    </Card.Header>
                    {hasBuilds && (
                        <Collapse in={showBuilds}>
                            <div>
                                <Card.Body>
                                    <Builds
                                        revision={revision}
                                        developerMode={true}
                                        shouldBeVisible={showBuildDetails}
                                    />
                                </Card.Body>
                            </div>
                        </Collapse>
                    )}
                </Card>
            </Container>
        </>
    );
};

export const BuildsManager = React.memo(BuildsManagerImplementation);
