import React, { FC, useState } from "react";
import { useQuery } from "@apollo/client";
import { GET_PROJECTS } from "../../../graphql/queries";
import { Project, ProjectsAdminData, User } from "../../../models/types";
import { Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import cx from "classnames";

interface Props {
    user: User;
    updateProjects: (projectId: number) => void;
}

export const SelectableProjects: FC<Props> = ({ user, updateProjects }) => {
    const { data: { allProjects } = {} } = useQuery<ProjectsAdminData>(
        GET_PROJECTS,
        {
            variables: {
                organizationId: Number(user.organization?.id)
            }
        }
    );

    const selectedProject = (project: Project): boolean => {
        if (!user.projects) {
            return false;
        }
        return user.projects.some(
            (userProject: Project) =>
                Number(userProject.id) === Number(project.id)
        );
    };

    const alternatives = allProjects?.map((project: Project, index: number) => (
        <li
            key={index}
            className={cx(
                "list-group-item  mouseHover",
                selectedProject(project)
                    ? "list-group-item-success"
                    : "list-group-item-secondary"
            )}
            onClick={() => updateProjects(Number(project.id))}
        >
            {project.id}: {project.name} ({project.product?.name})
        </li>
    ));

    const [showList, setShowList] = useState(true);

    return (
        <>
            {allProjects && allProjects?.length > 0 ? (
                <Row className="m-0 p-0 d-flex align-items-center mb-2">
                    <h4
                        className="mouseHover"
                        onClick={() => setShowList(!showList)}
                    >
                        Projects
                        <FontAwesomeIcon
                            icon={showList ? faChevronUp : faChevronDown}
                            style={{
                                color: "gray",
                                marginLeft: "6px",
                                fontSize: "16px"
                            }}
                        />
                    </h4>
                </Row>
            ) : null}
            {showList && (
                <ul className="list-group" id="selectable-projects-list">
                    {alternatives}
                </ul>
            )}
        </>
    );
};
