import React, { FC, useEffect, useState } from "react";
import { Card, Col, Container, InputGroup, Row } from "react-bootstrap";
import { AnalyticsTimeRangeDropdown } from "../../dropdowns/analytics/AnalyticsTimeRangeDropdown";
import {
    AnalyticsLoadingState,
    AnalyticsQueryConfig,
    AnalyticsQueryTask,
    AnalyticsQueryTaskFetchState,
    AnalyticsQueryType,
    AnalyticsQueryVariables,
    AnalyticsTaskResult,
    PlatformConfigData
} from "../../../models/types";
import {
    getDatesInRange,
    getFormattedTimeFromUnix,
    getLastAnalyticsUpdateDateString,
    getTimestampRangeForDate
} from "../../../common/Helpers";
import { AnalyticsDateInput } from "../../simple/analytics/DateInput";
import { AnalyticsQueryButtonWrapper } from "../../buttons/analytics/AnalyticsQueryButtonWrapper";
import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import { GENERATE_ANALYTICS_TASK } from "../../../graphql/mutations";
import { useLocalAnalyticsState } from "../../../graphql/hooks";
import {
    generateQuerySets,
    hasExtraSelection,
    resetAnalyticsResults,
    updateAthenaTask,
    updateLoadingState
} from "../../../common/AnalyticsHelpers";
import { SimpleToast } from "../../simple/SimpleToast";
import { GET_PLATFORM_CONFIG } from "../../../graphql/queries";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

interface Props {
    apiVersion: string;
    queryType: AnalyticsQueryType;
}

export const QueryOptions: FC<Props> = ({ apiVersion, queryType }) => {
    const client = useApolloClient();
    const analyticsState = useLocalAnalyticsState();
    const { analyticsTimestamps } = useLocalAnalyticsState();
    const [taskLoading, updateTaskLoading] = useState(false);
    const [includeDayFunnel, updateIncludeDayFunnel] = useState(false);

    const { data: { getPlatformConfig } = {} } =
        useQuery<PlatformConfigData>(GET_PLATFORM_CONFIG);
    const [getAnalyticsTask] = useMutation<
        AnalyticsTaskResult,
        AnalyticsQueryVariables
    >(GENERATE_ANALYTICS_TASK);
    const lastAnalyticsUpdate = getLastAnalyticsUpdateDateString();
    const [errorMessage, setErrorMessage] = useState("");
    const [showOrphanWarning, setShowOrphanWarning] = useState(false);
    const updateErrorMessage = (message: string) => {
        setErrorMessage(message);
    };
    const getTimeString = () => {
        if (queryType === AnalyticsQueryType.DaySelectionFunnel) {
            const dateList = getDatesInRange(
                analyticsTimestamps.startTimestamp,
                analyticsTimestamps.endTimestamp
            );
            return `${getFormattedTimeFromUnix(
                getTimestampRangeForDate(dateList[0]).startTimestamp
            )} - ${getFormattedTimeFromUnix(
                getTimestampRangeForDate(dateList[dateList.length - 1])
                    .endTimestamp
            )}`;
        }
        return `${getFormattedTimeFromUnix(
            analyticsTimestamps.startTimestamp
        )} - ${getFormattedTimeFromUnix(analyticsTimestamps.endTimestamp)}`;
    };

    const generateAthenaQuery = async (config: AnalyticsQueryConfig) => {
        try {
            const result = await getAnalyticsTask({
                variables: {
                    input: config
                }
            });

            if (result.data?.generateAnalyticsTask.taskId) {
                const subQueries = result.data?.generateAnalyticsTask.subTasks
                    ? result.data?.generateAnalyticsTask.subTasks
                    : undefined;
                const task: AnalyticsQueryTask = {
                    taskId: result.data?.generateAnalyticsTask.taskId,
                    type: AnalyticsQueryType.Funnel,
                    isReady: false,
                    includeDayFunnel: includeDayFunnel,
                    fetchStatus: AnalyticsQueryTaskFetchState.Querying,
                    subQueries: subQueries
                };
                return task;
            }
            return undefined;
        } catch (error) {
            console.log("[DEBUG] generateAthenaQuery error ", error);
        }
    };

    const taskQuery = async () => {
        updateTaskLoading(true);

        let hasEmptyEventData = false;
        loop1: for (let i = 0; i < analyticsState.funnelEvents.length; i++) {
            if (analyticsState.funnelEvents[i].eventData) {
                for (
                    let j = 0;
                    j < analyticsState.funnelEvents[i].eventData.length;
                    j++
                ) {
                    if (
                        !analyticsState.funnelEvents[i].eventData[j].key ||
                        !analyticsState.funnelEvents[i].eventData[j].value
                    ) {
                        hasEmptyEventData = true;
                        break loop1;
                    }
                }
            }
        }

        if (
            getPlatformConfig === undefined ||
            ((queryType === AnalyticsQueryType.DaySelectionFunnel ||
                queryType === AnalyticsQueryType.Funnel) &&
                (analyticsState.funnelEvents.length <= 0 ||
                    analyticsState.funnelEvents.some(item => !item.eventName) ||
                    hasEmptyEventData))
        ) {
            updateErrorMessage(
                "Event selection and/or event data is incomplete."
            );
            updateTaskLoading(false);
            return;
        }
        let analyticsTask: AnalyticsQueryTask | undefined = undefined;
        const config: AnalyticsQueryConfig = {
            apiVersion: getPlatformConfig.analyticsApiVersion,
            eventList: analyticsState.funnelEvents,
            dates: analyticsState.analyticsTimestamps,
            includeDayFunnel: includeDayFunnel,
            querySets: generateQuerySets(analyticsState)
        };

        analyticsTask = await generateAthenaQuery(config);
        if (analyticsTask) {
            resetAnalyticsResults(client, AnalyticsQueryType.Funnel);
            updateAthenaTask(client, analyticsTask);
            updateLoadingState(client, AnalyticsLoadingState.QueryingAthena);
            updateErrorMessage("");
        } else {
            console.log("[DEBUG] Failed to generate athena query");
            updateErrorMessage("Failed to start the analytics query.");
        }
        updateTaskLoading(false);
    };

    const timeString: string | null =
        analyticsTimestamps.startTimestamp !== -1 &&
        analyticsTimestamps.endTimestamp !== -1
            ? getTimeString()
            : null;

    const { hasOrphans, orphans } = hasExtraSelection(analyticsState);

    useEffect(() => {
        setShowOrphanWarning(hasOrphans);
    }, [hasOrphans]);

    return (
        <>
            <Card>
                <Card.Header className="d-flex text-inverse-50 align-items-center">
                    <div>
                        <h5>Query Options</h5>
                    </div>
                    <div className="float-end ms-auto">
                        <InputGroup className="custom-input-background d-flex justify-content-end">
                            <InputGroup.Text className="pe-0 text-inverse">
                                Include day funnels
                            </InputGroup.Text>
                            <InputGroup.Checkbox
                                title="Include day selection funnels"
                                aria-label="Include day selection funnels"
                                checked={includeDayFunnel}
                                onChange={(event: any) => {
                                    updateIncludeDayFunnel(
                                        event.target.checked
                                    );
                                }}
                            />
                        </InputGroup>
                    </div>
                </Card.Header>
                <Card.Body className="text-inverse">
                    <Row>
                        <Col
                            xs={12}
                            sm={6}
                            md={8}
                            lg={8}
                            xl={8}
                            className="mb-3 mb-sm-0 "
                        >
                            <AnalyticsDateInput />
                        </Col>
                        <Col
                            xs={12}
                            sm={6}
                            md={4}
                            lg={4}
                            xl={4}
                            className="d-flex flex-column"
                        >
                            <Container className="m-0 mb-1 p-0 text-end">
                                <AnalyticsTimeRangeDropdown />
                            </Container>
                            <Container className="m-0 p-0 text-end">
                                <AnalyticsQueryButtonWrapper
                                    taskLoading={taskLoading}
                                    taskQuery={taskQuery}
                                />
                            </Container>
                        </Col>
                    </Row>
                    {timeString ? (
                        <Row className="mt-2 mt-md-0">
                            <Col>
                                <b>Query Time Range</b>
                                <span className="bread-text ms-2">
                                    {timeString}
                                </span>
                            </Col>
                        </Row>
                    ) : null}
                </Card.Body>
                <Card.Footer>
                    {errorMessage && (
                        <p className="text-danger bread-text">{errorMessage}</p>
                    )}
                    {showOrphanWarning && (
                        <p>
                            <FontAwesomeIcon
                                icon={faExclamationTriangle}
                                className="text-warning"
                                size="1x"
                            />
                            <span className="bread-text text-inverse ms-2">
                                Some selected data is not included in the query
                                because an item is set to comparison mode, and
                                only the children of that item are queried.
                                Reconfigure the comparison or ignore this
                                message.
                            </span>
                        </p>
                    )}
                    <span className="text-inverse-50 bread-text">
                        Last Analytics Data Update: {lastAnalyticsUpdate}
                    </span>
                </Card.Footer>
            </Card>
            <SimpleToast />
        </>
    );
};
