import React, { FC } from "react";
import cx from "classnames";

interface Props {
    header: string;
    size?: string;
    color?: string;
}

export const LoadingSpinner: FC<Props> = ({
    header,
    size,
    color = "text-white-50"
}) => {
    return (
        <div
            className={cx(
                "m-0 p-0 d-flex justify-content-center align-content-center align-items-center",
                { "spinner-sm": size && size === "sm" }
            )}
        >
            <span className="text-nowrap">{header}</span>
            <div className={cx("spinner-border ms-2", color)} role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
        </div>
    );
};
