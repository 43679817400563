import React, { FC, ReactElement } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import styles from "./Dropdowns.module.scss";

interface Props {
    onNewProduct: () => void;
    onCreateDeveloperProject: () => void;
    isDeveloperMode: boolean;
}

export const CreateNewDropdown: FC<Props> = ({
    onNewProduct,
    onCreateDeveloperProject,
    isDeveloperMode
}) => {
    const handleSelect = (eventKey: string | null) => {
        if (eventKey === "create-new-product") {
            onNewProduct();
        } else if (eventKey === "create-new-project-dev") {
            onCreateDeveloperProject();
        }
    };

    const alternatives: ReactElement[] = [];
    alternatives.push(
        <Dropdown.Item key="create-new-product" eventKey="create-new-product">
            New Product
        </Dropdown.Item>
    );

    if (isDeveloperMode) {
        alternatives.push(
            <Dropdown.Item
                key="create-new-project-dev"
                eventKey="create-new-project-dev"
            >
                New Project (Git and Platform)
            </Dropdown.Item>
        );
    }

    return (
        <DropdownButton
            id="create-new-dropdown"
            title="Create New"
            variant="primary"
            onSelect={handleSelect}
            className="pb-1"
            size="sm"
        >
            {alternatives}
        </DropdownButton>
    );
};
