import React, { FC } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import styles from "./DailyBadges.module.scss";
import { useLocalAnalyticsState } from "../../../graphql/hooks";

interface Props {
    impressions?: number;
}

const DailyImpressionsImplementation: FC<Props> = ({ impressions }) => {
    const { analyticsEnabled } = useLocalAnalyticsState();
    if (!analyticsEnabled || !impressions) {
        return null;
    }
    return (
        <>
            <div className={`${styles.analyticsText} text-inverse`}>
                <OverlayTrigger
                    placement="top"
                    delay={{ show: 200, hide: 100 }}
                    overlay={
                        <Tooltip id="daily-badge-tooltip-impressions">
                            Impressions - previous 24 hrs
                        </Tooltip>
                    }
                >
                    <span>{impressions.toLocaleString()}</span>
                </OverlayTrigger>
            </div>
        </>
    );
};

export const DailyImpressions = React.memo(DailyImpressionsImplementation);
