import React, { FC } from "react";
import {
    BatchBuild,
    BatchBuildsData,
    BatchBuildState,
    BatchBuildsVariables,
    DropdownAction,
    ReleaseType,
    Revision
} from "../../models/types";
import { Card, Col, Row } from "react-bootstrap";
import { useMatch, useNavigate } from "react-router-dom";
import { useQuery, useSubscription } from "@apollo/client";
import { GET_BATCH_BUILDS } from "../../graphql/queries";
import { ActionDropdown } from "../dropdowns/ActionDropdown";
import { DailyCTR } from "./analytics/DailyCTR";
import { DailyImpressions } from "./analytics/DailyImpressions";
import { UserQueryCTR } from "./analytics/UserQueryCTR";
import { UserQueryImpressions } from "./analytics/UserQueryImpressions";
import { SimpleDate } from "./SimpleDate";
import { useLocalAnalyticsState } from "../../graphql/hooks";
import { BUILD_CHANGED_SUBSCRIPTION } from "../../graphql/subscriptions";
import cx from "classnames";

interface Props {
    revision?: Revision;
    projectId: number;
    developerMode: boolean;
    showReleaseNotes: (id: number) => void;
    onPromote?: () => void;
    onDemote?: () => void;
    onRemove?: () => void;
}

const ReviewReleaseItemImplementation: FC<Props> = ({
    revision,
    projectId,
    developerMode,
    showReleaseNotes,
    onPromote,
    onDemote
}) => {
    const navigate = useNavigate();
    const { analyticsEnabled } = useLocalAnalyticsState();
    const match = useMatch("/:path/*");
    const { data } = useQuery<BatchBuildsData, BatchBuildsVariables>(
        GET_BATCH_BUILDS,
        {
            variables: { revisionId: revision?.id },
            fetchPolicy: "network-only",
            skip: revision === undefined
        }
    );

    useSubscription(BUILD_CHANGED_SUBSCRIPTION, {
        variables: { projectId: projectId },
        onData: ({
            data: { data: { buildChangedNotification } = {} },
            client
        }) => {
            const currentBuilds = data?.builds;
            if (currentBuilds && buildChangedNotification) {
                const newBuilds = currentBuilds.map(build => {
                    if (
                        build.id === buildChangedNotification.buildId.toString()
                    ) {
                        return {
                            ...build,
                            state: buildChangedNotification.buildState
                        };
                    } else {
                        if (
                            build.state === BatchBuildState.Review ||
                            build.state === BatchBuildState.Test
                        ) {
                            return {
                                ...build,
                                state: BatchBuildState.Completed
                            };
                        }
                        return build;
                    }
                });
                client.cache.writeQuery<BatchBuildsData, BatchBuildsVariables>({
                    query: GET_BATCH_BUILDS,
                    data: { builds: newBuilds },
                    variables: { revisionId: revision?.id }
                });
            }
        }
    });

    if (!revision) {
        return null;
    }

    const productId = revision?.project.product?.id;
    let historyPath = `${match?.pathname}/${productId}/projects/${projectId}/revisions/${revision?.id}/history`;
    const eventPath = `${match?.pathname}/${productId}/projects/${projectId}/revisions/${revision?.id}/events`;
    const variationsPath = `${match?.pathname}/${productId}/projects/${projectId}/revisions/${revision?.id}/variations`;

    let reviewBuild: BatchBuild | undefined;
    const hasBuilds = data && data.builds.length > 0;
    if (hasBuilds) {
        reviewBuild = data?.builds.find(
            (build: BatchBuild) => build.state === BatchBuildState.Review
        );
        if (reviewBuild) {
            historyPath += `/${reviewBuild.id}`;
        } else {
            return (
                <Card.Header>
                    [Placeholder] Review is missing review builds!
                </Card.Header>
            );
        }
    }

    const linkPath = developerMode ? variationsPath : historyPath;

    let actions: DropdownAction[] = [];
    if (developerMode) {
        actions = [
            {
                header: "Edit",
                variant: "inverse",
                callback: () => navigate(variationsPath)
            },
            {
                header: "Events",
                variant: "inverse",
                callback: () => navigate(eventPath)
            },
            {
                header: "Builds",
                variant: "inverse",
                className: !hasBuilds ? "disabled" : undefined,
                callback: () => {
                    if (hasBuilds) {
                        navigate(historyPath);
                    }
                }
            },
            {
                header: "Changelog",
                variant: "inverse",
                callback: () => showReleaseNotes(revision?.id)
            },
            {
                header: "Promote",
                variant:
                    !hasBuilds || revision?.releaseType === ReleaseType.Review
                        ? "secondary"
                        : "inverse",
                className:
                    !hasBuilds || revision?.releaseType === ReleaseType.Review
                        ? "disabled"
                        : undefined,
                callback: () => {
                    if (
                        hasBuilds &&
                        onPromote &&
                        revision?.releaseType !== ReleaseType.Review
                    ) {
                        onPromote();
                    }
                }
            },
            {
                header: "Demote",
                variant: "inverse",
                callback: () => {
                    if (onDemote) {
                        onDemote();
                    }
                }
            }
        ];
    } else {
        actions.push({
            header: "Review",
            variant:
                !hasBuilds || revision?.releaseType !== ReleaseType.Review
                    ? "disabled"
                    : "inverse",
            className:
                !hasBuilds || revision?.releaseType !== ReleaseType.Review
                    ? "disabled"
                    : undefined,
            callback: () => {
                if (revision?.releaseType === ReleaseType.Review) {
                    navigate(linkPath);
                }
            }
        });
    }

    return (
        <Card.Header
            onClick={e => {
                if (e.defaultPrevented) return;
                navigate(linkPath);
            }}
            className={cx(
                "text-inverse btn bread-text custom-card-body-children",
                {
                    "bg-review": revision?.releaseType === ReleaseType.Review
                },
                {
                    "bg-rejected":
                        revision?.releaseType === ReleaseType.ReviewRejected
                },
                {
                    "bg-success":
                        revision?.releaseType === ReleaseType.ReviewAccepted
                }
            )}
        >
            <Row className="d-flex align-items-center">
                <Col
                    className={cx("text-start revision-title", {
                        "col-4": analyticsEnabled,
                        "col-6": !analyticsEnabled
                    })}
                >
                    {revision?.project.name}
                </Col>
                <Col className="col-1 d-flex ps-0 no-wrap xs-font justify-content-center">
                    <SimpleDate dateUpdated={revision.dateUpdated} />
                </Col>
                <Col
                    className={cx("text-start", {
                        "col-2": analyticsEnabled,
                        "col-4": !analyticsEnabled
                    })}
                ></Col>
                {analyticsEnabled ? (
                    <>
                        <Col className="col-1 text-start pe-0 ps-1">
                            <DailyImpressions
                                impressions={revision.impressions}
                            />
                        </Col>
                        <Col className="col-1 text-start ps-2 pe-0">
                            <DailyCTR
                                gameplay={revision.gameplay}
                                endScreen={revision.endScreen}
                                impressions={revision.impressions}
                            />
                        </Col>
                        <Col className="col-1 text-start pe-0">
                            <UserQueryImpressions
                                impressions={revision.userImpressions}
                            />
                        </Col>
                        <Col className="col-1 text-start pe-0">
                            <UserQueryCTR
                                gameplay={revision.userGameplay}
                                endScreen={revision.userEndScreen}
                                impressions={revision.userImpressions}
                            />
                        </Col>
                    </>
                ) : null}
                <Col className="col-1 d-flex justify-content-end pe-1">
                    <ActionDropdown
                        id={`review-${revision.id}`}
                        variant={cx(
                            {
                                review:
                                    revision?.releaseType === ReleaseType.Review
                            },
                            {
                                rejected:
                                    revision?.releaseType ===
                                    ReleaseType.ReviewRejected
                            },
                            {
                                success:
                                    revision?.releaseType ===
                                    ReleaseType.ReviewAccepted
                            }
                        )}
                        actions={actions}
                    />
                </Col>
            </Row>
        </Card.Header>
    );
};

export const ReviewReleaseItem = React.memo(ReviewReleaseItemImplementation);
