import React from "react";
import { OverlayBadge } from "./OverlayBadge";
import { useEditPanelState } from "../../graphql/hooks";

export const LegacyLocalizationCheckOverlayBadge = () => {
    const { legacyLocalizationCheck, legacyLocalizationErrorString } =
        useEditPanelState();

    if (!legacyLocalizationCheck) {
        return null;
    }

    return (
        <OverlayBadge
            badgeVariant="danger"
            className="mt-2 p-1 me-2"
            badgeHeader="LegacyLocalization Config Error"
            overlayText={legacyLocalizationErrorString}
        />
    );
};
