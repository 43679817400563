import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import {
    Button,
    Card,
    Col,
    Dropdown,
    DropdownButton,
    Row
} from "react-bootstrap";

import { GET_ORGANIZATIONS } from "../../../graphql/queries";
import { Organization, OrganizationData } from "../../../models/types";
import { NewOrganizationModal } from "../../modals/NewOrganizationModal";
import { EditableOrganization } from "./EditableOrganization";

export const OrganizationTools = () => {
    const [selectedOrganization, updateSelectedOrganization] = useState<
        Organization | undefined
    >(undefined);
    const [showOrganizationModal, setOrganizationModal] = useState(false);
    const { data: { organizations } = {} } =
        useQuery<OrganizationData>(GET_ORGANIZATIONS);

    const generateAlternatives = organizations?.map(
        (organization: Organization, index: number) => (
            <Dropdown.Item key={index} eventKey={organization.id.toString()}>
                {organization.name}
            </Dropdown.Item>
        )
    );

    return (
        <>
            <Card>
                <Card.Header>
                    <h5 className="text-inverse-50">Organization Tools</h5>
                </Card.Header>
                <Card.Body className="d-flex flex-column align-items-center text-inverse">
                    <Row className="w-100 justify-content-center mb-2">
                        <Col className="d-flex flex-column align-items-center">
                            <p>New Organization</p>
                            <Button onClick={() => setOrganizationModal(true)}>
                                Create
                            </Button>
                        </Col>
                    </Row>

                    <Row className="w-100 justify-content-center mb-2 m-3">
                        <Col className="d-flex flex-column align-items-center">
                            <p>Edit Organization</p>
                            <DropdownButton
                                id="organization-dropdown"
                                title={
                                    selectedOrganization?.name ||
                                    "Select Organization"
                                }
                                variant="primary"
                                onSelect={(selectedItem: string | null) => {
                                    if (selectedItem && organizations) {
                                        for (
                                            let i = 0;
                                            i < organizations.length;
                                            i++
                                        ) {
                                            if (
                                                Number(organizations[i].id) ===
                                                Number(selectedItem)
                                            ) {
                                                updateSelectedOrganization(
                                                    organizations[i]
                                                );
                                                break;
                                            }
                                        }
                                    }
                                }}
                            >
                                {generateAlternatives}
                            </DropdownButton>
                        </Col>
                    </Row>

                    <Row className="w-100 d-flex justify-content-center">
                        {selectedOrganization ? (
                            <EditableOrganization
                                key={
                                    selectedOrganization.organizationIdentifier ||
                                    "current-editable-organization"
                                }
                                defaultValue={selectedOrganization}
                            />
                        ) : null}
                    </Row>
                </Card.Body>
            </Card>
            <NewOrganizationModal
                show={showOrganizationModal}
                onClose={() => setOrganizationModal(false)}
            />
        </>
    );
};
