import React, { FC, SyntheticEvent, useEffect } from "react";
import { useApolloClient, useLazyQuery } from "@apollo/client";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useLocalAnalyticsState } from "../../../graphql/hooks";
import {
    GET_ANALYTICS_FUNNEL_ENABLED,
    GET_LOCAL_ANALYTICS_STATE
} from "../../../graphql/queries";
import {
    AnalyticsStateData,
    BatchBuild,
    FunnelEnabledData,
    FunnelEnabledVariables,
    NetworkBuild,
    Product,
    Project,
    Revision
} from "../../../models/types";

interface Props {
    reference: string;
    product?: Product;
    project?: Project;
    revision?: Revision;
    build?: BatchBuild;
    networkBuild?: NetworkBuild;
    className?: string;
}

const AnalyticsFunnelSelectionToggleImplementation: FC<Props> = ({
    reference,
    product,
    project,
    revision,
    build,
    networkBuild,
    className
}) => {
    const analyticsState = useLocalAnalyticsState();
    const client = useApolloClient();

    const [
        isFunnelEnabled,
        // @ts-ignore
        { data }
    ] = useLazyQuery<FunnelEnabledData, FunnelEnabledVariables>(
        GET_ANALYTICS_FUNNEL_ENABLED,
        {
            variables: {
                productId: product?.id,
                projectId: project?.id,
                revisionId: revision?.id,
                buildId: build?.id
            }
        }
    );
    useEffect(() => {
        if (
            analyticsState.analyticsEnabled &&
            analyticsState.analyticsFunnelSelectionMode &&
            !data
        ) {
            console.log("[DEBUG] get funnel enabled data");
            isFunnelEnabled();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        analyticsState.analyticsEnabled,
        analyticsState.analyticsFunnelSelectionMode
    ]);

    if (
        !analyticsState.analyticsEnabled ||
        !analyticsState.analyticsFunnelSelectionMode ||
        !data ||
        !data.funnelEnabled
    ) {
        return null;
    }

    const isSelected = () => {
        switch (reference) {
            case "product":
                return (
                    product &&
                    analyticsState.analyticsProducts.find(
                        element => element.id === product.id && element.selected
                    )
                );
            case "project":
                return (
                    project &&
                    analyticsState.analyticsProjects.find(
                        element => element.id === project.id && element.selected
                    )
                );
            case "revision":
                return (
                    revision &&
                    analyticsState.analyticsRevisions.find(
                        element =>
                            element.id === revision.id && element.selected
                    )
                );
            case "build":
                return (
                    build &&
                    analyticsState.analyticsBuilds.find(
                        element => element.id === build.id && element.selected
                    )
                );
            case "networkbuild":
                return (
                    networkBuild &&
                    analyticsState.analyticsNetworkBuilds.find(
                        element =>
                            element.id === networkBuild.id && element.selected
                    )
                );
            default:
                return false;
        }
    };

    const toggleSelection = () => {
        const updateList = (list: any[], element: any) => {
            if (list.find(item => item.id === element.id)) {
                return list.filter(item => item.id !== element.id);
            }
            return [...list, element];
        };

        const currentCache = client.readQuery<AnalyticsStateData>({
            query: GET_LOCAL_ANALYTICS_STATE
        });

        if (currentCache) {
            const newState = JSON.parse(
                JSON.stringify(currentCache.analyticsState)
            );
            switch (reference) {
                case "product":
                    newState.analyticsProducts = updateList(
                        newState.analyticsProducts,
                        product
                    );
                    break;
                case "project":
                    newState.analyticsProjects = updateList(
                        newState.analyticsProjects,
                        project
                    );
                    break;
                case "revision":
                    newState.analyticsRevisions = updateList(
                        newState.analyticsRevisions,
                        revision
                    );
                    break;
                case "build":
                    newState.analyticsBuilds = updateList(
                        newState.analyticsBuilds,
                        build
                    );
                    break;
                case "networkbuild":
                    newState.analyticsNetworkBuilds = updateList(
                        newState.analyticsNetworkBuilds,
                        networkBuild
                    );
                    break;
                default:
                    break;
            }
            client.writeQuery<AnalyticsStateData, AnalyticsStateData>({
                query: GET_LOCAL_ANALYTICS_STATE,
                data: {
                    analyticsState: newState
                }
            });
        }
    };

    return (
        <OverlayTrigger
            placement="top"
            delay={{ show: 200, hide: 100 }}
            overlay={
                <Tooltip id="tooltip-top">
                    {isSelected()
                        ? "Remove from funnel view"
                        : "Select for funnel view"}
                </Tooltip>
            }
        >
            <Button
                className={`py-0 px-1 ${className || ""}`}
                variant={isSelected() ? "analytics" : "secondary"}
                onClick={(event: SyntheticEvent) => {
                    event.stopPropagation();
                    toggleSelection();
                }}
            >
                <FontAwesomeIcon icon={faFilter} size="xs" />
            </Button>
        </OverlayTrigger>
    );
};

export const AnalyticsFunnelSelectionToggle = React.memo(
    AnalyticsFunnelSelectionToggleImplementation
);
