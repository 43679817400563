import React, { FC, SyntheticEvent } from "react";
import { Card, Col, Form, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCircleQuestion,
    faPlusSquare,
    faXmark
} from "@fortawesome/free-solid-svg-icons";
import { AnalyticsSchema, EventData, FunnelData } from "../../../models/types";
import { AnalyticsEventNameDropdown } from "../../dropdowns/analytics/AnalyticsEventNameDropdown";
import { FunnelEventData } from "../../simple/analytics/FunnelEventData";
import styles from "../../../sass/components/EditableText.module.scss";

interface Props {
    id: number;
    isRemovable: boolean;
    funnelItem: FunnelData;
    analyticsSchema?: AnalyticsSchema;
    updateItem: (id: number, funnelItem: FunnelData) => void;
    removeText: (id: number) => void;
}

export const EditableFunnelComponent: FC<Props> = ({
    id,
    isRemovable,
    funnelItem,
    analyticsSchema,
    updateItem,
    removeText
}) => {
    const updateEventData = (eventData: EventData, updateIndex: number) => {
        const newEventData = funnelItem.eventData.map(
            (element: EventData, index: number) => {
                if (index === updateIndex) {
                    return eventData;
                } else {
                    return element;
                }
            }
        );
        updateItem(id, {
            ...funnelItem,
            eventData: newEventData
        });
    };

    const removeEventData = (removeIndex: number) => {
        const newEventData = [
            ...funnelItem.eventData.slice(0, removeIndex),
            ...funnelItem.eventData.slice(removeIndex + 1)
        ];
        updateItem(id, {
            ...funnelItem,
            eventData: newEventData
        });
    };

    const addEventData = () => {
        updateItem(id, {
            ...funnelItem,
            eventData: [...funnelItem.eventData, { key: "", value: "" }]
        });
    };

    const getEventComment = () => {
        if (analyticsSchema) {
            for (let i = 0; i < analyticsSchema.events.length; i++) {
                if (
                    analyticsSchema.events[i].eventName === funnelItem.eventName
                ) {
                    if (analyticsSchema.events[i].comment !== "") {
                        return analyticsSchema.events[i].comment;
                    }
                }
            }
        }
        return null;
    };

    const comment = getEventComment();

    const keys: string[] = [];
    if (analyticsSchema) {
        for (let i = 0; i < analyticsSchema.events.length; i++) {
            if (analyticsSchema.events[i].eventName === funnelItem.eventName) {
                for (
                    let j = 0;
                    j < analyticsSchema.events[i].eventData?.length;
                    j++
                ) {
                    if (
                        !keys.includes(
                            analyticsSchema.events[i].eventData[j].key
                        )
                    ) {
                        keys.push(analyticsSchema.events[i].eventData[j].key);
                    }
                }
                break;
            }
        }
    }

    const eventData = funnelItem.eventData.map(
        (element: EventData, index: number) => (
            <FunnelEventData
                element={element}
                index={index}
                analyticsSchema={analyticsSchema}
                keys={keys}
                funnelItem={funnelItem}
                updateEventData={updateEventData}
                removeEventData={removeEventData}
                key={index}
            />
        )
    );

    return (
        <Card className="mb-1 inner-card">
            <Card.Body className="p-2">
                <Row className="g-0">
                    <Col
                        xs={7}
                        sm={7}
                        md={7}
                        lg={5}
                        xl={5}
                        className="d-flex align-items-center"
                    >
                        {analyticsSchema &&
                        analyticsSchema.events.length > 0 ? (
                            <div className={styles.eventDropdown}>
                                <AnalyticsEventNameDropdown
                                    header={
                                        funnelItem.eventName === ""
                                            ? "Select Event"
                                            : funnelItem.eventName
                                    }
                                    eventNames={analyticsSchema.events.map(
                                        event => event.eventName
                                    )}
                                    updateSelection={selection => {
                                        updateItem(id, {
                                            eventName: selection,
                                            eventData: []
                                        });
                                    }}
                                />
                            </div>
                        ) : (
                            <Form
                                className={styles.eventForm}
                                onSubmit={(
                                    event: React.FormEvent<HTMLFormElement>
                                ) => {
                                    event.preventDefault();
                                }}
                            >
                                <Form.Control
                                    type="text"
                                    placeholder="Event Name"
                                    value={funnelItem.eventName}
                                    onTouchStart={(
                                        event: React.TouchEvent<HTMLElement>
                                    ) => event.stopPropagation()}
                                    onChange={(
                                        event: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                        updateItem(id, {
                                            ...funnelItem,
                                            eventName: event.target.value
                                        });
                                    }}
                                />
                            </Form>
                        )}
                        <Form
                            onSubmit={(
                                event: React.FormEvent<HTMLFormElement>
                            ) => {
                                event.preventDefault();
                            }}
                            title="Exclude"
                            className="d-flex ms-lg-0 ms-xl-2"
                        >
                            <Form.Group>
                                <Form.Check
                                    checked={funnelItem.exclude || false}
                                    type="checkbox"
                                    label={
                                        <span className="d-none d-lg-block text-inverse-50">
                                            Exclude
                                        </span>
                                    }
                                    onTouchStart={(
                                        event: React.TouchEvent<HTMLElement>
                                    ) => event.stopPropagation()}
                                    onChange={(
                                        event: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                        updateItem(id, {
                                            ...funnelItem,
                                            exclude: event.target.checked
                                        });
                                    }}
                                />
                            </Form.Group>
                        </Form>
                    </Col>
                    <Col
                        xs={4}
                        sm={4}
                        md={4}
                        lg={6}
                        xl={6}
                        className="col-4 d-flex align-items-center"
                    >
                        {funnelItem.eventName ? (
                            <>
                                <FontAwesomeIcon
                                    icon={faPlusSquare}
                                    size="lg"
                                    className="text-primary mouseHover me-2"
                                    onTouchStart={(
                                        event: React.TouchEvent<SVGSVGElement>
                                    ) => event.stopPropagation()}
                                    onClick={(event: SyntheticEvent) => {
                                        event.stopPropagation();
                                        addEventData();
                                    }}
                                />
                                <span className="d-none d-md-block text-inverse-50 me-2">
                                    Add Event Data
                                </span>
                                {comment && (
                                    <OverlayTrigger
                                        placement="top"
                                        delay={{ show: 100, hide: 100 }}
                                        overlay={
                                            <Tooltip id="event-comment-tooltip">
                                                {`Comment: ${comment}`}
                                            </Tooltip>
                                        }
                                    >
                                        <FontAwesomeIcon
                                            onTouchStart={(
                                                event: React.TouchEvent<SVGSVGElement>
                                            ) => event.stopPropagation()}
                                            icon={faCircleQuestion}
                                            size="1x"
                                            className="text-inverse-50 ms-2"
                                            cursor="default"
                                        />
                                    </OverlayTrigger>
                                )}
                            </>
                        ) : null}
                    </Col>
                    <Col className="col-1 d-flex align-items-center">
                        {isRemovable ? (
                            <FontAwesomeIcon
                                icon={faXmark}
                                size="lg"
                                onTouchStart={(
                                    event: React.TouchEvent<SVGSVGElement>
                                ) => event.stopPropagation()}
                                onClick={(event: SyntheticEvent) => {
                                    event.stopPropagation();
                                    removeText(id);
                                }}
                                title="Delete event"
                                className="text-inverse-50 mouseHover ms-auto float-end me-3"
                            />
                        ) : null}
                    </Col>
                </Row>
            </Card.Body>
            <Card.Body className="py-0">
                <Row className="g-0">
                    <Col xs={7} sm={7} md={7} lg={5} xl={5}></Col>
                    <Col
                        xs={4}
                        sm={4}
                        md={4}
                        lg={6}
                        xl={6}
                        className="text-start pt-0 pb-1"
                    >
                        {eventData}
                    </Col>
                    <Col className="col-1"></Col>
                </Row>
            </Card.Body>
        </Card>
    );
};
