import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { Card, Button } from "react-bootstrap";
import { GET_CREATE_PROJECT_CONFIG } from "../../../graphql/queries";
import {
    NewProjectConfigVariables,
    NewProjectConfig,
    NewProjectConfigData
} from "../../../models/types";
import { NewProjectConfigModal } from "../../modals/admin/NewProjectConfigModal";
import { UPDATE_NEW_PROJECT_CONFIG } from "../../../graphql/mutations";

export const CreateProjectConfigTools = () => {
    const initConfig: NewProjectConfig = {
        gitModules: [],
        baseProjects: [],
        techStack: undefined
    };
    const [config, updateConfig] = useState<NewProjectConfig>(initConfig);
    const [configKey, updateConfigKey] = useState("");
    const [configValue, updateConfigValue] = useState("");
    const [updating, updateUpdating] = useState(false);
    const [showConfigModal, updateShowConfigModal] = useState(false);
    const { data: { getCreateProjectConfig } = {} } =
        useQuery<NewProjectConfigData>(GET_CREATE_PROJECT_CONFIG);

    const [updateConfigMutation] = useMutation<NewProjectConfigVariables>(
        UPDATE_NEW_PROJECT_CONFIG
    );

    useEffect(() => {
        if (getCreateProjectConfig) {
            updateConfig(getCreateProjectConfig);
        }
    }, [getCreateProjectConfig]);

    const updateConfigure = async (configData: string) => {
        updateUpdating(true);
        try {
            await updateConfigMutation({
                variables: {
                    configKey: configKey,
                    configValue: JSON.stringify(configData)
                },
                refetchQueries: [
                    {
                        query: GET_CREATE_PROJECT_CONFIG
                    }
                ]
            });
            updateUpdating(false);
        } catch (error) {
            console.log("[DEBUG] update configure error: ", error);
        }
    };

    const alternatives = Object.entries(config)
        .filter(([key]) => key !== "__typename")
        .map(([key, value]) => (
            <Button
                key={key}
                className="btn-analytics me-2 mt-2"
                onClick={() => {
                    updateConfigKey(key);
                    updateConfigValue(value);
                    updateShowConfigModal(true);
                }}
            >
                {key}
            </Button>
        ));

    return (
        <>
            <Card className="my-3">
                <Card.Header>
                    <h5 className="text-inverse-50">
                        Create New Project Config
                    </h5>
                </Card.Header>
                <Card.Body className="mb-2">{alternatives}</Card.Body>
            </Card>
            <NewProjectConfigModal
                configKey={configKey}
                configValue={configValue}
                updateConfig={updateConfigure}
                updating={updating}
                show={showConfigModal}
                onClose={() => updateShowConfigModal(!showConfigModal)}
            />
        </>
    );
};
