import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import "../modals/Modals.scss";
import {
    BasicResult,
    CreateNewProjectData,
    CreateProjectConfig,
    CreateProjectState,
    DeveloperProjectVariables,
    GitlabSlugResultData,
    GitlabSlugVariables,
    NewProjectConfigData,
    Organization,
    Product
} from "../../models/types";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { CREATE_DEVELOPER_PROJECT } from "../../graphql/mutations";
import {
    GET_CREATE_PROJECT_CONFIG,
    GET_GITLAB_SLUG_STATUS,
    GET_PROJECTS_BY_PRODUCT
} from "../../graphql/queries";
import { OrganizationSelectionView } from "../views/OrganizationSelectionView";
import { ProductSelectionView } from "../views/ProductSelectionView";
import { CreateProjectResultWaiter } from "../simple/CreateProjectResultWaiter";
import { CreateProjectResult } from "../simple/CreateProjectResult";
import { CreateProjectBasics } from "../forms/CreateProjectBasics";
import { CreateProjectTechStack } from "../forms/CreateProjectTechStack";
import { ExperimentalDeveloperProjectWarningBox } from "../warnings/ExperimentalDeveloperProjectWarningBox";
import { ExperimentalDeveloperProjectWarningBoxCLI } from "../warnings/ExperimentalDeveloperProjectWarningBoxCLI";
import { LoadingSpinner } from "../simple/LoadingSpinner";
import { LegacyDeveloperProjectWarningBox } from "../warnings/LegacyDeveloperProjectWarningBox";

export const DeveloperNewProjectPage = () => {
    const errorReference = useRef(null);
    const [state, updateState] = useState<CreateProjectState>(
        CreateProjectState.Organization
    );
    const [organization, updateOrganization] = useState<
        Organization | undefined
    >(undefined);
    const [taskId, updateTaskId] = useState<string | undefined>(undefined);
    const [taskResult, updateTaskResult] = useState<BasicResult | undefined>(
        undefined
    );
    const [product, updateProduct] = useState<Product | undefined>(undefined);
    const emptyProject = {
        name: "",
        gitSlug: "",
        fullName: "",
        description: "",
        genre: "",
        productId: undefined,
        keepBaseSets: false,
        gitModules: [],
        baseProject: undefined,
        slackChannel: undefined,
        techStack: undefined
    };
    const [project, setProject] = useState<CreateProjectConfig>(emptyProject);
    const [showError, updateShowError] = useState(false);
    const [acceptedSlug, updateAcceptedSlug] = useState(true);
    const { data: { getCreateProjectConfig } = {} } =
        useQuery<NewProjectConfigData>(GET_CREATE_PROJECT_CONFIG);

    const [createProject, { error }] = useMutation<
        CreateNewProjectData,
        DeveloperProjectVariables
    >(CREATE_DEVELOPER_PROJECT);

    const [getGitlabStatus, { data }] = useLazyQuery<
        GitlabSlugResultData,
        GitlabSlugVariables
    >(GET_GITLAB_SLUG_STATUS);

    useEffect(() => {
        if (data && data.getGitlabSlugStatus) {
            updateAcceptedSlug(data.getGitlabSlugStatus.success);
        }
    }, [data]);

    useEffect(() => {
        if (organization) {
            updateProduct(undefined);
            updateState(CreateProjectState.Product);
        }
    }, [organization]);

    useEffect(() => {
        if (product) {
            setProject(emptyProject);
            updateState(CreateProjectState.Project);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [product]);

    useEffect(() => {
        if (
            project.name &&
            project.gitSlug &&
            project.gitModules &&
            project.gitModules.length >= 2 &&
            project.baseProject &&
            state === CreateProjectState.Project
        ) {
            updateState(CreateProjectState.Valid);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [project]);

    useEffect(() => {
        let timer: any | undefined = undefined;
        if (error) {
            updateShowError(true);
            timer = setTimeout(() => {
                updateShowError(false);
            }, 5000);
        }
        return () => {
            if (timer) {
                clearTimeout(timer);
            }
        };
    }, [error]);

    useEffect(() => {
        if (showError) {
            // @ts-ignore
            errorReference.current?.scrollIntoView();
        }
    }, [showError]);

    const onResult = (result: BasicResult) => {
        updateTaskResult(result);
        updateState(CreateProjectState.Completed);
    };

    const createProjectTask = async () => {
        if (!product) {
            console.log("[DEBUG] missing product!");
            return;
        }
        try {
            const result = await createProject({
                variables: {
                    config: JSON.stringify({
                        ...project,
                        productId: Number(product.id)
                    })
                },
                refetchQueries: [
                    {
                        query: GET_PROJECTS_BY_PRODUCT,
                        variables: { productId: product.id }
                    }
                ]
            });
            updateTaskId(result.data?.createDeveloperProject.taskId);
            updateState(CreateProjectState.Pipeline);
        } catch (error) {
            console.log("[DEBUG] createProjectTask error: ", error);
            updateState(CreateProjectState.Valid);
        }
    };

    const startProjectCreation = () => {
        updateState(CreateProjectState.Start);
        createProjectTask();
    };

    if (!getCreateProjectConfig) {
        return (
            <Container className="fullSize p-2 pt-5 d-flex justify-content-center text-inverse">
                <p>Loading Configuration...</p>
            </Container>
        );
    }

    const inProgress =
        state < CreateProjectState.Valid ||
        (state > CreateProjectState.Pipeline && !acceptedSlug);
    const techStackValid =
        (project.baseProject &&
            project.baseProject.name !== "Project Template") ||
        (project.techStack &&
            project.techStack.frameworks.selection.length > 0 &&
            project.techStack.networks.selection.length > 0);
    return (
        <Container className="fullSize p-2 pt-4">
            <Row className="d-flex justify-content-center align-items-center m-2">
                <Col xs="auto">
                    <h4 className="text-inverse-50">Create New Project</h4>
                </Col>
            </Row>
            <Row className="text-inverse m-0 p-0 d-flex justify-content-center align-items-center">
                <Col xs="auto">
                    <div className="p-3 no-border">
                        {state < CreateProjectState.Start ? (
                            <OrganizationSelectionView
                                organization={organization}
                                updateOrganization={updateOrganization}
                            />
                        ) : null}
                        {state < CreateProjectState.Start &&
                        state >= CreateProjectState.Organization &&
                        organization ? (
                            <ProductSelectionView
                                organization={organization}
                                product={product}
                                updateProduct={updateProduct}
                            />
                        ) : null}
                        {state === CreateProjectState.Project ||
                        state === CreateProjectState.Valid ? (
                            <>
                                <CreateProjectBasics
                                    project={project}
                                    setProject={setProject}
                                    acceptedSlug={acceptedSlug}
                                    updateAcceptedSlug={updateAcceptedSlug}
                                    getGitlabStatus={getGitlabStatus}
                                    getCreateProjectConfig={
                                        getCreateProjectConfig
                                    }
                                />
                                {project.baseProject?.allowTemplator &&
                                project.gitModules.find(
                                    element => element.path === "templator"
                                ) ? (
                                    <>
                                        <CreateProjectTechStack
                                            project={project}
                                            getCreateProjectConfig={
                                                getCreateProjectConfig
                                            }
                                            setProject={setProject}
                                        />
                                    </>
                                ) : null}
                                {project.baseProject?.experimental ? (
                                    <ExperimentalDeveloperProjectWarningBox />
                                ) : null}
                                {project.baseProject?.legacy ? (
                                    <LegacyDeveloperProjectWarningBox />
                                ) : null}
                                <div className="mt-2 align-content-center text-center">
                                    <Button
                                        className="text-center p-2"
                                        type="submit"
                                        disabled={inProgress || !techStackValid}
                                        onClick={() => {
                                            if (!inProgress && techStackValid) {
                                                startProjectCreation();
                                            }
                                        }}
                                    >
                                        Create
                                    </Button>
                                </div>
                            </>
                        ) : null}
                        {state === CreateProjectState.Start ? (
                            <Card className="border-analytics text-inverse p-2">
                                <LoadingSpinner header="Starting Project Creation... " />
                            </Card>
                        ) : null}
                        {state === CreateProjectState.Pipeline && taskId ? (
                            <CreateProjectResultWaiter
                                taskId={taskId}
                                updateResult={onResult}
                            />
                        ) : null}
                        {state === CreateProjectState.Completed &&
                        taskResult ? (
                            <CreateProjectResult result={taskResult} />
                        ) : null}
                        {error && showError ? (
                            <Card
                                ref={errorReference}
                                className="text-danger border-danger"
                            >
                                <Card.Header>Error</Card.Header>
                                <Card.Body>
                                    <span>{error.message}</span>
                                </Card.Body>
                            </Card>
                        ) : null}
                        {state >= CreateProjectState.Project &&
                        project.baseProject?.experimental ? (
                            <ExperimentalDeveloperProjectWarningBoxCLI />
                        ) : null}
                    </div>
                </Col>
            </Row>
        </Container>
    );
};
