import React, { FC, useState } from "react";
import { useQuery } from "@apollo/client";
import { Card } from "react-bootstrap";
import { GET_ANALYTICS_BATCH_BUILDS } from "../../../graphql/queries";
import {
    AnalyticsBatchBuildData,
    AnalyticsBatchBuildVariables,
    BatchBuild,
    Revision
} from "../../../models/types";
import { QueryDetailsBuildModal } from "../QueryDetailsBuildModal";
import { FunnelBatchBuild } from "../../simple/analytics/FunnelBatchBuild";

interface Props {
    revision: Revision;
}

export const AnalyticsBatchBuilds: FC<Props> = ({ revision }) => {
    const [selectedItem, setSelectedItem] = useState<BatchBuild | undefined>(
        undefined
    );
    const [showModal, setShowModal] = useState(false);

    const { loading, data, error } = useQuery<
        AnalyticsBatchBuildData,
        AnalyticsBatchBuildVariables
    >(GET_ANALYTICS_BATCH_BUILDS, {
        variables: {
            revisionId: revision.id
        }
    });

    const handleShow = (item: BatchBuild) => {
        setSelectedItem(item);
        setShowModal(true);
    };

    const handleClose = () => {
        setSelectedItem(undefined);
        setShowModal(false);
    };

    const renderList = () => {
        if (loading) {
            return <div>Loading...</div>;
        }
        if (error) {
            return <div>Error: {error.message}</div>;
        }
        if (!data) {
            return null;
        }
        if (data.analyticsBuilds.length === 0) {
            return (
                <Card.Body className="p-2 bread-text">
                    No builds with analytics enabled.
                </Card.Body>
            );
        }

        return data.analyticsBuilds.map((build: BatchBuild) => (
            <FunnelBatchBuild
                key={build.id}
                build={build}
                handleShow={handleShow}
            />
        ));
    };

    return (
        <>
            {renderList()}
            {selectedItem ? (
                <QueryDetailsBuildModal
                    batchBuild={selectedItem}
                    showModal={showModal}
                    hideModal={handleClose}
                />
            ) : null}
        </>
    );
};
