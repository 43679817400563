import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { Card, Button } from "react-bootstrap";
import { GET_ORGANIZATIONS } from "../../../graphql/queries";
import { Organization, OrganizationData } from "../../../models/types";
import { AnalyticsLibEventsPerOrganizationModal } from "../../modals/admin/AnalyticsLibEventsPerOrganizationModal";

export const AnalyticsLibraryEventTools = () => {
    const [orgId, updateOrgId] = useState(0);
    const [orgName, updateOrgName] = useState("");
    const [showEventLibraryModal, updateShowEventLibraryModal] =
        useState(false);
    const { data: { organizations } = {} } =
        useQuery<OrganizationData>(GET_ORGANIZATIONS);

    const alternatives = organizations?.map(
        (organization: Organization, index: number) => (
            <Button
                key={index}
                className="btn-analytics me-2 mt-2"
                onClick={() => {
                    updateOrgId(organization.id);
                    updateOrgName(organization.name);
                    updateShowEventLibraryModal(true);
                }}
            >
                {organization.name}
            </Button>
        )
    );

    return (
        <>
            <Card className="my-3">
                <Card.Header>
                    <h5 className="text-inverse-50">
                        Analytics Library Event Tools
                    </h5>
                </Card.Header>
                <Card.Body className="mb-2">{alternatives}</Card.Body>
            </Card>
            <AnalyticsLibEventsPerOrganizationModal
                organizationName={orgName}
                organizationId={orgId}
                show={showEventLibraryModal}
                onClose={() =>
                    updateShowEventLibraryModal(!showEventLibraryModal)
                }
            />
        </>
    );
};
