import React, { FC, SyntheticEvent } from "react";
import { faScaleBalanced } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import {
    AnalyticsStateData,
    BatchBuild,
    NetworkBuild,
    Product,
    Project,
    Revision
} from "../../../models/types";
import { useLocalAnalyticsState } from "../../../graphql/hooks";
import { GET_LOCAL_ANALYTICS_STATE } from "../../../graphql/queries";
import { useApolloClient } from "@apollo/client";
import cx from "classnames";

interface Props {
    reference: string;
    product?: Product;
    project?: Project;
    revision?: Revision;
    build?: BatchBuild;
    networkBuild?: NetworkBuild;
    className?: string;
}

const AnalyticsFunnelViewComparisonToggleImplementation: FC<Props> = ({
    reference,
    product,
    project,
    revision,
    build,
    networkBuild,
    className
}) => {
    const client = useApolloClient();
    const analyticsState = useLocalAnalyticsState();
    const getItem = () => {
        switch (reference) {
            case "product":
                return analyticsState.analyticsProducts.find(
                    element => element.id === product?.id && element.selected
                );
            case "project":
                return analyticsState.analyticsProjects.find(
                    element => element.id === project?.id && element.selected
                );
            case "revision":
                return analyticsState.analyticsRevisions.find(
                    element => element.id === revision?.id && element.selected
                );
            case "build":
                return analyticsState.analyticsBuilds.find(
                    element => element.id === build?.id && element.selected
                );
            case "networkbuild":
                return analyticsState.analyticsNetworkBuilds.find(
                    element =>
                        element.id === networkBuild?.id && element.selected
                );
            default:
                return undefined;
        }
    };

    const item:
        | Product
        | Project
        | Revision
        | BatchBuild
        | NetworkBuild
        | undefined = getItem();

    if (item === undefined) {
        return null;
    }

    const toggleSelection = () => {
        const currentItem:
            | Product
            | Project
            | Revision
            | BatchBuild
            | NetworkBuild = { ...item };
        currentItem.comparison =
            currentItem.comparison !== undefined
                ? undefined
                : `${reference}-${item?.id}`;
        const currentCache = client.readQuery<AnalyticsStateData>({
            query: GET_LOCAL_ANALYTICS_STATE
        });
        if (currentCache) {
            const newState = JSON.parse(
                JSON.stringify(currentCache.analyticsState)
            );
            switch (reference) {
                case "product":
                    newState.analyticsProducts = newState.analyticsProducts.map(
                        (element: Product) => {
                            if (element.id === currentItem.id) {
                                return currentItem;
                            }
                            return element;
                        }
                    );
                    break;
                case "project":
                    newState.analyticsProjects = newState.analyticsProjects.map(
                        (element: Project) => {
                            if (element.id === currentItem.id) {
                                return currentItem;
                            }
                            return element;
                        }
                    );
                    break;
                case "revision":
                    newState.analyticsRevisions =
                        newState.analyticsRevisions.map((element: Revision) => {
                            if (element.id === currentItem.id) {
                                return currentItem;
                            }
                            return element;
                        });
                    break;
                case "build":
                    newState.analyticsBuilds = newState.analyticsBuilds.map(
                        (element: BatchBuild) => {
                            if (element.id === currentItem.id) {
                                return currentItem;
                            }
                            return element;
                        }
                    );
                    break;
                case "networkbuild":
                    newState.analyticsNetworkBuilds =
                        newState.analyticsNetworkBuilds.map(
                            (element: NetworkBuild) => {
                                if (element.id === currentItem.id) {
                                    return currentItem;
                                }
                                return element;
                            }
                        );
                    break;
                default:
                    break;
            }
            client.writeQuery<AnalyticsStateData, AnalyticsStateData>({
                query: GET_LOCAL_ANALYTICS_STATE,
                data: {
                    analyticsState: newState
                }
            });
        }
    };

    const getTooltip = () => {
        if (item !== undefined && item.comparison) {
            return "This and all its children are added as a dataset";
        }
        return "Add item to comparison";
    };

    return (
        <>
            <OverlayTrigger
                placement="top"
                delay={{ show: 200, hide: 100 }}
                overlay={<Tooltip id="tooltip-top">{getTooltip()}</Tooltip>}
            >
                <FontAwesomeIcon
                    onClick={(event: SyntheticEvent) => {
                        event.stopPropagation();
                        toggleSelection();
                    }}
                    className={cx(
                        className,
                        "mouseHover",
                        { "text-success": item && item.comparison },
                        { "text-secondary": !item?.comparison }
                    )}
                    icon={faScaleBalanced}
                    size="xs"
                />
            </OverlayTrigger>
        </>
    );
};

export const AnalyticsFunnelViewComparisonToggle = React.memo(
    AnalyticsFunnelViewComparisonToggleImplementation
);
