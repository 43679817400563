import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import styles from "./ConsentBanner.module.scss";

interface Props {
    onConsent: (consent: boolean) => void;
}

export const ConsentBanner: React.FC<Props> = ({ onConsent }) => {
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        const consentGiven = localStorage.getItem("consentGiven");
        if (!consentGiven) {
            setVisible(true);
        }
    }, []);

    const handleConsent = (consent: "true" | "false") => {
        localStorage.setItem("consentGiven", consent);
        setVisible(false);
        onConsent(consent === "true");
    };

    const { REACT_APP_GA_ID } = process.env;
    if (REACT_APP_GA_ID === undefined) {
        return null;
    }

    if (!visible) {
        return null;
    }

    return (
        <div className={styles.consentBanner}>
            <p>
                We use cookies to improve your experience. Do you consent to the
                use of analytics cookies?
            </p>
            <Button
                variant="success"
                className={styles.button}
                onClick={() => handleConsent("true")}
            >
                Yes
            </Button>
            <Button
                variant="danger"
                className={styles.button}
                onClick={() => handleConsent("false")}
            >
                No
            </Button>
        </div>
    );
};
