import React from "react";
import { OverlayBadge } from "./OverlayBadge";
import { useEditPanelState } from "../../graphql/hooks";

export const DefaultLocalizationMissingOverlayBadge = () => {
    const { defaultLocalizationMissing } = useEditPanelState();

    if (!defaultLocalizationMissing) {
        return null;
    }

    return (
        <OverlayBadge
            badgeVariant="danger"
            className="mt-2 p-1 me-2"
            badgeHeader="Default Localization Missing!"
            overlayText="Mixed localization requires at least one localization and it must be set as default"
        />
    );
};
