import React, { FC, useState } from "react";
import { Button, Card, Collapse, Container } from "react-bootstrap";
import {
    PartialRevisionData,
    PartialRevisionVariables,
    Revision
} from "../../../models/types";
import { SetPlaceholder } from "./SetPlaceholder";
import "../views.scss";
import { AddSetModal } from "../../modals/AddSetModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCheckDouble,
    faChevronDown,
    faChevronUp,
    faPlus
} from "@fortawesome/free-solid-svg-icons";
import { useApolloClient, useMutation } from "@apollo/client";
import { PARTIAL_UPDATE_REVISION } from "../../../graphql/mutations";
import {
    getVariationCollapseState,
    saveVariationCollapseState,
    updateRevisionPartially
} from "../../../common/Helpers";
import { VariationCollapseState } from "../../../models/common";

interface Props {
    revision: Revision;
    developerMode: boolean;
}

export const Placeholders: FC<Props> = ({ revision, developerMode }) => {
    const client = useApolloClient();
    const [showPlaceholders, setShowPlaceholders] = useState(
        getVariationCollapseState(VariationCollapseState.Placeholders)
    );
    const [showModal, updateShowModal] = useState(false);
    const [partialUpdateRevision] = useMutation<
        PartialRevisionData,
        PartialRevisionVariables
    >(PARTIAL_UPDATE_REVISION);

    const updateShowPlaceholders = (newState: boolean) => {
        saveVariationCollapseState(
            VariationCollapseState.Placeholders,
            newState
        );
        setShowPlaceholders(newState);
    };

    const selectAllPlaceholder = async (include: boolean) => {
        const revisionConf = revision?.revisionConfig;
        if (revisionConf && revisionConf.sets) {
            const newBuildData = {
                placeholders: include ? revisionConf.sets : []
            };
            // updateRevision(newBuildData);
            await updateRevisionPartially(
                client,
                revision,
                partialUpdateRevision,
                newBuildData
            );
        }
    };

    const addSet = async (newSet: string) => {
        const placeholder = revision.buildData?.placeholders;
        if (!placeholder) {
            const newBuildData = {
                placeholders: [newSet]
            };
            // updateRevision(newBuildData);
            await updateRevisionPartially(
                client,
                revision,
                partialUpdateRevision,
                newBuildData
            );
            return;
        }
        if (!placeholder.includes(newSet)) {
            const newBuildData = {
                placeholders: [...placeholder, newSet]
            };
            // updateRevision(newBuildData);
            await updateRevisionPartially(
                client,
                revision,
                partialUpdateRevision,
                newBuildData
            );
        }
    };

    if (!developerMode) {
        return null;
    }

    return (
        <>
            <Container className="mb-4 p-0">
                <Card>
                    <Card.Header className="d-flex align-items-center">
                        {developerMode ? (
                            <h5 className="text-inverse-50 mb-1 me-1">
                                Set placeholder
                            </h5>
                        ) : null}
                        <div
                            onClick={() => {
                                updateShowPlaceholders(!showPlaceholders);
                            }}
                            data-toggle="tooltip"
                            title="Toggle Placeholders visibility"
                            className="ms-2 mouseHover text-inverse-50"
                        >
                            <FontAwesomeIcon
                                icon={
                                    showPlaceholders
                                        ? faChevronUp
                                        : faChevronDown
                                }
                            />
                        </div>
                        <div className="d-flex float-end ms-auto">
                            <Button
                                variant="primary"
                                size="sm"
                                className="me-1"
                                onClick={() => updateShowModal(true)}
                                data-toggle="tooltip"
                                title="Add new placeholder set manually"
                            >
                                <FontAwesomeIcon icon={faPlus} />
                            </Button>

                            <Button
                                variant="success"
                                size="sm"
                                className="mx-1"
                                onClick={() => selectAllPlaceholder(true)}
                                data-toggle="tooltip"
                                title="Add all sets to placeholders"
                            >
                                <FontAwesomeIcon icon={faCheckDouble} />
                            </Button>
                            <Button
                                variant="danger"
                                size="sm"
                                className="ms-1"
                                onClick={() => selectAllPlaceholder(false)}
                                data-toggle="tooltip"
                                title="Remove all sets from placeholders"
                            >
                                <FontAwesomeIcon icon={faCheckDouble} />
                            </Button>
                        </div>
                    </Card.Header>

                    <Collapse in={showPlaceholders}>
                        <div>
                            <Card.Body className="p-2">
                                <SetPlaceholder revision={revision} />
                            </Card.Body>
                        </div>
                    </Collapse>
                </Card>
                {showModal ? (
                    <AddSetModal
                        showModal={showModal}
                        hide={() => updateShowModal(false)}
                        addSet={addSet}
                    />
                ) : null}
            </Container>
        </>
    );
};
