import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC, useState } from "react";
import {
    Button,
    Card,
    Col,
    Collapse,
    FormControl,
    InputGroup,
    Row
} from "react-bootstrap";
import {
    AnalyticsEventDataSchema,
    AnalyticsEventSchema
} from "../../../models/types";
import { AnalyticsEventDataLibraryItem } from "./AnalyticsEventDataLibraryItem";

interface Props {
    event: AnalyticsEventSchema;
    updateAnalyticsSchema: (event: AnalyticsEventSchema) => void;
}

export const AnalyticsEventLibraryItemImplementation: FC<Props> = ({
    event,
    updateAnalyticsSchema
}) => {
    const [showEventData, updateShowEventData] = useState(false);

    const updateSchema = () => {
        updateAnalyticsSchema(event);
    };

    const eventData = event.eventData.map(
        (eventData: AnalyticsEventDataSchema, index: number) => (
            <AnalyticsEventDataLibraryItem
                frozen={true}
                index={index}
                key={index}
                defaultValue={eventData}
                updateAnalyticsDataSchema={() =>
                    console.log("[DEBUG] Nothing should happen")
                }
            />
        )
    );

    return (
        <Card className="mb-3 mx-2">
            <Card.Header
                onClick={() => {
                    updateShowEventData(!showEventData);
                }}
                className="no-border p-3"
            >
                <Row className="d-flex align-items-center">
                    <Col className="col-10">
                        <InputGroup>
                            <InputGroup.Text>Event Name</InputGroup.Text>
                            <FormControl
                                className="pe-5"
                                value={event.eventName}
                                readOnly={true}
                                onClick={(e: React.MouseEvent<HTMLElement>) => {
                                    e.stopPropagation();
                                }}
                            />
                        </InputGroup>
                    </Col>
                    <Col className="col-2">
                        <FontAwesomeIcon
                            icon={showEventData ? faAngleUp : faAngleDown}
                            size="lg"
                            className="mouseHover text-secondary"
                        />
                    </Col>
                </Row>
            </Card.Header>
            <Collapse in={showEventData}>
                <div>
                    <Card.Body className="p-4">
                        {eventData}
                        <InputGroup>
                            <InputGroup.Text>Comment</InputGroup.Text>
                            <FormControl
                                as="textarea"
                                rows={3}
                                value={event.comment ? event.comment : ""}
                                readOnly={true}
                            />
                        </InputGroup>
                    </Card.Body>
                </div>
            </Collapse>
            {event.frozen ? null : (
                <Card.Footer className="p-3">
                    <Button onClick={updateSchema} className="mt-0 btn-success">
                        Add Event To Schema
                    </Button>
                </Card.Footer>
            )}
        </Card>
    );
};

export const AnalyticsEventLibraryItem = React.memo(
    AnalyticsEventLibraryItemImplementation
);
