import React, { FC, useState } from "react";
import { Button } from "react-bootstrap";
import { useQuery, useSubscription } from "@apollo/client";
import { GET_NOTIFICATIONS } from "../../graphql/queries";
import {
    BuildNotification,
    User,
    UserNotification,
    UserNotificationData,
    UserNotificationQueryData,
    UserNotificationVariables
} from "../../models/types";
import { NOTIFICATION_SUBSCRIPTION } from "../../graphql/subscriptions";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NotificationsModal } from "../modals/NotificationsModal";
import { useLocalState } from "../../graphql/hooks";
import cx from "classnames";

interface Params {
    user: User | undefined;
    mobileMode: boolean;
}

export const NotificationsIcon: FC<Params> = ({ user, mobileMode }) => {
    const state = useLocalState();
    const [showModificationModal, updateShowModificationModal] =
        useState(false);
    const { data } = useQuery<UserNotificationQueryData>(GET_NOTIFICATIONS, {
        skip: user === undefined
    });

    useSubscription<UserNotificationData, UserNotificationVariables>(
        NOTIFICATION_SUBSCRIPTION,
        {
            variables: { userId: user?.id },
            skip: user === undefined,
            onData: ({ data: { data: { userNotification } = {} }, client }) => {
                const currentNotificationsData =
                    client.cache.readQuery<UserNotificationQueryData>({
                        query: GET_NOTIFICATIONS
                    });
                const currentNotifications =
                    currentNotificationsData?.getNotifications;
                if (currentNotifications && userNotification) {
                    const updatedNotifications = [
                        ...currentNotifications,
                        userNotification
                    ];
                    client.cache.writeQuery<UserNotificationQueryData>({
                        query: GET_NOTIFICATIONS,
                        data: { getNotifications: updatedNotifications }
                    });
                }
            }
        }
    );

    if (!user) {
        return null;
    }

    const newBuildNotifications = state.buildNotifications.some(
        (item: BuildNotification) => item.isNew
    );

    const pendingNotifications = data?.getNotifications.some(
        (item: UserNotification) => item.isNew
    );

    return (
        <>
            <div className={cx({ "ms-0 ps-0 me-1": mobileMode })}>
                <Button
                    variant={cx({
                        secondary:
                            pendingNotifications || newBuildNotifications,
                        dark: state.useDarkMode
                    })}
                    onClick={() => updateShowModificationModal(true)}
                >
                    <FontAwesomeIcon
                        icon={faBell}
                        size="lg"
                        className={cx(
                            {
                                "text-black-50":
                                    !pendingNotifications &&
                                    !newBuildNotifications &&
                                    !state.useDarkMode
                            },
                            {
                                "text-white-50":
                                    !pendingNotifications &&
                                    !newBuildNotifications &&
                                    state.useDarkMode
                            },
                            {
                                "text-danger":
                                    pendingNotifications ||
                                    newBuildNotifications
                            }
                        )}
                    />
                </Button>
            </div>
            {showModificationModal ? (
                <NotificationsModal
                    show={showModificationModal}
                    notifications={data?.getNotifications}
                    buildNotifications={state.buildNotifications}
                    onClose={() => updateShowModificationModal(false)}
                />
            ) : null}
        </>
    );
};
