import React, { FC, useEffect, useState } from "react";
import {
    Button,
    Card,
    Col,
    Container,
    FormControl,
    InputGroup
} from "react-bootstrap";
import {
    LegacyLocalization,
    Localization,
    PartialRevisionData,
    PartialRevisionVariables
} from "../../models/types";
import { VariationItemProps } from "../../models/common";
import { DetectedSetsPopover } from "../selectable/DetectedSetsPopover";
import { useApolloClient, useMutation } from "@apollo/client";
import { PARTIAL_UPDATE_REVISION } from "../../graphql/mutations";
import { handleSetUpdate, updateRevisionPartially } from "../../common/Helpers";
import "./variationItems.scss";

interface LocalizationProps extends VariationItemProps {
    defaultValue: Localization;
    version: number;
}

export const LegacyLocalizationItem: FC<LocalizationProps> = ({
    defaultValue,
    version,
    revision
}) => {
    const client = useApolloClient();
    const [localization, setLocalization] =
        useState<LegacyLocalization>(defaultValue);
    const [currentVersion, setCurrentVersion] = useState(version);
    const [partialUpdateRevision] = useMutation<
        PartialRevisionData,
        PartialRevisionVariables
    >(PARTIAL_UPDATE_REVISION);

    useEffect(() => {
        if (defaultValue && currentVersion < version) {
            setCurrentVersion(version);
            setLocalization(defaultValue);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultValue, version]);

    const updateLocalizations = async (
        localizationData?: LegacyLocalization,
        operation?: string
    ) => {
        if (revision) {
            if (!localizationData) {
                localizationData = localization;
            }
            // updateRevision({ legacyLocalizations: [localizationData] });
            await updateRevisionPartially(
                client,
                revision,
                partialUpdateRevision,
                { legacyLocalizations: [localizationData] },
                operation
            );
        }
    };

    const remove = () => {
        if (revision) {
            // updateRevision(
            //     { legacyLocalizations: [localization] },
            //     undefined,
            //     "delete"
            // );
            updateLocalizations(localization, "delete");
        }
    };

    const updateLocalizationAndSave = (newLocalization: string) => {
        const newLocalizationData = {
            ...localization,
            localization: newLocalization
        };
        setLocalization(newLocalizationData);
        updateLocalizations(newLocalizationData);
    };

    return (
        <Col xs="auto">
            <Card className="inner-card m-2 p-0">
                <Card.Header className="d-flex text-inverse justify-content-center align-items-center">
                    <Container className="m-0 p-0 col-11">
                        <InputGroup className="m-0 p-0">
                            <InputGroup.Text>Name</InputGroup.Text>
                            <FormControl
                                value={localization.name}
                                onChange={(event: any) => {
                                    setLocalization({
                                        ...localization,
                                        name: event.target.value
                                    });
                                }}
                                onBlur={() => {
                                    updateLocalizations();
                                }}
                            />
                        </InputGroup>
                    </Container>
                    <Container className="m-0 p-0 col-1">
                        <InputGroup className="m-0 p-0 me-1 custom-input-background">
                            <InputGroup.Checkbox
                                title="Include in next Build set"
                                aria-label="Include in next build set"
                                checked={localization.include}
                                onChange={(event: any) => {
                                    const newLocalizationData = {
                                        ...localization,
                                        include: event.target.checked
                                    };
                                    setLocalization(newLocalizationData);
                                    updateLocalizations(newLocalizationData);
                                }}
                            />
                        </InputGroup>
                    </Container>
                </Card.Header>
                <Card.Body className="p-2 d-flex flex-column">
                    <InputGroup className="mb-3">
                        <DetectedSetsPopover
                            onToggle={set => updateLocalizationAndSave(set)}
                            allSets={revision?.revisionConfig?.sets}
                            usedSets={[localization.localization]}
                        />
                        <FormControl
                            value={localization.localization}
                            onChange={(event: any) => {
                                setLocalization({
                                    ...localization,
                                    localization: handleSetUpdate(
                                        event.target.value
                                    )
                                });
                            }}
                            onBlur={() => {
                                updateLocalizations();
                            }}
                        />
                    </InputGroup>
                    <Button className="btn-danger" onClick={remove}>
                        Remove
                    </Button>
                </Card.Body>
            </Card>
        </Col>
    );
};
