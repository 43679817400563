import React, { FC } from "react";
import { Button, Dropdown, DropdownButton, InputGroup } from "react-bootstrap";
import { BaseModule, CreateProjectConfig, Submodule } from "../../models/types";
import { InfoMessage } from "../simple/InfoMessage";

interface Props {
    project: CreateProjectConfig;
    modules: BaseModule[];
    setProject: (project: CreateProjectConfig) => void;
}
export const SelectableModules: FC<Props> = ({
    project,
    modules,
    setProject
}) => {
    const alternatives = modules?.flatMap(
        (baseModule: BaseModule, index: number) =>
            !project.gitModules.find(
                module => module.path === baseModule.path
            ) &&
            !(
                baseModule.path === "templator" &&
                !project.baseProject?.allowTemplator
            ) ? (
                <Dropdown.Item key={index} eventKey={baseModule.path}>
                    {baseModule.name}
                </Dropdown.Item>
            ) : (
                []
            )
    );

    const checkRequiredModule = (submodulePath: string): boolean => {
        if (!project.baseProject) {
            return false;
        }
        for (
            let i = 0;
            i < project.baseProject.requiredSubmodules.length;
            i++
        ) {
            if (
                project.baseProject.requiredSubmodules[i].path === submodulePath
            ) {
                return true;
            }
        }
        return false;
    };

    const getModule = (submodulePath: string): Submodule | undefined => {
        for (let i = 0; i < modules.length; i++) {
            if (modules[i].path === submodulePath) {
                return modules[i];
            }
        }
        return undefined;
    };

    const updateProject = (submodule: Submodule) => {
        if (checkRequiredModule(submodule.path)) {
            return;
        }
        const newProject = {
            ...project,
            gitModules: project.gitModules.filter(
                module => module.path !== submodule.path
            ),
            techStack: undefined
        };
        setProject(newProject);
    };

    const selected = modules?.flatMap(element =>
        project.gitModules.find(module => module.path === element.path) ? (
            <Button
                key={element.url}
                className="mx-1 text-inverse"
                variant="analytics-alt"
                onClick={() => updateProject(element)}
            >
                {element.name}
            </Button>
        ) : (
            []
        )
    );

    return (
        <div className="mb-3">
            <InputGroup className="mb-2">
                <InputGroup.Text>Base Modules</InputGroup.Text>
                <DropdownButton
                    id="selectable-base-modules-dropdown"
                    title="Add module"
                    variant="analytics-alt"
                    className="text-center"
                    onSelect={(selectedItem: string | null | undefined) => {
                        if (
                            selectedItem &&
                            !project.gitModules.find(
                                module => module.path === selectedItem
                            )
                        ) {
                            const selectedModule = getModule(selectedItem);
                            if (selectedModule) {
                                const newProject = {
                                    ...project,
                                    gitModules: [
                                        ...project.gitModules,
                                        selectedModule
                                    ],
                                    techStack: undefined
                                };
                                setProject(newProject);
                            }
                        }
                    }}
                >
                    {alternatives}
                </DropdownButton>
            </InputGroup>
            {selected}
            <div className="mt-2">
                <InfoMessage text="Modules which are going to be added to the project. Currently, some modules are required depending on the base project" />
            </div>
        </div>
    );
};
