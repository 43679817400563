import React, { FC } from "react";
import { Dropdown } from "react-bootstrap";

interface Props {
    header: string;
    choices: string[];
    updateSelection: (newSelection: string) => void;
}

export const SimpleDropdown: FC<Props> = ({
    header,
    choices,
    updateSelection
}) => {
    const generateAlternatives = choices.map(
        (choice: string, index: number) => (
            <Dropdown.Item
                key={index}
                eventKey={index.toString()}
                onClick={() => updateSelection(choice)}
            >
                {choice}
            </Dropdown.Item>
        )
    );

    return (
        <Dropdown align="end">
            <Dropdown.Toggle id="user-dropdown">{header}</Dropdown.Toggle>
            <Dropdown.Menu>{generateAlternatives}</Dropdown.Menu>
        </Dropdown>
    );
};
