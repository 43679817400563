import React, { SyntheticEvent, useEffect, useRef } from "react";
import { useApolloClient } from "@apollo/client";
import {
    Container,
    FormControl,
    OverlayTrigger,
    Tooltip
} from "react-bootstrap";
import { useLocalAnalyticsState } from "../../../graphql/hooks";
import { updateAnalyticsTimestamps } from "../../../common/AnalyticsHelpers";
import cx from "classnames";
import styles from "./DailyBadges.module.scss";

export const AnalyticsDateInput = () => {
    const client = useApolloClient();
    const { analyticsTimestamps } = useLocalAnalyticsState();
    const startDateRef = useRef<any>(null);
    const endDateRef = useRef<any>(null);

    useEffect(() => {
        if (startDateRef && endDateRef) {
            if (analyticsTimestamps.startTimestamp !== -1) {
                startDateRef.current.valueAsNumber =
                    analyticsTimestamps.startTimestamp * 1000;
            } else {
                startDateRef.current.valueAsNumber = undefined;
            }
            if (analyticsTimestamps.endTimestamp !== -1) {
                endDateRef.current.valueAsNumber =
                    analyticsTimestamps.endTimestamp * 1000;
            } else {
                endDateRef.current.valueAsNumber = undefined;
            }
        }
    }, [startDateRef, endDateRef, analyticsTimestamps]);

    return (
        <Container className="p-0 d-flex align-items-center">
            <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="start-date-tooltip">Start Date</Tooltip>}
            >
                <FormControl
                    className={cx("mt-0", styles.dateInput)}
                    type="date"
                    ref={startDateRef}
                    onInput={(event: SyntheticEvent) => {
                        const timeStamp =
                            // @ts-ignore
                            event.currentTarget.valueAsNumber / 1000;
                        updateAnalyticsTimestamps(client, {
                            ...analyticsTimestamps,
                            startTimestamp: timeStamp
                        });
                    }}
                />
            </OverlayTrigger>
            <div className="mx-1">→</div>
            <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="end-date-tooltip">End Date</Tooltip>}
            >
                <FormControl
                    className={cx(styles.dateInput)}
                    type="date"
                    ref={endDateRef}
                    onInput={(event: SyntheticEvent) => {
                        const timeStamp =
                            // @ts-ignore
                            event.currentTarget.valueAsNumber / 1000;
                        updateAnalyticsTimestamps(client, {
                            ...analyticsTimestamps,
                            endTimestamp: timeStamp
                        });
                    }}
                />
            </OverlayTrigger>
        </Container>
    );
};
