import React, { FC, useEffect, useMemo, useState } from "react";
import { Card, Col, Container, Form, Row } from "react-bootstrap";
import {
    useApolloClient,
    useLazyQuery,
    useMutation,
    useQuery,
    useSubscription
} from "@apollo/client";
import {
    GET_DAILY_ANALYTICS,
    GET_RESULTS,
    GET_REVISION
} from "../../graphql/queries";
import {
    BatchBuild,
    BatchBuildsData,
    BatchBuildState,
    BatchBuildsVariables,
    DailyAnalyticsData,
    DailyAnalyticsVariables,
    OrderMode,
    ReleaseType,
    RevisionData,
    RevisionVariables
} from "../../models/types";
import {
    useLocation,
    useNavigate,
    useNavigationType,
    useParams
} from "react-router-dom";
import { useLocalAnalyticsState, useLocalState } from "../../graphql/hooks";
import { BackButton } from "../simple/BackButton";
import { UPDATE_BUILD } from "../../graphql/mutations";
import { HistoryItem } from "../simple/HistoryItem";
import { injectDailyData } from "../../common/Helpers";
import { BuildLogsModal } from "../modals/BuildLogsModal";
import { Paginator } from "../lists/Paginator";
import { BUILD_HISTORY_CHANGED_SUBSCRIPTION } from "../../graphql/subscriptions";
import { UpButton } from "../buttons/UpButton";
import { BuildSortButton } from "../buttons/BuildSortButton";
import { AnalyticsFunnelModeToggle } from "../buttons/analytics/AnalyticsFunnelModeToggle";
import { clearFunnelSelection } from "../../common/AnalyticsHelpers";
import "../pages/pages.scss";
import styles from "./BuildReport.module.scss";

interface Props {
    isFullHistory: boolean;
}

export const History: FC<Props> = ({ isFullHistory }) => {
    const client = useApolloClient();
    const state = useLocalState();
    const navigate = useNavigate();
    const location = useLocation();
    const navigationType = useNavigationType();
    const { analyticsEnabled } = useLocalAnalyticsState();
    const [builds, updateBuilds] = useState<BatchBuild[]>([]);
    const [showErrorLogs, updateShowErrorLogs] = useState(false);
    const [funnelSelection, updateFunnelSelection] = useState<BatchBuild[]>([]);
    const showAnalyticsSort = builds.some(build => build.analyticsEnabled);
    const [sortConfig, setSortConfig] = useState({
        key: "",
        direction: OrderMode.None
    });
    const [errorBatchBuildId, updateErrorBatchBuildId] = useState<
        number | undefined
    >(undefined);
    const { productId, projectId, revisionId } = useParams<{
        productId: string;
        projectId: string;
        revisionId: string;
    }>();
    const [queryBatchBuilds, { data, error }] = useLazyQuery<{
        builds: BatchBuild[];
    }>(GET_RESULTS);
    const { data: { revision } = {} } = useQuery<
        RevisionData,
        RevisionVariables
    >(GET_REVISION, { variables: { revisionId: Number(revisionId) } });
    const { data: { getDailyAnalytics } = {} } = useQuery<
        DailyAnalyticsData,
        DailyAnalyticsVariables
    >(GET_DAILY_ANALYTICS, { variables: { reference: "build" } });
    const [updateBuildStateMutation] = useMutation(UPDATE_BUILD);
    const [sortAnalyticsToTop, setSortAnalyticsToTop] = useState(false);

    const calculateCtr = (build: BatchBuild): number => {
        const { gameplay = 0, endScreen = 0, impressions = 0 } = build;
        if (!impressions) {
            return 0;
        }
        const ctr = ((gameplay + endScreen) / impressions) * 100;
        return parseFloat(ctr.toFixed(1));
    };

    const startSort =
        (sortConfig.key && sortConfig.direction !== OrderMode.None) ||
        sortAnalyticsToTop;

    const sortedJobs = useMemo(() => {
        if (!startSort) {
            return builds;
        }

        const sortableJobs = [...builds];
        sortableJobs.sort((a, b) => {
            const isAscending = sortConfig.direction === OrderMode.Ascending;
            if (sortAnalyticsToTop) {
                if (a.analyticsEnabled && !b.analyticsEnabled) {
                    return -1;
                }
                if (!a.analyticsEnabled && b.analyticsEnabled) {
                    return 1;
                }
            }

            if (sortConfig.key === "impressions") {
                const impressionsA = a.impressions || 0;
                const impressionsB = b.impressions || 0;
                return (isAscending ? 1 : -1) * (impressionsA - impressionsB);
            }
            if (sortConfig.key === "ctr") {
                const ctrA = calculateCtr(a);
                const ctrB = calculateCtr(b);
                return sortConfig.direction === OrderMode.Ascending
                    ? ctrA - ctrB
                    : ctrB - ctrA;
            }
            if (
                sortConfig.key === "dateCreated" ||
                sortConfig.key === "dateUpdated"
            ) {
                const dateA = new Date(a[sortConfig.key]).getTime();
                const dateB = new Date(b[sortConfig.key]).getTime();
                return (isAscending ? 1 : -1) * (dateA - dateB);
            }
            return 0;
        });
        return sortableJobs;
    }, [builds, sortConfig, sortAnalyticsToTop, startSort]);

    const handleSortClick = (criteria: string) => {
        setSortConfig(prevConfig => {
            if (prevConfig.key === criteria) {
                switch (prevConfig.direction) {
                    case OrderMode.Descending:
                        if (criteria === "ctr" || criteria === "impressions") {
                            setSortAnalyticsToTop(true);
                        }
                        return {
                            ...prevConfig,
                            direction: OrderMode.Ascending
                        };
                    case OrderMode.Ascending:
                        return { key: "", direction: OrderMode.None };
                    case OrderMode.None:
                        if (criteria === "ctr" || criteria === "impressions") {
                            setSortAnalyticsToTop(true);
                        }
                        return {
                            key: criteria,
                            direction: OrderMode.Descending
                        };
                    default:
                        return prevConfig;
                }
            } else {
                if (criteria === "ctr" || criteria === "impressions") {
                    setSortAnalyticsToTop(true);
                }
                return { key: criteria, direction: OrderMode.Descending };
            }
        });
    };

    useEffect(() => {
        clearFunnelSelection(client);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (revision) {
            queryBatchBuilds({
                variables: {
                    revisionId: revision.id,
                    clientTest:
                        !state.developerMode &&
                        revision.releaseType === ReleaseType.Development
                }
            });
        }
    }, [
        revision,
        state.developerMode,
        state.buildNotifications,
        queryBatchBuilds
    ]);

    useEffect(() => {
        if (data && data.builds && getDailyAnalytics) {
            const builds = [...data.builds];
            injectDailyData(builds, getDailyAnalytics, "build");
            updateBuilds(builds);
        }
    }, [data, getDailyAnalytics]);

    useSubscription(BUILD_HISTORY_CHANGED_SUBSCRIPTION, {
        variables: { projectId: projectId },
        onData: ({
            data: { data: { buildHistoryChangedNotification } = {} },
            client
        }) => {
            console.log(
                "[DEBUG] buildHistoryChangedNotification: ",
                buildHistoryChangedNotification
            );
            const currentBuilds = data?.builds;
            if (currentBuilds && buildHistoryChangedNotification) {
                const newBuilds = currentBuilds.map(build => {
                    if (
                        build.id ===
                        buildHistoryChangedNotification.buildId.toString()
                    ) {
                        return {
                            ...build,
                            state: buildHistoryChangedNotification.buildState
                        };
                    } else {
                        if (
                            build.state === BatchBuildState.Review ||
                            build.state === BatchBuildState.Test
                        ) {
                            return {
                                ...build,
                                state: BatchBuildState.Completed
                            };
                        }
                        return build;
                    }
                });
                client.cache.writeQuery<BatchBuildsData, BatchBuildsVariables>({
                    query: GET_RESULTS,
                    data: { builds: newBuilds },
                    variables: { revisionId: revision?.id }
                });
                client.refetchQueries({
                    include: [GET_REVISION]
                });
            }
        }
    });

    const updateBuildState = async (
        buildId: number,
        newState: BatchBuildState
    ) => {
        try {
            await updateBuildStateMutation({
                variables: { id: buildId, state: newState },
                refetchQueries: [
                    {
                        query: GET_RESULTS,
                        variables: { revisionId: revisionId }
                    },
                    {
                        query: GET_REVISION,
                        variables: { revisionId: revisionId }
                    }
                ]
            });
        } catch (error) {
            console.log("[DEBUG] updateBuildState error ", error);
        }
    };

    if (!revision || !data) {
        if (error) {
            return (
                <Container className="fullSize p-2 pt-5 text-center">
                    <h5 className="text-danger">{error.message}</h5>
                </Container>
            );
        }

        return null;
    }

    const findLatestBuild = (builds: BatchBuild[]): number => {
        let latestDate = 0;
        let latestIndex = 0;
        builds.forEach((build, index) => {
            if (
                build.state !== BatchBuildState.Failed &&
                build.state !== BatchBuildState.Started &&
                build.state !== BatchBuildState.Stopped
            ) {
                const buildDate = new Date(build.dateCreated).getTime();
                if (buildDate > latestDate) {
                    latestDate = buildDate;
                    latestIndex = index;
                }
            }
        });
        return latestIndex;
    };
    const latestBuild = findLatestBuild(sortedJobs);

    const toggleFunnelSelection = (build: BatchBuild) => {
        const newFunnelSelection = [...funnelSelection];
        if (!newFunnelSelection.includes(build)) {
            newFunnelSelection.push(build);
        } else {
            newFunnelSelection.splice(newFunnelSelection.indexOf(build), 1);
        }
        updateFunnelSelection(newFunnelSelection);
    };

    return (
        <>
            <Container className="fullSize">
                {isFullHistory ? (
                    <div className={styles.backButton}>
                        <BackButton
                            hasHistory={
                                !(location.key && navigationType === "POP")
                            }
                            goBack={() => navigate(-1)}
                            size={"lg"}
                        />
                    </div>
                ) : null}
                <Card
                    className={`text-inverse mb-3 ${
                        isFullHistory
                            ? "ms-xs-4 me-xs-4 mt-4 ms-md-4 me-md-4 mb-0"
                            : "p-0"
                    }`}
                >
                    <Card.Header>
                        <h4>Build History of {revision?.name}</h4>
                    </Card.Header>
                    <Card.Body className="p-2">
                        {builds.length > 0 && (
                            <Row className="align-items-center ps-3 pe-2 mb-2">
                                <Col xs="auto">
                                    <BuildSortButton
                                        criteria="dateCreated"
                                        label="Date"
                                        tooltipText="Sort by last updated"
                                        sortDirection={sortConfig.direction}
                                        isSelected={
                                            sortConfig.key === "dateCreated"
                                        }
                                        onClick={handleSortClick}
                                    />
                                </Col>
                                {analyticsEnabled && showAnalyticsSort && (
                                    <>
                                        <Col xs="auto">
                                            <BuildSortButton
                                                criteria="impressions"
                                                label="Daily Impr"
                                                tooltipText="Sort by Impressions - previous day"
                                                sortDirection={
                                                    sortConfig.direction
                                                }
                                                isSelected={
                                                    sortConfig.key ===
                                                    "impressions"
                                                }
                                                onClick={handleSortClick}
                                            />
                                        </Col>
                                        <Col xs="auto">
                                            <BuildSortButton
                                                criteria="ctr"
                                                label="Daily CTR"
                                                tooltipText="Sort by CTR - previous day"
                                                sortDirection={
                                                    sortConfig.direction
                                                }
                                                isSelected={
                                                    sortConfig.key === "ctr"
                                                }
                                                onClick={handleSortClick}
                                            />
                                        </Col>

                                        <Col className="d-flex align-items-center justify-content-end text-inverse-50">
                                            <Form.Check
                                                type="checkbox"
                                                title="Set builds with analytics enabled to top of list"
                                                label={
                                                    <b className="d-none d-md-block bread-text">
                                                        Analytics to Top
                                                    </b>
                                                }
                                                checked={sortAnalyticsToTop}
                                                onChange={() =>
                                                    setSortAnalyticsToTop(
                                                        !sortAnalyticsToTop
                                                    )
                                                }
                                                id="sort-analytics-checkbox"
                                            />
                                        </Col>
                                        <Col xs="auto" className="text-end">
                                            <AnalyticsFunnelModeToggle
                                                funnelSelection={
                                                    funnelSelection
                                                }
                                                reference="build"
                                            />
                                        </Col>
                                    </>
                                )}
                            </Row>
                        )}
                        {sortedJobs && sortedJobs.length > 0 ? (
                            <Paginator
                                list={sortedJobs.map(
                                    (build: BatchBuild, index: number) => (
                                        <HistoryItem
                                            key={build.id}
                                            object={build}
                                            developerMode={state.developerMode}
                                            updateBuildState={updateBuildState}
                                            isLatestBuild={
                                                latestBuild === index
                                            }
                                            revisionType={revision.releaseType}
                                            linkPath={
                                                build.state !==
                                                BatchBuildState.Started
                                                    ? `/products/${productId}/projects/${projectId}/revisions/${revisionId}/history/${build.id}`
                                                    : "#"
                                            }
                                            openErrorLogs={buildId => {
                                                updateErrorBatchBuildId(
                                                    buildId
                                                );
                                                updateShowErrorLogs(true);
                                            }}
                                            isInFunnel={funnelSelection.includes(
                                                build
                                            )}
                                            toggleFunnelSelection={() =>
                                                toggleFunnelSelection(build)
                                            }
                                        />
                                    )
                                )}
                                maxElementCount={isFullHistory ? 25 : 7}
                                activeItem={undefined}
                            />
                        ) : (
                            <h5 className="text-inverse-50 text-center m-3">
                                No build history found.
                            </h5>
                        )}
                    </Card.Body>
                </Card>
                <Row className="m-0 justify-content-center">
                    {isFullHistory && sortedJobs && sortedJobs.length > 20 ? (
                        <span className="mb-3">
                            <UpButton />
                        </span>
                    ) : null}
                </Row>
            </Container>
            {showErrorLogs && errorBatchBuildId && state.developerMode ? (
                <BuildLogsModal
                    show={showErrorLogs}
                    onClose={() => updateShowErrorLogs(false)}
                    job={undefined}
                    id={undefined}
                    targetIndex={undefined}
                    buildReport={undefined}
                    batchBuildId={errorBatchBuildId}
                />
            ) : null}
        </>
    );
};
