import React, { FC, useState } from "react";
import { Button, Card, Collapse, Container } from "react-bootstrap";
import { SetBlacklist } from "./SetBlacklist";
import { Revision, RevisionConfigInput } from "../../../models/types";
import { useMutation } from "@apollo/client";
import { UPDATE_REVISION_CONFIG } from "../../../graphql/mutations";
import { AddSetModal } from "../../modals/AddSetModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCheckDouble,
    faChevronDown,
    faChevronUp,
    faPlus
} from "@fortawesome/free-solid-svg-icons";
import {
    getVariationCollapseState,
    saveVariationCollapseState
} from "../../../common/Helpers";
import { VariationCollapseState } from "../../../models/common";
import "../views.scss";

interface Props {
    revision: Revision;
    developerMode: boolean;
}

export const ReskinBlacklist: FC<Props> = ({ revision, developerMode }) => {
    const [showBlackList, setShowBlackList] = useState(
        getVariationCollapseState(VariationCollapseState.Reskins)
    );
    const [showModal, updateShowModal] = useState(false);
    const [updateRevisionConfig] = useMutation(UPDATE_REVISION_CONFIG);

    const selectAllBlacklist = (include: boolean) => {
        const revisionConf = revision?.revisionConfig;
        if (revisionConf && revisionConf.sets) {
            updateRevConfigBlacklist(include ? revisionConf.sets : []);
        }
    };

    const updateShowBlackList = (newState: boolean) => {
        saveVariationCollapseState(VariationCollapseState.Reskins, newState);
        setShowBlackList(newState);
    };

    const updateRevConfigBlacklist = async (setBlacklist: string[]) => {
        if (revision && setBlacklist && revision.revisionConfig) {
            const input: RevisionConfigInput = {
                revisionId: Number(revision.id),
                setBlacklist: setBlacklist
            };
            try {
                await updateRevisionConfig({
                    variables: {
                        input: input
                    }
                });
            } catch (error) {
                console.log("[DEBUG] updateRevConfigBlacklist error ", error);
            }
        }
    };

    const addSet = (newSet: string) => {
        const blacklist = revision.revisionConfig?.setBlacklist;
        if (!blacklist) {
            updateRevConfigBlacklist([newSet]);
            return;
        }
        if (!blacklist.includes(newSet)) {
            updateRevConfigBlacklist([...blacklist, newSet]);
        }
    };

    if (!developerMode) {
        return null;
    }

    return (
        <>
            <Container className="mb-4 p-0">
                <Card>
                    <Card.Header className="d-flex align-items-center">
                        {developerMode ? (
                            <h5 className="text-inverse-50 mb-1 me-1">
                                Set blacklist
                            </h5>
                        ) : null}
                        <div
                            onClick={() => {
                                updateShowBlackList(!showBlackList);
                            }}
                            data-toggle="tooltip"
                            title="Toggle Blacklist visibility"
                            className="text-inverse-50 mouseHover ms-2 mb-1"
                        >
                            <FontAwesomeIcon
                                icon={
                                    showBlackList ? faChevronUp : faChevronDown
                                }
                            />
                        </div>
                        <div className="d-flex float-end ms-auto">
                            <Button
                                variant="primary"
                                size="sm"
                                onClick={() => updateShowModal(true)}
                                data-toggle="tooltip"
                                title="Add new blacklisted set manually"
                            >
                                <FontAwesomeIcon icon={faPlus} />
                            </Button>
                            <Button
                                variant="success"
                                size="sm"
                                className="ms-2"
                                onClick={() => selectAllBlacklist(true)}
                                data-toggle="tooltip"
                                title="Add all sets to blacklist"
                            >
                                <FontAwesomeIcon icon={faCheckDouble} />
                            </Button>
                            <Button
                                variant="danger"
                                size="sm"
                                className="ms-2"
                                onClick={() => selectAllBlacklist(false)}
                                data-toggle="tooltip"
                                title="Remove all sets from blacklist"
                            >
                                <FontAwesomeIcon icon={faCheckDouble} />
                            </Button>
                        </div>
                    </Card.Header>

                    <Collapse in={showBlackList}>
                        <div>
                            <Card.Body className="p-2">
                                <SetBlacklist
                                    revision={revision}
                                    developerMode={developerMode}
                                    updateRevisionConfig={
                                        updateRevConfigBlacklist
                                    }
                                />
                            </Card.Body>
                        </div>
                    </Collapse>
                </Card>
                {showModal ? (
                    <AddSetModal
                        showModal={showModal}
                        hide={() => updateShowModal(false)}
                        addSet={addSet}
                    />
                ) : null}
            </Container>
        </>
    );
};
