import React, { FC } from "react";
import { Button } from "react-bootstrap";
import { hasValidQuery } from "../../../common/AnalyticsHelpers";
import { useLocalAnalyticsState } from "../../../graphql/hooks";

interface Props {
    onQuery: () => void;
}

export const AnalyticsQueryButton: FC<Props> = ({ onQuery }) => {
    const analyticsState = useLocalAnalyticsState();
    return (
        <Button
            className="ms-3"
            disabled={!hasValidQuery(analyticsState)}
            onClick={onQuery}
        >
            Run Query
        </Button>
    );
};
