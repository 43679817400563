import React, { FC } from "react";
import { Button, Dropdown, DropdownButton, InputGroup } from "react-bootstrap";
import { MeData, User } from "../../../models/types";
import { useQuery } from "@apollo/client";
import { GET_ME } from "../../../graphql/queries";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";

const seepiaScopes = ["admin", "organizations-admin", "organization-admin"];
const otherScopes = ["organization-admin"];

const getSeepiaScopes = (userScopes: string[] | undefined): string[] => {
    if (!userScopes) {
        return [];
    }
    const lowestCommonIndex = seepiaScopes.findIndex(value =>
        userScopes.includes(value)
    );
    if (lowestCommonIndex === -1) {
        return [];
    }

    return seepiaScopes.slice(lowestCommonIndex);
};

interface Props {
    user: User;
    updateScopes: (scopes: string[]) => void;
}
export const SelectableScopes: FC<Props> = ({ user, updateScopes }) => {
    const { data: { me } = {} } = useQuery<MeData>(GET_ME, {
        fetchPolicy: "cache-first"
    });
    const scopes: string[] =
        user.organization?.name === "Seepia"
            ? getSeepiaScopes(me?.scopes)
            : otherScopes;

    const removeScope = (scope: string) => {
        if (user.scopes?.includes(scope)) {
            const updatedScopes = user.scopes.filter(
                element => element !== scope
            );
            updateScopes(updatedScopes);
        }
    };

    const addScope = (scope: string) => {
        if (!user.scopes || !user.scopes.includes(scope)) {
            const updatedScopes = user.scopes
                ? [...user.scopes, scope]
                : [scope];
            updateScopes(updatedScopes);
        }
    };

    const alternatives = scopes?.flatMap((scope: string, index: number) =>
        !user.scopes || !user.scopes.find(userScope => userScope === scope) ? (
            <Dropdown.Item key={index} eventKey={scope}>
                {scope}
            </Dropdown.Item>
        ) : (
            []
        )
    );
    const selected =
        user.scopes?.map(scope => (
            <Button
                key={scope}
                className="mx-1 text-inverse"
                variant="analytics-alt"
                onClick={() => removeScope(scope)}
            >
                <FontAwesomeIcon className="me-2" icon={faTrashCan} />
                {scope}
            </Button>
        )) || [];

    return (
        <div className="mb-3">
            <InputGroup className="mb-2">
                <InputGroup.Text>Scopes</InputGroup.Text>
                <DropdownButton
                    id="selectable-base-modules-dropdown"
                    title="Add Scope"
                    variant="analytics-alt"
                    className="text-center"
                    onSelect={(selectedItem: string | null | undefined) => {
                        if (selectedItem) {
                            addScope(selectedItem);
                        }
                    }}
                >
                    {alternatives}
                </DropdownButton>
            </InputGroup>
            {selected}
        </div>
    );
};
