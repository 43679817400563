import React from "react";

interface Props {
    dateUpdated: string;
}

export const SimpleDate: React.FC<Props> = ({ dateUpdated }) => {
    const formatDate = (date: string) => {
        const d = new Date(date);
        const day = String(d.getDate()).padStart(2, "0");
        const month = String(d.getMonth() + 1).padStart(2, "0");
        const year = d.getFullYear().toString().substr(-2);

        return `${day}-${month}-${year}`;
    };

    return <span className="text-inverse-50">{formatDate(dateUpdated)}</span>;
};
