import React, { FC, useCallback, useEffect } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { ToggleButtonComponent } from "../editable/ToggleButtonComponent";
import { useApolloClient, useLazyQuery, useSubscription } from "@apollo/client";
import {
    GET_CURRENT_BUILDS,
    GET_LOCAL_BUILD_STATE,
    GET_REVISION
} from "../../graphql/queries";
import {
    BuildJobData,
    BuildsStateData,
    RevisionData,
    RevisionVariables,
    TempBuildsData,
    TempBuildVariables
} from "../../models/types";
import "./Panels.scss";
import { useLocalBuildsState } from "../../graphql/hooks";
import { CURRENT_BUILDS_SUBSCRIPTION } from "../../graphql/subscriptions";
import styles from "./CurrentBuildsPanel.module.scss";
import cx from "classnames";

interface Props {
    revisionId: number;
    developerMode: boolean;
}

export const CurrentBuildsPanel: FC<Props> = ({
    revisionId,
    developerMode
}) => {
    const client = useApolloClient();
    const buildsState = useLocalBuildsState();
    const { buildJobsData } = buildsState;
    const [getCurrentBuilds] = useLazyQuery<TempBuildsData, TempBuildVariables>(
        GET_CURRENT_BUILDS,
        { fetchPolicy: "network-only" }
    );

    const getBuildsDataFromS3 = useCallback(
        async (url: string): Promise<BuildJobData[]> => {
            try {
                const response = await fetch(url);
                return await response.json();
            } catch (error) {
                console.log("[DEBUG] getBuildsDataFromS3 error ", error);
                throw error;
            }
        },
        []
    );

    useSubscription(CURRENT_BUILDS_SUBSCRIPTION, {
        variables: { revisionId: revisionId },
        onData: ({
            data: { data: { currentBuildsNotification } = {} },
            client
        }) => {
            try {
                if (currentBuildsNotification.builds) {
                    const currentRevisionData = client.cache.readQuery<
                        RevisionData,
                        RevisionVariables
                    >({
                        query: GET_REVISION,
                        variables: { revisionId: Number(revisionId) }
                    });

                    let buildDataVersion = -1;
                    const currentData = currentRevisionData?.revision.buildData;
                    buildDataVersion = currentData.version;

                    if (!buildDataVersion) {
                        const currentData = JSON.parse(
                            currentRevisionData?.revision.buildData
                        );
                        buildDataVersion = currentData.version;
                    }

                    if (
                        !buildDataVersion ||
                        currentBuildsNotification.version >= buildDataVersion
                    ) {
                        getBuildsDataFromS3(
                            currentBuildsNotification.builds
                        ).then(builds =>
                            client.writeQuery<BuildsStateData, BuildsStateData>(
                                {
                                    query: GET_LOCAL_BUILD_STATE,
                                    data: {
                                        buildsState: {
                                            ...buildsState,
                                            buildJobsData: builds
                                        }
                                    }
                                }
                            )
                        );
                    } else {
                        console.log(
                            "[DEBUG] mismatched versions, skip updating current build list!"
                        );
                    }
                }
            } catch (error) {
                console.log("[DEBUG] getBuildsDataFromS3 error ", error);
            }
        }
    });

    const updateBuildJobsData = useCallback(
        (newJobs: BuildJobData[]) => {
            client.writeQuery<BuildsStateData, BuildsStateData>({
                query: GET_LOCAL_BUILD_STATE,
                data: {
                    buildsState: { ...buildsState, buildJobsData: newJobs }
                }
            });
        },
        [client, buildsState]
    );

    useEffect(() => {
        getCurrentBuilds({
            variables: {
                revisionId: Number(revisionId)
            }
        });

        return () => {
            const currentState = client.readQuery<BuildsStateData>({
                query: GET_LOCAL_BUILD_STATE
            });

            if (currentState) {
                client.writeQuery<BuildsStateData, BuildsStateData>({
                    query: GET_LOCAL_BUILD_STATE,
                    data: {
                        buildsState: {
                            ...currentState.buildsState,
                            buildJobsData: undefined
                        }
                    }
                });
            }
        };
        // We don't want client and state as deps here as this effect would fire constantly
        // This is only used cleanup in unmount
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const toggleSelection = (newState: boolean) => {
        if (buildJobsData === undefined) {
            return;
        }
        const newBuildJobsData = [...buildJobsData];
        for (let i = 0; i < newBuildJobsData.length; i++) {
            newBuildJobsData[i] = { ...newBuildJobsData[i], include: newState };
        }
        updateBuildJobsData(newBuildJobsData);
    };

    const updateBuildState = (newState: BuildJobData) => {
        if (buildJobsData === undefined) {
            return;
        }
        const newBuildJobsData = [...buildJobsData];
        for (let i = 0; i < newBuildJobsData.length; i++) {
            if (newBuildJobsData[i].name === newState.name) {
                newBuildJobsData[i] = newState;
                break;
            }
        }
        updateBuildJobsData(newBuildJobsData);
    };

    let jobs;
    if (
        buildJobsData !== undefined &&
        buildJobsData !== null &&
        buildJobsData.length > 0
    ) {
        jobs = buildJobsData.map((object: BuildJobData, index: number) => (
            <ToggleButtonComponent
                key={index}
                jobData={object}
                onToggle={
                    developerMode
                        ? (item: BuildJobData) => updateBuildState(item)
                        : undefined
                }
            />
        ));
    }

    if (!developerMode) {
        return null;
    }

    return (
        <Col className="m-0 p-0 col-2">
            <Container
                className={cx("secondary-background", styles.resultsPanel)}
            >
                {developerMode ? (
                    <Row
                        className={cx(
                            "d-flex justify-content-center mb-1 text-center",
                            styles.resultsPanelButtons
                        )}
                    >
                        <Col className="m-0 p-1">
                            <Button
                                className="btn-info btn-sm w-100 h-100"
                                onClick={() => toggleSelection(true)}
                            >
                                Select All
                            </Button>
                        </Col>
                        <Col className="m-0 p-1">
                            <Button
                                className="btn-secondary btn-sm"
                                onClick={() => toggleSelection(false)}
                            >
                                Select None
                            </Button>
                        </Col>
                    </Row>
                ) : null}
                <Container className={cx("pt-1 mt-0", styles.resultsPanelMain)}>
                    {buildJobsData !== undefined ? (
                        jobs
                    ) : (
                        <p className={cx("text-inverse", styles.emptyPanel)}>
                            No Builds
                        </p>
                    )}
                </Container>
            </Container>
        </Col>
    );
};
