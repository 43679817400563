import React, { FC, useState } from "react";
import { DropdownAction, Revision } from "../../models/types";
import { Card, Col, Row } from "react-bootstrap";
import { useMatch, useNavigate } from "react-router-dom";
import { ActionDropdown } from "../dropdowns/ActionDropdown";
import { DailyCTR } from "./analytics/DailyCTR";
import { DailyImpressions } from "./analytics/DailyImpressions";
import { UserQueryCTR } from "./analytics/UserQueryCTR";
import { UserQueryImpressions } from "./analytics/UserQueryImpressions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { useLocalAnalyticsState } from "../../graphql/hooks";
import { SimpleDate } from "./SimpleDate";
import cx from "classnames";
import "./simple.scss";

interface Props {
    revision: Revision | undefined;
    projectId: number;
    developerMode: boolean;
    showReleaseNotes: () => void;
    onClick?: () => void;
    onCreate?: () => void;
    onRemove?: () => void;
}

const ReleaseItemImplementation: FC<Props> = ({
    revision,
    projectId,
    developerMode,
    showReleaseNotes,
    onClick,
    onCreate,
    onRemove
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();
    const { analyticsEnabled } = useLocalAnalyticsState();
    const match = useMatch("/:path/*");
    if (!revision) {
        return null;
    }
    const productId = revision.project.product?.id;
    const eventPath = `${match?.pathname}/${productId}/projects/${projectId}/revisions/${revision.id}/events`;
    const historyPath = `${match?.pathname}/${productId}/projects/${projectId}/revisions/${revision.id}/history`;

    const actions: DropdownAction[] = [
        {
            header: "Events",
            variant: "inverse",
            callback: () => navigate(eventPath)
        },
        {
            header: "New BuildSet",
            variant: "inverse",
            callback: () => {
                if (onCreate) {
                    onCreate();
                }
            }
        }
    ];

    if (developerMode) {
        actions.push({
            header: "Builds",
            variant: "inverse",
            callback: () => navigate(historyPath)
        });
        actions.push({
            header: "Changelog",
            variant: "inverse",
            callback: showReleaseNotes
        });
        actions.push({
            header: "Remove",
            variant: "danger",
            callback: () => {
                if (onRemove) {
                    onRemove();
                }
            }
        });
    }

    return (
        <Card.Header
            className="text-inverse bread-text custom-card-body-children mouseHover btn bg-primary"
            onClick={e => {
                if (onClick) {
                    if (e.defaultPrevented) return;
                    onClick();
                }
                setIsOpen(!isOpen);
            }}
        >
            <Row className="d-flex align-items-center no-wrap">
                <Col
                    className={cx("text-start revision-title", {
                        "col-4": analyticsEnabled,
                        "col-6": !analyticsEnabled
                    })}
                >
                    {developerMode ? revision.name : revision.project.name}
                    <FontAwesomeIcon
                        className={`ms-2 icon-chevron ${
                            isOpen ? "" : "rotate-180"
                        }`}
                        icon={faChevronDown}
                    />
                </Col>
                <Col className="col-1 d-flex ps-0 no-wrap xs-font justify-content-center">
                    <SimpleDate dateUpdated={revision.dateUpdated} />
                </Col>
                <Col
                    className={cx("text-start", {
                        "col-2": analyticsEnabled,
                        "col-4": !analyticsEnabled
                    })}
                ></Col>
                {analyticsEnabled ? (
                    <>
                        <Col className="col-1 text-start pe-0 ps-1">
                            <DailyImpressions
                                impressions={revision.impressions}
                            />
                        </Col>
                        <Col className="col-1 text-start pe-0">
                            <DailyCTR
                                gameplay={revision.gameplay}
                                endScreen={revision.endScreen}
                                impressions={revision.impressions}
                            />
                        </Col>
                        <Col className="col-1 text-start pe-0">
                            <UserQueryImpressions
                                impressions={revision.userImpressions}
                            />
                        </Col>
                        <Col className="col-1 text-start pe-0">
                            <UserQueryCTR
                                gameplay={revision.userGameplay}
                                endScreen={revision.userEndScreen}
                                impressions={revision.userImpressions}
                            />
                        </Col>
                    </>
                ) : null}
                <Col className="col-1 d-flex justify-content-end pe-1">
                    <ActionDropdown
                        id={`release-${revision.id}`}
                        variant="primary"
                        actions={actions}
                    />
                </Col>
            </Row>
        </Card.Header>
    );
};

export const ReleaseItem = React.memo(ReleaseItemImplementation);
