import React, { FC } from "react";
import { Card, Col, Form, FormControl, InputGroup } from "react-bootstrap";
import {
    getGitSupportedName,
    validateBuildSetName
} from "../../common/Helpers";
import { InfoMessage } from "../simple/InfoMessage";
import { SelectableBaseProject } from "../selectable/SelectableBaseProject";
import { SelectableModules } from "../selectable/SelectableModules";
import { SlackInfoMessage } from "../simple/SlackInfoMessage";
import { CreateProjectConfig, NewProjectConfig } from "../../models/types";

interface Props {
    project: CreateProjectConfig;
    setProject: (project: CreateProjectConfig) => void;
    acceptedSlug: boolean;
    getCreateProjectConfig: NewProjectConfig;
    getGitlabStatus: (options: any) => void;
    updateAcceptedSlug: (accepted: boolean) => void;
}

export const CreateProjectBasics: FC<Props> = ({
    project,
    acceptedSlug,
    setProject,
    getCreateProjectConfig,
    getGitlabStatus,
    updateAcceptedSlug
}) => {
    return (
        <Card className="border-success p-2">
            <Col md={12} className="m-0 ps-2 mb-2">
                <h5 className="text-inverse">Project</h5>
            </Col>
            <Form.Group controlId="projectName">
                <InputGroup className="mb-3">
                    <InputGroup.Text>Name</InputGroup.Text>
                    <Form.Control
                        type="text"
                        name="projectName"
                        value={project.name ?? ""}
                        onChange={(event: any) => {
                            const newName = event.target.value;
                            if (newName === "") {
                                setProject({
                                    ...project,
                                    name: newName,
                                    gitSlug: newName
                                });
                            }
                            if (validateBuildSetName(newName)) {
                                const gitName = getGitSupportedName(newName);
                                if (gitName) {
                                    setProject({
                                        ...project,
                                        name: newName,
                                        gitSlug: gitName
                                    });
                                }
                            }
                        }}
                        onBlur={() => {
                            if (project.gitSlug !== "") {
                                getGitlabStatus({
                                    variables: {
                                        slug: project.gitSlug
                                    }
                                });
                            } else {
                                updateAcceptedSlug(false);
                            }
                        }}
                    />
                </InputGroup>
                <InputGroup className="mb-3">
                    <InputGroup.Text>Git Slug</InputGroup.Text>
                    <Form.Control
                        type="text"
                        name="gitSlug"
                        value={project.gitSlug ?? ""}
                        readOnly={true}
                    />
                </InputGroup>
                {acceptedSlug ? null : (
                    <InfoMessage
                        text="Slug cannot be empty or already used in Gitlab"
                        textCss="text-danger"
                    />
                )}
                <InputGroup className="mb-3">
                    <InputGroup.Text>Full Name</InputGroup.Text>
                    <Form.Control
                        type="text"
                        name="fullName"
                        value={project.fullName ?? ""}
                        onChange={(event: any) => {
                            setProject({
                                ...project,
                                fullName: event.target.value
                            });
                        }}
                    />
                </InputGroup>
                <div className="mt-2 mb-3">
                    <InfoMessage text="Some networks require 'fullName' as part of their metadata. Can be modified in package.json later" />
                </div>
                <InputGroup className="mb-3">
                    <InputGroup.Text>Description</InputGroup.Text>
                    <Form.Control
                        type="text"
                        name="description"
                        value={project.description ?? ""}
                        onChange={(event: any) => {
                            setProject({
                                ...project,
                                description: event.target.value
                            });
                        }}
                    />
                </InputGroup>
                <div className="mt-2 mb-3">
                    <InfoMessage text="Some networks require 'description' as part of their metadata. Can be modified in package.json later" />
                </div>
                <InputGroup className="mb-3">
                    <InputGroup.Text>Genre</InputGroup.Text>
                    <Form.Control
                        type="text"
                        name="genre"
                        value={project.genre ?? ""}
                        onChange={(event: any) => {
                            setProject({
                                ...project,
                                genre: event.target.value
                            });
                        }}
                    />
                </InputGroup>
                <div className="mt-2 mb-3">
                    <InfoMessage text="Some networks require 'genre' as part of their metadata. Can be modified in package.json later" />
                </div>
                <SelectableBaseProject
                    project={project}
                    projects={JSON.parse(
                        JSON.stringify(getCreateProjectConfig.baseProjects)
                    )}
                    setProject={setProject}
                    modules={JSON.parse(
                        JSON.stringify(getCreateProjectConfig.gitModules)
                    )}
                />
                {project.baseProject ? (
                    <>
                        <SelectableModules
                            project={project}
                            modules={getCreateProjectConfig.gitModules}
                            setProject={setProject}
                        />
                        <InputGroup>
                            <InputGroup.Text>
                                Keep Base Project Sets
                            </InputGroup.Text>
                            <InputGroup.Checkbox
                                title="Keep base project sets (e.g. for re-skins)"
                                aria-label="Keep base project sets (e.g. for re-skins)"
                                checked={project.keepBaseSets}
                                disabled={
                                    project.baseProject?.name ===
                                    "Build Testbed"
                                }
                                onClick={(event: any) => {
                                    event.stopPropagation();
                                }}
                                onChange={(event: any) => {
                                    setProject({
                                        ...project,
                                        keepBaseSets: event.target.checked
                                    });
                                }}
                            />
                        </InputGroup>
                        <div className="mt-2 mb-3">
                            <InfoMessage text="Keep base project default sets? Intended only for re-skins" />
                        </div>
                        <InputGroup className="mb-2">
                            <InputGroup.Text>Slack Channel</InputGroup.Text>
                            <FormControl
                                value={project.slackChannel ?? ""}
                                onChange={(event: any) => {
                                    setProject({
                                        ...project,
                                        slackChannel: event.target.value
                                    });
                                }}
                            />
                        </InputGroup>
                        <SlackInfoMessage />
                    </>
                ) : null}
            </Form.Group>
        </Card>
    );
};
