import React, { RefObject } from "react";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import styles from "./UpButton.module.scss";

interface Props {
    scrollableRef?: RefObject<HTMLElement>;
}

export const UpButton: React.FC<Props> = ({ scrollableRef }) => {
    const scrollToTop = () => {
        if (scrollableRef && scrollableRef.current) {
            scrollableRef.current.scrollTo({
                top: 0,
                behavior: "smooth"
            });
        } else {
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
        }
    };

    return (
        <>
            <Button
                onClick={scrollToTop}
                variant="secondary"
                className={styles.upButton}
                title="Scroll up"
            >
                <FontAwesomeIcon icon={faArrowUp} />
            </Button>
        </>
    );
};
