import React, { FC, useState } from "react";
import {
    Button,
    Card,
    Container,
    FormControl,
    InputGroup,
    Modal
} from "react-bootstrap";
import "./Modals.scss";
import {
    CreateOrganizationData,
    Organization,
    OrganizationData,
    OrganizationVariables
} from "../../models/types";
import { GET_ORGANIZATIONS } from "../../graphql/queries";
import { useMutation } from "@apollo/client";
import { CREATE_ORGANIZATION } from "../../graphql/mutations";
import { useLocalState } from "../../graphql/hooks";

interface Props {
    show: boolean;
    onClose: (newOrganization: Organization | undefined) => void;
}

export const NewOrganizationModal: FC<Props> = props => {
    const [info, updateInfo] = useState<string>("");
    const [organization, setOrganization] = useState<OrganizationVariables>({
        name: ""
    });
    const { useDarkMode } = useLocalState();

    const [createOrganization] = useMutation<
        CreateOrganizationData,
        OrganizationVariables
    >(CREATE_ORGANIZATION);

    const createNewOrganization = async () => {
        try {
            let newOrg = undefined;
            await createOrganization({
                variables: organization,
                update: (cache, { data }) => {
                    const currentOrganizationData =
                        cache.readQuery<OrganizationData>({
                            query: GET_ORGANIZATIONS
                        });
                    const currentOrganizations =
                        currentOrganizationData?.organizations;
                    if (currentOrganizations && data) {
                        newOrg = data.createOrganization;
                        const newOrganizations = [
                            newOrg,
                            ...currentOrganizations
                        ];
                        cache.writeQuery({
                            query: GET_ORGANIZATIONS,
                            data: { organizations: newOrganizations }
                        });
                    }
                }
            });
            props.onClose(newOrg);
        } catch (error) {
            console.log("[DEBUG] createNewOrganization error: ", error);
            // @ts-ignore
            updateInfo(error.message);
            setTimeout(() => {
                updateInfo("");
            }, 4000);
        }
    };

    return (
        <Modal
            show={props.show}
            onHide={() => props.onClose(undefined)}
            backdrop="static"
            keyboard={false}
            className={useDarkMode ? "modal-dark" : ""}
        >
            <Modal.Header closeButton>
                <Modal.Title>Add New Organization</Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-body d-flex justify-content-center align-items-center">
                <Card className="editableCard no-border fullSize px-3 pb-3">
                    <InputGroup className="mb-4">
                        <InputGroup.Text>Name</InputGroup.Text>
                        <FormControl
                            placeholder="Organization name required"
                            onChange={(event: any) => {
                                setOrganization({
                                    ...organization,
                                    name: event.target.value
                                });
                            }}
                        />
                    </InputGroup>
                    <div className=" text-center">
                        <Button
                            className="w-75"
                            onClick={createNewOrganization}
                            disabled={!organization.name?.trim()}
                        >
                            Create
                        </Button>
                    </div>
                </Card>
            </Modal.Body>
            {info ? (
                <Modal.Footer>
                    <Container className="text-center text-danger">
                        {info}
                    </Container>
                </Modal.Footer>
            ) : null}
        </Modal>
    );
};
