import React from "react";
import { Card } from "react-bootstrap";

export const LegacyDeveloperProjectWarningBox = () => {
    return (
        <Card className="mt-2 border-warning">
            <Card.Body>
                This is a legacy base project. You should{" "}
                <span className="text-warning">NOT</span> use this in new client
                projects!
            </Card.Body>
        </Card>
    );
};
