import React from "react";
import { Card } from "react-bootstrap";

export const ExperimentalDeveloperProjectWarningBox = () => {
    return (
        <Card className="mt-2 border-danger">
            <Card.Body className="text-inverse">
                This is an experimental base project. Do{" "}
                <span className="text-danger">NOT</span> use in client projects!
            </Card.Body>
        </Card>
    );
};
