import React, { FC } from "react";
import {
    BatchBuildsData,
    BatchBuildsVariables,
    DropdownAction,
    Revision
} from "../../models/types";
import { useMatch, useNavigate } from "react-router-dom";
import { Col, Container, ListGroup, Row } from "react-bootstrap";
import { useQuery } from "@apollo/client";
import { GET_BATCH_BUILDS } from "../../graphql/queries";
import { checkBuildsStatus } from "../../common/Helpers";
import { ActionDropdown } from "../dropdowns/ActionDropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import { useLocalAnalyticsState } from "../../graphql/hooks";
import { AnalyticsFunnelSelectionToggle } from "../buttons/analytics/AnalyticsFunnelSelectionToggle";
import { DailyCTR } from "./analytics/DailyCTR";
import { DailyImpressions } from "./analytics/DailyImpressions";
import { UserQueryCTR } from "./analytics/UserQueryCTR";
import { UserQueryImpressions } from "./analytics/UserQueryImpressions";
import { SimpleDate } from "./SimpleDate";
import cx from "classnames";
import "./simple.scss";

interface Props {
    revision: Revision;
    projectId: number;
    developerMode: boolean;
    showArchived: boolean;
    showReleaseNotes: () => void;
    onArchive: () => void;
    onCreate: () => void;
    onRemove: () => void;
}

export const RevisionItem: FC<Props> = ({
    revision,
    projectId,
    developerMode,
    showArchived,
    showReleaseNotes,
    onArchive,
    onCreate,
    onRemove
}) => {
    const navigate = useNavigate();
    const match = useMatch("/:path/*");
    const { analyticsEnabled, analyticsFunnelSelectionMode } =
        useLocalAnalyticsState();
    const { data } = useQuery<BatchBuildsData, BatchBuildsVariables>(
        GET_BATCH_BUILDS,
        {
            variables: { revisionId: revision.id },
            fetchPolicy: "network-only",
            skip: revision === undefined
        }
    );

    if (!revision || (!developerMode && !showArchived && revision.archived)) {
        return null;
    }

    const { hasBuilds, hasRunning, latestCompleted } = checkBuildsStatus(data);
    const productId = revision.project.product?.id;
    const variationsPath = `${match?.pathname}/${productId}/projects/${projectId}/revisions/${revision.id}/variations`;
    let historyPath = `${match?.pathname}/${productId}/projects/${projectId}/revisions/${revision.id}/history`;

    if (latestCompleted) {
        historyPath += `/${latestCompleted.id}`;
    }

    const actions: DropdownAction[] = [
        {
            header: "Edit",
            variant: "inverse",
            callback: () => navigate(variationsPath)
        },
        {
            header: hasRunning ? (
                <Container className="m-0 p-0">
                    <span className="text-start text-inverse">Builds</span>
                    {hasRunning ? (
                        <FontAwesomeIcon
                            icon={faSyncAlt}
                            size="1x"
                            className="text-inverse float-end ms-2 rotate-image-animation"
                        />
                    ) : null}
                </Container>
            ) : (
                "Builds"
            ),
            variant: hasBuilds ? "inverse" : "disabled",
            className: hasBuilds ? undefined : "disabled",
            callback: () => {
                if (hasBuilds) {
                    navigate(historyPath);
                }
            }
        },
        {
            header: "Duplicate",
            variant: "inverse",
            callback: onCreate
        },
        {
            header: "Changelog",
            variant: "inverse",
            callback: showReleaseNotes
        }
    ];

    if (developerMode) {
        actions.push({
            header: "Remove",
            variant: "danger",
            callback: onRemove
        });
    } else {
        actions.push({
            header: revision.archived ? "Unarchive" : "Archive",
            variant: "inverse",
            callback: onArchive
        });
    }

    const edit = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (e.defaultPrevented) return;
        navigate(variationsPath);
    };

    return (
        <ListGroup.Item
            onClick={edit}
            className="custom-card-body-children mb-1 border border-primary text-inverse transparentListItem mouseHover"
        >
            <Row className="d-flex bread-text align-items-center no-wrap">
                <Col
                    className={cx("text-start", {
                        "col-4": analyticsEnabled,
                        "col-6": !analyticsEnabled
                    })}
                >
                    {revision.name}
                </Col>
                <Col className="col-1 d-flex ps-0 no-wrap xs-font justify-content-center">
                    <SimpleDate dateUpdated={revision.dateUpdated} />
                </Col>
                <Col
                    className={cx("text-start", {
                        "col-2": analyticsEnabled,
                        "col-4": !analyticsEnabled
                    })}
                ></Col>
                {analyticsEnabled ? (
                    <>
                        <Col className="col-1 text-start pe-0 ps-2">
                            <DailyImpressions
                                impressions={revision.impressions}
                            />
                        </Col>
                        <Col className="col-1 text-start pe-0">
                            <DailyCTR
                                gameplay={revision.gameplay}
                                endScreen={revision.endScreen}
                                impressions={revision.impressions}
                            />
                        </Col>
                        <Col className="col-1 text-start pe-0">
                            <UserQueryImpressions
                                impressions={revision.userImpressions}
                            />
                        </Col>
                        <Col className="col-1 text-start pe-0">
                            <UserQueryCTR
                                gameplay={revision.userGameplay}
                                endScreen={revision.userEndScreen}
                                impressions={revision.userImpressions}
                            />
                        </Col>
                    </>
                ) : null}
                <Col className="d-flex col-1 justify-content-end pe-1">
                    {analyticsEnabled && analyticsFunnelSelectionMode ? (
                        <AnalyticsFunnelSelectionToggle
                            reference="revision"
                            revision={{
                                ...revision,
                                selected: true,
                                comparison: `revision-${revision.id}`
                            }}
                            className="custom-funnel-button"
                        />
                    ) : null}
                    {actions ? (
                        <ActionDropdown
                            id={`revision-${revision.id}`}
                            variant="info"
                            actions={actions}
                        />
                    ) : null}
                </Col>
            </Row>
        </ListGroup.Item>
    );
};
