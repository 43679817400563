import React, { FC } from "react";
import { Card, Col, Form, ListGroup } from "react-bootstrap";
import { useApolloClient, useMutation } from "@apollo/client";
import {
    AdsetVariables,
    CreateAdsetData,
    ReleaseType,
    Revision,
    RevisionVariables,
    StateData
} from "../../models/types";
import {
    GET_LOCAL_STATE,
    GET_PRODUCT_META_DATA,
    GET_REVISIONS
} from "../../graphql/queries";
import {
    CREATE_ADSET,
    REMOVE_REVISION,
    UPDATE_REVISION
} from "../../graphql/mutations";
import { useNavigate } from "react-router-dom";
import { useLocalState } from "../../graphql/hooks";
import { RevisionItem } from "../simple/RevisionItem";

interface Props {
    revisions: Revision[];
    projectId: number;
    updateReleaseNotesRevisionId: (revisionId: number) => void;
    updateShowReleaseNotes: () => void;
}

const AdSetsImplementation: FC<Props> = ({
    revisions,
    projectId,
    updateReleaseNotesRevisionId,
    updateShowReleaseNotes
}) => {
    const navigate = useNavigate();
    const client = useApolloClient();
    const state = useLocalState();
    const [createAdset] = useMutation<CreateAdsetData, AdsetVariables>(
        CREATE_ADSET
    );
    const [removeRevision] = useMutation<RevisionVariables>(REMOVE_REVISION);
    const [updateRevision] = useMutation(UPDATE_REVISION);

    const createFromRevision = async (revision: Revision) => {
        try {
            const productId = revision.project.product?.id;
            const response = await createAdset({
                variables: {
                    projectId: projectId,
                    revisionId: revision.id
                }
            });
            navigate(
                `/products/${productId}/projects/${projectId}/revisions/${response.data?.createAdset.id}/variations`
            );
        } catch (error) {
            console.log("[DEBUG] error: ", error);
        }
    };

    const updateRelease = async (
        id: number,
        productId?: number,
        newType?: ReleaseType,
        archived?: boolean
    ) => {
        try {
            await updateRevision({
                variables: {
                    revisionId: id,
                    releaseType: newType,
                    archived: archived
                },
                refetchQueries: [
                    {
                        query: GET_PRODUCT_META_DATA,
                        variables: { productId: productId }
                    }
                ]
            });
        } catch (error) {
            console.log("[DEBUG] updateRelease error ", error);
        }
    };

    const removeRevisionById = async (id: number) => {
        try {
            await removeRevision({
                variables: { revisionId: id },
                refetchQueries: [
                    {
                        query: GET_REVISIONS,
                        variables: { projectId: projectId }
                    }
                ]
            });
        } catch (error) {
            console.log("[DEBUG] removeRevisionById error ", error);
        }
    };

    const updateShowArchived = (archived: boolean) => {
        client.writeQuery<StateData, StateData>({
            query: GET_LOCAL_STATE,
            data: { state: { ...state, showArchived: archived || false } }
        });
    };

    const hasArchived = revisions?.some(
        (revision: Revision) => revision.archived
    );

    const items = revisions?.map((item: Revision) => (
        <RevisionItem
            key={item.id}
            projectId={projectId}
            revision={item}
            developerMode={state.developerMode}
            showArchived={state.showArchived}
            showReleaseNotes={() => {
                updateReleaseNotesRevisionId(item.id);
                updateShowReleaseNotes();
            }}
            onArchive={() =>
                updateRelease(
                    item.id,
                    item.project.product?.id,
                    undefined,
                    !item.archived
                )
            }
            onCreate={() => createFromRevision(item)}
            onRemove={() => removeRevisionById(item.id)}
        />
    ));

    if (!items || items.length === 0) {
        return null;
    }

    return (
        <Card.Body className="custom-card-body me-1 ms-1 mb-0">
            {hasArchived && !state.developerMode ? (
                <Col className="col-12 m-2 d-flex justify-content-end">
                    <Form className="text-inverse bread-text">
                        <Form.Check
                            type="switch"
                            id={projectId + "-archived-switch"}
                            label="Show archived"
                            checked={state.showArchived}
                            onChange={(event: any) =>
                                updateShowArchived(event.target.checked)
                            }
                        />
                    </Form>
                </Col>
            ) : null}
            <ListGroup className="mt-2 mb-1">{items}</ListGroup>
        </Card.Body>
    );
};

export const AdSets = React.memo(AdSetsImplementation);
