import React, { FC, useEffect, useState } from "react";
import {
    DropdownAction,
    Product,
    ProductMetaData,
    ProductVariables
} from "../../models/types";
import { Card, Col, Collapse, Row } from "react-bootstrap";
import { useApolloClient, useQuery } from "@apollo/client";
import { GET_PRODUCT_META_DATA } from "../../graphql/queries";
import { UPDATE_PRODUCT } from "../../graphql/mutations";
import { EditableTextComponent } from "../editable/EditableTextComponent";
import { ActionDropdown } from "../dropdowns/ActionDropdown";
import { DailyCTR } from "./analytics/DailyCTR";
import { DailyImpressions } from "./analytics/DailyImpressions";
import { useNavigate } from "react-router-dom";
import { Projects } from "../views/Projects";
import { ClientViewProjects } from "../views/ClientViewProjects";
import { useLocalAnalyticsState, useLocalState } from "../../graphql/hooks";
import { AnalyticsFunnelSelectionToggle } from "../buttons/analytics/AnalyticsFunnelSelectionToggle";
import {
    saveCollapseState,
    updateCollapseState,
    updateCollapseTiersInCache
} from "../../common/Helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { SimpleDate } from "./SimpleDate";
import { UserQueryImpressions } from "./analytics/UserQueryImpressions";
import { UserQueryCTR } from "./analytics/UserQueryCTR";
import { StatusBadges } from "./StatusBadges";
import cx from "classnames";

interface Props {
    product: Product;
    onCreate?: (variables?: any) => void;
    onRemove: () => void;
}

const ProductCardItemImplementation: FC<Props> = ({
    product,
    onCreate,
    onRemove
}) => {
    const client = useApolloClient();
    const state = useLocalState();
    const navigate = useNavigate();
    const { analyticsEnabled, analyticsFunnelSelectionMode } =
        useLocalAnalyticsState();
    const [showChildren, updateShowChildren] = useState(
        state.productTier && state.productTier.has(Number(product.id))
    );
    const [loaded, updateLoaded] = useState(false);
    const { data: { productMetaData } = {} } = useQuery<
        ProductMetaData,
        ProductVariables
    >(GET_PRODUCT_META_DATA, {
        variables: { productId: Number(product?.id) },
        fetchPolicy: "network-only"
    });

    useEffect(() => {
        const updatedSet = updateCollapseState(
            state.productTier,
            Number(product.id),
            showChildren
        );
        saveCollapseState(0, updatedSet);
        updateCollapseTiersInCache(client, updatedSet);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showChildren]);

    const routeChange = () => {
        navigate("/assets");
    };

    const actions: DropdownAction[] = [
        {
            header: "Go to Assets",
            variant: "inverse",
            callback: routeChange
        }
    ];

    if (onCreate) {
        actions.push({
            header: "New Project",
            variant: "inverse",
            callback: onCreate
        });
    }

    if (state.developerMode) {
        actions.push({
            header: "Remove Product",
            variant: "danger",
            callback: onRemove
        });
    }

    const hasMetaData =
        productMetaData &&
        (productMetaData.development > 0 ||
            productMetaData.review > 0 ||
            productMetaData.accepted > 0 ||
            productMetaData.rejected > 0 ||
            productMetaData.released > 0 ||
            productMetaData.testAvailable > 0 ||
            productMetaData.bugged > 0);

    let productColumnClass = "col-2";
    let statusColumnClass = "col-2";

    if (!analyticsEnabled && state.developerMode) {
        productColumnClass = "col-3";
        statusColumnClass = "col-4 ps-2";
    } else if (analyticsEnabled && !state.developerMode) {
        productColumnClass = "col-4";
    } else if (!analyticsEnabled && !state.developerMode) {
        productColumnClass = "col-6";
        statusColumnClass = "col-4";
    }

    return (
        <Card className="no-border inner-card">
            <Card.Header
                className="text-inverse btn mouseHover custom-card-product highlight-card"
                onClick={e => {
                    if (e.defaultPrevented) return;
                    updateShowChildren(!showChildren);
                }}
            >
                <Row className="d-flex align-items-center mb-1">
                    <Col
                        className={`d-flex align-items-center ${productColumnClass}`}
                    >
                        <div className="icon-placeholder me-2">
                            {hasMetaData ? (
                                <FontAwesomeIcon
                                    className={`icon-chevron ${
                                        showChildren ? "rotate-180" : ""
                                    }`}
                                    icon={faChevronDown}
                                />
                            ) : null}
                        </div>
                        <span className="ms-2 bread-text">
                            <EditableTextComponent
                                productId={product.id}
                                isEditable={true}
                                text={product.name}
                                mutation={UPDATE_PRODUCT}
                                valueName="name"
                                className={{
                                    container: "text-start"
                                }}
                            />
                        </span>
                    </Col>
                    {state.developerMode && (
                        <Col
                            className={cx("d-flex", {
                                "col-2": analyticsEnabled,
                                "col-3": !analyticsEnabled
                            })}
                        >
                            <span className="product-title text-inverse-50 ps-2 bread-text">
                                {product?.organization?.name}
                            </span>
                        </Col>
                    )}
                    <Col className="col-1 d-flex p-1 no-wrap xs-font justify-content-center">
                        <SimpleDate dateUpdated={product.dateUpdated} />
                    </Col>
                    <Col className={cx("text-start", statusColumnClass)}>
                        {productMetaData ? (
                            <StatusBadges
                                developerMode={state.developerMode}
                                metaData={productMetaData}
                            />
                        ) : null}
                    </Col>
                    {analyticsEnabled && (
                        <>
                            <Col className="col-1 ps-2 text-start daily-impr-product">
                                <DailyImpressions
                                    impressions={product.impressions}
                                />
                            </Col>
                            <Col className="col-1 text-start pe-0">
                                <DailyCTR
                                    gameplay={product.gameplay}
                                    endScreen={product.endScreen}
                                    impressions={product.impressions}
                                />
                            </Col>
                            <Col className="col-1 text-start pe-0">
                                <UserQueryImpressions
                                    impressions={product.userImpressions}
                                />
                            </Col>
                            <Col className="col-1 text-start pe-0">
                                <UserQueryCTR
                                    gameplay={product.userGameplay}
                                    endScreen={product.userEndScreen}
                                    impressions={product.userImpressions}
                                />
                            </Col>
                        </>
                    )}
                    <Col className="d-flex col-1 justify-content-end px-1">
                        {analyticsEnabled && analyticsFunnelSelectionMode ? (
                            <AnalyticsFunnelSelectionToggle
                                className="custom-funnel-button"
                                reference="product"
                                product={{
                                    ...product,
                                    selected: true,
                                    comparison: `product-${product.id}`
                                }}
                            />
                        ) : null}
                        {actions.length > 0 ? (
                            <ActionDropdown
                                id={`product-${product.id}`}
                                variant="primary"
                                actions={actions}
                            />
                        ) : null}
                    </Col>
                </Row>
            </Card.Header>
            <Collapse in={showChildren && loaded}>
                <div>
                    <Card.Body className="custom-card-body mt-1">
                        {state.developerMode ? (
                            <Projects
                                productId={product.id}
                                show={showChildren}
                                updateLoaded={updateLoaded}
                            />
                        ) : (
                            <ClientViewProjects
                                productId={product.id}
                                show={showChildren}
                                updateLoaded={updateLoaded}
                            />
                        )}
                    </Card.Body>
                </div>
            </Collapse>
        </Card>
    );
};

export const ProductCardItem = React.memo(ProductCardItemImplementation);
