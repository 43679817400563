import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { GET_PRODUCTS_BY_USER_ORGANIZATION } from "../../graphql/queries";
import { Product, ProductsData } from "../../models/types";
import { Col, Container, Row } from "react-bootstrap";
import { AssetsItem } from "../simple/AssetItem";
import { BackButton } from "../simple/BackButton";
import { useLocation, useNavigate, useNavigationType } from "react-router-dom";

export const AssetsPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const navigationType = useNavigationType();
    const [sorted, setSorted] = useState<Product[]>([]);

    const {
        loading,
        data: { products } = {},
        error
    } = useQuery<ProductsData>(GET_PRODUCTS_BY_USER_ORGANIZATION);

    const sortItems = () => {
        if (products) {
            const temp = [...products];
            temp.sort((a, b) => {
                return a.organization.name.localeCompare(b.organization.name);
            });
            setSorted([...temp]);
        }
    };

    useEffect(() => {
        if (!loading) {
            sortItems();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);

    let previousOrg = "";
    const organizationName = (currentOrg: string) => {
        if (previousOrg !== currentOrg) {
            previousOrg = currentOrg;
            return currentOrg;
        }
        return "";
    };

    const items = sorted
        ? sorted.map((product: Product) => (
              <AssetsItem
                  key={product.id}
                  product={product}
                  organizationName={organizationName}
              />
          ))
        : [];

    if (loading || !products) {
        return (
            <Container className="fullSize p-2 pt-5 d-flex justify-content-center text-inverse">
                <p>Loading...</p>
            </Container>
        );
    }
    if (error) {
        return (
            <Container className="fullSize p-2 pt-5 d-flex justify-content-center text-inverse">
                <p>Error: {error.message}</p>
            </Container>
        );
    }

    return (
        <>
            <div className="ps-3 pt-3">
                <BackButton
                    hasHistory={!(location.key && navigationType === "POP")}
                    goBack={() => navigate(-1)}
                    size={"lg"}
                />
            </div>
            <Container className="fullSize">
                <Row className="d-flex align-items-center m-0">
                    <Col className="text-center">
                        <h3 className="text-inverse-50 mb-4">File Uploads</h3>
                    </Col>
                </Row>
                <Row className="d-flex justify-content-center m-0">
                    <Col className="col-12">{items}</Col>
                </Row>
            </Container>
        </>
    );
};
