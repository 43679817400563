import React, { FC, useEffect, useState } from "react";
import { Badge, Card, Collapse } from "react-bootstrap";
import { AssetBundle, MutatorProperty } from "../../../models/types";
import { AssetbundleDropdown } from "../../dropdowns/mutator/AssetbundleDropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { useMutatorState } from "../../../graphql/hooks";
import styles from "./CustomTypeCard.module.scss";
import cx from "classnames";

interface Props {
    property: MutatorProperty;
    assetbundles: AssetBundle[];
    getVariantByType: (type: string) => string;
    onChange: (name: string, value: string) => void;
}

const AssetbundleCardImplementation: FC<Props> = ({
    property,
    assetbundles,
    getVariantByType,
    onChange
}) => {
    const mutatorState = useMutatorState();
    const [showMeta, updateShowMeta] = useState(mutatorState.showMeta);

    useEffect(() => {
        if (showMeta !== mutatorState.showMeta) {
            updateShowMeta(mutatorState.showMeta);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mutatorState.showMeta]);

    const allowedAssetbundles =
        property?.meta?.path !== undefined
            ? assetbundles.filter(element => {
                  return (
                      element.path.split("/")[0] === property.meta?.path ||
                      false
                  );
              })
            : assetbundles;
    return (
        <Card className="text-inverse inner-card m-2">
            <Card.Header
                className={`d-flex align-items-center ${styles.cardHeader}`}
            >
                <span className={styles.headerText}>{property.name}</span>
                <Badge className="mx-2" bg={getVariantByType(property.type)}>
                    {property.type}
                </Badge>
                {property.meta ? (
                    <div
                        className="float-end mouseHover ms-auto"
                        onClick={() => updateShowMeta(!showMeta)}
                        title="Help and additional info"
                    >
                        <FontAwesomeIcon
                            icon={faCircleInfo}
                            className={cx({
                                "text-inverse-25": !showMeta,
                                "text-inverse-50": showMeta
                            })}
                        />
                    </div>
                ) : null}
            </Card.Header>
            <div>
                <Card.Body className={`${styles.cardBody} ms-1`}>
                    <AssetbundleDropdown
                        property={property}
                        assetbundles={allowedAssetbundles}
                        getVariantByType={getVariantByType}
                        onChange={onChange}
                    />
                </Card.Body>
            </div>
            {property.meta ? (
                <Collapse in={showMeta}>
                    <div>
                        <Card.Footer className={styles.footerInfo}>
                            {property.meta.description ? (
                                <div className="bread-text text-inverse-50">
                                    {property.meta.description}
                                </div>
                            ) : (
                                <span className="bread-text text-inverse-50">
                                    No description provided.
                                </span>
                            )}
                            {property.meta.path ? (
                                <div className="bread-text text-info">
                                    Path: {property.meta.path}
                                </div>
                            ) : null}
                        </Card.Footer>
                    </div>
                </Collapse>
            ) : null}
        </Card>
    );
};

export const AssetbundleCard = React.memo(AssetbundleCardImplementation);
