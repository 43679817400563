import React, { FC } from "react";
import cx from "classnames";

interface Props {
    currentPage: number;
    maxPages: number;
    toPage: (page: number) => void;
}

export const PaginationNavigator: FC<Props> = ({
    currentPage,
    maxPages,
    toPage
}) => {
    const pages = () => {
        const elements: JSX.Element[] = [];
        const start = Math.max(1, Math.min(currentPage - 1, maxPages - 2));
        const end = Math.min(maxPages, Math.max(start + 2, currentPage + 1));
        for (let i = start; i <= end; i++) {
            elements.push(
                <span
                    key={i}
                    className={cx(
                        "mx-1",
                        {
                            "text-primary text-underline": currentPage === i
                        },
                        {
                            "mouseHover mouseHoverLink text-inverse":
                                currentPage !== i
                        }
                    )}
                    onClick={() => goToPage(i)}
                >
                    {i}
                </span>
            );
        }
        return elements;
    };

    const goToPage = (targetPage: number) => {
        if (targetPage !== currentPage) {
            toPage(targetPage);
        }
    };

    return (
        <div className="mx-1 my-auto">
            {currentPage !== 1 ? (
                <span
                    className="ms-1 mouseHover mouseHoverLink"
                    onClick={() => goToPage(1)}
                >
                    first
                </span>
            ) : null}
            {pages()}
            {currentPage !== maxPages ? (
                <span
                    className="me-1 mouseHover mouseHoverLink"
                    onClick={() => goToPage(maxPages)}
                >
                    last
                </span>
            ) : null}
        </div>
    );
};
