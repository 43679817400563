import React, { Component } from "react";
import { ChartConfiguration } from "chart.js";
import { ChartJsWrapper } from "./ChartJsWrapper";

interface Props {
    label: string;
    labels: string[];
    dataset: number[];
    isPercentage: boolean;
    eventCounts: number[];
}

export class SimpleLineChart extends Component<Props> {
    shouldComponentUpdate() {
        return false;
    }

    render() {
        const { label, labels, dataset, isPercentage, eventCounts } =
            this.props;
        if (!dataset) {
            return null;
        }
        const config: ChartConfiguration = {
            type: "line",
            data: {
                labels: labels,
                datasets: [
                    {
                        label: label,
                        data: dataset,
                        backgroundColor: "rgb(75, 192, 192)",
                        borderColor: "rgb(75, 192, 192)"
                    }
                ]
            },
            options: {
                maintainAspectRatio: true,
                aspectRatio: 2.5,
                scales: {
                    y: {
                        ticks: {
                            callback: function (value) {
                                if (isPercentage) {
                                    return value + "%";
                                } else {
                                    return value.toLocaleString();
                                }
                            }
                        }
                    }
                },
                plugins: {
                    tooltip: {
                        callbacks: {
                            label: function (context) {
                                let label = context.dataset.label || "";
                                if (label) {
                                    label += ": ";
                                }
                                if (context.parsed.y !== null) {
                                    if (isPercentage) {
                                        label +=
                                            Number(context.parsed.y).toFixed(
                                                1
                                            ) + "%";
                                        const total =
                                            eventCounts?.[context.dataIndex];
                                        if (total !== undefined) {
                                            label += ` (${total.toLocaleString()})`;
                                        }
                                    } else {
                                        label +=
                                            context.parsed.y.toLocaleString();
                                    }
                                }
                                return label;
                            }
                        }
                    }
                }
            }
        };
        return <ChartJsWrapper config={config} />;
    }
}
