import React, { FC, useEffect, useState } from "react";
import { Badge, Card } from "react-bootstrap";
import { AnalyticsLoadingState } from "../../models/types";
import { useLocalAnalyticsState } from "../../graphql/hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

const badgeColor = ["success", "secondary", "info", "primary", "danger"];

interface Props {
    showQuerySettings: boolean;
    updateShowQuerySettings: (show: boolean) => void;
}

export const QueryHeader: FC<Props> = ({
    showQuerySettings,
    updateShowQuerySettings
}) => {
    const { analyticsLoadingState } = useLocalAnalyticsState();
    const [loadingState, updateLoadingState] = useState(
        AnalyticsLoadingState.Ready
    );
    const [badgeVariant, updateBadgeVariant] = useState(
        badgeColor[analyticsLoadingState]
    );

    useEffect(() => {
        updateLoadingState(analyticsLoadingState);
        updateBadgeVariant(badgeColor[analyticsLoadingState]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [analyticsLoadingState]);

    return (
        <Card.Header
            onClick={() => updateShowQuerySettings(!showQuerySettings)}
            className="d-flex mouseHover"
        >
            <h5 className="text-inverse-50 d-flex align-items-center">
                Query
                <FontAwesomeIcon
                    size="xs"
                    icon={faChevronDown}
                    className="ms-2 mt-1 text-inverse-25"
                />
            </h5>
            <Badge bg={badgeVariant} className="ms-3 mt-1 mb-2">
                {AnalyticsLoadingState[loadingState]}
            </Badge>
        </Card.Header>
    );
};
