import React, { FC } from "react";
import { OverlayBadge } from "./OverlayBadge";

interface Props {
    debug: boolean;
}

export const DebugOverlayBadge: FC<Props> = ({ debug }) => {
    if (!debug) {
        return null;
    }
    return (
        <OverlayBadge
            badgeHeader="DEBUG"
            badgeVariant="warning"
            overlayText="Debug mode enabled in settings. Do not create release builds with this setting on!"
            className="mt-2 p-1 me-2"
        />
    );
};
