import React, { FC } from "react";
import { ProductMeta, ProjectMeta } from "../../models/types";
import { BadgeOverlay } from "./BadgeOverlay";

interface Props {
    metaData: ProductMeta | ProjectMeta;
    developerMode: boolean;
}

const StatusBadgesImplementation: FC<Props> = ({ metaData, developerMode }) => {
    const {
        development,
        review,
        accepted,
        rejected,
        released,
        bugged,
        testAvailable,
        testRequests
    } = metaData;

    return (
        <>
            {development > 0 && (
                <BadgeOverlay text="In Development" variant="info">
                    {development}
                </BadgeOverlay>
            )}

            {review > 0 && (
                <BadgeOverlay text="In Review" variant="review">
                    {review}
                </BadgeOverlay>
            )}

            {accepted > 0 && (
                <BadgeOverlay text="Accepted" variant="success">
                    {accepted}
                </BadgeOverlay>
            )}

            {rejected > 0 && (
                <BadgeOverlay text="Rejected" variant="rejected">
                    {rejected}
                </BadgeOverlay>
            )}
            {released > 0 && (
                <BadgeOverlay text="Released" variant="primary">
                    {released}
                </BadgeOverlay>
            )}
            {bugged > 0 && (
                <BadgeOverlay text="Bugged" variant="warning">
                    {bugged}
                </BadgeOverlay>
            )}
            {testAvailable > 0 && (
                <BadgeOverlay text="Test(s) Available" variant="test">
                    {testAvailable}
                </BadgeOverlay>
            )}
            {testRequests > 0 && developerMode && (
                <BadgeOverlay
                    text={`Test(s) Requested: ${testRequests} - see Events`}
                    variant="warning"
                >
                    !
                </BadgeOverlay>
            )}
        </>
    );
};

export const StatusBadges = React.memo(StatusBadgesImplementation);
