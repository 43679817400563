import React, { FC, useEffect } from "react";
import { Card } from "react-bootstrap";
import Prism from "prismjs";
import "prismjs/components/prism-json.min";
import "prismjs/themes/prism.css";

interface Props {
    codeBlock: string;
}

export const SoftLimitViewOnly: FC<Props> = ({ codeBlock }) => {
    useEffect(() => {
        if (codeBlock) {
            Prism.highlightAll();
        }
    }, [codeBlock]);

    return (
        <Card.Body>
            <pre>
                <code className="language-json">{codeBlock}</code>
            </pre>
        </Card.Body>
    );
};
