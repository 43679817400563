import React, { FC } from "react";
import { Modal } from "react-bootstrap";
import { useQuery } from "@apollo/client";
import {
    ReleaseNotesData,
    RevisionData,
    RevisionVariables
} from "../../models/types";
import { FETCH_RELEASE_NOTES, GET_REVISION } from "../../graphql/queries";
import Markdown from "react-markdown";
import { Buffer } from "buffer";
import { useLocalState } from "../../graphql/hooks";
import "./Modals.scss";

interface Props {
    revisionId: number;
    show: boolean;
    onClose: () => void;
}

export const ReleaseNotesModal: FC<Props> = ({ revisionId, show, onClose }) => {
    const { useDarkMode } = useLocalState();
    const { data } = useQuery<ReleaseNotesData, RevisionVariables>(
        FETCH_RELEASE_NOTES,
        {
            variables: { revisionId: revisionId }
        }
    );
    const { data: { revision } = {} } = useQuery<
        RevisionData,
        RevisionVariables
    >(GET_REVISION, { variables: { revisionId: revisionId } });

    const raw = data?.getReleaseNotes?.releaseNotes;
    const markdown = raw ? Buffer.from(raw, "base64").toString("ascii") : "";

    return (
        <Modal
            size="xl"
            show={show}
            onHide={onClose}
            backdrop="static"
            keyboard={false}
            className={useDarkMode ? "modal-dark" : ""}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    Changelog for{" "}
                    <span className="text-primary">{revision?.name}</span>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-2 m-3">
                {raw ? <Markdown children={markdown} /> : "No changelog found!"}
            </Modal.Body>
        </Modal>
    );
};
